<template>
  <div class="tp-Configs">
    <ui-list :data="configs">
      <ui-list-item 
        v-for="config in configs" 
        :key="config.id">
        <!-- key -->
        <span>{{ config.key }}</span>

        <!-- value -->
        <span slot="details">{{ config.value }}</span>

        <!-- action -->
        <div slot="actions">
          <el-button 
            type="primary" 
            @click="edit(config)">Editar</el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Configs",

  computed: {
    ...mapGetters(["configs"]),
  },

  methods: {
    ...mapActions(["updateConfig"]),

    async edit(config) {
      const { value } = await this.$prompt(
        "Valor da Config.",
        "Alteração de Valor",
        {
          confirmButtonText: "Alterar",
          inputValue: config.value,
        },
      );

      this.updateConfig({ ...config, value });
    },
  },
};
</script>
