<template>
  <div
    :class="{
      'page-toolbar--push-right': $slots.right && !$slots.left
    }"
    class="page-toolbar">
    <div
      v-if="$slots.left"
      class="page-toolbar__zone page-toolbar__zone--left">
      <slot name="left" />
    </div>
    <div
      v-if="$slots.right"
      class="page-toolbar__zone page-toolbar__zone--right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageToolbar",
};
</script>

<style lang="scss">
$elementGap: 8px;

.page-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  &__zone--left > * {
    margin-right: $elementGap;
  }

  &__zone--right > * {
    margin-left: $elementGap;
  }

  &--push-right {
    justify-content: flex-end;
  }
}
</style>
