import { IMaterial } from "@/services/data/material.interface";

/**
 * Format the material name.
 *
 * @param material Material which the name must be formated.
 */
export const formatMaterialName = (material: IMaterial): string => {
  let out = material.description;

  if (material.brand.length > 0) {
    out += `, ${material.brand}`;
  }

  if (material.model.length > 0) {
    out += ` ${material.model}`;
  }

  return out;
};

// date formats patterns

/**
 * Day from month.
 *
 * Example: 29 de Maio
 */
export const DATE_DAY_FROM_MONTH = "dd 'de' MMMM";

/**
 * Date numerically formate.
 *
 * Example: 26/12/1988
 */
export const DATE_DEFAULT_NUMERIC = "dd/MM/yyyy";
