<template>
  <el-dialog
    :visible.sync="visibility"
    title="Observações para o cliente">
    <el-form label-position="top">
      <el-form-item>
        <el-input
          v-model="repairObservation"
          placeholder="Observações para o cliente"
          row="6" />
      </el-form-item>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        :loading="isLoading"
        @click="closeHandler">
        Cancelar
      </el-button>
      <el-button
        :loading="isLoading"
        type="primary"
        @click="saveRepairObservationHandler">
        Alterar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

export default {
  name: "ChangeInformationToClientModal",

  data() {
    return {
      visibility: false,
      repairObservation: "",
    };
  },

  computed: {
    ...mapGetters("repair", ["repair"]),
    ...mapState("repair", {
      isLoading: state => state.loading,
    }),
  },

  created() {
    this.repairObservation = this.repair.repairObservation;
  },

  methods: {
    show() {
      this.visibility = true;
    },

    closeHandler() {
      this.visibility = false;
      this.$emit("closed");
    },

    async saveRepairObservationHandler() {
      await this.$store.dispatch(ERepairActions.UPDATE, {
        repairObservation: this.repairObservation,
      });
      this.closeHandler();
      this.$message.success("Observações atualizadas.");
    },
  },
};
</script>

<style>
</style>
