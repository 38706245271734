
<div class="tp-notes">
  <note-form
    ref="formNote"
    @created="reloadCurrentPage"
    @updated="reloadCurrentPage"/>

  <!-- list of notes -->
  <div v-if="notes">
    <ui-list
      :creation-method="createNote"
      :loading="loading"
      :data="notes"
      allow-creation>
      <ui-list-item
        v-for="note in notes"
        :key="note.key">
        <!-- title -->
        <span class="">{{ note.title }}</span>

        <!-- description -->
        <div slot="details">
          <span class="">{{ note.content }}</span>
        </div>

        <div slot="actions">
          <!-- edit button -->
          <el-button
            type="info"
            @click="edit(note)">
            <i class="fa fa-pencil"/>
          </el-button>

          <!--delete  button -->
          <el-button
            type="danger"
            @click="deleteHandler(note)">
            <i class="fa fa-trash"/>
          </el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </div>
</div>
