
<div class="tp-technicianForm_wrap">
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="saveHandler">
        Guardar
      </el-button>
      <el-button @click="cancelHandler">Cancelar</el-button>
    </div>

    <!-- error alert -->
    <el-alert
      v-if="showError"
      type="error"
      title="Ocorreu um error no servidor."/>

    <!-- form -->
    <el-form
      ref="technician"
      :model="technician"
      :rules="rules"
      label-position="top">
      <el-row :gutter="20">
        <!-- name -->
        <el-col :span="18">
          <el-form-item
            label="Nome"
            prop="name">
            <el-input
              v-model="technician.name"
              placeholder="Nome"/>
          </el-form-item>
        </el-col>

        <!-- external -->
        <el-col :span="3">
          <el-form-item label="Externo">
            <el-switch
              v-model="technician.external"
              on-text=""
              off-text=""/>
          </el-form-item>
        </el-col>

        <!-- active -->
        <el-col :span="3">
          <el-form-item label="Activo">
            <el-switch
              v-model="technician.active"
              on-text=""
              off-text=""/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- type of remoneration -->
        <el-col :span="12">
          <el-form-item label="Tipo de Remuneração">
            <el-select
              v-model="technician.type"
              :style="{ width: '100%' }"
              placeholder="Tipo de Remuneração">
              <el-option
                label="Salário"
                value="salary"/>
              <el-option
                label="Comissão"
                value="commission"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- percentagem -->
        <el-col :span="12">
          <el-form-item label="Percentagem">
            <el-input-number
              v-model="technician.percentage"
              :style="{ width: '100%' }"
              :disabled="technician.type !== 'commission'"
              :min="1"
              :max="100"
              placeholder="percentagem"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- street -->
        <el-col :span="12">
          <el-form-item label="Rua">
            <el-input
              v-model="technician.street"
              placeholder="Rua"/>
          </el-form-item>
        </el-col>

        <!-- city -->
        <el-col :span="12">
          <el-form-item label="Cidade">
            <el-input
              v-model="technician.city"
              placeholder="Cidade"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- zip-code -->
        <el-col :span="12">
          <el-form-item
            label="Código Postal (CP)"
            prop="zipCode">
            <el-input
              v-model="technician.zipCode"
              placeholder="Código Postal"/>
          </el-form-item>
        </el-col>

        <!-- location -->
        <el-col :span="12">
          <el-form-item label="Localidade">
            <el-input
              v-model="technician.location"
              placeholder="Localidade"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- contacts -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="Telemovel"
            prop="phone">
            <el-input
              v-model="technician.phone"
              placeholder="Telemovel"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="technician.email"
              placeholder="Email"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
