
<div class="tp-login_root">
  <div class="tp-login_wrap">
    <div class="tp-login_form">
      <!-- title -->
      <h1>Login</h1>

      <!-- info text -->
      <p class="is-info">
        Para iniciar sessão, deve fazer uso das credencias fornecedidas pela TecniPhone.
      </p>
      <!-- error alert -->
      <el-alert
        v-if="errorMessage !== null"
        ref="alert"
        :title="errorMessage"
        class="tp-login_alert"
        type="error"/>

      <!-- form -->
      <el-form
        v-loading.body="loading"
        :model="data"
        label-position="top"
        class="tp-login_formContainer">
        <!-- username -->
        <el-form-item>
          <el-input
            v-model="data.email"
            placeholder="Email"/>
        </el-form-item>

        <!-- password -->
        <el-form-item>
          <el-input
            v-model="data.password"
            type="password"
            placeholder="Password"
            @keyup.enter.native="login"/>
        </el-form-item>

        <!-- actions -->
        <div class="tp-login_actions">
          <!-- login button -->
          <el-form-item>
            <el-button
              type="primary"
              @click.native.prevent="login">Login</el-button>
          </el-form-item>

        <!-- todo: convert this to a link -->
        <!-- <button v-on:click="forgot">Forgot account?</buttom> -->
        </div>
      </el-form>
    </div>
  </div>
</div>
