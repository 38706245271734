<template>
  <div class="entities-form">
    <el-form :model="form">
      <el-form-item label="Nome">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="Descrição">
        <el-input v-model="form.description" />
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="entities-form__footer">
      <el-button
        :loading="isLoading"
        @click="$emit('close')">
        Cancelar
      </el-button>
      <el-button
        :loading="isLoading"
        type="primary"
        @click="createEntityHandler">
        <template v-if="entity === null">Criar Entidade</template>
        <template v-if="entity !== null">Atualizar Entidade</template>
      </el-button>
    </span>
  </div>
</template>

<script>
import {
  ACTION_ENTITY_CREATE,
  ACTION_ENTITY_EDIT,
} from "@/services/store/modules/entities/entities-types";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EntitiesForm",

  props: {
    entity: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      form: {
        id: null,
        name: "",
        description: "",
      },
    };
  },

  computed: {
    ...mapGetters("entities", ["isLoading"]),
  },

  created() {
    if (this.entity !== null) {
      this.form = { ...this.entity };
    }
  },

  methods: {
    ...mapActions("entities", {
      createNewEntity: ACTION_ENTITY_CREATE,
      editExistingEntity: ACTION_ENTITY_EDIT,
    }),

    reset() {
      this.form = {
        id: null,
        name: "",
        description: "",
      };
    },

    async createEntity() {
      await this.createNewEntity(this.form);
      this.$message.success("Nova entidade criada.");
    },

    async editEntity() {
      await this.editExistingEntity(this.form);
      this.$message.success("Entidade atualida.");
    },

    async createEntityHandler() {
      if (this.entity === null) {
        await this.createEntity();
      } else {
        await this.editEntity();
      }

      this.$emit("close");
      this.reset();
    },
  },
};
</script>
