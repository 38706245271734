
<el-table
  :data="commissions"
  class="tp-comissionsReturnsTable"
  stripe
  style="width: 100%">
  <el-table-column label="#">
    <template slot-scope="scope">
      {{ scope.row.repair.repairNumber }}
    </template>
  </el-table-column>
  <el-table-column label="Equipamento">
    <template slot-scope="scope">
      {{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}
    </template>
  </el-table-column>
  <el-table-column label="Valor a Devolver">
    <template slot-scope="scope">
      {{ scope.row.value.toFixed(3) }}€
    </template>
  </el-table-column>
</el-table>
