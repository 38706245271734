<template>
  <section class="tp-RepairsList">
    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler" />

    <!-- list filters -------------------------------------------------------- -->
    <div class="tp-RepairsList__filters">
      <div>
        <!-- select technician -->
        <el-select
          v-model="filters.technician"
          placeholder="Técnico"
          class="tp-RepairsList__filters__technician"
          clearable>
          <el-option
            v-for="tech in technicians"
            :key="tech.id"
            :value="tech.id"
            :label="tech.name">
            <span>{{ tech.name }}</span>
          </el-option>
        </el-select>

        <!-- states -->
        <el-select
          v-model="filters.states"
          class="tp-RepairsList__filters__states"
          placeholder="Estado"
          clerable
          multiple>
          <el-option
            v-for="state in allStatesOrdered"
            :key="state.key"
            :value="state.key"
            :label="state.value">
            <span>{{ state.value }}</span>
          </el-option>
        </el-select>

        <el-date-picker
          v-model="range"
          :picker-options="pickerOptions"
          class="tp-RepairList__filters__dateSet"
          type="daterange"
          align="right"
          placeholder="Intervalo de Datas"
          @change="fetchPage"/>
      </div>

      <div class="tp-RepairsList__filters__right">
        <el-checkbox
          v-model="filters.rejectQuotation"
          class="tp-RepairsList__filters__quotation">
          Orçamento rejeitado
        </el-checkbox>

        <!-- client filter -->
        <client-selection
          ref="clientSelect"
          class="tp-RepairsList__filters__client"
          @change="clientChanged"/>

        <!-- search bar  -->
        <el-input
          v-model="searchQuery"
          class="tp-RepairsList__filters__search"
          placeholder="Reparações..."/>

        <!-- clean -->
        <el-button
          :plain="true"
          type="primary"
          size="small"
          class="tp-RepairsList__filters__clean"
          @click="cleanFilters">
          Limpar
        </el-button>

      </div>
    </div>

    <!-- client dialog -->
    <el-dialog
      :visible.sync="seeClientDialog"
      title="Informação do cliente"
      size="small">
      <client-card :client="client"/>
    </el-dialog>

    <!-- list -------------------------------------------------------------- -->
    <div class="tp-RepairsList__tableContent">
      <el-table
        v-loading="loading"
        :data="repairs"
        :row-class-name="tableRowClassName"
        stripe
        class="tp-RepairsList__table"
        style="width: 100%">
        <!-- repair number -->
        <el-table-column
          :width="70"
          label="#">
          <template slot-scope="scope">
            <div class="tp-repairList_quotation">
              <span class="tp-RepairsList-approved">
                <el-tooltip
                  v-if="scope.row.quotationApproved === true"
                  effect="dark"
                  content="Orçamento aprovado"
                  placement="top">
                  <div class="tp-RepairsList-approved__circle"/>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.quotationApproved === false"
                  effect="dark"
                  content="Orçamento recusado"
                  placement="top">
                  <div class="tp-RepairsList-rejected__circle"/>
                </el-tooltip>
                {{ scope.row.repairNumber }}
              </span>
            </div>
          </template>

        </el-table-column>

        <!-- store number -->
        <el-table-column
          :width="95"
          label="F. Loja"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.storeRepairIdentifier || '-' }}
          </template>
        </el-table-column>

        <!-- show the box where the equipment is stored -->
        <el-table-column
          :width="60"
          align="center"
          label="Cx.">
          <template slot-scope="scope">
            {{ scope.row.storeBox || '-' }}
          </template>
        </el-table-column>

        <!-- equipment brand and model -->
        <el-table-column
          :width="150"
          label="Modelo">
          <template slot-scope="scope">
            <span>{{ scope.row.clientEquipment.brand
            }} {{ scope.row.clientEquipment.model }}</span>
          </template>
        </el-table-column>

        <!-- client -->
        <el-table-column
          :width="200"
          label="Cliente">
          <template slot-scope="scope">
            <a @click="seeClient(scope.row.client)">
              <span
                v-if="scope.row.client.isStore">{{ scope.row.client.name | truncate(20)
                }}</span>
              <span v-else>{{ scope.row.client.name }}</span>
            </a>
          </template>
        </el-table-column>

        <!-- repair state -->
        <el-table-column
          :width="150"
          label="Estado">
          <template slot-scope="{ row }">
            <span>{{ row.state | repairState }}</span>
          </template>
        </el-table-column>

        <!-- problem -->
        <el-table-column
          label="Avaria"
          align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.problemName === 'Outro'">
              <span>{{ scope.row.problemDescription }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row.problemName }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :width="100"
          label="Entrada"
          align="center">
          <template slot-scope="scope">
            {{ diffedDuration(scope.row.createdAt) }}
          </template>
        </el-table-column>

        <!-- operations -->
        <el-table-column
          :width="200"
          :context="_self"
          label="Operações">
          <template slot-scope="scope">
            <!-- see repair details -->
            <el-button
              size="mini"
              type="info"
              @click="showRepairDetails(scope.row)">Ver
            </el-button>

            <!-- change technician -->
            <el-button
              :loading="loading"
              :disabled="!canChangeTechnician(scope.row)"
              size="mini"
              type="warning"
              @click="changeTechnician(scope.row)">Alterar Tecnico
            </el-button>
          </template>
        </el-table-column>

        <!-- technician -->
        <el-table-column
          :width="120"
          label="Tecnico">
          <template slot-scope="scope">
            <span v-if="scope.row.technician">{{ scope.row.technician.name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination ------------------------------------------------------ -->
      <el-pagination
        :current-page="currentPage"
        :page-size="20"
        :total="total"
        class="tp-RepairsList__pagination"
        layout="prev, pager, next"
        @current-change="currentPageChange"/>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { formatRelative } from "date-fns/esm/fp";

import { now, dateFrom } from "@/services/fp/date";

import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";
import ClientCardCmp from "../client/ClientCard.vue";
import ClientSelectionCmp from "../client/ClientSearchInput.vue";
import { ETechnicianActions } from "../../modules/technician/services/store/technician/technician.types";
import { differenceInDays } from "date-fns";

// store a timeout instance
let timer = null;

export default {
  components: {
    "client-card": ClientCardCmp,
    "client-selection": ClientSelectionCmp,
    SelectTechnicianModal,
  },

  data() {
    return {
      isSelectTechnicianVisible: false,

      // data picker
      pickerOptions: {
        shortcuts: [
          {
            text: "Hoje",
            onClick(picker) {
              const end = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
              const start = end;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ultima semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ultimo mês",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ultimos 3 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      range: [],

      // variables for the listing
      repairs: [],
      currentPage: 1,
      total: 0,

      // variables for the filter system
      filters: {
        states: [],
        technician: null,
        rejectQuotation: false,
        complaints: false,
      },
      allStates: [
        { key: "waiting_attribution", value: "Espera atribuição" },
        { key: "waiting_repair", value: "Espera reparação" },
        { key: "in_repair", value: "Em reparação" },
        {
          key: "waiting_quotation_approve",
          value: "Espera aprovação do orçamento",
        },
        { key: "waiting_material", value: "Espera material" },
        { key: "waiting_delivery", value: "Espera entrega" },
        { key: "closed_without_permission", value: "Fechado sem permissão" },
        { key: "delivered", value: "Entregue" },
        { key: "waiting_avaluation", value: "Espera avaliação" },
        { key: "in_avaluation", value: "Em avaliação" },
        { key: "contact_client", value: "Contactar cliente" },
        { key: "paused", value: "Em pausa" },
        {
          key: "waiting_complaint_avaluation",
          value: "Avaliar responsabilidade",
        },
        { key: "in_tests", value: "Em testes" },
        { key: "problematic", value: "Problemático" },
        { key: "in_transit", value: "Em transito" },
        { key: "waiting_warranty_dispatch", value: "Espera garantia" },
        { key: "in_warranty", value: "Na garantia" },
      ],

      // loading
      loading: false,

      // repair details
      selectedRepair: null,
      repairVisible: false,
      seeClientDialog: false,

      // client to fill dialog
      client: null,

      // query for searching
      searchQuery: "",
      selectedClient: "",
    };
  },

  computed: {
    allStatesOrdered() {
      return _.sortBy(this.allStates, "value");
    },

    technicians() {
      return this.$store.get("technician/technicians");
    },
  },

  watch: {
    searchQuery(newVal) {
      // create a timer to prevent multiple calls
      clearTimeout(timer);

      // when the newVal has less than two characters we load the normal page
      // if (newVal.length < 2) { return this.fetchPage(this.currentPage) }

      // otherwise we need to search for the materials
      timer = setTimeout(() => {
        this.fetchPage(1);
      }, 700);
    },

    /**
     * When any filters chnages we must reload the current fetched page.
     */
    filters: {
      deep: true,
      handler() {
        this.fetchPage(this.currentPage);
      },
    },
  },

  async created() {
    const curQuery = this.$route.query;

    // restore the previous state
    let filtersFromUrl = null;
    try {
      filtersFromUrl = JSON.parse(curQuery.filters);
    } catch (_) {
      filtersFromUrl = curQuery.filters;
    }

    // merge filtersFromUrl with default value for filters
    this.filters = _.merge(this.filters, filtersFromUrl);

    this.selectedTechnician = curQuery.technician || "";
    this.searchQuery = curQuery.query || "";
    this.selectedClient = curQuery.client || null;
    try {
      this.range = JSON.parse(curQuery.range) || [];
    } catch (_) {
      // Do nothing
    }

    // fetch the page requested on the URL or the first page
    this.currentPage = parseInt(curQuery.page, 10) || 1;
    this.fetchPage(curQuery.page || null);

    // fetch active technicians
    this.$store.dispatch(ETechnicianActions.FETCH_ALL);
  },

  methods: {
    /**
     * Generate the class row class.
     */
    tableRowClassName({ row }) {
      return row.complaint ? ` is-complaint` : "";
    },

    diffedDuration(createdAt) {
      const createdAtDate = new Date(createdAt);
      const days = differenceInDays(now(), createdAtDate);

      return days !== 0 ? `${days} dias` : dateFrom(createdAtDate);
    },

    seeClient(client) {
      this.client = client;
      this.seeClientDialog = true;
    },

    /**
     * Fetch a page of materials.
     *
     * @type {number} page
     */
    fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // update the URL query
      this.updateUrlQuery(
        {
          filters: JSON.stringify(this.filters),
          technician: this.selectedTechnician,
          query: this.searchQuery,
          client: this.selectedClient,
          range: JSON.stringify(this.range),
          rejectQuotation: this.rejectQuotation,
          page,
        },
        true,
      );

      // request the page to the API
      this.$stellar
        .action("paginateRepairs", {
          page,
          limit: 20,
          query: this.searchQuery,
          client: this.selectedClient,
          range: this.range,
          ...this.filters,
        })
        .then(response => {
          this.total = response.total;
          this.repairs = response.data;

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Change the current page for the new one and fetch
     */
    currentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchPage(newPage);

      // change the URL to store the current page
      this.updateUrlQuery({ page: newPage });
    },

    /**
     * Show the repair details.
     */
    showRepairDetails(repair) {
      this.$router.push({ name: "repair.single", params: { id: repair.id } });

      this.selectedRepair = repair;
    },

    /**
     * Check if the technician can be changed.
     */
    canChangeTechnician(repair) {
      const validStates = ["waiting_atribution", "problematic", "in_tests", "paused", "waiting_repair", "in_repair"];
      return validStates.includes(repair.state);
    },

    /**
     * Open the list of technicians.
     */
    changeTechnician(repair) {
      this.selectedRepair = repair;
      this.isSelectTechnicianVisible = true;
    },

    /**
     * Handle the change of technician.
     */
    async onSelectTechnicianHandler(technician) {
      const { repair } = await this.$stellar.action("setTechnicianForRepair", {
        id: this.selectedRepair.id,
        technician: technician.id,
      });

      this.$message.success("Tecnico alterado.");
      this.fetchPage(this.currentPage);
    },

    /**
     * Update the page query.
     */
    updateUrlQuery(newVal, replace = false) {
      let _query = newVal;

      if (!replace) {
        // get current query
        _query = Object.assign({}, this.$router.currentRoute.query);

        // just replace the passed params
        for (const index in newVal) {
          if (!newVal.hasOwnProperty(index)) {
            continue;
          }

          _query[index] = newVal[index];
        }
      }

      // replace the URL query
      this.$router.replace({ query: _query });
    },

    clientChanged(client) {
      this.selectedClient = client.id;
      this.fetchPage();
    },

    cleanFilters() {
      this.$refs.clientSelect.reset();
      this.selectedTechnician = "";
      this.selectedClient = null;
      this.range = [];
      this.filters = {
        states: [],
        technician: null,
        rejectQuotation: false,
        complaints: false,
      };
      this.searchQuery = "";
      this.fetchPage();
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-RepairsList {
    &__tableContent {
      max-width: 98%;
      margin: 0 auto;
    }

    &__table {
      margin-top: 10px;
    }

    &__pagination {
      padding-left: 0 !important;
      margin-top: 8px;
    }

    &__filters {
      display: flex;
      width: 98%;
      margin: 20px auto;

      flex-direction: row;
      justify-content: space-between;

      /** technician **/
      &__technician {
        display: inline-block;
      }

      /** states **/
      &__states {
        min-width: 250px;
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &__quotation {
        padding: 3px 10px 0 0;
      }

      &__client {
        width: 220px !important;
      }

      /** search bar **/
      &__search {
        width: 220px !important;
        display: inline-block !important;
        margin-left: 5px;
      }

      &__clean {
        margin-left: 5px !important;
      }
    }

    .el-table {
      .is-complaint:not(:hover),
      .is-complaint:not(:hover) > td {
        background: #f1a9a0 !important;
        color: #fff !important;

        transition: color 0.3s ease;
        will-change: color;

        &:hover {
          color: #1f2d3d;
        }
      }
    }
  }
}

.tp-repairList_quotation {
  position: relative;
  display: block;
}

.tp-RepairsList-approved__circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;

  background-color: #54c688;
  border-radius: 50%;
}

.tp-RepairsList-rejected__circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;

  background-color: #da1e35;
  border-radius: 50%;
}

.tp-RepairsList-approved {
  display: inline-block;
}
</style>
