<template>
  <section class="tp-ContactsForm">

    <!-- group form ---------------------------------------------------------- -->

    <group-form
      ref="groupForm"
      @created="afterGroupCreationHandler"/>

    <!-- contact form -------------------------------------------------------- -->
    <ui-bottom-sheet
      ref="form"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="save">Guardar</el-button>
        <el-button @click="cancelHandler">Cancelar</el-button>
      </div>

      <!-- content -->
      <div>
        <el-form
          ref="contact"
          :model="contact"
          :rules="rules"
          label-position="top"
          class="tp-Contacts__form">
          <el-row :gutter="10">
            <!-- name -->
            <el-col :span="12">
              <el-form-item
                label="Nome"
                prop="name">
                <el-input
                  v-model="contact.name"
                  type="text"
                  placeholder="Nome"/>
              </el-form-item>
            </el-col>

            <!-- surname -->
            <el-col :span="12">
              <el-form-item label="Sobrenome">
                <el-input
                  v-model="contact.surname"
                  type="text"
                  placeholder="Sobrenome"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <!-- cellphone -->
            <el-col :span="8">
              <el-form-item
                label="Telemovel"
                prop="cellphone">
                <el-input
                  v-model="contact.cellphone"
                  type="text"
                  placeholder="Telemovel"/>
              </el-form-item>
            </el-col>

            <!-- phone -->
            <el-col :span="8">
              <el-form-item label="Telefone">
                <el-input
                  v-model="contact.phone"
                  type="text"
                  placeholder="Telefone"/>
              </el-form-item>
            </el-col>

            <!-- email -->
            <el-col :span="8">
              <el-form-item label="Email">
                <el-input
                  v-model="contact.email"
                  type="text"
                  placeholder="Email"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <!-- address -->
            <el-col :span="12">
              <el-form-item label="Morada">
                <el-input
                  v-model="contact.address"
                  type="text"
                  placeholder="Morada"/>
              </el-form-item>
            </el-col>

            <!-- company -->
            <el-col :span="12">
              <el-form-item label="Empresa">
                <el-input
                  v-model="contact.company"
                  type="text"
                  placeholder="Empresa"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <!-- groups -->
            <el-col :span="10">
              <el-form-item
                label="Grupos"
                prop="groups">
                <el-select
                  v-model="contact.groups"
                  multiple
                  placeholder="Grupos"
                  class="tp-Contacts__groupSelect">
                  <el-option
                    v-for="item in groups"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="2">
              <el-button
                type="primary"
                class="tp-Contacts__newGroupBtn"
                @click="newGroupHandler">
                Novo
              </el-button>
            </el-col>

            <!-- url -->
            <el-col :span="12">
              <el-form-item label="Link">
                <el-input
                  v-model="contact.url"
                  type="text"
                  placeholder="Link"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <!-- notes -->
            <el-col :span="24">
              <el-form-item label="Notas">
                <el-input
                  v-model="contact.note"
                  type="textarea"
                  placeholder="Notas"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </ui-bottom-sheet>
  </section>
</template>

<script>
import gravatar from "../../filters/gravatar";

import GroupFormCmp from "./GroupForm.vue";

export default {
  components: {
    "group-form": GroupFormCmp,
  },

  props: {
    groups: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      // object with the new contact data
      contact: {
        name: "",
        surname: "",
        address: "",
        company: "",
        cellphone: "",
        phone: "",
        url: "",
        email: "",
        note: "",
        groups: [],
      },

      rules: {
        name: [
          {
            required: true,
            message: "Introduza  marca o nome",
            trigger: "blur",
          },
        ],
        cellphone: [
          {
            required: true,
            message: "Introduza  o nymero de telemovel",
            trigger: "blur",
          },
        ],
        groups: [
          {
            required: true,
            type: "array",
            min: 1,
            message: "Introduza  pelo menos um grupo",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    /**
     * Set a resource to edit.
     */
    setResource(contact) {
      this.$stellar
        .action("contacts.getContact", { id: contact.id })
        .then(({ contact: _contact }) => {
          this.contact = _contact;

          // convert group objects into ids
          this.contact.groups = this.contact.groups.map(item => item.id);
        });

      this.edit = true;
    },

    /**
     * Append a new group id to the list of groups.
     */
    addGroup(id) {
      this.contact.groups.push(id);
    },

    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    /**
     * Handle the new group button click.
     */
    newGroupHandler() {
      this.$refs.groupForm.show(true);
    },

    /**
     * Handle the group creation.
     */
    afterGroupCreationHandler(group) {
      this.groups.push(group);
      this.contact.groups.push(group.id);
    },

    save() {
      // reset the error message
      this.showError = false;

      // enable loading
      this.loading = true;

      this.$refs.contact.validate(valid => {
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.saveHandler();
        } else {
          this.editHAndler();
        }
      });
    },

    saveHandler() {
      this.loading = true;

      this.$refs.contact.validate(valid => {
        if (!valid) {
          this.loading = false;
          return false;
        }

        this.$stellar
          .action("contacts.createContact", this.contact)
          .then(response => {
            // emit an event with the created contact
            this.$emit("created", response.contact);

            // close the panel
            this.show(false);

            // show a message to the user to inform the resource creation
            this.$message.success("O contato foi criado.");
            this.loading = false;
          })
          // when we encontered a problem we must show an error message
          .catch(response => {
            this.showError = true;
          });
      });
    },

    editHAndler() {
      // make an API call to update the material information
      this.$stellar
        .action("contacts.editContact", this.contact)
        .then(response => {
          // emit an event with the resource update
          this.$emit("updated", this.contact);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource update
          this.$message({
            type: "success",
            message: "O contacto foi atualizado.",
          });
        })
        // when we encontered a problem we must show an error message
        .catch(response => {
          this.showError = true;
        });
    },

    /**
     * Hide dialog.
     */
    cancelHandler() {
      this.show(false);
    },

    show(val = true) {
      if (!val) {
        this.reset();
      }

      this.$refs.form.show(val);
    },

    /**
     * Reset the form stats.
     */
    reset() {
      // reset form data
      this.contact = {
        name: "",
        surname: "",
        address: "",
        company: "",
        cellphone: "",
        phone: "",
        url: "",
        email: "",
        note: "",
        groups: [],
      };

      // reset other states
      this.edit = false;
      this.showError = false;
      this.loading = false;
    },
  },
};
</script>
