<template>
  <section class="tp-users_wrap">
    <user-edit
      ref="editUserPanel"
      @updated="afterUpdate"
      @created="afterCreation"/>

    <ui-list
      :loading="isLoading"
      :creation-method="add"
      :data="users"
      allow-creation>
      <template slot="rightActions">
        <el-radio-group v-model="selectedFilter">
          <el-radio-button :label="null">Todos</el-radio-button>
          <el-radio-button :label="true">Activos</el-radio-button>
          <el-radio-button :label="false">Inativos</el-radio-button>
        </el-radio-group>
      </template>

      <p slot="noElements">Não existem utilizadores!</p>

      <ui-list-item
        v-for="user in orderedUsers"
        :key="user.id">
        <div slot="image">
          <img
            :src="getAvatar(user.email)"
            class="tp-users_avatar img-circle"
            alt="Avatar" >
        </div>

        <span class="email">{{ user.name }}</span>

        <!-- description -->
        <div slot="details">
          <!-- user type -->
          <span v-if="user.role">{{ user.role.name }}</span>
          <span v-else>Sem grupo</span>
        </div>

        <div slot="actions">
          <el-button
            v-show="user.active"
            type="danger"
            @click.native.prevent="disableUser(user)">Desativar</el-button>
          <el-button
            v-show="!user.active"
            type="success"
            @click.native.prevent="activateUser(user)">Ativar</el-button>

          <el-button
            v-show="user.active"
            :plain="true"
            type="info"
            alt="Editar"
            @click.native="editUser(user)">Editar</el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </section>
</template>

<script>
import _ from "lodash";
import gravatar from "../../filters/gravatar";

import UserEditCpm from "./UsersForm.vue";

export default {
  name: "UsersList",

  components: {
    "user-edit": UserEditCpm,
  },

  data() {
    return {
      users: [],
      selectedUser: null,
      userToEdit: null,
      isLoading: false,

      /**
       * Define the user filter state.
       *
       * It can be:
       * - null: that means no filter (returns all users)
       * - true: that means that only active users would be presend
       * - false: that means that only inactive users must be returned
       */
      selectedFilter: true,
    };
  },

  computed: {
    orderedUsers() {
      return _.orderBy(this.users, "name");
    },
  },

  watch: {
    selectedFilter: {
      immediate: true,
      async handler(newVal) {
        const params = {
          active: newVal,
        };

        this.isLoading = true;
        const { users } = await this.$stellar.action("auth.getUsers", params);
        this.isLoading = false;

        this.users = users;
      },
    },
  },

  methods: {
    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    /**
     * Disable an user.
     *
     * @param user  object with the user.
     */
    disableUser(user) {
      this.$confirm("Tem a certeza que pretende desativar o utilizador?", "Desativar Utilizador", {
        type: "warning",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desativar",
      })
        .then(() => {
          // make a call to the API to disable the user
          this.$stellar
            .action("auth.disableUser", { id: user.id })
            .then(() => {
              // change the user state on the list
              this.users.find(el => el.email === user.email).active = false;

              // show a message
              this.$message.success("Utilizador desativado.");
            })
            .catch(response => {
              throw response.error;
            });
        })
        .catch(() => {
          // don't do nothing!
        });
    },

    /**
     * Activate the user.
     */
    activateUser(user) {
      this.$confirm("Tem a certeza que pretende activar o utilizador?", "Activar utilizador", {
        type: "success",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Ativar",
      })
        .then(() => {
          // make a call to the API to activate the user
          this.$stellar
            .action("auth.activateUser", { id: user.id })
            .then(() => {
              // change the user state on the list
              this.users.find(el => el.email === user.email).active = true;

              // show a message
              this.$message({
                type: "success",
                message: "O utilizador foi ativado.",
              });
            })
            .catch(response => {
              throw response.error;
            });
        })
        .catch(() => {
          // don't do nothing
        });
    },

    /**
     * Show the bottom sheet to edit the user.
     *
     * The cancel and thr save action are all controlled by the UserEdit
     * component.
     *
     * @param user  User to be editted.
     */
    editUser(user) {
      const editPanel = this.$refs.editUserPanel;

      // define the user to edit
      editPanel.setUser(user);

      // show the edit panel
      editPanel.show();
    },

    add() {
      this.$refs.editUserPanel.show();
    },

    /**
     * Handler for the response update.
     *
     * After the update we need to search for the current resource, remove it
     * and add the new one.
     */
    afterUpdate(resource) {
      const index = this.users.findIndex(item => item.id === resource.id);
      this.users.splice(index, 1);
      this.users.push(resource);
    },

    /**
     * Handler for the resource creation.
     *
     * After a the insertion we need to add the new resource to the array list.
     */
    afterCreation(resource) {
      this.users.push(resource);
    },
  },
};
</script>
