<template>
  <section class="stock-requests-list">
    <div class="material-requests-list material-requests-list--client">
      <el-table
        v-loading.body="loading"
        :data="data"
        stripe>

        <!-- request number -->
        <el-table-column
          :width="100"
          label="#Pedido"
          align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.requestNumber"> {{ scope.row.requestNumber }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <!-- date of request -->
        <el-table-column label="Data">
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt"> {{ scope.row.createdAt | dateFormat('dd/MM/Y') }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- Hour of request -->
        <el-table-column label="Hora">
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt"> {{ scope.row.createdAt | dateFormat('HH:mm') }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <!-- material description -->
        <el-table-column label="Descrição">
          <template slot-scope="scope">
            {{ scope.row.material.brand }} {{ scope.row.material.model }}, {{ scope.row.material.description }}
          </template>
        </el-table-column>

        <!-- quantity -->
        <el-table-column
          :width="70"
          property="quantity"
          label="Qtn."
          align="center"/>

        <!-- box -->
        <el-table-column
          :width="80"
          label="Caixa"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.material.storeBox || '-' }}
          </template>
        </el-table-column>

        <!-- current price -->
        <el-table-column
          :width="150"
          align="center"
          label="Preço unidade">
          <template slot-scope="scope">
            {{ scope.row.price || scope.row.material.price | money }}
          </template>
        </el-table-column>

        <!-- request observation-->
        <el-table-column label="Observação">
          <template slot-scope="scope">
            <span v-if="scope.row.observation"> {{ scope.row.observation }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <!-- Actions -->
        <el-table-column
          v-if="$auth.role==='attendance' || $auth.can('edit_material_requests')"
          :width="230"
          align="right"
          label="">
          <template slot-scope="scope">
            <!-- See the repair view -->
            <el-button
              v-if="scope.row.repair"
              type="info"
              size="small"
              @click="showRepairDetails(scope.row.repair)">
              Reparação
            </el-button>

            <span v-if="scope.row.state !== 'waiting_delivery' && $auth.role!=='attendance'">
              <!-- perform a purchase -->
              <el-button
                v-if="scope.row.state !== 'waiting_delivery'"
                type="primary"
                size="small"
                @click="$emit('process-request', scope.row)">
                Comprar
              </el-button>

              <!-- cancel a request -->
              <el-tooltip
                effect="dark"
                content="Cancelar">
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="$emit('remove-request', scope.row)"/>
              </el-tooltip>
            </span>
            <span v-else>
              <!-- mark as delivered -->
              <el-button
                v-if="($auth.role=='attendance' && !scope.row.repair) ||($auth.can('edit_material_requests'))"
                type="primary"
                size="small"
                @click="$emit('deliver-material', scope.row)">
                Entregar
              </el-button>

              <!-- put the material into the stock -->
              <el-tooltip
                effect="dark"
                content="Colocar no stock">
                <el-button
                  v-if="$auth.role!=='attendance'"
                  type="warning"
                  size="small"
                  icon="el-icon-upload2"
                  @click="$emit('put-material-on-stock', scope.row)"/>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>
<script>
export default {
  name: "ClientRequestsList",

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    destinationLabel: {
      type: String,
      default: null,
    },
    hideDestination: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
