<template>
  <div class="tp-RepairView__entry">
    <h2 class="title">
      <span>Detalhes do Equipamento</span>
      <el-button v-if="repair.state !== 'delivered' && !isEditing" icon="el-icon-edit" size="mini" class="EditButton" @click="onClickEdit" />

      <!-- editing mode buttons -->
      <div v-if="isEditing">
        <el-button icon="el-icon-close" type="danger" size="mini" @click="onClickCancel" />
        <el-button icon="el-icon-check" type="primary" size="mini" @click="onClickSave" />
      </div>
    </h2>

    <div class="content">
      <el-form label-position="top">
        <!-- show the equipment's details -->
        <dynamic-attributes
          v-if="repair.clientEquipment.attributes"
          :read-only="true"
          :equipment="equipmentType"
          :value="repair.clientEquipment.attributes"
          moment="normal"
           />

        <!-- show the equipment's repair details -->
        <dynamic-attributes
          :read-only="!isEditing"
          :equipment="equipmentType"
          v-model="repairAttributes"
          moment="repair"/>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { clone } from "ramda";

import DynamicAttributes from "../EquipmentDynamicForm.vue";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

export default Vue.extend({
  name: "EquipmentDetails",

  components: { DynamicAttributes },

  props: {
    repair: {
      type: Object,
      required: true,
    },
    equipmentType: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isEditing: false,

      repairAttributes: {},
    };
  },

  watch: {
    repair: {
      immediate: true,
      deep: true,
      handler() {
        this.restoreOldData();
      },
    },
  },

  methods: {
    onClickEdit() {
      this.isEditing = true;
    },

    onClickCancel() {
      this.$confirm("Pretende descardar as alterações?", "Descartas Alterações", {
        confirmButtonText: "Descartar",
        cancelButtonText: "Cancelar",
      }).then(() => {
        this.restoreOldData();
        this.isEditing = false;
      });
    },

    onClickSave() {
      this.$confirm("Pretende guardar as alterações?", "Guardar Alterações", {
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      })
        .then(() => this.saveChanges())
        .then(() => (this.isEditing = false));
    },

    restoreOldData() {
      this.repairAttributes = clone(this.repair.attributes);
    },

    saveChanges() {
      return this.$store
        .dispatch(ERepairActions.UPDATE, {
          id: this.repair.id,
          attributes: this.repairAttributes,
        })
        .then(() => {
          this.$message.success("Atributos atualizados.");

          return this.$store.dispatch(ERepairActions.RELOAD);
        })
        .catch(() => {
          this.$message.error("Impossivel atualizar atributos.");
        });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../var.css";

.tp-RepairView__entry {
  position: relative;
  display: block;
  border-radius: 2px;
  padding: 25px 40px;
  margin: 20px auto;
  max-width: 97%;

  /* enable vertical scroll */
  overflow: hidden;
  overflow-y: scroll;

  background-color: white;
  color: #3d4a5a;
  box-shadow: 0 1px 5px #d7e4ed, 0 1px 10px rgba(215, 228, 237, 0.77);

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 1.1em;

    color: #303e49;
  }

  .content {
    width: 100%;
    display: block;
  }

  &.is-equipmentDetails {
    max-width: 97%;
    margin: 20px auto;
  }

  &.is-problem {
    span {
      display: inline-block;
    }
  }

  &.is-internalObservation {
    display: flex;
    justify-content: space-between;
    max-width: 97%;
    margin: 20px auto;
    padding: 25px 40px;

    & > div {
      width: 50%;

      &:first-child {
        width: calc(50% - 20px);
        margin-right: 20px;

        word-break: break-word;
      }
    }
  }

  &.is-material {
    max-width: 97%;
    margin: 20px auto;
    padding: 25px 40px;

    .no-items {
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 2px;

      color: var(--sidebar-color);
      background: var(--sidebar-active-color);
      border: 1px solid var(--sidebar-color);

      p {
        margin: 10px 0;
      }
    }
  }

  &.is-history {
    max-width: 97%;
    margin: 20px auto;
    padding: 25px 40px;
  }
}
</style>
