<template>
  <ui-bottom-sheet ref="editSheet" class="materialGroupForm" actions>
    <div slot="actions" class="right">
      <el-button :loading="isLoading" type="primary" @click="onClickSaveHandler">Guardar</el-button>
      <el-button :loading="isLoading" @click="onCancelHandler">Cancelar</el-button>
    </div>

    <el-form ref="form" :model="formData" :rules="rules" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Nome" prop="name">
            <el-input v-model="formData.name" placeholder="Nome" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Descrição" prop="description">
            <el-input v-model="formData.description" placeholder="Descrição" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Labels" prop="labels">
            <el-select
              v-model="formData.labels"
              class="formItemLabels"
              filterable
              multiple
              allow-create
              default-first-option
              placeholder="Labels"
            >
              <el-option
                v-for="item in existingLabels"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <MaterialGroupClientForm :entries.sync="formData.customerGroups" />
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</template>


<script lang="ts">
import Vue from "vue";
import { get } from "vuex-pathify";
import { cloneDeep } from "lodash";

import MaterialGroupClientForm from "./material-group-client-form.vue";

import { EMaterialGroupActions } from "../services/store/material-groups/material-groups.types";
import {
  generateRequiredRule,
  doFormValidation,
} from "@/utils/form-validation";
import { ELabelActions } from "../../common/services/store/labels/labels.types";
import { ILabel } from "../../common/types/label.interface";

const INITIAL_STATE = {
  formData: {
    name: "",
    description: "",
    customerGroups: [],
    labels: [],
  },
};

const FORM_RULES = {
  name: [generateRequiredRule("Introduza um nome")],
};

export default Vue.extend({
  components: { MaterialGroupClientForm },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      formData: {
        ...INITIAL_STATE.formData,
      },
      rules: FORM_RULES,
    };
  },

  computed: {
    isLoading: get("materialGroups/loading"),
    existingLabels: get("labels/labels"),
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      const sheetElement = this.$refs.editSheet as any;
      if (newVal) {
        sheetElement.show();
        this.cloneDataToForm();
      } else {
        sheetElement.show(false);
        this.reset();
      }
    },

    group(newData) {
      if (!newData) {
        return;
      }

      this.cloneDataToForm();
    },
  },

  created() {
    this.$store.dispatch(ELabelActions.FETCH_ALL);
  },

  methods: {
    cloneDataToForm() {
      this.formData = {
        ...cloneDeep(this.group),
        labels: this.group.labels.map((label: ILabel) => label.name),
      };
    },

    onCancelHandler() {
      this.$emit("update:visibility", false);
    },

    async onClickSaveHandler() {
      try {
        await doFormValidation(this.$refs.form);
      } catch (_) {
        return this.$message.error("Reveja o formulário.");
      }

      const editSheetEl = this.$refs.editSheet as any;
      const isUpdate = this.formData.hasOwnProperty("id");
      const successMessage = isUpdate ? "Group atualizado." : "Grupo criado.";

      const newRecord = await this.$store.dispatch(
        EMaterialGroupActions.CREATE_OR_UPDATE,
        this.formData,
      );

      this.$message(successMessage);
      this.$emit("update:visibility", false);
      this.$emit("created", newRecord, isUpdate);
    },

    reset() {
      this.formData = {
        ...INITIAL_STATE.formData,
      };
      (this.$refs.form as any).resetFields();
    },
  },
});
</script>


<style lang="scss" scoped>
.formItemLabels {
  display: block;
}
</style>
