
<div class="tp-typesList_wrap">
  <!-- type form ----------------------------------------------------------- -->

  <type-form ref="form"/>

  <!-- equipment list ------------------------------------------------------ -->

  <ui-list :data="resources">
    <ui-list-item
      v-for="item in orderedData"
      :key="item.id">
      <!-- name -->
      <span class="name">{{ item.name }}</span>

      <!-- actions -->
      <div slot="actions">
        <!-- edit types -->
        <el-button
          type="info"
          size="small"
          @click="editHandler(item)">
          <i class="fa fa-pencil"/>
        </el-button>

        <!-- enable and disable types -->
        <el-button
          v-show="item.active"
          type="danger"
          size="small"
          @click="disable(item)">Desativar</el-button>
        <el-button
          v-show="!item.active"
          type="success"
          size="small"
          @click="activate(item)">Ativar</el-button>
      </div>
    </ui-list-item>
  </ui-list>
</div>
