<template>
  <section class="warranty-list-to-dispatch">
    <SelectTechnicianModal
      :visibility.sync="isSelectRepairVisible"
      @selected="changeRepair" />

    <PageToolbar>
      <template slot="left">
        <el-select
          v-model="entity"
          placeholder="Entidade"
          size="small">
          <el-option
            v-for="e in entities"
            :key="e.value"
            :label="e.name"
            :value="e.id" />
        </el-select>
        <el-button
          :disabled="isGetButtonDisabled"
          type="success"
          size="small"
          @click="fetchList">
          Obter
        </el-button>
      </template>

      <template slot="right">
        <el-button
          :loading="loading"
          size="small"
          type="success"
          @click="dispatchHandler">
          Enviar
        </el-button>
      </template>
    </PageToolbar>

    <el-table
      v-if="entity !== null && requested"
      id="toPrint"
      :data="repairs"
      :loading="loading">
      <el-table-column
        :width="100"
        label="N. Ficha"
        prop="repairNumber" />

      <el-table-column
        :width="300"
        label="Equipamento">
        <template slot-scope="scope">
          {{ scope.row | formatEquipmentName }}
        </template>
      </el-table-column>

      <el-table-column
        :width="160"
        label="Imei/SN">
        <template slot-scope="scope">
          <span
            v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber)">
            {{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber
            }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Anomalia"
        prop="problemDescription" />
      <el-table-column
        :width="270"
        label="">
        <template slot-scope="scope">
          <el-button
            :loading="loading"
            type="success"
            size="mini"
            @click="onReturnRepairClick(scope.row)">
            Atribuir a Tecnico
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div
      v-else
      class="warranty-list-to-dispatch__empty-panel">
      <span>Selecine uma entidade para obter os equipamentos a enviar.</span>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ACTION_ENTITY_FETCH } from "@/services/store/modules/entities/entities-types";
import { printHTML } from "@/utils/print-html";

import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";

export default {
  components: {
    SelectTechnicianModal,
  },

  data() {
    return {
      entity: null,
      repairs: [],
      loading: false,
      repair: null,
      requested: false,
      isSelectRepairVisible: false,
      technicians: null,
      repairToEdit: null,
    };
  },

  computed: {
    ...mapGetters("entities", ["entities"]),

    ...mapGetters(["user"]),

    isGetButtonDisabled() {
      return _.isNull(this.entity);
    },
  },

  watch: {
    entity() {
      this.requested = false;
    },
  },

  created() {
    this.fetchEntities();
  },

  methods: {
    ...mapActions("entities", {
      fetchEntities: ACTION_ENTITY_FETCH,
    }),

    // fetch list
    async fetchList() {
      if (this.entity === null) {
        return this.$message.error("Deve escolher uma entidade");
      }
      this.loading = true;

      const { repairs } = await this.$stellar.action("getRepairsByEntity", {
        warrantyEntity: this.entity,
      });
      this.repairs = repairs;

      this.loading = false;
      this.requested = true;
    },

    async dispatchHandler() {
      if (this.repairs.length === 0) {
        return this.$message.error("Não tem equipamentos para enviar");
      }
      try {
        await this.$confirm(
          "Pretende continuar com esta operação?",
          "Enviar para garantia",
        );
      } catch (e) {
        return;
      }

      this.loading = true;

      await this.$stellar.action("sendRepairsToWarranty", {
        repairs: this.repairs,
        user: this.user,
      });

      this.loading = false;
      this.printElem();
      this.repairs = [];
    },

    /**
     * Move equipment back to the internal repair workflow.
     */
    async onReturnRepairClick(repair) {
      this.repairToEdit = repair;
      this.isSelectRepairVisible = true;
    },

    /**
     * Change the repair technician for the selected one.
     */
    async changeRepair(technician) {
      await this.$stellar.action("setTechnicianForRepair", {
        id: this.repairToEdit.id,
        technician: technician.id,
      });

      // reload the list of repairs
      this.fetchList();

      this.$message.success("Tecnico atribuido.");
    },

    printElem() {
      const printStyle = `
        body {
          font-family: Roboto, 'arial', sans-serif;
          font-size: 12pt;
          color: #757575 !important;

          -webkit-print-color-adjust: exact;
        }
        .tp-TechnicianCommission__name {
          font-size: 1.5em;
          margin-bottom: 10px;
        }
        .tp-TechnicianCommission__date {
          margin-bottom: 10px;
        }
        .el-table {
          overflow: hidden;
          background-color: #fff !important;
          border: 1px solid #e0e6ed !important;
          font-size: 14px;
          color: #1f2d3d;

          margin-bottom: 10px;
        }
        .el-table .cell {
          padding: 0 10px;
        }
        .el-table__body, .el-table__header {
          table-layout: fixed;
        }
        .el-table th {
          overflow: hidden;
          background-color: #EFF2F7 !important;
          text-align: left;
        }
        .el-table td, .el-table th {
          height: 40px;
          min-width: 0;
          text-overflow: ellipsis;
        }
        .el-table--striped .el-table__body tr:nth-child(2n) td {
          background: #FAFAFA !important;
        }
        .el-table .cell {
          white-space: normal;
          word-break: break-all;
          line-height: 24px;
        }
        .expand-trigger { display: none; }

        .el-table, .el-table__body, .el-table__header {
          width: 756px !important;
        }
        tr td {
          width: 100px !important;
        }
      `;

      const contents = document.getElementById("toPrint").innerHTML;
      printHTML(contents, printStyle);
    },
  },
};
</script>

<style lang="scss">
.warranty-list-to-dispatch__empty-panel {
  display: block;
  padding: 30px 10px;

  background-color: #eef1f6;
  border: 1px solid #c8cbd0;
  border-radius: 3px;

  span {
    position: relative;
    display: block;

    text-align: center;
    color: #93959b;
  }
}
</style>
