<template>
  <div class="tp-notes">
    <note-form
      ref="formNote"
      @created="reloadCurrentPage"
      @updated="reloadCurrentPage"/>

    <!-- list of notes -->
    <div v-if="notes">
      <ui-list
        :creation-method="createNote"
        :loading="loading"
        :data="notes"
        allow-creation>
        <ui-list-item
          v-for="note in notes"
          :key="note.key">
          <!-- title -->
          <span class="">{{ note.title }}</span>

          <!-- description -->
          <div slot="details">
            <span class="">{{ note.content }}</span>
          </div>

          <div slot="actions">
            <!-- edit button -->
            <el-button
              type="info"
              @click="edit(note)">
              <i class="fa fa-pencil"/>
            </el-button>

            <!--delete  button -->
            <el-button
              type="danger"
              @click="deleteHandler(note)">
              <i class="fa fa-trash"/>
            </el-button>
          </div>
        </ui-list-item>
      </ui-list>
    </div>
  </div>
</template>

<script>
import NoteFormCmp from "./noteForm.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "note-form": NoteFormCmp,
  },
  data() {
    return {
      loading: false,
      notes: [],
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  created() {
    // fetch the list
    this.fetchTheList();
  },

  methods: {
    /**
     * Fetch the list of repairs waiting for complaint avaluation.
     */
    async fetchTheList() {
      const response = await this.$stellar.action("getNotesByUser", {
        user: this.user.id,
      });

      this.notes = response.notes;
    },

    createNote() {
      const panel = this.$refs.formNote;
      panel.show();
    },

    edit(note) {
      // get the edit panel
      const panel = this.$refs.formNote;

      // set the resource to be edited
      panel.setResource(note);

      // show edit panel
      panel.show();
    },

    async deleteHandler(note) {
      try {
        await this.$confirm(
          "Pretende apagar definitivamente a nota?",
          "Apagar Nota",
          {
            confirmButtonText: "Apagar",
            type: "warning",
          },
        );
      } catch (e) {
        return;
      }

      // make an API call to update the material information
      await this.$stellar.action("removeNote", { id: note.id });

      // emit an event with the resource update
      this.reloadCurrentPage();

      // show a message to the user to inform the resource update
      this.$message.success("Nota apagada.");
    },

    reloadCurrentPage() {
      this.fetchTheList();
    },
  },
};
</script>
