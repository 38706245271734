import Vue from "vue";

const stateTranscriptions: { [key: string]: string } = {
  delivered: "Entregue ao Cliente",
  end: "Reparação finalizada",
  newState: "Novo estado",
  registration: "Entrada para Reparação",
  newTechnician: "Novo tecnico atribuido",
  quotation: "Novo orçamento",
  quotationAccepted: "Orçamento aprovado",
  canceled: "Cancelamento",
  clientResponse: "Resposta do Cliente",
  materialAvailable: "Material Disponível",
  addMaterial: "Material Adicionado",
  contactRequest: "Pedido de Contacto ao Cliente",
  creationAsComplaint: "Entrada como reclamação",
};

Vue.filter(
  "repairLogType",
  (value: string): string => {
    const key = value.replace("repair.", "");

    if (!stateTranscriptions.hasOwnProperty(key)) {
      // @ts-ignore
      // eslint-disable-next-line
      console.error(`Invalid repair log type: "${value}"`);
      return value;
    }

    return stateTranscriptions[key];
  },
);
