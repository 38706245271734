<template>
  <repair-view
    v-if="repair"
    :repair="repair"/>
</template>

<script>
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";
import { mapGetters } from "vuex";

import RepairViewCmp from "./RepairView.vue";
import { get } from "vuex-pathify";

export default {
  components: {
    "repair-view": RepairViewCmp,
  },

  computed: {
    ...mapGetters(["user"]),

    repair: get("repair/repair"),

    /**
     * Get the current repair id.
     */
    repairId() {
      return this.$store.state.route.params.id;
    },
  },

  watch: {
    repairId() {
      this.fetchRepair();
    },

    repair() {
      this.processRepair();
    },
  },

  created() {
    this.$store.dispatch(ERepairActions.FETCH, this.repairId);
  },

  methods: {
    /**
     * Process the additional repair information.
     *
     * TODO: try to move this validation to the router level.
     */
    async processRepair() {
      // check if the user has permissions to see this repair
      if (!(this.$auth.can("see_repairs") || repair.technician.id === this.user.personId)) {
        // redirect the user to root
        this.$router.push({ path: "/" });

        // show an error message
        this.$message.error("Sem autorização!");
      }
    },
  },
};
</script>
