<template>
  <section class="tp-purchases_wrap">

    <material-form
      ref="materialForm"
      @created="materialCreationHandler"
      @canceled="resetVisibility"/>

    <supplier-form
      ref="supplierForm"
      @created="resetVisibility"
      @canceled="resetVisibility"/>

    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="savePurchase">Guardar</el-button>
        <el-button @click="resetForm">Cancelar</el-button>
      </div>

      <!-- error alert -->
      <el-alert
        v-if="errorMessageVisibility"
        type="error"
        title="Ocorreu um erro no servidor."/>

      <el-form
        ref="data"
        :model="data"
        :rules="rules"
        label-position="top">
        <el-row :gutter="10">
          <!-- supplier -->
          <el-col :span="10">
            <el-form-item
              label="Fornecedor"
              prop="supplier">
              <el-select
                v-model="data.supplier"
                :remote-method="searchForSuppliers"
                :loading="search.loading"
                class="tp-Purchases__fullField"
                filterable
                remote
                placeholder="Fornecedor">
                <el-option
                  v-for="item in search.suppliers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- new supplier -->
          <el-col :span="2">
            <el-button
              class="tp-Purchases__newButton"
              size="medium"
              type="primary"
              @click="showSupplierForm">Novo
            </el-button>
          </el-col>

          <!-- material -->
          <el-col :span="10">
            <el-form-item
              label="Material"
              prop="material">
              <el-select
                v-model="data.material"
                :remote-method="searchForMaterials"
                :disabled="disableMaterial"
                :loading="search.loading"
                class="tp-Purchases__fullField"
                filterable
                remote
                placeholder="Material">
                <el-option
                  v-for="item in search.materials"
                  :key="item._id"
                  :label="item.brand + ', ' + item.model + ', ' + item.description"
                  :value="item._id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- new material -->
          <el-col :span="2">
            <el-button
              :disabled="disableMaterial"
              class="tp-Purchases__newButton"
              size="medium"
              type="primary"
              @click="showMaterialForm">Novo
            </el-button>
          </el-col>

          <!-- quantity -->
          <el-col :span="12">
            <el-form-item label="Quantidade">
              <el-input
                v-model="data.quantity"
                :min="minQuantity"
                type="number"
                placeholder="Quantidade"/>
            </el-form-item>
          </el-col>

          <!-- price -->
          <el-col :span="12">
            <el-form-item label="Preço Unitario">
              <el-input
                v-model="data.price"
                :min="0"
                type="number"
                placeholder="Preço Unitario">
                <template slot="append">€</template>
              </el-input>
            </el-form-item>
          </el-col>

          <!-- estimated delivery date -->
          <el-col :span="12">
            <el-form-item label="Origem">
              <el-select
                v-model="data.origin"
                style="width: 100%"
                placeholder="Origem">
                <el-option
                  label="Europa"
                  value="europe"/>
                <el-option
                  label="Outro"
                  value="other"/>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- link for supplier -->
          <el-col :span="12">
            <el-form-item label="Link da Compra">
              <el-input
                v-model="data.link"
                type="text"
                placeholder="Link da Compra"/>
            </el-form-item>
          </el-col>

          <!-- observations -->
          <el-col :span="24">
            <el-form-item label="Observações">
              <el-input
                v-model="data.observation"
                type="textarea"
                placeholder="Observações"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </section>
</template>

<script>
import MaterialFormCmp from "../material/MaterialForm.vue";
import SupplierFormCmp from "../suppliers/SuppliersForm.vue";

export default {
  components: {
    "material-form": MaterialFormCmp,
    "supplier-form": SupplierFormCmp,
  },

  data() {
    return {
      loading: false,
      search: {
        suppliers: [],
        materials: [],
        loading: false,
      },

      edit: false,

      data: {
        supplier: null,
        material: null,

        origin: "",

        price: 1,
        quantity: 1,
        observation: "",

        link: "",
        request: null,
      },

      errorMessageVisibility: false,
      disableMaterial: false,
      minQuantity: 1,

      rules: {
        supplier: [
          {
            required: true,
            message: "Introduza o fornecedor",
            trigger: "blur",
          },
        ],
        material: [
          { required: true, message: "Selecione o material", trigger: "blur" },
        ],
        origin: [
          { required: true, message: "Selecione uma origem", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    setMaterial(material, quantity, request) {
      this.data.request = request;
      this.disableMaterial = true;
      // push the material to the list of found material in order to show the
      // material information on the select
      this.search.materials.push(material);

      // set the selected material
      this.data.material = material.id;

      this.minQuantity = quantity;
      this.data.quantity = quantity;
    },

    resetForm() {
      this.reset();
      this.show(false);
      this.$emit("canceled");
    },

    /**
     * This will search for suppliers using a given query.
     */
    searchForSuppliers(query) {
      if (query.length < 2) {
        this.search.suppliers = [];
        return;
      }

      this.search.loading = true;

      this.$stellar.action("searchSuppliers", { query }).then(response => {
        this.search.suppliers = response.suppliers;
        this.search.loading = false;
      });
    },

    /**
     * This will search for materials using a given query.
     */
    async searchForMaterials(query) {
      if (query.length < 2) {
        this.search.materials = [];
        return;
      }

      this.search.loading = true;
      const { materials } = await this.$stellar.action("searchMaterials", {
        query,
      });
      this.search.materials = materials;
      this.search.loading = false;
    },

    show(val = true) {
      this.innerShow = val;
      this.$refs.editSheet.show(val);
    },

    /**
     * Cick handler for the save button.
     */
    savePurchase() {
      this.loading = true;
      this.errorMessageVisibility = false;

      this.$refs.data.validate(valid => {
        if (!valid) {
          this.loading = false;
          return false;
        }

        if (!this.edit) {
          this.addPurchase();
        } else {
          this.saveChangesPurchase();
        }
      });
    },

    /**
     * Save changes in a purchase.
     */
    saveChangesPurchase() {
      this.$stellar
        .action("editPurchase", this.data)
        .catch(response => {
          this.errorMessageVisibility = true;
          this.loading = false;
        })
        .then(response => {
          // emit an event with the updated user data
          this.$emit("updated", this.data);

          // close the panel and reset the form
          this.show(false);
          this.reset();

          // show a message to inform the user about the update
          this.$message.success("Compra atualizada.");
        });
    },

    /**
     * Create a new purchase.
     */
    addPurchase() {
      this.$stellar
        .action("createPurchase", this.data)
        .catch(response => {
          this.errorMessageVisibility = true;
          this.loading = false;
        })
        .then(response => {
          // emit an event with the new client object
          this.$emit("created", response.purchase);

          // close the panel and reset the form
          this.show(false);
          this.reset();

          // show a message to the user to inform the purchase creation
          this.$message.success("Compra efectuada.");
        });
    },

    /**
     * Reset the form data.
     */
    reset() {
      this.data = {
        supplier: null,
        material: null,
        origin: "",
        price: 1,
        quantity: 1,
        observation: "",
      };

      this.disableMaterial = false;
      this.minQuantity = 1;
      this.loading = false;
      this.edit = false;
    },

    setPurchase(original) {
      // create a copy of the original data
      const purchase = JSON.parse(JSON.stringify(original));
      this.data = purchase;

      // set the necessary data from the API
      this.$stellar
        .action("getMaterial", { id: purchase.material.id })
        .then(({ material }) => {
          // define the material
          this.search.materials = [material];
          this.data.material = original.material.id;

          // request for the supplier data
          return this.$stellar.action("getSupplier", {
            id: original.supplier.id,
          });
        })
        .then(({ supplier }) => {
          // define the supplier
          this.search.suppliers = [supplier];
          this.data.supplier = original.supplier.id;
        });

      // define as an edit
      this.edit = true;
    },

    /**
     * Show the material form.
     */
    showMaterialForm() {
      // get the panel
      const panel = this.$refs.materialForm;

      // set the execution mode
      panel.enableSimpleMode();

      // show the material form
      panel.show();

      // for now we need hide this manually
      this.$refs.editSheet.show(false);
    },

    /**
     * Handle the material creation.
     *
     * The material field most be set with the new created material.
     */
    materialCreationHandler(newMaterial) {
      // HACK: add the new material to the list of searched items
      this.search.materials = [newMaterial];

      // select the created material
      this.data.material = newMaterial.id;

      // reset visibility
      this.resetVisibility();
    },

    showSupplierForm() {
      this.$refs.supplierForm.show();

      // for now we need hide this manually
      this.$refs.editSheet.show(false);
    },

    resetVisibility() {
      // reset the visivility
      this.$refs.editSheet.show(true);
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-Purchases {
    &__fullField {
      display: block;
      width: 100%;
    }

    &__newButton {
      margin-top: 60px !important;
    }
  }
}
</style>
