
<div class="materialGroupClientForm">
  <el-table :data="formatedEntries">
    <el-table-column
      label="Grupo de Clientes"
      prop="customerGroup.name" />

    <el-table-column label="Margem" >
      <template slot-scope="scope">{{ scope.row.profitMargin | money }}</template>
    </el-table-column>

    <el-table-column label="Ações" align="right">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="onClickDeleteHandler(scope.$index)">
          Remover
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-form :data="formData" class="form">
    <el-row :gutter="20">
      <el-col :span="10">
        <el-select v-model="formData.customerGroup" class="customerGroupSelect">
          <el-option v-for="group in customerGroups" :key="group.id" :value="group.id" :label="group.name"/>
        </el-select>
      </el-col>

      <el-col :span="10">
        <el-input v-model="formData.profitMargin" type="number"/>
      </el-col>

      <el-col :span="4">
        <el-button type="success" @click="onAddNewEntryHandler">
          Adicionar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</div>
