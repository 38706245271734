<template>
  <div class="repair-list">
    <RepairListEntry
      v-for="entry in data"
      :key="entry.id"
      :repair="entry"/>
  </div>
</template>

<script>
import RepairListEntry from "./components/repair-list-entry.vue";

export default {
  name: "RepairList",

  components: {
    RepairListEntry,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.repair-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
