
<section class="tp-ContactsList">

  <!-- group form ---------------------------------------------------------- -->

  <group-form
    ref="groupForm"
    @created="fetchContactsList"
    @updated="fetchContactsList"/>

  <!-- contact form -------------------------------------------------------- -->

  <contact-form
    ref="contactForm"
    :groups="groups"
    @created="fetchContactsList"
    @updated="fetchContactsList"/>

  <!-- list of contacts and groups ----------------------------------------- -->

  <div class="tp-ContactList__container">
    <!-- button to add a new group -->
    <el-button
      type="success"
      @click="newGroupHandler">
      Novo Grupo
    </el-button>

    <el-card class="tp-ContactList__card">
      <el-row :gutter="0">
        <!-- groups -->
        <el-col :span="6">
          <el-menu
            :default-active="selectedGroupId"
            @select="handleGroupChange">
            <el-menu-item
              v-for="group in groups"
              :key="group.id"
              :index="group.id">
              <!-- group name -->
              <span>{{ group.name }}</span>

              <div>
                <!-- edit contact -->
                <el-button
                  circle
                  class="tp-ContactList__groupEditBtn"
                  size="small"
                  icon="el-icon-edit"
                  @click.stop="updateGroupHandler(group)"/>

                <!-- delete contact -->
                <el-button
                  circle
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  class="tp-ContactList__groupDeleteBtn"
                  @click.stop="deleteGroupHandler(group)"/>
              </div>
            </el-menu-item>
          </el-menu>
        </el-col>

        <!-- group contacts -->
        <el-col :span="18">
          <el-table
            v-loading.body="loading"
            :data="contacts"
            class="tp-ContactList__table"
            striped>
            <!-- full details -->
            <el-table-column type="expand">
              <template slot-scope="props">
                <p>Empresa: {{ props.row.company || 'N/A' }}</p>
                <p>Telefone: {{ props.row.phone || 'N/A' }}</p>
                <p v-if="props.row.address">{{ props.row.address }}</p>
                <p v-if="props.row.url"><a :url="props.row.url">Website</a></p>
                <p v-if="props.row.email">Email: <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a></p>
                <p>Notas: {{ props.row.note || 'N/A' }}</p>
              </template>
            </el-table-column>

            <!-- gravatar -->
            <el-table-column
              label=""
              width="70">
              <template slot-scope="props">
                <img
                  :src="getAvatar(props.row.email)"
                  class="tp-ContactList__avatar img-circle"
                  alt="Avatar" >
              </template>
            </el-table-column>

            <!-- name -->
            <el-table-column label="Nome">
              <template slot-scope="props">
                {{ props.row.name }} {{ props.row.surname }}
              </template>
            </el-table-column>
            <el-table-column
              label="Telemóvel"
              prop="cellphone"/>

            <el-table-column :width="150">
              <template slot-scope="props">
                <!-- edit button -->
                <el-button
                  :plain="true"
                  size="small"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editContactHandler(props.row)"/>

                <!-- delete button -->
                <el-button
                  :plain="true"
                  size="small"
                  type="danger"
                  icon="el-icon-delete"
                  @click="deleteContactHandler(props.row)"/>
              </template>
            </el-table-column>
          </el-table>

          <!-- button to add a new contact -->
          <div
            class="tp-ContactList__newBtn"
            @click="newContactHandler">
            <i class="fa fa-plus"/>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</section>
