
<section class="tp-MaterialRequestForm">
  <el-dialog
    ref="dailyRepairList"
    :visible.sync="requestVisibility"
    title="Pedido"
    size="large">
    <!-- actions -->
    <div class="right">
      <el-button @click="requestVisibility=false">Cancelar</el-button>
      <el-button @click="printElem">Imprimir</el-button>
    </div>

    <!-- table that will be used to print -->
    <div id="toPrint">
      <span v-if="requestClient">Cliente: {{ requestClient.name }} ({{ requestClient.phone }})</span>

      <div
        v-if="clientRequests && clientRequests.length>0"
        class="tp-MaterialRequestForm-toPrint">
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Material:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].name }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Quantidade:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].quantity }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Preço unitário:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].price | money }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Sinal:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].guarantee | money }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Valor a pagar:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].quotation | money }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Urgencia:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].urgency }}</span>
        </div>
        <div class="tp-MaterialRequestForm-toPrint__entry">
          <span class="tp-MaterialRequestForm-toPrint__entry-title">Observação:</span>
          <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].observation }}</span>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- edit form for material ---------------------------------------------- -->
  <material-form
    ref="materialForm"
    @created="afterMaterialCreation"
    @canceled="resetVisibility" />

  <!-- form to create a new client -->
  <client-form
    ref="clientPanel"
    @created="afterClientCreation"
    @canceled="resetVisibility" />

  <ui-bottom-sheet
    ref="panel"
    actions>
    <div
      slot="actions"
      class="right">
      <!-- add the material to the request -->
      <el-button
        v-if="!isClient"
        :loading="loading"
        type="primary"
        @click="addToRequestHandler">
        Adicionar
      </el-button>

      <!-- create a new material request -->
      <el-button
        :loading="loading"
        type="success"
        @click="processRequestHandler">
        Requisitar
      </el-button>

      <!-- cancel button -->
      <el-button @click="show(false)">Cancelar</el-button>
    </div>

    <!-- material list ----------------------------------------------------- -->
    <el-table
      v-if="!isClient && requestsToMade.length > 0"
      v-loading.body="loading"
      :data="requestsToMade"
      class="tp-MaterialRequest__table"
      stripe>
      <el-table-column
        :width="170"
        label="Quantidade"
        prop="quantity" />
      <el-table-column
        label="Material"
        prop="name" />
      <el-table-column :width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="warning"
            @click="returnMaterialHandler(scope.row)">
            Remover
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-form
      ref="data"
      :model="data"
      :rules="rules"
      label-position="top" >
      <el-row :gutter="10">
        <!--material -->
        <el-col :span="10">
          <el-form-item
            label="Material"
            prop="material">
            <el-autocomplete
              v-model="tempMaterial"
              :style="{ width: '100%' }"
              :fetch-suggestions="materialRemoteMethod"
              :trigger-on-focus="false"
              placeholder="Material"
              custom-item="material-item"
              @select="materialChangeHandler" />
          </el-form-item>
        </el-col>

        <!-- new material -->
        <el-col :span="2">
          <el-button
            class="tp-MaterialRequest__newMaterial"
            type="primary"
            @click="showMaterialForm">
            Novo
          </el-button>
        </el-col>

        <!-- Client -->
        <!-- <el-col :span="10" v-if="!$auth.is('technician')">      retirado para o tecnico joão ter acessoa ao campo cliente-->
        <el-col :span="10">
          <el-form-item
            label="Cliente"
            prop="client">
            <el-select
              v-model="data.client"
              :disabled="!isClient"
              :remote-method="userRemoveMethod"
              :loading="search.loading"
              label="Cliente"
              filterable
              remote
              class="tp-MaterialRequest__fullFill"
              placeholder="Cliente"
              @change="getClient(search.clients)">
              <el-option
                v-for="client in search.clients"
                :key="client.id"
                :label="client.name + ' (' + client.phone + ')'"
                :value="client.id" />
            </el-select>
          </el-form-item>
        </el-col>


        <!-- button to create a new client -->
        <el-col :span="2">
          <el-button
            class="tp-MaterialRequest__newMaterial"
            type="primary"
            @click="showClientCreationForm">
            Novo
          </el-button>
        </el-col>

        <!-- urgency -->
        <el-col
          v-if="!$auth.is('technician')"
          :span="12">
          <el-form-item label="Urgencia">
            <el-select
              v-model="data.urgency"
              :style="{ width: '100%' }"
              placeholder="Urgencia"
              class="tp-MaterialRequest__urgency">
              <el-option
                v-for="type in options"
                :key="type.value"
                :label="type.label"
                :value="type.value" />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- quantity -->
        <el-col :span="12">
          <el-form-item
            label="Quantidade">
            <el-input-number
              v-model="data.quantity"
              :min="1"
              :change="totalQuotation"
              class="tp-MaterialRequest__quantity"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- observation -->
      <el-form-item label="Observação">
        <el-input
          v-model="data.observation"
          type="textarea"
          placeholder="observação" />
      </el-form-item>

      <el-row :gutter="10">
        <div v-if="isClient">

          <!-- Guarantee -->
          <el-col :span="8">
            <el-form-item
              label="Preço unitário">
              <el-input
                v-model="data.price"
                type="number"
                class="tp-MaterialRequest__guarantee"/>
            </el-form-item>
          </el-col>

          <!-- Guarantee -->
          <el-col :span="8">
            <el-form-item
              label="Valor de Entrada (sinal)">
              <el-input
                v-model="data.guarantee"
                class="tp-MaterialRequest__guarantee" />
            </el-form-item>
          </el-col>


          <!-- Quotation -->
          <el-col :span="8">
            <el-form-item
              label="Valor a Pagar">
              <el-input
                v-model="data.quotation"
                value="data.quotation"
                class="tp-MaterialRequest__quotation"
                readonly/>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</section>
