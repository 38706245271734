<template>
  <div id="tp-suppliers_wrap">
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="saveHandler">Guardar</el-button>
        <el-button @click="cancelHandler">Cancelar</el-button>
      </div>

      <!-- error alert -->
      <el-alert
        v-if="showError"
        type="error"
        title="Existem erros no formulario."/>

      <!-- form -->
      <el-form
        ref="resource"
        :model="resource"
        :rules="rules"
        label-position="top">
        <el-row :gutter="20">
          <!-- name -->
          <el-col :span="15">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="resource.name"
                placeholder="Nome"/>
            </el-form-item>
          </el-col>

          <!-- tax-number -->
          <el-col :span="9">
            <el-form-item label="NIF">
              <el-input
                v-model="resource.taxNumber"
                placeholder="NIF"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- descrição -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descrição">
              <el-input
                v-model="resource.description"
                type="textarea"
                placeholder="Descrição"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- contacts -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Telemovel">
              <el-input
                v-model="resource.phone"
                placeholder="Telemovel"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email">
              <el-input
                v-model="resource.email"
                placeholder="Email"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- street -->
          <el-col :span="12">
            <el-form-item label="Rua">
              <el-input
                v-model="resource.street"
                placeholder="Rua"/>
            </el-form-item>
          </el-col>

          <!-- city -->
          <el-col :span="12">
            <el-form-item label="Cidade">
              <el-input
                v-model="resource.city"
                placeholder="Cidade"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- zip-code -->
          <el-col :span="8">
            <el-form-item label="Código Postal (CP)">
              <el-input
                v-model="resource.zipCode"
                placeholder="Código Postal"/>
            </el-form-item>
          </el-col>

          <!-- location -->
          <el-col :span="8">
            <el-form-item label="Localidade">
              <el-input
                v-model="resource.location"
                placeholder="Localidade"/>
            </el-form-item>
          </el-col>

          <!-- country -->
          <el-col :span="8">
            <el-form-item label="Pais">
              <el-input
                v-model="resource.country"
                placeholder="Pais"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      resource: {
        name: "",
        nif: "",
        description: "",
        street: "",
        location: "",
        zipCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "O nome do fornecedor é de preenchimento obrigatório",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Verifique o formato do email inserido",
            trigger: "blur",
          },
        ],
      },

      edit: false,
      showError: false,
    };
  },
  methods: {
    /**
     * Set a supplier for edit.
     */
    setResource(resource) {
      // fill the form with the supplier date
      this.resource = JSON.parse(JSON.stringify(resource));

      // put the form in edit mode
      this.edit = true;
    },

    /**
     * Chnage the component visivility.
     */
    show(val = true) {
      this.$refs.editSheet.show(val);

      // when we hide the form we must reset it
      if (!val) {
        this.reset();
      }
    },

    /**
     * Reset form data.
     */
    reset() {
      // reset some misellenious fields
      this.loading = false;
      this.edit = false;

      // reset form field
      this.$refs.resource.resetFields();

      // reset form data
      this.resource = {
        name: "",
        nif: "",
        description: "",
        street: "",
        location: "",
        zipCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
      };
    },

    /**
     * Save the resource.
     *
     * If the form is in edit mode we save the changes, otherwise we create a
     * new entry.
     */
    saveHandler() {
      // put it in loading
      this.loading = true;

      // reset error visivility
      this.showError = false;

      // validate the form and save/create the resource
      this.$refs.resource.validate(valid => {
        // we must return if the data form is invalid
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.createResource();
        } else {
          this.editResource();
        }
      });
    },

    /**
     * Create a new supplier.
     */
    createResource() {
      // call the creation action
      this.$stellar
        .action("createSupplier", this.resource)
        .catch(response => {
          this.showError = true;
        })
        .then(response => {
          // emit a 'created' event with the new resource
          this.$emit("created", this.resource);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource creation
          this.$message({
            type: "success",
            message: "O fornecedor foi criado.",
          });
        });
    },

    /**
     * Save the changes on the resource.
     */
    editResource() {
      const newSuplierDate = {
        ...this.resource,
        id: this.resource.id || this.resource._id,
      };

      // call the API action to save the resource changes
      this.$stellar
        .action("editSupplier", newSuplierDate)
        .catch(response => {
          this.showError = true;
        })
        .then(response => {
          // emit an 'updated' event with the resource update
          this.$emit("updated", newSuplierDate);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource update
          this.$message({
            type: "success",
            message: "O fornecedor foi atualizado.",
          });
        });
    },

    /**
     * Cancel the current action.
     */
    cancelHandler() {
      // hide the form
      this.show(false);

      // emit the 'canceled' event
      this.$emit("canceled");
    },
  },
};
</script>
