<template>
  <div class="tp-event_wrap">
    <div class="form">
      <p id="tittle">Adicionar Evento</p>
      <p>Evento: <input
        class="form-control"
        placeholder="titulo" ></p>
      <p>Descrição: <input
        class="form-control"
        placeholder="descrição"></p>
      <p>Date: <input
        type="date"
        class="form-control"
        placeholder="data"></p>
      Periodicidade
      <select v-model="selectedP" >
        <option value="daily">Díariamente</option>
        <option value="weekly">Semanalmente</option>
        <option value="monthly">Mensalmente</option>
        <option value="yearly">Anualmente</option>
        <option value="date">Data</option>
      </select>
      <p v-if="selectedP === 'date'">Date: <input
        type="date"
        class="form-control"
        placeholder="data"></p>
      Anulàvel:
      <select
        v-model="selectedA"
        type="boolean">
        <option>verdadeiro</option>
        <option>falso</option>
      </select>
      Bloqueado:
      <select
        v-model="selectedB"
        type="boolean">
        <option>verdadeiro</option>
        <option>falso</option>
      </select>
      <p>Dono: <input
        class="form-control"
        placeholder="dono"></p>
    </div>
    <div class="button">
      <button
        class="btn btn-primary"
        @click="addEvent">Adicionar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Event",

  data() {
    return {
      selectedP: "daily",
      selectedA: "verdadeiro",
      selectedB: "falso",
    };
  },
  methods: {
    addEvent() {
      // TODO: implement this
    },
  },
};
</script>
