
<div class="entities-form">
  <el-form :model="form">
    <el-form-item label="Nome">
      <el-input v-model="form.name" />
    </el-form-item>

    <el-form-item label="Descrição">
      <el-input v-model="form.description" />
    </el-form-item>
  </el-form>

  <span
    slot="footer"
    class="entities-form__footer">
    <el-button
      :loading="isLoading"
      @click="$emit('close')">
      Cancelar
    </el-button>
    <el-button
      :loading="isLoading"
      type="primary"
      @click="createEntityHandler">
      <template v-if="entity === null">Criar Entidade</template>
      <template v-if="entity !== null">Atualizar Entidade</template>
    </el-button>
  </span>
</div>
