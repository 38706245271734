
<section class="tp-ongoing__table">
  <el-table
    v-loading="loading"
    :data="innerData"
    stripe>
    <!-- store repair identifier -->
    <el-table-column
      :width="75"
      label="#Loja">
      <template slot-scope="scope">
        <span v-if="scope.row.storeRepairIdentifier"> {{ scope.row.storeRepairIdentifier }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- repair number -->
    <el-table-column
      :width="90"
      label="#Rep">
      <template slot-scope="scope">
        <span v-if="scope.row.repairNumber"> {{ scope.row.repairNumber }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- Date -->
    <el-table-column
      :width="115"
      label="DataEntrada">
      <template slot-scope="scope">
        <span v-if="scope.row.createdAt"> {{ scope.row.createdAt | dateFormat('dd/MM/yyyy') }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- equipment -->
    <el-table-column
      :width="160"
      label="Equipamento">
      <template slot-scope="scope">
        <span v-if="scope.row.clientEquipment">
          {{ scope.row.clientEquipment | formatEquipmentName }}
        </span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- IMEI -->
    <el-table-column
      :width="160"
      label="IMEI/SN">
      <template slot-scope="scope">
        <span
          v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber)">
          {{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber }}
        </span>

      </template>
    </el-table-column>

    <!-- Problem -->
    <el-table-column label="Problema">
      <template slot-scope="scope">
        <span v-if="scope.row.problemDescription">{{ scope.row.problemDescription }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- quotation -->
    <el-table-column
      :width="200"
      label="Orçamento">
      <template slot-scope="scope">
        <span v-if="!repairTime(scope.row).length > 0">-</span>
        <span
          v-if="scope.row.quotation && (!scope.row.technician || !scope.row.technician.external)">{{ scope.row.quotation | money
        }} - {{ repairTime(scope.row) }} </span>
        <span v-if="scope.row.value">{{ scope.row.value | money }} - {{ repairTime(scope.row) }} </span>
      </template>
    </el-table-column>

    <!-- Prevision date  -->
    <el-table-column
      :width="115"
      label="Previsão">
      <template slot-scope="scope">
        <span v-if="scope.row.materials.length >= 0">{{ previsionDates[scope.row.id] || 'A carregar...' }} </span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- quotation message -->
    <el-table-column
      :width="200"
      label="Estado">
      <template slot-scope="scope">
        <span v-if="scope.row.state==='waiting_delivery'">{{ (scope.row.repairObservation) }}</span>
        <span v-if="scope.row.quotationApproved">Aprovado</span>

        <div v-if="scope.row.quotationApproved === false && scope.row.quotation > 0 && !canShowApproveQuotation(scope.row)">
          <span>Orçamento rejeitado</span>
        </div>
        <div v-else>
          <span v-if="isInRepair(scope.row)">Em reparação</span>
        </div>

        <div v-if="canShowApproveQuotation(scope.row)">
          <span>Espera aprovar orçamento </span>
          <el-button
            type="success"
            size="small"
            @click="approveQuotationBtnHandler(scope.row)">
            Aprovar
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click="rejectQuotationHandler(scope.row)">
            Recusar
          </el-button>
        </div>
        <span v-if="scope.row.state==='waiting_material'">Espera material</span>
        <span v-if="scope.row.state==='in_tests'">Em testes</span>

      </template>
    </el-table-column>
  </el-table>
</section>
