import { GetterTree } from "vuex";
import { IUsersState, EUsersGetters } from "./users.types";
import { IStoreRootState } from "../../types/store-root-state.interface";
import { getBaseName } from "../../utils/get-base-name";

export const usersGetters: GetterTree<IUsersState, IStoreRootState> = {
  [getBaseName(EUsersGetters.GET_USER)](state) {
    return (usersId: string) => state.users[usersId];
  },
};
