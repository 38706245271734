<template>
  <el-dialog
    v-loading.fullscreen="isLoading"
    :visible.sync="visibility"
    class="change-brand-and-model-modal"
    title="Editar Marca/Modelo">
    <el-form label-position="top">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Modelo">
            <el-select
              v-model="tempBrand"
              v-loading.fullscreen="isLoading"
              :remote-method="equipmentRemoteMethod"
              class="change-brand-and-model-modal__model"
              placeholder="Modelo"
              filterable
              allow-create
              remote
              @change="modelChange">
              <el-option
                v-for="equip in equipments"
                :key="equip.id"
                :label="equip.model"
                :value="equip.id">
                <span>{{ equip.brand + ' ' + equip.model }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="Marca">
            <el-input
              v-model="selectedEquipment.brand"
              class="change-brand-and-model-modal__brand"
              type="text"
              placeholder="Marca"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        v-loading="isLoading"
        @click="closeHandler">
        Cancelar
      </el-button>
      <el-button
        v-loading="isLoading"
        type="primary"
        @click="updateBrandModelHandler">
        Alterar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { get } from "vuex-pathify";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

let searchEquipmentTimer = null;

export default {
  name: "ChangeBrandAndModelModal",

  data() {
    return {
      // list of equipment to chose
      equipments: [],
      visibility: false,
      brand: "",
      model: "",
      tempBrand: null,
      // search data
      search: {
        loading: false,
      },
      selectedEquipment: {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      },
    };
  },

  computed: {
    ...mapGetters("repair", ["repair"]),
    ...mapState("repair", {
      isLoading: state => state.loading,
    }),
    equipment: get("equipment/equipment"),
  },

  watch: {
    equipmentType(newVal) {
      if (newVal.name === "Telemóvel") {
        this.selectedEquipment.attributes.imei = this.tempIdentifier;
      } else {
        this.selectedEquipment.serialNumber = this.tempIdentifier;
      }
    },

    equipment: {
      immediate: true,
      handler(newEquipment, oldEquipment) {
        // ignore when the new value is the same as the old one
        if (newEquipment === oldEquipment) {
          return;
        }

        this.copyData(newEquipment);
      },
    },
  },

  methods: {
    /**
     * Copy equipment data from the prop into the data.
     */
    copyData(newEquipment) {
      this.selectedEquipment = JSON.parse(JSON.stringify(newEquipment));
      this.tempBrand = newEquipment.model;
    },

    show() {
      this.copyData(this.equipment);
      this.visibility = true;
    },

    equipmentRemoteMethod(query = "") {
      // clean the previous query
      clearTimeout(searchEquipmentTimer);

      if (query.length < 3) {
        this.equipments = [];
        return;
      }

      searchEquipmentTimer = setTimeout(async () => {
        // enter in loading mode
        this.search.loading = true;

        // make an API call in order to search for the equipments
        const { equipments } = await this.$stellar.action("searchEquipment", {
          query,
        });

        // disable loading
        this.search.loading = false;

        // save the found equipments
        this.equipments = equipments;
      }, 600);
    },

    modelChange(value) {
      // try get the equipment data from the list of found equipments
      const serverItem = this.equipments.find(equipment => equipment.id === value);

      if (serverItem) {
        this.selectedEquipment.brand = serverItem.brand;
        this.selectedEquipment.model = serverItem.model;
        return;
      }

      // mark the equipment as a new equipment
      this.selectedEquipment.id = "new";
      this.tempBrand = value;

      // update the model
      this.selectedEquipment = {
        model: value,
        brand: "",
      };

      // push the selectedEquipment to the array of fond equipments
      this.equipments.push(this.selectedEquipment);
    },

    reloadRepair() {
      this.$store.dispatch(ERepairActions.RELOAD);
    },

    closeHandler() {
      this.visibility = false;
    },

    /**
     * Update the Brand and Model.
     */
    async updateBrandModelHandler() {
      // when the ID value is "new", we need to create a new equipment before updating the client equipment
      if (this.selectedEquipment.id === "new") {
        await this.$stellar.action("createEquipment", {
          brand: this.selectedEquipment.brand,
          model: this.selectedEquipment.model,
        });
      }

      // update the brand a model for the current selected repair
      await this.$stellar.action("editClientequipment", {
        id: this.repair.clientEquipment,
        brand: this.selectedEquipment.brand,
        model: this.selectedEquipment.model,
      });

      // reload repair to show the latest changes and close this dialog
      this.$emit("updated");
      this.reloadRepair();
      this.closeHandler();

      this.$message.success("Modelo e marca atualizados.");
    },
  },
};
</script>

<style lang="scss" scoped>
.change-brand-and-model-modal {
  &::v-deep .el-dialog {
    width: 30%;
    max-width: 500px;
  }

  &__model {
    display: block;
    width: 100%;
  }
}
</style>
