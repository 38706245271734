
<section class="tp-History">
  <el-table
    v-loading="loading"
    :data="repairs"
    stripe
    class="tp-History__table">
    <!-- store repair identifier -->
    <el-table-column
      :width="75"
      label="#Loja">
      <template slot-scope="scope">
        <span
          v-if="scope.row.storeRepairIdentifier"> {{ scope.row.storeRepairIdentifier }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- repair number -->
    <el-table-column
      :width="90"
      label="#Rep">
      <template slot-scope="scope">
        <span v-if="scope.row.repairNumber"> {{ scope.row.repairNumber
        }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- Date -->
    <el-table-column
      :width="115"
      label="Entrada">
      <template slot-scope="scope">
        <span
          v-if="scope.row.updatedAt">
          {{ scope.row.updatedAt | dateFormat('yyyy/MM/dd') }}
        </span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- equipment -->
    <el-table-column
      :width="160"
      label="Equipamento">
      <template slot-scope="scope">
        <span
          v-if="scope.row.clientEquipment">{{ scope.row.clientEquipment.brand
          }} {{ scope.row.clientEquipment.model }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- IMEI -->
    <el-table-column
      :width="160"
      label="IMEI/SN">
      <template slot-scope="scope">
        <span
          v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.attributes.serialNumber)">{{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.attributes.serialNumber
          }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- Problem -->
    <el-table-column label="Problema">
      <template slot-scope="scope">
        <span
          v-if="scope.row.problemDescription">{{ scope.row.problemDescription
          }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- observation message -->
    <el-table-column
      :width="200"
      label="Intervenção">
      <template slot-scope="scope">
        <span
          v-if="scope.row.state==='delivered'">{{ scope.row.repairObservation
          }}</span>
        <span v-else>Orçamento aprovado</span>
      </template>
    </el-table-column>

    <!-- actions -->
    <el-table-column
      :width="180"
      label="Estado">
      <template slot-scope="scope">
        <span v-if="scope.row.fixed === false">Sem reparação</span>
        <span v-else>Reparado</span>
      </template>
    </el-table-column>

    <!-- price -->
    <el-table-column
      :width="200"
      label="Preço">
      <template slot-scope="scope">
        <span v-if="scope.row.fixed === false">
          -
        </span>
        <span v-else>{{ scope.row.value | money }}</span>
      </template>
    </el-table-column>
  </el-table>

  <!-- pagination ------------------------------------------------------ -->
  <el-pagination
    :current-page="curPage"
    :total="total"
    :page-size="20"
    class="tp-History__pagination"
    layout="prev, pager, next"
    @current-change="fetchPage"/>
</section>
