
<section class="tp-clientList_wrap">
  <client-form
    ref="clientForm"
    @updated="fetchPage"
    @created="afterClientCreation"/>

  <send-message ref="sendMessage"/>

  <ui-list
    ref="list"
    :data="clients"
    :total="total"
    :creation-method="addClient"
    :loading="loading"
    :remote-search="searchClientsHandler"
    :per-page="customersPerPage"
    allow-creation
    paginate
    searchable
    @current-changed="fetchPage"
    @mode-changed="modeChangeHandler">
    <template slot="actions" class="extra-actions">
      <el-button type="info" @click="onClickCustomerGroupsHandler">Grupos</el-button>
    </template>
    <ui-list-item
      v-for="client in orderedClients"
      :key="client.id">
      <!-- avatar -->
      <div slot="image">
        <img
          :src="getAvatar(client.email)"
          class="tp-clientList_avatar img-circle"
          alt="Avatar" >
      </div>

      <!-- name -->
      <span class="name">{{ client.name }}</span>

      <!-- email -->
      <span slot="details">{{ client.email || client.phone }}</span>

      <!-- actions -->
      <div slot="actions">
        <el-button
          :plain="true"
          type="info"
          @click="sendMessage(client)">Contactar</el-button>
        <el-button
          type="info"
          @click.native="editClient(client)">Editar</el-button>
      </div>
    </ui-list-item>
  </ui-list>

</section>
