<template>
  <section class="tp-ProblemDescription">
    <el-dialog
      v-loading.fullscreen="problemDescriptionForm.loading"
      :visible.sync="problemDescriptionForm.visibility"
      title="Descrição do Problema">
      <el-form label-position="top">
        <el-form-item>
          <el-input
            v-model="problemDescriptionForm.value"
            placeholder="Descrição do Problema"
            type="textarea"
            row="6"/>
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="problemDescriptionForm.visibility = false">Cancelar</el-button>
        <el-button
          type="primary"
          @click="saveProblemDescriptionHandler">Guardar</el-button>
      </span>
    </el-dialog>

    <!-- problem -->
    <div class="tp-ProblemDescription__entry">
      <h2 class="tp-ProblemDescription__entry__title">
        <span>Problema</span>
      </h2>
      <p class="tp-ProblemDescription__entry__value is-problem">
        <span v-if="repair.problemName">
          <img
            :src="problemImageUrl"
            alt="Problema">

          {{ repair.problemName }}
        </span>
        <span v-else>-</span>
      </p>
    </div>

    <!-- problem -->
    <div class="tp-ProblemDescription__entry">
      <h2 class="tp-ProblemDescription__entry__title">
        <span>Descrição do Problema</span>

        <el-button
          v-if="repair.state !== 'delivered'"
          icon="edit"
          size="mini"
          class="tp-ProblemDescription__entry__title__editBtn"
          @click="problemDescriptionHandler"/>
      </h2>
      <p class="tp-ProblemDescription__entry__value">
        <span v-if="repair.problemDescription">
          {{ repair.problemDescription }}
        </span>
        <span v-else>-</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    repair: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      // observation for edit
      problemDescriptionForm: {
        visibility: false,
        value: "",
        loading: false,
      },
    };
  },

  computed: {
    problemImageUrl() {
      return `${import.meta.env.VITE_API_URL}/problems/${
        this.repair.attributes.problem
      }.jpg`;
    },
  },

  methods: {
    /**
     * ProblemDescriptionHandler
     * @type {[type]}
     */
    problemDescriptionHandler() {
      // show dialog
      this.problemDescriptionForm.visibility = true;

      // set information to edit
      this.problemDescriptionForm.value = this.repair.problemDescription;
    },

    /**
     * Save the internal observations changes.
     */
    async saveProblemDescriptionHandler() {
      // enable loading
      this.problemDescriptionForm.loading = true;

      // make an API call
      await this.$stellar.action("editRepair", {
        id: this.repair.id,
        problemDescription: this.problemDescriptionForm.value,
      });

      // disable loading
      this.problemDescriptionForm.loading = false;

      // update the date
      this.repair.problemDescription = this.problemDescriptionForm.value;
      this.$emit("update:repair", this.repair);

      // close the dialog
      this.problemDescriptionForm.visibility = false;

      // show a success message
      this.$message.success("Observações atualizadas.");
    },
  },
};
</script>

<style lang="scss">
.tp-ProblemDescription {
  max-width: 97%;
  margin: 20px auto;
  padding: 25px 40px;

  display: flex;
  justify-content: space-between;

  &__entry {
    width: 50%;

    &:first-child {
      width: calc(50% - 20px);
      margin-right: 20px;
    }

    &__title {
      font-size: 1.1em;
      margin-bottom: 20px;
      display: block;

      &__editBtn {
        border: none !important;
        margin-left: 5px !important;
      }
    }
  }
}

.tp-ProblemDescription__entry__value.is-problem {
  img {
    position: relative;
    display: inline-block;
    width: 30px;
    max-width: 30px;
    margin-left: -5px;
  }
}
</style>
