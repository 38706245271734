import Vue from "vue";
import { formatDistance } from "date-fns";

/**
 * Filter to format the given date directly on Vue templates.
 */
Vue.filter("dateDistanceFrom", (value: Date | string, targetDate: Date = new Date()) => {
  const dateToFormat = typeof value === "string" ? new Date(value) : value;
  return formatDistance(dateToFormat, targetDate);
});
