import { IBaseState } from "@/services/store/types/base-state.interface";
import { ITechnician } from "../../../types/technician.interface";

export interface ITechnicianStore extends IBaseState {
  technicians: Array<ITechnician>;
  selected: ITechnician | null;
}

export enum ETechnicianActions {
  CLEAN = "technician/clean",
  FETCH_ALL = "technician/fetch",
  FETCH_SINGLE = "technician/fetchSingle",
}

export enum ETechnicianMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_TECHNICIANS = "SET_TECHNICIANS",
  SET_SELECTED = "SET_SELECTED",
}
