
<section class="tp-materialRequest_wrap">
  <!-- edit form for material ---------------------------------------------- -->

  <material-form
    ref="materialForm"
    @created="afterMaterialCreation"
    @canceled="resetVisibility"/>

  <!-- material sheet ------------------------------------------------------ -->

  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="addToRequestHandler">
        Adicionar
      </el-button>
      <el-button
        :loading="loading"
        type="success"
        @click="processRequestHandler">
        Finalizar
      </el-button>
      <el-button @click="show(false)">Cancelar</el-button>
    </div>

    <!-- list of materials to request -->
    <el-table
      v-if="requestsToMade.length > 0"
      v-loading.body="loading"
      :data="requestsToMade"
      class="tp-MaterialRequest__table">
      <el-table-column
        :width="100"
        label="Dest.">
        <template slot-scope="scope">
          <span v-if="scope.row.toStock">Stock</span>
          <span v-else>Reparação</span>
        </template>
      </el-table-column>
      <el-table-column
        :width="85"
        label="Qnt."
        prop="quantity"/>
      <el-table-column
        label="Material"
        prop="name"/>
      <el-table-column
        :width="100"
        label="Estado"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'new'">Novo</span>
          <span v-else>Usado</span>
        </template>
      </el-table-column>
      <el-table-column :width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="warning"
            @click="returnMaterialHandler(scope.row)">Remover</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- show an alert when the stock is low -->
    <el-alert
      v-if="data.selectedMaterial && data.selectedMaterial.newStock + data.selectedMaterial.requestedQuantity < data.selectedMaterial.stockMin"
      :closable="false"
      class="tp-MaterialRequest__alert"
      show-icon
      type="warning"
      title="O Stock está baixo! Considere comprar mais."/>

    <!-- show the stock quantity and the number of requests -->
    <el-alert
      v-if="data.selectedMaterial"
      :closable="false"
      :title="infoMessageMaterial"
      class="tp-MaterialRequest__alert"
      show-icon
      type="info"/>

    <el-form
      ref="data"
      :model="data"
      :rules="rules"
      label-position="top" >
      <el-row :gutter="10">
        <!--material -->
        <el-col :span="11">
          <el-form-item
            label="Material"
            prop="selectedMaterial">
            <el-select
              v-model="selectedMaterial"
              :remote-method="materialRemoteMethod"
              :loading="search.loading"
              filterable
              remote
              placeholder="Material"
              class="tp-MaterialRequest__material">
              <el-option
                v-for="item in search.materials"
                :key="item.id"
                :label="item.brand + ' ' + item.model + ', ' + item.description"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- new material -->
        <el-col :span="2">
          <el-button
            class="tp-MaterialRequest__newMaterial"
            type="primary"
            @click="showMaterialForm">Novo
          </el-button>
        </el-col>

        <!-- quantity -->
        <el-col :span="5">
          <el-form-item label="Qtn. Reparação">
            <el-input-number
              v-model="data.quantity"
              :min="0"
              type="number"/>
          </el-form-item>
        </el-col>

        <!-- quantity -->
        <el-col :span="5">
          <el-form-item label="Qtn. Stock">
            <el-input-number
              v-model="data.stockQuantity"
              :min="0"
              type="number"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :span="17">
          <el-form-item label="Retirar do material">
            <el-select
              v-model="data.type"
              :style="{ width: '100%' }">
              <el-option
                label="Novo"
                value="new"/>
              <el-option
                label="Usado"
                value="used"/>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="Observações">
            <el-input
              v-model="data.observation"
              type="textarea" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</section>
