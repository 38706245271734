<template>
  <section class="tp-ongoing__table">
    <el-table
      v-loading="loading"
      :data="innerData"
      stripe>
      <!-- store repair identifier -->
      <el-table-column
        :width="75"
        label="#Loja">
        <template slot-scope="scope">
          <span v-if="scope.row.storeRepairIdentifier"> {{ scope.row.storeRepairIdentifier }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- repair number -->
      <el-table-column
        :width="90"
        label="#Rep">
        <template slot-scope="scope">
          <span v-if="scope.row.repairNumber"> {{ scope.row.repairNumber }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- Date -->
      <el-table-column
        :width="115"
        label="DataEntrada">
        <template slot-scope="scope">
          <span v-if="scope.row.createdAt"> {{ scope.row.createdAt | dateFormat('dd/MM/yyyy') }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- equipment -->
      <el-table-column
        :width="160"
        label="Equipamento">
        <template slot-scope="scope">
          <span v-if="scope.row.clientEquipment">
            {{ scope.row.clientEquipment | formatEquipmentName }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- IMEI -->
      <el-table-column
        :width="160"
        label="IMEI/SN">
        <template slot-scope="scope">
          <span
            v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber)">
            {{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber }}
          </span>

        </template>
      </el-table-column>

      <!-- Problem -->
      <el-table-column label="Problema">
        <template slot-scope="scope">
          <span v-if="scope.row.problemDescription">{{ scope.row.problemDescription }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- quotation -->
      <el-table-column
        :width="200"
        label="Orçamento">
        <template slot-scope="scope">
          <span v-if="!repairTime(scope.row).length > 0">-</span>
          <span
            v-if="scope.row.quotation && (!scope.row.technician || !scope.row.technician.external)">{{ scope.row.quotation | money
          }} - {{ repairTime(scope.row) }} </span>
          <span v-if="scope.row.value">{{ scope.row.value | money }} - {{ repairTime(scope.row) }} </span>
        </template>
      </el-table-column>

      <!-- Prevision date  -->
      <el-table-column
        :width="115"
        label="Previsão">
        <template slot-scope="scope">
          <span v-if="scope.row.materials.length >= 0">{{ previsionDates[scope.row.id] || 'A carregar...' }} </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- quotation message -->
      <el-table-column
        :width="200"
        label="Estado">
        <template slot-scope="scope">
          <span v-if="scope.row.state==='waiting_delivery'">{{ (scope.row.repairObservation) }}</span>
          <span v-if="scope.row.quotationApproved">Aprovado</span>

          <div v-if="scope.row.quotationApproved === false && scope.row.quotation > 0 && !canShowApproveQuotation(scope.row)">
            <span>Orçamento rejeitado</span>
          </div>
          <div v-else>
            <span v-if="isInRepair(scope.row)">Em reparação</span>
          </div>

          <div v-if="canShowApproveQuotation(scope.row)">
            <span>Espera aprovar orçamento </span>
            <el-button
              type="success"
              size="small"
              @click="approveQuotationBtnHandler(scope.row)">
              Aprovar
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="rejectQuotationHandler(scope.row)">
              Recusar
            </el-button>
          </div>
          <span v-if="scope.row.state==='waiting_material'">Espera material</span>
          <span v-if="scope.row.state==='in_tests'">Em testes</span>

        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { isNil } from "lodash";
import { mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      tec: "",
      rep: [],

      previsionDates: {},
      innerData: null,
      logs: [],
    };
  },

  watch: {
    data() {
      this.preprocessData();
    },
  },

  created() {
    this.preprocessData();
  },

  methods: {
    ...mapActions(["approveQuotation", "rejectQuotation"]),

    /**
     * Check if the given repair should be presented as
     * "In Repair" state.
     */
    isInRepair(repair) {
      return (
        !this.canShowApproveQuotation(repair) &&
        repair.state !== "waiting_delivery" &&
        !repair.quotationApproved &&
        repair.state !== "waiting_material" &&
        repair.state !== "in_tests"
      );
    },

    showQuotationMessage(entry) {
      // get the last quotation entry from the logs
      for (let i = entry.logs.length - 1; i > 0; i--) {
        const logEntry = entry.logs[i];
        if (logEntry.type === "quotation_set") {
          return logEntry.content;
        }
      }
      return "-";
    },

    repairTime(entry) {
      const repTime = this.logsFilter(entry.logs || []);

      if (repTime.split("-")[1] === undefined) {
        return "-";
      }
      let finalMessage = repTime.split("-")[1].trim();
      const observation = repTime
        .split("-")
        .splice(2)
        .join("-")
        .trim();

      if (observation && observation.length > 9) {
        finalMessage += ` - ${observation}`;
      }
      return finalMessage;
    },
    /**
     * Approve the quotation.
     */
    approveQuotationBtnHandler(item) {
      // request confirmation to the user
      this.$confirm("Pretende aprovar o orçamento?", "Confirmação")
        .then(_ => {
          // enter in loading mode
          this.loading = true;

          // call the service to store the data permanently
          return this.approveQuotation([item.id, item.value || item.quotation]);
        })
        .then(_ => {
          // inform update
          this.$emit("update");

          // exit from the loading mode
          this.loading = false;

          // show a success message
          this.$message.success("Orçamento aprovado!");

          // Reload data
          this.fetchPage();
        })
        .catch(_ => {});
    },

    /**
     * Reject quotation.
     */
    rejectQuotationHandler(repair) {
      // request confirmation to the user
      this.$confirm("Pretende recusar o orçamento?", "Confirmação")
        .then(_ => {
          // enter in loading mode
          this.loading = true;

          // call the service to store the data permanently
          return this.rejectQuotation(repair.id);
        })
        .then(_ => {
          // inform update
          this.$emit("update");

          // exit from the loading mode
          this.loading = false;

          // show a success message
          this.$message.success("Orçamento recusado!");

          // Reload data
          this.fetchPage();
        })
        .catch(_ => {});
    },
    quotationValue(item) {
      if (item === "-") {
        return "-";
      }
      return item.match(/(.*)€/)[1];
    },

    async previsionDate(item) {
      // get material requests for a given repair
      const { requests } = await this.$stellar.action("materialRequestsForRepair", {
        repair: item.id,
      });

      let purchaseDate = new Date(item.createdAt);
      const allDates = requests.filter(request => request.purchase).map(request => {
        // get the purchase object
        const purchase = request.purchase;
        purchaseDate = new Date(purchase.createdAt);
        let tempDate = new Date();
        if (purchase.createdAt !== null) {
          if (purchase.origin === "europe") {
            tempDate.setDate(purchaseDate.getDate() + 4);
          } else {
            tempDate.setDate(purchaseDate.getDate() + 6);
          }
        } else {
          tempDate = new Date(purchase.createdAt);
        }

        return tempDate;
      });

      if (allDates.length === 0) {
        allDates.push(new Date(purchaseDate).getTime());
      }

      const higherDate = Math.max(...allDates);

      const message = this.logsFilter(item.logs);

      let date = null;

      try {
        date = message.split("-")[1].trim();
      } catch (e) {
        this.previsionDates[item.id] = "-";
        return;
      }
      const newDate = new Date(higherDate);

      if (date) {
        if (date === "Um dia") {
          newDate.setDate(newDate.getDate() + 1);
        }
        if (date === "Uma semana") {
          newDate.setDate(newDate.getDate() + 7);
        }
        if (date === "Duas semanas") {
          newDate.setDate(newDate.getDate() + 14);
        }
        if (date === "4 a 7 semanas") {
          newDate.setMonth(newDate.getMonth() + 1);
          newDate.setDate(newDate.getDate() + 21);
        }
        if (newDate.getDay() === 6) {
          newDate.setDate(newDate.getDate() + 2);
        }
        if (newDate.getDay() === 0) {
          newDate.setDate(newDate.getDate() + 1);
        }
        this.previsionDates[item.id] = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      } else {
        this.previsionDates[item.id] = "-";
      }

      const copy = this.innerData;
      this.innerData = null;
      this.$nextTick(_ => {
        this.innerData = copy;
      });
    },

    canShowApproveQuotation(item) {
      return (
        item.state === "waiting_quotation_approve" &&
        ((item.technician.external && item.value) || !item.technician.external)
      );
    },

    async preprocessData() {
      this.innerData = this.data;
      this.data.forEach(repair => this.previsionDate(repair));
    },
    logsFilter(item) {
      // There is some old repairs that don't have logs so we need to create an work around
      if (isNil(item)) {
        return "-";
      }

      const repLogs = item.filter(itemLog => itemLog.type === "repair.quotation");
      if (!repLogs[0]) {
        return " - ";
      } else {
        return repLogs[0].message;
      }
    },
  },
};
</script>
