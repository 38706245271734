
<el-dialog
  v-loading.fullscreen="isLoading"
  :visible.sync="visibility"
  title="Numero da ficha de loja">
  <el-form label-position="top">
    <el-form-item>
      <el-input
        v-model="storeIdentifier"
        placeholder="Numero de ficha da loja" />
    </el-form-item>
  </el-form>

  <div
    slot="footer"
    class="dialog-footer">
    <el-button
      :loading="isLoading"
      @click="closeHandler">
      Cancelar
    </el-button>
    <el-button
      :loading="isLoading"
      type="primary"
      @click="updateStoreIdentifierHandler">
      Alterar
    </el-button>
  </div>
</el-dialog>
