<template>
  <span>{{ tweeningValue }}</span>
</template>

<script>
import TWEEN from "tween.js";

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tweeningValue: 0,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.tween(oldValue, newValue);
    },
  },
  mounted() {
    this.tween(0, this.value);
  },
  methods: {
    tween(startValue, endValue) {
      const vm = this;
      let animationFrame;

      const animate = time => {
        TWEEN.update(time);
        animationFrame = window.requestAnimationFrame(animate);
      };

      if (!this.tweeningValue || !Number.isInteger(this.tweeningValue)) {
        return;
      }

      new TWEEN.Tween({ tweeningValue: startValue })
        .to({ tweeningValue: endValue }, 500)
        .onUpdate(() => {
          vm.tweeningValue = this.tweeningValue.toFixed(0);
        })
        .onComplete(() => {
          window.cancelAnimationFrame(animationFrame);
        })
        .start();

      animationFrame = window.requestAnimationFrame(animate);
    },
  },
};
</script>
