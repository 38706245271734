import actions from "./entities-actions";
import { MUTATION_ENTITY_SET } from "./entities-types";

export default {
  namespaced: true,
  state: {
    loading: false,
    entities: [],
    lastUpdate: null,
    error: null,
  },
  actions,
  mutations: {
    [MUTATION_ENTITY_SET](state, data) {
      Object.keys(data).forEach(key => {
        state[key] = data[key] !== undefined ? data[key] : state[key];
      });
    },
  },
  getters: {
    isLoading: state => state.loading,
    entities: state => state.entities,
  },
};
