import stellar from "@/services/stellar";

const CONFIG_SET = "CONFIG_SET";
const CONFIG_SET_ONE = "CONFIG_SET_ONE";

const state = {
  configs: {},
};

const mutations = {
  [CONFIG_SET](state, states) {
    state.configs = states;
  },

  [CONFIG_SET_ONE](state, { key, value }) {
    const index = state.configs.findIndex(item => item.key === key);
    state.configs[index].value = value;
  },
};

const actions = {
  async loadConfigs({ commit }) {
    // get all configs
    const { configs } = await stellar.action("getConfigs");

    // save configs to use it later
    commit(CONFIG_SET, configs);
  },

  async updateConfig({ commit }, data) {
    // get all configs
    const { config } = await stellar.action("editConfig", data);

    // save configs to use it later
    commit(CONFIG_SET_ONE, config);
  },
};

const getters = {
  configs(state) {
    return state.configs;
  },
};

export default {
  actions,
  getters,
  mutations,
  state,
};
