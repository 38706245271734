/**
 * Generate a required rule for the form validator.
 *
 * @param message Message to be shown when the field isn't filled
 * @param options Extra options to be passed to the rule
 */
export const generateRequiredRule = (message = "O campo é obrigatório.", options: any = {}) => ({
  required: true,
  message,
  trigger: "blur",
  ...options,
});

/**
 * Perform a form validation and convert the result into a
 * Promise to be easier to deal with.
 *
 * @param form Form reference
 */
export const doFormValidation = (form: any) =>
  new Promise((resolve, reject) => {
    form.validate((valid: boolean) => (valid ? resolve() : reject()));
  });
