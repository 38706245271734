
<div class="tp-dashboard_wrap">
  <!-- other information for the logged user -->
  <dash-states/>

  <section class="tp-Mural">
    <!-- sidebar -->
    <div class="tp-Mural__sidebar">
      <!-- list of users -->
      <div
        v-for="user in users"
        :key="user.id"
        :class="
          [
            'tp-Mural__sidebar__entry is-user',
            { 'is-selected': user.selected }
          ]"
        @click="user.selected = !user.selected">
        <div class="avatar">
          <img
            :src="getAvatar(user.email)"
            :alt="user.name">
        </div>
        <div class="name">{{ user.name }}</div>
      </div>
    </div>

    <!-- messages -->
    <div class="messages_container">
      <div class="tp-Mural__messages">
        <li
          v-for="message in orderedMessages"
          :key="message.id"
          :class="{
            'is-mine': user.id === message.sender.id,
            'is-other': user.id !== message.sender.id,
            'is-toAttach': isToAttach(message),
            'is-hideName': isToHideName(message)
          }"
          class="tp-Mural__entry">

          <div class="avatar">
            <!-- user image -->
            <img
              :alt="message.sender.name"
              :src="getAvatar(message.sender.email)" >
          </div>

          <!-- name -->
          <div class="name">{{ message.sender.name }}</div>

          <!-- text -->
          <div class="text">{{ message.content }}</div>

          <!-- time -->
          <div class="time">{{ message.createdAt | dateDistanceFrom }}</div>
        </li>
      </div>

      <!-- form -->
      <div class="tp-Mural__form">
        <input
          v-model="newMessage"
          type="text"
          placeholder="Escreva uma mensagem..."
          @keyup.enter="sendMessage">
        <a
          class="fa fa-paper-plane send"
          @click.prevent="sendMessage"/>
      </div>
    </div>
  </section>
</div>
