
<div
  :class="{
    'page-toolbar--push-right': $slots.right && !$slots.left
  }"
  class="page-toolbar">
  <div
    v-if="$slots.left"
    class="page-toolbar__zone page-toolbar__zone--left">
    <slot name="left" />
  </div>
  <div
    v-if="$slots.right"
    class="page-toolbar__zone page-toolbar__zone--right">
    <slot name="right" />
  </div>
</div>
