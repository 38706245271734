import { getStore } from "@/services/store";
import VueRouter, { Route } from "vue-router";

/**
 * Before each transition we check if the route need authentication.
 */
export function registerNeedsAuthenticationMiddleware(router: VueRouter): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router.beforeEach(async (to: Route, from: Route, next: any) => {
    const store = await getStore();

    // get login token from the localStorage
    const token = localStorage.getItem("token");

    // check if the user needs to be authenticated. If the yes, redirect to the login page if the token is null
    if (to.matched.some(m => m.meta.auth) && token === null) {
      return next({ name: "login", query: { redirectTo: to.fullPath } });
    }

    // check if a logged user should see this page
    if (to.matched.some(m => m.meta.guest) && token !== null) {
      // get user data
      const user = store.getters.user;

      // when the user is a store we must be redirected to the 'on_going' repairs, otherwise we must go to the
      // 'dashboard'
      if (user.role && user.role.options && user.role.options.homepage) {
        return next({ name: user.role.options.homepage });
      }

      if (user.role.slug === "store") {
        return next({ name: "store.ongoing" });
      } else {
        return next({ name: "dashboard" });
      }
    }

    // authorize transition
    next();
  });
}
