
<section class="tp-purchases_wrap">

  <material-form
    ref="materialForm"
    @created="materialCreationHandler"
    @canceled="resetVisibility"/>

  <supplier-form
    ref="supplierForm"
    @created="resetVisibility"
    @canceled="resetVisibility"/>

  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="savePurchase">Guardar</el-button>
      <el-button @click="resetForm">Cancelar</el-button>
    </div>

    <!-- error alert -->
    <el-alert
      v-if="errorMessageVisibility"
      type="error"
      title="Ocorreu um erro no servidor."/>

    <el-form
      ref="data"
      :model="data"
      :rules="rules"
      label-position="top">
      <el-row :gutter="10">
        <!-- supplier -->
        <el-col :span="10">
          <el-form-item
            label="Fornecedor"
            prop="supplier">
            <el-select
              v-model="data.supplier"
              :remote-method="searchForSuppliers"
              :loading="search.loading"
              class="tp-Purchases__fullField"
              filterable
              remote
              placeholder="Fornecedor">
              <el-option
                v-for="item in search.suppliers"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- new supplier -->
        <el-col :span="2">
          <el-button
            class="tp-Purchases__newButton"
            size="medium"
            type="primary"
            @click="showSupplierForm">Novo
          </el-button>
        </el-col>

        <!-- material -->
        <el-col :span="10">
          <el-form-item
            label="Material"
            prop="material">
            <el-select
              v-model="data.material"
              :remote-method="searchForMaterials"
              :disabled="disableMaterial"
              :loading="search.loading"
              class="tp-Purchases__fullField"
              filterable
              remote
              placeholder="Material">
              <el-option
                v-for="item in search.materials"
                :key="item._id"
                :label="item.brand + ', ' + item.model + ', ' + item.description"
                :value="item._id"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- new material -->
        <el-col :span="2">
          <el-button
            :disabled="disableMaterial"
            class="tp-Purchases__newButton"
            size="medium"
            type="primary"
            @click="showMaterialForm">Novo
          </el-button>
        </el-col>

        <!-- quantity -->
        <el-col :span="12">
          <el-form-item label="Quantidade">
            <el-input
              v-model="data.quantity"
              :min="minQuantity"
              type="number"
              placeholder="Quantidade"/>
          </el-form-item>
        </el-col>

        <!-- price -->
        <el-col :span="12">
          <el-form-item label="Preço Unitario">
            <el-input
              v-model="data.price"
              :min="0"
              type="number"
              placeholder="Preço Unitario">
              <template slot="append">€</template>
            </el-input>
          </el-form-item>
        </el-col>

        <!-- estimated delivery date -->
        <el-col :span="12">
          <el-form-item label="Origem">
            <el-select
              v-model="data.origin"
              style="width: 100%"
              placeholder="Origem">
              <el-option
                label="Europa"
                value="europe"/>
              <el-option
                label="Outro"
                value="other"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- link for supplier -->
        <el-col :span="12">
          <el-form-item label="Link da Compra">
            <el-input
              v-model="data.link"
              type="text"
              placeholder="Link da Compra"/>
          </el-form-item>
        </el-col>

        <!-- observations -->
        <el-col :span="24">
          <el-form-item label="Observações">
            <el-input
              v-model="data.observation"
              type="textarea"
              placeholder="Observações"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</section>
