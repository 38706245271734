<template>
  <div class="page page-warranty">
    <PageToolbar>
      <template slot="left">
        <el-tabs v-model="activeTab">
          <el-tab-pane
            label="Em garantia"
            name="inWarrantyTab" />
          <el-tab-pane
            label="À espera de despacho"
            name="waitingDispatchTab" />
        </el-tabs>
      </template>

      <template slot="right">
        <el-button
          size="small"
          icon="setting"
          @click="$router.push({ name: 'entities.list' })">
          Entidades
        </el-button>
      </template>
    </PageToolbar>

    <WarrantyListInWarranty
      v-if="activeTab === 'inWarrantyTab'"
      class="page-section" />

    <WarrantyDispatchList
      v-if="activeTab === 'waitingDispatchTab'"
      class="page-section"/>
  </div>
</template>

<script>
import WarrantyListInWarranty from "./warranty-list-in-warranty.vue";

import WarrantyDispatchList from "./warranty-list-to-dispatch.vue";

export default {
  name: "Warranty",

  components: {
    WarrantyListInWarranty,
    WarrantyDispatchList,
  },

  data() {
    return {
      activeTab: "inWarrantyTab",
    };
  },
};
</script>
