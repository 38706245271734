<template>
  <div class="tp-clientEquipment_wrap">
    <div class="form">
      <p id="tittle">Registar Equipamento</p>
      Tipo Equipamento:
      <select v-model="selected">
        <option value="telemovel">Telemovel</option>
        <option value="tablet">Tablet</option>
        <option value="array">[array (criado pelo admin)]</option>
      </select>
      <p>Marca: <input
        type="text"
        class="form-control"
        placeholder="marca"></p>
      <p>Modelo: <input
        type="text"
        class="form-control"
        placeholder="modelo"></p>
      <p>Numero de série: <input
        type="number"
        placeholder="numero de série"
        name=""></p>

      <div v-if="selected === 'telemovel'">IMEI:
        <input
          type="number"
          class="form-control"
          placeholder="imei">
      </div>
      <div v-if="selected === 'array'">Atributos do Equipamento:
        <input
          type="text"
          class="form-control"
          placeholder="atributos">
      </div>
    </div>
    <div class="button">
      <button
        class="btn btn-primary"
        @click="addEvent">Adicionar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientEquipment",

  data() {
    return {
      selected: "array",
    };
  },
  methods: {
    addEvent() {
      // TODO: implement this
    },
  },
};
</script>
