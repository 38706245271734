<template>
  <el-dialog
    v-loading.fullscreen="isLoading"
    :visible.sync="visibility"
    class="change-value-and-quotation-modal"
    title="Editar Valor e/ou Orçamento">
    <el-form label-position="top">
      <el-row>
        <el-col :span="24">
          <el-form-item label="Orçamento">
            <el-input
              v-model="tempQuotation"
              class="change-value-and-quotation-modal__brand"
              type="text"
              placeholder="Orçamento"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="Valor">
            <el-input
              v-model="tempValue"
              v-loading.fullscreen="isLoading"
              class="change-value-and-quotation-modal__model"
              placeholder="Valor"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        v-loading.fullscreen="isLoading"
        @click="closeHandler">
        Cancelar
      </el-button>
      <el-button
        v-loading.fullscreen="isLoading"
        type="primary"
        @click="updateValueQuotationHandler">
        Alterar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { get } from "vuex-pathify";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

export default {
  name: "ChangeValueQuotationModal",

  data() {
    return {
      visibility: false,
      tempValue: 0,
      tempQuotation: 0,
    };
  },

  computed: {
    ...mapGetters("repair", ["repair"]),
    ...mapState("repair", {
      isLoading: state => state.loading,
    }),
    repair: get("repair"),
  },

  created() {
    // copy field values to temporary fields prop to local
    this.tempValue = this.repair.repair.value;
    this.tempQuotation = this.repair.repair.quotation;
  },
  methods: {
    show() {
      this.visibility = true;
    },

    reloadRepair() {
      this.$store.dispatch(ERepairActions.RELOAD);
    },

    closeHandler() {
      this.visibility = false;
      this.$emit("updated");
      this.reloadRepair();
    },
    /**
     * Update the Brand and Model.
     */
    async updateValueQuotationHandler() {
      await this.$stellar.action("editRepair", {
        id: this.repair.repair.id,
        value: this.tempValue,
        quotation: this.tempQuotation,
      });

      this.closeHandler();
      this.$message.success("Alteração efecruada com sucesso.");
    },
  },
};
</script>

<style lang="scss" scoped>
.change-brand-and-model-modal {
  &::v-deep .el-dialog {
    width: 30%;
    max-width: 500px;
  }

  &__model {
    display: block;
    width: 100%;
  }
}
</style>
