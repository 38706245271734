import { IBaseState } from "@/services/store/types/base-state.interface";
import { IEquipment } from "@/modules/repairs/types/equipment.interface";

export interface IEquipmentState extends IBaseState {
  equipment: IEquipment | null;
}

export enum EEquipmentAction {
  FETCH = "equipment/FETCH",
  UPDATE = "equipment/UPDATE",
}

export enum EEquipmentMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_EQUIPMENT = "SET_EQUIPMENT",
}
