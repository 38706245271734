<template>
  <section class="tp-DashStates" v-if="user.personId">
    <el-dialog
      :visible.sync="listVisibility"
      title=" Novos clientes"
      size="large">
      <el-button @click="listVisibility = false">Sair</el-button>
      <el-table
        :data="newClients.clients"
        stripe=""
        width="100%">
        <el-table-column
          prop="name"
          label="Nome"
          width="280"/>

        <el-table-column
          prop="phone"
          label="Telefone"
          width="100"/>


        <el-table-column
          prop="street"
          label="Rua"
          width="240"/>

        <el-table-column
          label="Loja"
          width="40">
          <template slot-scope="scope">
            <span v-if="scope.row.isStore === true"> X </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      ref="dailyRepairList"
      :visible.sync="todayRepairsVisibility"
      title="Reparações fechadas hoje"
      size="large">
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button @click="show(false)">Cancelar</el-button>
        <el-button @click="printElem">Imprimir</el-button>
      </div>

      <div id="toPrint">
        <el-button @click="todayRepairsVisibility = false">Cancelar</el-button>
        <el-button @click.native="printElem">Imprimir</el-button>
        <el-table
          :data="todayRepairs"
          stripe
          style="width: 100%">
          <el-table-column
            prop="repair.repairNumber"
            label="#Rep"
            width="180"/>
          <el-table-column
            prop="repair.clientEquipment.brand"
            label="Equipamento"
            width="180"/>
          <el-table-column
            prop="totalMaterial"
            label="Material"
            width="180"/>
          <el-table-column
            prop="repair.value"
            label="Valor da Reparação"
            width="180"/>
          <el-table-column
            prop="value"
            label="Comissão"
            width="180"/>

        </el-table>
        <span>Total comissões dia: {{ dailyTotal }}</span>
      </div>

    </el-dialog>

    <div
      v-if="loggedTechnician && loggedTechnician.technician.external === false"
      class="tp-DashStates__grid">
      <el-tooltip
        v-for="(entry, key) in dashStates"
        :key="key"
        :content="availableStates[key].hint">
        <div
          :md="6"
          :sm="24"
          :class="[ 'is-' + key ]"
          class="tp-DashStates__entry">
          <i
            :class="[ 'fa-' + availableStates[key].icon ]"
            class="fa tp-DashStates__entry__icon" />
          <ui-animated-number
            :value="entry"
            class="tp-DashStates__entry__content"
            @click.native="handleBoxClick(key)" />
        </div>
      </el-tooltip>

      <el-tooltip
        v-if="newClients.clients"
        :content="availableStates['newClients'].hint"
        class="is-newClients">
        <div
          :md="6"
          :sm="24"
          class="tp-DashStates__entry is-newClients">
          <i
            :class="[ 'fa-' + availableStates['newClients'].icon ]"
            class="fa tp-DashStates__entry__icon" />
          <ui-animated-number
            :value="newClients.clients.length"
            class="tp-DashStates__entry__content"
            @click.native="handleBoxClick('newClients')" />
        </div>
      </el-tooltip>

      <el-tooltip
        :content="availableStates['complaintRate'].hint"
        class="is-newClients">
        <div
          :md="6"
          :sm="24"
          class="tp-DashStates__entry is-newClients">
          <i
            :class="[ 'fa-' + availableStates['complaintRate'].icon ]"
            class="fa tp-DashStates__entry__icon" />
          <ui-animated-number
            :value="tecComplaintsRate"
            class="tp-DashStates__entry__content"
            @click.native="handleBoxClick('newClients')" />
        </div>
      </el-tooltip>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const availableStates = {
  repairsClosedToday: {
    icon: "rocket",
    hint: "Reparações Fechadas Hoje",
  },
  totalRepairsClosedToday: {
    icon: "rocket",
    hint: "Total Reparações Fechadas Hoje",
  },
  earnedCommissions: {
    icon: "eur",
    hint: "Comissões do Dia",
  },
  newClients: {
    icon: "user",
    hint: "Novos Clientes",
  },
  complaintRate: {
    icon: "percent",
    hint: "Reclamações",
  },
};

export default {
  name: "DashboardStates",

  data() {
    return {
      availableStates,
      technician: "",
      todayRepairs: "",
      todayRepairsVisibility: false,
      newClients: [],
      listVisibility: false,
      problemRepairs: [],
      allComplaints: {
        complaints: [],
      },
      damagedMaterial: [],
      loggedTechnician: "",
    };
  },

  computed: {
    ...mapGetters(["dashStates", "user"]),

    dailyTotal() {
      if (this.todayRepairs.length === 0) {
        return 0;
      }

      return this.todayRepairs.reduce((totalRepairs, row) => {
        if (row.value <= 0) {
          return totalRepairs;
        }

        return totalRepairs + row.value;
      }, 0);
    },
  },

  async created() {
    // when this persons isn't an technician ignore this component
    if (!this.user.personId) {
      return;
    }

    this.tecComplaintsRate = 7;

    // get technician
    this.loggedTechnician = await this.$stellar.action("getTechnician", {
      id: this.user.personId,
    });

    // get new clients
    const role = this.user.role;
    if (role && role.slug === "admin") {
      this.newClients = await this.$stellar.action("getTodayNewClients");
    }

    this.dashFetchStates();
  },

  methods: {
    ...mapActions(["dashFetchStates"]),

    handleBoxClick(key) {
      switch (key) {
        case "repairsClosedToday":
          return this.dailyRepairs();
        case "newClients":
          return this.newClientsList();
        default:
          throw new Error(`Not implemented for '${key}'`);
      }
    },
    async dailyRepairs() {
      this.todayRepairsVisibility = true;
      const today = new Date();
      const date1 = new Date();
      today.setHours(0, 0, 1, 0);
      const date = new Date(date1.setDate(date1.getDate() + 1));

      date.setHours(0, 0, 1, 0);
      this.technician = this.user.personId;

      // Request the data to the API
      const response = await this.$stellar.action("getCommissionForTechnician", {
        technician: this.technician,
        start: today,
        end: date,
      });

      this.todayRepairs = response.repairs;
    },

    printElem() {
      const mywindow = window.open("", "PRINT", "height=400,width=600");
      mywindow.document.write("<html><head><title>" + document.title + "</title>");

      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(document.getElementById("toPrint").innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    },
    newClientsList() {
      this.listVisibility = true;
    },
    goToComplaints() {
      // redirect the user to the list of work
      this.$router.push({ name: "complaint.create" });
    },
    goToProblematics(items) {
      // redirect the user to the list of work
      this.$router.push({
        name: "repairs.problematic",
        params: { repairs: items },
      });
    },

    show() {
      this.todayRepairsVisibility = false;
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.tp {
  &-DashStates {
    margin: 20px 30px 40px 30px;

    &__grid {
      display: flex;
    }

    &__entry {
      height: 100%;
      margin-right: 30px;

      background-color: #ffffff;
      box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12),
        0 24px 38px 3px rgba(0, 0, 0, 0.2);

      &:last-child {
        margin-right: 0;
      }

      &__icon {
        padding: 20px 0;
        width: 75px;

        font-size: 2em;
        text-align: center;
      }

      &__content {
        padding: 5px;
        min-width: 100px;
        display: inline-block;
        text-align: center;

        color: var(--colors-dark);
        font-size: 2em;
      }

      &.is-repairsClosedToday,
      &.is-newClients {
        .tp-DashStates__entry__icon {
          background-color: #549cc6;
        }

        color: white;
      }

      &.is-earnedCommissions {
        .tp-DashStates__entry__icon {
          background-color: #54c688;
        }

        color: white;
      }

      &.is-totalRepairsClosedToday {
        .tp-DashStates__entry__icon {
          background-color: #54c688;
        }

        color: white;
      }
    }
  }
}
.daily-commissions {
  display: block;
  margin-left: 150px;
  text-align: center;
}

.commission-item1 {
}

.commission-item2 {
  display: block;
  border: 4px solid grey;
  border-radius: 5px;
  padding: 10px 6px 10px 6px;
  background-color: lightgray;
}

.numbers {
  margin-top: 5px;
  width: 310px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-gap: 10px;
  background-color: #54c688;
  color: #444;
}
.box {
  margin: 5px 5px 4px 5px;
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  font-size: 100%;
}
</style>
