/* global localStorage */
const USER_UPDATE_ONE_PREF = "USER_UPDATE_ONE_PREF";
const USER_LOAD_PREFS = "USER_LOAD_PREFS";

const state = {
  preferences: {},
};

const mutations = {
  [USER_UPDATE_ONE_PREF](state, [pref, value]) {
    state.preferences[pref] = value;
  },

  [USER_LOAD_PREFS](state, prefs) {
    state.preferences = prefs;
  },
};

const actions = {
  updateUserPreference({ commit }, pref) {
    commit(USER_UPDATE_ONE_PREF, pref);

    // update the Local Storage
    localStorage.setItem("user_preferences", JSON.stringify(state.preferences));
  },

  loadUserPrefs({ commit }) {
    // get the preferences from the local storage
    const preferences = JSON.parse(localStorage.getItem("user_preferences"));

    // load the preferences into the storage if there is any one
    if (!preferences) {
      return;
    }
    commit(USER_LOAD_PREFS, preferences);
  },
};

const getters = {
  getPreferences: state => state.preferences,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
