
<el-card
  v-if="client"
  class="tp-ClientCard">
  <div class="tp-ClientCard__content">
    <!-- avatar -->
    <img
      :src="getAvatar(client.email)"
      class="tp-ClientCard__avatar"
      alt="Avatar" >

    <div class="tp-ClientCard__info">
      <div class="tp-ClientCard__info__name">
        {{ client.name }}
      </div>

      <div class="tp-ClientCard__entry">
        <span class="title">NIF:</span>
        <span class="value">{{ client.taxNumber }}</span>
      </div>

      <div
        v-if="client.email"
        class="tp-ClientCard__entry">
        <span class="title">Email:</span>
        <a
          :href="emailHref"
          class="value">{{ client.email }}</a>
      </div>

      <div
        v-if="client.phone"
        class="tp-ClientCard__entry">
        <span class="title">Telefone:</span>
        <a
          :href="phoneHref"
          class="value">{{ client.phone }}</a>
      </div>
    </div>
  </div>

  <div class="tp-ClientCard__actions">
    <slot name="actions"/>
  </div>
</el-card>
