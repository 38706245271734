import { IUser } from "../user-types";
import { IBaseState } from "../../types/base-state.interface";

export interface IUsersState extends IBaseState {
  users: { [key: string]: IUser };
}

export enum EUsersActions {
  FETCH_USER = "users/fetchUsers",
}

export enum EUsersMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_USERS = "SET_USERS",
}

export enum EUsersGetters {
  GET_USER = "GET_USER",
}
