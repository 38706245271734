
<div
  :class="{ 'is-loading': loading }"
  class="ui-List">
  <!-- actions ------------------------------------------------------------- -->
  <div class="ui-List__actions">
    <div class="ui-List__leftActions">
      <el-button
        v-if="allowCreation"
        type="success"
        class="ui-List__creationButton"
        @click="creationMethod">
        Adicionar
      </el-button>

      <slot name="actions"/>
    </div>


    <div class="ui-List__rightActions">
      <slot name="rightActions" />

      <!-- search -->
      <el-input
        v-if="searchable"
        v-model="query"
        :placeholder="searchPlaceholder"
        class="ui-List__search"
        icon="search"
        type="text"/>
    </div>
  </div>

  <!-- list it self -------------------------------------------------------- -->
  <div class="ui-List__content">
    <slot
      v-if="showNoItem && data.length === 0"
      name="noElements">
      <p>Não existem elementos!</p>
    </slot>

    <!-- item render area -->
    <slot/>
  </div>

  <!-- bottom section ------------------------------------------------------ -->
  <div class="ui-List__bottomSection">
    <div
      v-if="paginate"
      class="ui-List__pagination">
      <el-pagination
        :current-page="currentPage"
        :total="total"
        :page-size="perPage"
        layout="prev, pager, next"
        @current-change="currentPageChangeHandler"/>
    </div>
  </div>
</div>
