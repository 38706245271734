import { getStore } from "@/services/store";
import { Message } from "element-ui";
import VueRouter, { Route, RouteRecord } from "vue-router";

/**
 * Mechanism to check if the user has the correct permissions to access the route
 */
export function registerNeedsPermissionMiddleware(router: VueRouter): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router.beforeEach((to: Route, from: Route, next: any) => {
    // get login token from the localStorage
    const token = localStorage.getItem("token");

    // if there is no token defined we can ignore this step
    if (token === null) {
      return next();
    }

    const errorFunction = (user: any) => {
      Message({
        message: "Oops, não tem permissões para isso!",
        type: "error",
      });

      // when the user has a homepage defined we must use it instead of the default behaviour
      if (user && user.role && user.role.options && user.role.options.homepage) {
        return next({ name: user.role.options.homepage });
      }

      if (user.role.slug === "store") {
        next({ name: "store.ongoing" });
      } else {
        next({ name: "dashboard" });
      }
    };

    to.matched.forEach(async (item: RouteRecord) => {
      const store = await getStore();

      // get route metadata
      const meta = item.meta;

      // whether this route don't need authentication that means that we don't need check the authentication
      if (meta.guest) {
        return next();
      }

      // We need the user and the role prop to be defined.
      const user = store.getters.user;
      if (!user || !user.role) {
        return next();
      }

      // if the user is an admin they can do everything, we don't need check for
      // role or permissions
      if (user.role.slug === "admin") {
        return next();
      }

      // check if the the user a specific role to access the route (needRole)
      if (meta.needRole) {
        // convert to array is there is not one
        if (!Array.isArray(meta.needRole)) {
          meta.needRole = [meta.needRole];
        }

        // check if the user role is contained in the array
        if (meta.needRole.indexOf(user.role.slug) === -1) {
          return errorFunction(user);
        }
      }

      // make a check by permissions
      if (meta.needPermissions) {
        // get the permission mode
        const mode = meta.permissionsMode || "one";

        // merge the user permissions with the role ones
        const userPermissions = user.role.permissions.concat(user.permissions);

        // compare the user permissions with the user ones
        const result = meta.needPermissions.filter((permission: string) => {
          return userPermissions.indexOf(permission) >= 0;
        });

        // whether the rote is using the 'one' model the number of array items
        // needs to be 1 or more, otherwise if we are using the 'all' mode the
        // result length needs to be quals to the `needPermissions`
        if (
          (mode === "one" && result.length === 0) ||
          (mode === "all" && result.length !== item.meta.needPermissions.length)
        ) {
          return errorFunction(user);
        }
      }

      // the use has permissions to access this route
      return next();
    });
  });
}
