
<el-dialog
  :visible.sync="visibility"
  title="Observações para o cliente">
  <el-form label-position="top">
    <el-form-item>
      <el-input
        v-model="repairObservation"
        placeholder="Observações para o cliente"
        row="6" />
    </el-form-item>
  </el-form>

  <div
    slot="footer"
    class="dialog-footer">
    <el-button
      :loading="isLoading"
      @click="closeHandler">
      Cancelar
    </el-button>
    <el-button
      :loading="isLoading"
      type="primary"
      @click="saveRepairObservationHandler">
      Alterar
    </el-button>
  </div>
</el-dialog>
