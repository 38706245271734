<template>
  <div class="tp-materialForm_wrap">
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="save">Guardar</el-button>
        <el-button
          :loading="loading"
          @click="cancelAction">Cancelar</el-button>
      </div>

      <!-- error alert -->
      <el-alert
        v-if="showError"
        type="error"
        title="Existem erros no formulario."/>

      <!-- form -->
      <el-form
        ref="resource"
        :model="resource"
        :rules="rules"
        label-position="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              label="Cod."
              prop="identifier">
              <el-input
                v-model="resource.identifier"
                placeholder="Cod." />
            </el-form-item>
          </el-col>

          <!-- model -->
          <el-col :span="8">
            <el-form-item
              label="Modelo"
              prop="model">
              <el-autocomplete
                v-model="resource.model"
                :style="{ width: '100%' }"
                :fetch-suggestions="materialRemoteMethodHandler"
                :trigger-on-focus="false"
                placeholder="Modelo"
                custom-item="brand-model-item"
                @select="modelChangeHandler"/>
            </el-form-item>
          </el-col>

          <!-- brand -->
          <el-col :span="8">
            <el-form-item
              label="Marca"
              prop="brand">
              <el-input
                v-model="resource.brand"
                placeholder="Marca"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- description -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Descrição"
              prop="description">
              <el-input
                ref="descriptionField"
                v-model="resource.description"
                type="textarea"
                :rows="1"
                placeholder="Descrição"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- only show this if simple mode is disabled -->
        <div v-if="isSimpleMode === false">
          <el-row :gutter="20">
            <!-- newStock -->
            <el-col :span="8">
              <el-form-item label="Numero de Peças Novas">
                <el-input-number
                  v-model="resource.newStock"
                  :style="{ width: '100%' }"
                  placeholder="Numero de Peças Novas"/>
              </el-form-item>
            </el-col>

            <!-- usedStock -->
            <el-col :span="8">
              <el-form-item label="Numero de Peças Usadas">
                <el-input-number
                  v-model="resource.usedStock"
                  :style="{ width: '100%' }"/>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Stock Minimo">
                <el-input-number
                  v-model="resource.stockMin"
                  :style="{ width: '100%' }"
                  placeholder="Stock Minimo"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <!-- box -->
            <el-col :span="6">
              <el-form-item label="Caixa">
                <el-input
                  v-model="resource.storeBox"
                  placeholder="Caixa"/>
              </el-form-item>
            </el-col>

            <!-- price -->
            <el-col :span="6">
              <el-form-item label="Preço de Compra">
                <el-input
                  v-model="resource.price"
                  placeholder="Preço de Compra">
                  <template slot="append">€</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Categoria" >
                <el-select v-model="resource.materialGroup" placeholder="Categoria" class="categoryField">
                  <el-option
                    v-for="group in groups"
                    :key="group.name"
                    :label="group.name"
                    :value="group.id"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>
<script>
import Vue from "vue";

import { get } from "vuex-pathify";

import { EMaterialGroupActions } from "@/modules/stock/services/store/material-groups/material-groups.types";
import MaterialGroupForm from "@/modules/stock/components/material-group-form.vue";

// store the timer for
let timer = null;

Vue.component("brand-model-item", {
  functional: true,

  props: {
    item: { type: Object, required: true },
  },

  render(h, ctx) {
    const item = ctx.props.item;

    // build the correct
    let label = "";
    if (item.brand.length > 0) {
      label += `${item.brand} `;
    }
    label += item.model;

    return h("li", ctx.data, [
      h("div", { attrs: { class: "value" } }, [label]),
    ]);
  },
});

export default {
  data() {
    return {
      resource: {
        identifier: "",
        brand: "",
        model: "",
        description: "",
        newStock: 0,
        usedStock: 0,
        storeBox: "",
        price: 0,
        stockMin: 3,
        materialGroup: "",
      },

      rules: {
        description: [
          {
            required: true,
            message: "Introduza a descrição do material",
            trigger: "blur",
          },
        ],
      },

      edit: false,
      showError: false,
      loading: false,

      // inform if the simple mode is enable
      isSimpleMode: false,
    };
  },
  computed: {
    groups: get("materialGroups/groups"),
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData(options = {}) {
      this.$store.dispatch(EMaterialGroupActions.FETCH_ALL, options);
    },

    /**
     * Enable simple mode.
     */
    enableSimpleMode() {
      this.isSimpleMode = true;
    },

    /**
     * Set a resource to edit.
     */
    setResource(resource) {
      this.resource = JSON.parse(JSON.stringify(resource));
      this.edit = true;
    },

    /**
     * Change the visibility of the component.
     *
     * @type {[type]}
     */
    show(val = true) {
      this.$refs.editSheet.show(val);

      // when we hide the form we must reset the state
      if (!val) {
        this.reset();
      }
    },

    /**
     * Reset the form stats.
     */
    reset() {
      // reset form data
      this.resource = {
        identifier: "",
        brand: "",
        model: "",
        description: "",
        newStock: 0,
        usedStock: 0,
        storeBox: "",
        price: 0,
        stockMin: 3,
        materialGroup: "",
      };

      // reset other states
      this.edit = false;
      this.isSimpleMode = false;
      this.showError = false;
      this.loading = false;

      // reset the form fields
      this.$refs.resource.resetFields();
    },

    save() {
      // reset the error message
      this.showError = false;

      // enable loading
      this.loading = true;

      this.$refs.resource.validate(valid => {
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.createResource();
        } else {
          this.editResource();
        }
      });
    },

    createResource() {
      // create a new material entry on the API
      this.$stellar
        .action("createMaterial", this.resource)
        .then(({ material }) => {
          // emit an event with the new resource
          this.$emit("created", material);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource creation
          this.$message.success("Material criado.");
        })
        // when we encontered a problem we must show an error message
        .catch(response => {
          this.showError = true;
        });
    },

    /**
     * Save the resource changes.
     */
    editResource() {
      // make an API call to update the material information
      this.$stellar
        .action("editMaterial", this.resource)
        .then(response => {
          // emit an event with the resource update
          this.$emit("updated", this.resource);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource update
          this.$message({
            type: "success",
            message: "O material foi atualizado.",
          });
        })
        // when we encontered a problem we must show an error message
        .catch(response => {
          this.showError = true;
        });
    },

    /**
     * Hide the form and emit the cancel event.
     */
    cancelAction() {
      // hide the component
      this.show(false);

      // emit a cancel event
      this.$emit("canceled");
    },

    /**
     * When the model change we must get the information and auto fill the
     * brand.
     */
    modelChangeHandler(equipment) {
      // set the brand
      this.resource.brand = equipment.brand;

      // focus the description field
      if (this.resource.description === "") {
        this.$refs.descriptionField.$vnode.elm
          .querySelector(".el-textarea__inner")
          .focus();
      }
    },

    /**
     * Search for a material using a query.
     */
    materialRemoteMethodHandler(query, cb) {
      // if there is a timer cancel it
      clearTimeout(timer);

      if (query.length < 2) {
        return cb([]);
      }

      timer = setTimeout(_ => {
        this.$stellar
          .action("searchEquipment", { query })
          .then(({ equipments }) => {
            // define a value field for each sugestion
            equipments = equipments.map(item => {
              item.value = item.model;
              return item;
            });

            // store found equipments
            cb(equipments);
          });
      }, 700);
    },
  },
};
</script>


<style lang="scss" scoped>
.categoryField {
  display: block;
  width: 100%;
}
</style>
