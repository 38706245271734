
<div class="tp-RepairView__bottomBar">
  <!-- other actions -->
  <el-dropdown
    split-button
    size="small"
    type="primary">
    Outros
    <el-dropdown-menu slot="dropdown">
      <!-- change value -->
      <el-dropdown-item
        v-if="repair.state == 'waiting_delivery' || repair.state == 'closed_without_permission' && $auth.is('admin')"
        @click.native="changeValueHandler">
        Alterar Valor
      </el-dropdown-item>

      <!-- cancel repair -->
      <el-dropdown-item
        v-if="canCancelCommission"
        @click.native="cancelComissionHandler">
        Cancelar Comissão
      </el-dropdown-item>

      <!-- receipt-->
      <el-dropdown-item @click.native="$emit('printReceipt')">
        Imprimir Talão
      </el-dropdown-item>

      <!-- change repair to complaint -->
      <el-dropdown-item
        v-if="repair.state != 'closed_without_permission' && repair.state != 'waiting_delivery' && repair.state != 'delivered' && repair.state != 'waiting_complaint_avaluation'"
        @click.native="changeRepairToComplaint">
        Reclamação
      </el-dropdown-item>

      <!-- send repair to warranty -->
      <el-dropdown-item
        @click.native="$emit('sendToWarranty')">
        Garantia
      </el-dropdown-item>

    </el-dropdown-menu>

  </el-dropdown>

  <!-- quotation -->
  <template v-if="showApproveQuotation">
    <el-button :loading="loading" size="small" type="success" @click.native="approveQuotationHandler">
      Aprovar Orçamento
    </el-button>

    <el-button :loading="loading" size="small" type="danger" @click.native="rejectQuotationHandler">
      Recusar Orçamento
    </el-button>
  </template>

  <div v-if="$auth.can('see_delivers')">
    <!-- delevery action -->
    <el-button
      v-if="['waiting_delivery', 'closed_without_permission', 'problematic'].includes(repair.state)"
      :loading="loading"
      size="small"
      type="success"
      @click="closeHandler">
      Entregar
    </el-button>

    <!-- button to cancel the repair -->
    <el-button
      v-if="!['waiting_delivery', 'closed_without_permission', 'delivered'].includes(repair.state)"
      :loading="loading"
      size="small"
      type="danger"
      @click="cancelHandler">
      Cancelar
    </el-button>
  </div>

  <div
    v-if="repair.state !== 'problematic'"
    class="noBlock">
    <div
      v-if="repair.state === 'in_repair' && isTechnicianUser"
      class="noBlock">
      <span>
        <el-button
          :loading="loading"
          size="small"
          type="warning"
          @click="$emit('showQuotation')">
          Orçamento
        </el-button>

        <!-- state change -->
        <el-button
          :loading="loading"
          size="small"
          type="info"
          @click="stopRepairHandler">
          Pausar
        </el-button>
        <el-button
          :loading="loading"
          size="small"
          type="info"
          @click="handleInTestes">
          Em Testes
        </el-button>
      </span>

      <el-button
        :loading="loading"
        size="small"
        type="warning"
        @click="$emit('finish')">
        Fechar Reparação
      </el-button>
    </div>
    <div v-else>
      <!-- start button -->
      <el-button
        v-if="showStartButton"
        :loading="loading"
        :disabled="workHasOpenRepair"
        size="small"
        type="primary"
        @click="startRepairHandler">
        Iniciar
      </el-button>
    </div>
  </div>

  <!-- problematic repairs -->
  <div v-else>
    <el-button
      v-if="$auth.can('see_work')"
      :disabled="workLastUpdate === null"
      :loading="isLoading"
      size="small"
      type="warning"
      @click="pickStartHandle">
      Pegar & Iniciar
    </el-button>

    <el-button
      :loading="loading"
      size="small"
      type="danger"
      @click="$emit('finish')">
      Fechar reparação
    </el-button>
  </div>
  <span v-if="repair.complaint === true && repair.prevRepair">
    <el-button
      :loading="loading"
      size="small"
      type="success"
      @click="emit('prevRepair')">
      Rep. Anterior
    </el-button>
  </span>
</div>
