import { MutationTree } from "vuex";

import {
  ISpacesSate,
  ESpacesMutations,
  ISpaceEntry,
} from "@/services/store/modules/spaces/spaces-types";

export const mutations: MutationTree<ISpacesSate> = {
  [ESpacesMutations.ADD_SPACE](state, data) {
    const order = data.order ? data.order : 0;

    const newState = { ...state.spaces };

    // Create the target space if it doesn't exists yet
    if (newState[data.space] === undefined) {
      newState[data.space] = [];
    }

    const space = newState[data.space] as Array<ISpaceEntry>;

    const newEntry: ISpaceEntry = {
      name: data.component,
      order,
    };
    space.push(newEntry);
    state.spaces = newState;
  },
};
