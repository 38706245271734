import Vue from "vue";

Vue.filter("formatRequestState", value => {
  switch (value) {
    case "waiting_purchase":
      return "Espera Compra";
    case "waiting_arrival":
      return "Espera Chegada";
    case "waiting_delivery":
      return "Espera Entrega";
    case "delivered":
      return "Entregue";
    case "no_supplier":
      return "Fornecedor não Encontrado";
    case "unsuppliable":
      return "Sem fornecedor";
    default:
      return "Invalido";
  }
});
