
<el-dialog
  :visible.sync="dialogVisibility"
  title="Dar Entrada">
  <el-form label-position="top">
    <el-form-item label="Nota para o Cliente">
      <el-input
        v-model="formData.observation"
        type="textarea"
        row="6" />
    </el-form-item>

    <el-form-item label="Nota Interna">
      <el-input
        v-model="formData.internalObservation"
        type="textarea"
        row="6" />
    </el-form-item>

    <el-form-item label="Valor da Reparação">
      <el-input
        v-model="formData.value"
        :min="0"
        placeholder="Nota"
        type="number">
        <template slot="append">€</template>
      </el-input>
    </el-form-item>
  </el-form>

  <template
    slot="footer"
    class="dialog-footer">
    <el-button
      :loading="loading"
      @click="onClickCancelHandler">
      Cancelar
    </el-button>

    <el-button
      :loading="loading"
      type="primary"
      @click="entranceHandler">
      Dar Entrada
    </el-button>
  </template>
</el-dialog>
