
<el-dialog
  v-loading.fullscreen="isLoading"
  :visible.sync="visibility"
  class="change-brand-and-model-modal"
  title="Editar Marca/Modelo">
  <el-form label-position="top">
    <el-row>
      <el-col :span="24">
        <el-form-item
          label="Modelo">
          <el-select
            v-model="tempBrand"
            v-loading.fullscreen="isLoading"
            :remote-method="equipmentRemoteMethod"
            class="change-brand-and-model-modal__model"
            placeholder="Modelo"
            filterable
            allow-create
            remote
            @change="modelChange">
            <el-option
              v-for="equip in equipments"
              :key="equip.id"
              :label="equip.model"
              :value="equip.id">
              <span>{{ equip.brand + ' ' + equip.model }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item label="Marca">
          <el-input
            v-model="selectedEquipment.brand"
            class="change-brand-and-model-modal__brand"
            type="text"
            placeholder="Marca"/>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>

  <div
    slot="footer"
    class="dialog-footer">
    <el-button
      v-loading="isLoading"
      @click="closeHandler">
      Cancelar
    </el-button>
    <el-button
      v-loading="isLoading"
      type="primary"
      @click="updateBrandModelHandler">
      Alterar
    </el-button>
  </div>
</el-dialog>
