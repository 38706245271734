<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :show-close="false"
    title="Grupo">
    <el-form
      ref="form"
      :model="group"
      :rules="rules"
      label-position="top"
      class="tp-Contacts__form">
      <!-- name -->
      <el-form-item
        label="Nome"
        prop="name">
        <el-input
          v-model="group.name"
          type="text"
          placeholder="Nome"/>
      </el-form-item>

      <!-- role -->
      <el-form-item
        label="Grupos de Utilizadores"
        prop="roles">
        <el-select
          v-model="group.roles"
          :style="{ width: '100%' }"
          multiple
          placeholder="Papeis">
          <el-option
            v-for="item in roles"
            :key="item.id"
            :label="item.name"
            :value="item.id"/>
        </el-select>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="cancelHandler">
        Cancel
      </el-button>

      <el-button
        :loading="loading"
        type="primary"
        @click="saveHandler">
        Guardar
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      // list of available roles
      roles: [],

      // group information
      group: {
        name: "",
        roles: [],
      },

      // validation rules for the form
      rules: {
        name: [
          { required: true, message: "Introduza  um  grupo", trigger: "blur" },
        ],
        roles: [
          {
            required: true,
            type: "array",
            min: 1,
            message: "Introduza  pelo menos um grupo",
            trigger: "blur",
          },
        ],
      },

      // other state vars
      loading: false,
      dialogVisible: false,
    };
  },

  created() {
    // get all available roles
    this.$stellar.action("entrust.getRoles").then(({ roles }) => {
      this.roles = roles;
    });
  },

  methods: {
    /**
     * Set the group to be edited.
     */
    setGroup(group) {
      // set the group for edit
      this.group = Object.assign({}, group);

      // get only the group ids
      this.group.roles = this.group.roles.map(item => item.id);

      // put the form in edit mode
      this.edit = true;
    },

    /**
     * Handle the click on the save button.
     */
    saveHandler() {
      // enable loading
      this.loading = true;

      // first we validate the form
      this.$refs.form.validate(valid => {
        if (!valid) {
          this.loading = false;
          return false;
        }

        if (this.edit) {
          this.editGroup();
        } else {
          this.createGroup();
        }
      });
    },

    createGroup() {
      // call the API to create a new group
      this.$stellar
        .action("contacts.createGroup", this.group)
        .then(({ group }) => {
          // disable loading
          this.loading = false;

          // emit an 'created' event
          this.$emit("created", group);

          // show success message
          this.$message.success("Grupo criado");

          // hide this dialog
          this.show(false);
        });
    },

    /**
     * Save the chnages in the group data.
     */
    editGroup() {
      // call the API to store the changes
      this.$stellar
        .action("contacts.editGroup", this.group)
        .then(({ group }) => {
          // disable loading
          this.loading = false;

          // emit an 'updated' event
          this.$emit("updated", group);

          // show success message
          this.$message.success("Grupo editado");

          // hide this dialog
          this.show(false);
        });
    },

    /**
     * Handler for the cancel action.
     */
    cancelHandler() {
      this.show(false);
    },

    /**
     * Change the form visibility.
     *
     * @type Boolean
     */
    show(val = true) {
      if (!val) {
        this.reset();
      }

      // change the dialog visibility
      this.dialogVisible = val;
    },

    /**
     * Reset form state.
     */
    reset() {
      // reset form state
      this.$refs.form.resetFields();

      // reset group information
      this.group = {
        name: "",
        roles: [],
      };

      // reset other vars
      this.loading = false;
      this.dialogVisible = false;
    },
  },
};
</script>
