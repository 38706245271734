
<div class="warranty-list-in-warranty">
  <el-table
    :data="data"
    :loading="loading">
    <el-table-column
      :width="100"
      label="N. Ficha"
      prop="repairNumber" />

    <el-table-column
      :width="300"
      label="Equipamento">
      <template slot-scope="scope">
        {{ scope.row | formatEquipmentName }}
      </template>
    </el-table-column>

    <el-table-column
      :width="160"
      label="Imei/SN">
      <template slot-scope="scope">
        <span
          v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber)">
          {{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber
          }}
        </span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column
      :width="100"
      label="Entidade">
      <template slot-scope="scope">
        {{ scope.row.warrantyEntity.name }}
      </template>
    </el-table-column>

    <el-table-column
      label="Anomalia"
      prop="problemDescription" />

    <el-table-column :width="120">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="success"
          @click="performEntranceHandler(scope.row)">
          Dar Entrada
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <WarrantyEntranceForm
    :repair="selectedRepair"
    :visibility.sync="dialogVisibility"
    @update="fetchRepairs" />
</div>
