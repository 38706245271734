<template>
  <div class="materialGroupClientForm">
    <el-table :data="formatedEntries">
      <el-table-column
        label="Grupo de Clientes"
        prop="customerGroup.name" />

      <el-table-column label="Margem" >
        <template slot-scope="scope">{{ scope.row.profitMargin | money }}</template>
      </el-table-column>

      <el-table-column label="Ações" align="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="onClickDeleteHandler(scope.$index)">
            Remover
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-form :data="formData" class="form">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-select v-model="formData.customerGroup" class="customerGroupSelect">
            <el-option v-for="group in customerGroups" :key="group.id" :value="group.id" :label="group.name"/>
          </el-select>
        </el-col>

        <el-col :span="10">
          <el-input v-model="formData.profitMargin" type="number"/>
        </el-col>

        <el-col :span="4">
          <el-button type="success" @click="onAddNewEntryHandler">
            Adicionar
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { get } from "vuex-pathify";

import { ECustomerGroupActions } from "../../clients/services/store/customer-groups/customer-groups.types";
import { ICustomerGroup } from "../../clients/types/customer-group.interface";

const DEFAULT_FORM_DATA = {
  customerGroup: null,
  profitMargin: 0,
};

export default Vue.extend({
  props: {
    entries: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formData: { ...DEFAULT_FORM_DATA },
    };
  },

  computed: {
    customerGroups: get("customerGroups/groups"),

    formatedEntries(): any {
      return this.entries.map((entry: any) => ({
        ...entry,
        customerGroup: this.customerGroups.find(
          (group: ICustomerGroup) => group.id === entry.customerGroup,
        ),
      }));
    },
  },

  async created() {
    await this.$store.dispatch(ECustomerGroupActions.FETCH_ALL);
  },

  methods: {
    onAddNewEntryHandler() {
      const newEntry = { ...this.formData };
      const newList = [...this.entries, newEntry];
      this.$emit("update:entries", newList);

      this.formData = { ...DEFAULT_FORM_DATA };
    },

    onClickDeleteHandler(indexToRemove: number) {
      const newList = this.entries.filter(
        (_, index) => index !== indexToRemove,
      );
      this.$emit("update:entries", newList);
    },
  },
});
</script>

<style lang="scss" scoped>
.form {
  margin-top: 20px;
}

.customerGroupSelect {
  display: block;
  width: 100%;
}
</style>
