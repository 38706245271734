<template>
  <div class="tp-RepairView">
    <!-- see previous repair -->
    <PrevRepair
      :visibility.sync="prevRepairVisibility"
      :previous-repair="previousRepair"
      :loading="loading" />

    <!-- send equipment to warranty -->
    <el-dialog
      v-loading.fullscreen="loading"
      :visible.sync="warrantyVisibility"
      class="tp-SendtoWarrantyDialog"
      title="Enviar para garantia"
      size="tiny">
      <el-select
        v-model="entity"
        placeholder="Entidade">
        <el-option
          v-for="ent in entities"
          :key="ent.value"
          :label="ent.name"
          :value="ent.id" />
      </el-select>

      <el-button
        type="success"
        @click="sendToWarrantyEntity(entity)">
        Enviar
      </el-button>
    </el-dialog>

    <!-- internal observations ----------------------------------------------- -->
    <el-dialog
      v-loading.fullscreen="internalObsForm.loading"
      :visible.sync="internalObsForm.visibility"
      title="Observações Internas">
      <el-form label-position="top">
        <el-form-item>
          <el-input
            v-model="internalObsForm.value"
            placeholder="Observações"
            type="textarea"
            row="6"/>
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="internalObsForm.visibility = false">Cancelar</el-button>
        <el-button
          type="primary"
          @click="saveInternalObservationHandler">Guardar</el-button>
      </span>
    </el-dialog>

    <!-- receipt ------------------------------------------------------------- -->
    <repair-receipt
      v-if="typeof innerRepair.clientEquipment === 'object' && equipmentType"
      ref="receipt"
      :repair="innerRepair"
      :client="innerRepair.client"
      :equipment="innerRepair.clientEquipment"
      :equipment-type="equipmentType"
      :print-value="imprimeValor"/>

    <!-- Create quotation ---------------------------------------------------- -->
    <el-dialog
      :visible.sync="quotation.visibility"
      title="Dar orçamento"
      size="tiny">
      <el-form label-position="top">
        <el-form-item label="Valor">
          <el-input v-model="quotation.data.value">
            <template slot="append">€</template>
          </el-input>
        </el-form-item>

        <!-- duration -->
        <el-form-item label="Duração da Reparação">
          <el-select
            v-model="quotation.data.duration"
            :style="{ width: '100%' }"
            placeholder="Duração">
            <el-option
              label="Um dia"
              value="Um dia"/>
            <el-option
              label="Uma semana"
              value="Uma semana"/>
            <el-option
              label="Duas semanas"
              value="Duas semanas"/>
            <el-option
              label="4 a 7 semanas"
              value="4 a 7 semanas"/>
          </el-select>
        </el-form-item>

        <el-form-item label="Descrição">
          <el-input
            v-model="quotation.data.description"
            type="textarea"/>
        </el-form-item>

        <el-checkbox
          v-if="!isExternalTechnician && innerRepair.client.isStore === false"
          v-model="quotation.data.byPhone">
          Contactar por telefone
        </el-checkbox>
      </el-form>

      <!-- actions -->
      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="quotation.visibility = false">
          Cancelar
        </el-button>
        <el-button
          type="primary"
          @click="createQuotationHandler">
          Orçamentar
        </el-button>
      </span>
    </el-dialog>

    <!-- finalize dialog ----------------------------------------------------- -->

    <el-dialog
      v-loading.fullscreen="finishForm.loading"
      :visible.sync="finishForm.visibility"
      title="Finalizar Reparação">
      <el-form label-position="top">
        <!-- observation -->
        <el-form-item label="Nota para o Cliente">
          <el-select
            v-model="finishForm.data.problem"
            :style="{ width: '100%' }"
            class="tp-RepairView__clientNote"
            @change="problemAdd">
            <el-option
              v-for="item in finishForm.messages"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>

          <el-input
            v-model="finishForm.data.observation"
            type="textarea"
            row="6"/>
        </el-form-item>

        <!-- repair value -->
        <el-form-item
          v-if="innerRepair.technician && !innerRepair.technician.external"
          label="Valor da Reparação">
          <el-input
            v-model="finishForm.data.value"
            :min="0"
            placeholder="Nota"
            type="number">
            <template slot="append">€</template>
          </el-input>
        </el-form-item>

        <!-- close without permission -->
        <el-checkbox v-model="finishForm.data.withoutPermission">
          Fechado sem permissão
        </el-checkbox>
      </el-form>

      <!-- footer -->
      <span
        slot="footer"
        class="dialog-footer">
        <!-- cancel button -->
        <el-button @click="finishForm.visibility = false">Cancelar</el-button>
        <!-- save button -->
        <el-button
          type="primary"
          @click="finishRepairHandler">Terminar</el-button>
      </span>
    </el-dialog>

    <!-- Closed without repair ----------------------------------------------- -->

    <close-without-repair-dialog
      :repair="innerRepair"
      :visibility.sync="toProblematicVisibility"
      @cancel="toProblematicVisibility = false"/>

    <!-- client dialog -->
    <el-dialog
      :visible.sync="seeClientDialog"
      title="Informação do cliente"
      size="small">
      <client-card :client="client"/>
    </el-dialog>

    <!-- material request panel ---------------------------------------------- -->

    <material-to-repair
      ref="materialAdd"
      :repair="innerRepair"
      @added="fetchRepairMaterials"/>

    <!-- repair panel -------------------------------------------------------- -->

    <div
      v-if="storeRepair"
      class="tp-RepairView__container">
      <div class="tp-RepairView__topBar">
        <!-- repair number -->
        <div class="tp-RepairView__topBar__action">
          <div class="icon fa fa-hashtag"/>
          <div class="content">{{ storeRepair.repairNumber }}</div>
        </div>

        <!-- box -->
        <div class="tp-RepairView__topBar__action">
          <div class="icon fa fa-archive"/>
          <div class="content">{{ storeRepair.storeBox || '-' }}</div>
        </div>

        <!-- priority -->
        <div class="tp-RepairView__topBar__action">
          <div
            :class="[ 'is-' + storeRepair.priority ]"
            class="icon fa fa-circle tp-RepairView__priority"/>
          <div
            class="content">
            {{ storeRepair.priority | priority }}
          </div>
          <el-button
            v-if="storeRepair.state !== 'delivered'"
            icon="el-icon-edit"
            size="small"
            class="tp-ProblemDescription__entry__title__editBtn"
            @click="repairPriorityEditHandler" />
        </div>

        <!-- store entry number -->
        <div class="tp-RepairView__topBar__action">
          <div class="icon fa fa-building"/>
          <div class="content">{{ storeRepair.storeRepairIdentifier || '-' }}</div>
          <el-button
            v-if="storeRepair.state !== 'delivered'"
            icon="el-icon-edit"
            size="small"
            class="tp-ProblemDescription__entry__title__editBtn"
            @click="storeIdentifierEditHandler" />
        </div>

        <div class="tp-RepairView__topBar__action">
          <div class="icon fa fa-handshake-o"/>
          <div class="content">
            <span v-if="!storeRepair.complaint &&!storeRepair.quotation">
              -
            </span>

            <!-- when is an external technician that is viewing, always show the quotation -->
            <span v-if="!storeRepair.complaint && isExternalTechnician">
              {{ storeRepair.quotation | money }}
            </span>

            <!-- Isn't an external technician and is as complaint -->
            <span v-if="!isExternalTechnician && storeRepair.complaint">
              <span v-if="storeRepair.value">
                -
              </span>
              ({{ storeRepair.quotation | money }})
            </span>

            <!-- reject quotation -->
            <span
              v-if="storeRepair.state === 'waiting_delivery' && storeRepair.quotationApproved === false"
              class="reject_quotation_delivery">
              RECUSADO
            </span>

            <!-- only show's the quotation nothing more -->
            <span v-else>
              <template v-if="storeRepair.quotation">
                {{ storeRepair.quotation | money }}
              </template>
            </span>
            <el-button
              v-if="storeRepair.state !== 'delivered' && $auth.is('admin')"
              icon="el-icon-edit"
              size="small"
              class="tp-ProblemDescription__entry__title__editBtn"
              @click="ValueQuotationEditHandler" />
          </div>
        </div>

        <div class="tp-RepairView__topBar__action">
          <div class="icon fa fa-money"/>
          <div class="content">
            <span v-if="!storeRepair.complaint && !storeRepair.value">
              -
            </span>

            <!-- when is an external technician that is viewing, always show the quotation -->
            <span v-if="!storeRepair.complaint && isExternalTechnician">
              -
            </span>

            <!-- Isn't an external technician and is as complaint -->
            <span v-if="!isExternalTechnician && storeRepair.complaint">
              <span v-if="storeRepair.value">
                {{ storeRepair.value | money }}
              </span>
              -
            </span>

            <!-- only show's the value nothing more -->
            <span v-else>
              <template v-if="storeRepair.value">
                {{ storeRepair.value | money }}
              </template>
            </span>
          </div>
          <el-button
            v-if="storeRepair.state !== 'delivered' && $auth.is('admin')"
            icon="el-icon-edit"
            size="small"
            class="tp-ProblemDescription__entry__title__editBtn"
            @click="ValueQuotationEditHandler" />
        </div>
        <!-- state -->
        <div class="tp-RepairView__topBar__action">
          <RepairState :state="storeRepair.state"/>
          <span v-if="storeRepair.state==='in_warranty'">{{ entity }}</span>
        </div>
      </div>

      <!-- content -->
      <div class="tp-RepairView__content">
        <!-- main section content -->
        <div class="tp-RepairView__mainSection">
          <!-- basic information -->
          <basicInfo
            :equipment="equipment"
            :repair.sync="storeRepair"
            :equipment-type="equipmentType"
            :times-in-store="timesInStore"
            :loading="isLoading" />

          <!-- dynamic attributes -->
          <EquipmentDetails :repair="innerRepair" :equipmentType="equipmentType" />

          <!-- problem description -->
          <problem-description
            :repair="innerRepair"
            :loading.sync="loading"
            class="tp-RepairView__entry"/>

          <div class="tp-RepairView__entry is-internalObservation">
            <!-- internal observations -->
            <div>
              <h2 class="title">
                <span>Observações internas</span>
                <el-button
                  v-if="storeRepair.state !== 'delivered'"
                  icon="el-icon-edit"
                  size="mini"
                  class="tp-ProblemDescription__entry__title__editBtn"
                  @click="internalObservationHandler"/>
              </h2>
              <div class="content">
                <p v-if="storeRepair.internalObservation">{{ storeRepair.internalObservation }}</p>
                <p v-else>-</p>
              </div>
            </div>

            <!-- info to the client -->
            <div>
              <h2 class="title">
                <span>Informação para o Cliente</span>
                <el-button
                  v-if="canEditInformationToClient"
                  icon="el-icon-edit"
                  size="mini"
                  class="tp-ProblemDescription__entry__title__editBtn"
                  @click="editInformationToClientHandler" />
              </h2>
              <p v-if="storeRepair.repairObservation">{{ storeRepair.repairObservation }}</p>
              <p v-else>-</p>
            </div>
          </div>

          <!-- pendent requests -->
          <material-requests :repair="innerRepair.id"/>

          <!-- used material -->
          <div class="tp-RepairView__entry is-material">
            <h2 class="title">
              <span>Material Usado</span>
              <el-button
                v-if="storeRepair.quotationApproved !== false && storeRepair.state === 'in_repair' && userIsTechnician"
                :loading="loading"
                type="text"
                @click="() => this.$refs.materialAdd.show()">
                Adicionar
              </el-button>
            </h2>

            <div class="content">
              <div v-if="innerRepair.materials.length > 0">
                <el-table
                  v-loading="loading"
                  :data="innerRepair.materials"
                  stripe>
                  <el-table-column
                    :width="70"
                    property="quantity"
                    label="Qtn."/>

                  <el-table-column label="Descrição">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>

                  <!-- store box -->
                  <el-table-column
                    :width="100"
                    property="storeBox"
                    label="Caixa"
                    align="center"/>

                  <el-table-column label="Disponivel a">
                    <template slot-scope="scope">
                      {{ arrivedMat(scope.row) }}
                    </template>
                  </el-table-column>

                  <!-- show price without taxas -->
                  <el-table-column
                    :width="100"
                    label="Preço"
                    align="center">
                    <template slot-scope="scope">
                      {{ scope.row.price | money }}
                    </template>
                  </el-table-column>

                  <!-- show price with taxes -->
                  <el-table-column
                    :width="150"
                    label="Preço C/ IVA"
                    align="center">
                    <template slot-scope="scope">
                      {{ (scope.row.price * 1.23) | money }}
                    </template>
                  </el-table-column>

                  <!-- show button to return the material -->
                  <el-table-column
                    :width="100"
                    label="">
                    <template slot-scope="scope">
                      <el-button
                        :disabled="isReturnBtnDisabled"
                        size="small"
                        type="warning"
                        @click="returnMaterialHandler(scope.row)">
                        Devolver
                      </el-button>
                    </template>
                  </el-table-column>

                  <!-- show button to return the material -->
                  <el-table-column
                    :width="100"
                    label="">
                    <template slot-scope="scope">
                      <el-button
                        :disabled="isReturnBtnDisabled"
                        size="small"
                        type="danger"
                        @click="returnDamagedMaterialHandler(scope.row)">
                        Defeituoso
                      </el-button>
                    </template>
                  </el-table-column>

                  <!-- show button to return broken material -->
                  <el-table-column
                    :width="100"
                    label="">
                    <template slot-scope="scope">
                      <el-button
                        :disabled="isReturnBtnDisabled"
                        size="small"
                        type="warning"
                        @click="returnBrokenMaterialHandler(scope.row)">
                        Partido
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <div style="margin-top: 10px; text-align: right">
                  <span>Total de material usado: </span>
                  <b>{{ matTotal | money }}</b>
                  <b>/ {{ 1.23 * matTotal | money }}</b>
                </div>
              </div>
              <div
                v-else
                class="no-items">
                <p>Não foi usado material!</p>
              </div>
            </div>
          </div>

          <HistoryPanel :repair-id="repair.id" />
        </div>
      </div>

      <RepairBottomBar
        :isTechnicianUser="userIsTechnician"
        @printReceipt="printReceipt"
        @sendToWarranty="sendToWarranty"
        @showQuotation="quotation.visibility=true"
        @finish="finishHandler"
        @prevRepair="getPrevRepair" />
    </div>

    <ChangeStoreNumberModal
      v-if="modalsVisibility.changeStoreNumber"
      ref="changeStoreNumberModal"
      @closed="modalsVisibility.changeStoreNumber = false" />

    <ChangeRepairPriorityModal
      v-if="modalsVisibility.changeRepairPriority"
      ref="changeRepairPriorityModal"
      @closed="modalsVisibility.changeRepairPriority = false" />

    <ChangeInformationToClientModal
      v-if="modalsVisibility.changeInformationToClient"
      ref="changeInformationToClientModal"
      @closed="modalsVisibility.changeInformationToClient = false" />

    <ChangeValueQuotationModal
      v-if="modalsVisibility.changeValueQuotation"
      ref="changeValueQuotationModal"
      @closed="modalsVisibility.changeValueQuotation = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import clone from "ramda/src/clone";

import RepairState from "@/components/ui/repair-state.vue";

import { ACTION_ENTITY_FETCH } from "@/services/store/modules/entities/entities-types";

import CloseWithoutRepairDialogCmp from "./CloseWithoutRepairDialog.vue";
import EquipmentDynamicFormCpm from "./EquipmentDynamicForm.vue";
import MaterialRequestCmp from "../materialRequest/MaterialRequestToRepair.vue";
import ClientCardCmp from "../client/ClientCard.vue";
import ReceiptCmp from "./Receipt.vue";
import MaterialRequests from "./MaterialRequests.vue";
import PrevRepair from "./prev-repair.vue";
import HistoryPanel from "./history/repair-history.vue";
import RepairBottomBar from "./RepairBottomBar.vue";
import EquipmentDetails from "./view/EquipmentDetails.vue";

import BasicInfo from "./view/BasicInfo.vue";
import ProblemDescription from "./view/ProblemDescription.vue";

import ChangeStoreNumberModal from "./modals/change-store-number-modal.vue";
import ChangeRepairPriorityModal from "./modals/change-repair-priority-modal.vue";
import ChangeInformationToClientModal from "./modals/change-information-to-client-modal.vue";
import ChangeValueQuotationModal from "./modals/change-value-quotation-modal.vue";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";
import { get } from "vuex-pathify";
import { format } from "date-fns";
import { DATE_DEFAULT_NUMERIC } from "@/services/formatters";

// var to store a timer to make the resize event fluid
let resizeTimer = null;

export default {
  name: "RepairView",

  components: {
    "close-without-repair-dialog": CloseWithoutRepairDialogCmp,
    "material-to-repair": MaterialRequestCmp,
    "dynamic-attributes": EquipmentDynamicFormCpm,
    "client-card": ClientCardCmp,
    "repair-receipt": ReceiptCmp,
    BasicInfo,
    HistoryPanel,
    MaterialRequests,
    ProblemDescription,
    RepairState,
    ChangeStoreNumberModal,
    ChangeRepairPriorityModal,
    ChangeInformationToClientModal,
    ChangeValueQuotationModal,
    PrevRepair,
    RepairBottomBar,
    EquipmentDetails,
  },

  props: {
    repair: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      matRequests: [],
      // other states
      loading: false,
      warrantyVisibility: false,
      entity: null,

      modalsVisibility: {
        changeStoreNumber: false,
        changeRepairPriority: false,
        changeInformationToClient: false,
        changeValueQuotation: false,
      },

      // inner repair data
      innerRepair: {},

      // contact dialog data
      createContactDialogVisible: false,
      contactForm: {
        message: "",
        repair: null,
        client: null,
        isContactRequest: true,
      },

      // observation for edit
      internalObsForm: {
        visibility: false,
        value: "",
        loading: false,
      },

      // finish dialog
      finishForm: {
        visibility: false,
        loading: false,

        data: {
          observation: "",
          value: 0.0,
          withoutPermission: false,
          problem: "",
          fixed: false,
        },
        messages: [
          "Reparação do circuito de audio",
          "Reparação do circuito de imagem",
          "Substituição do touch",
          "substituição do lcd",
          "Substituição do modulo lcd touch",
          "Reparação do circuito de carga",
          "Reparação do circuito de power",
          "Reparação do circuito de RF",
          "Reparação do circuito do leitor SIM",
          "Não foi possível testar. Falta código de segurança",
        ],
      },

      // equipment type
      equipmentType: null,

      need: {
        materialId: "",
        type: "repair",
        repairId: "",
        clientId: null,
        observation: "",
        quantity: "",
        state: "waiting_validation",
        validated: "true",
      },
      materials: [],
      requests: [],
      repairObservation: "",
      rep_material: [],
      value: -1,

      errorMessage: null,

      // quotation dialog
      quotation: {
        visibility: false,
        data: {
          value: 0,
          byPhone: false,
          description: "",
          duration: "Um dia",
        },
      },

      size: 5,
      technicianType: "",
      seeClientDialog: false,
      // client to fill dialog
      client: null,

      // dialogs visibility
      toProblematicVisibility: false,
      imprimeValor: false,
      prevRepairVisibility: false,
      previousRepair: null,
    };
  },

  computed: {
    ...mapGetters([
      "user",
      "workHasUrgent",
      "workHasOpenRepair",
      "isExternalTechnician",
      "workLastUpdate",
      "workLoading",
    ]),

    ...mapGetters("entities", ["entities"]),

    ...mapState("repair", {
      isRepairLoading: state => state.loading,
    }),

    storeRepair: get("repair/repair"),
    timesInStore: get("repair/timesInStore"),

    equipment: get("equipment/equipment"),

    isLoading() {
      return this.isRepairLoading || this.workLoading;
    },

    /**
     * Compute the total of used material.
     */
    matTotal() {
      return this.innerRepair.materials.reduce((total, item) => {
        total += item.price * item.quantity;
        return total;
      }, 0);
    },

    /**
     * Check if the current user is the technician.
     */
    userIsTechnician() {
      if (!this.innerRepair.technician || typeof this.innerRepair.technician === "string") {
        return false;
      }
      return this.innerRepair.technician.id === this.user.personId;
    },

    /**
     * Check if the return btn can be shown.
     *
     * Rules:
     * - the user must be the technician
     * - the state must be 'in_repair'
     */
    isReturnBtnDisabled() {
      if (typeof this.innerRepair.technician === "string") {
        return true;
      }

      return !(
        this.innerRepair.technician &&
        this.innerRepair.technician.id === this.user.personId &&
        this.innerRepair.state === "in_repair"
      );
    },

    canEditInformationToClient() {
      return ["waiting_delivery", "closed_without_permission", "in_transit", "problematic"].includes(
        this.storeRepair.state,
      );
    },
  },

  watch: {
    repair: {
      handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) {
          return;
        }

        // reload the repair data
        this.reloadRepairData(newVal);
      },
      deep: true,
      immediate: true,
    },

    storeRepair(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.adjustContainerHeight();
      }
    },
  },

  async created() {
    // get warranty entities
    this.fetchEntities();

    if (this.innerRepair.warrantyEntity) {
      this.entity = (await this.$stellar.action("getEntity", {
        id: this.innerRepair.warrantyEntity,
      })).entity.name;
    }

    // load the list of work to be done, if that wasn't already loaded
    this.getWork();

    this.arrivedDate();
  },

  mounted() {
    // adjust the height of the container and register a resize listener
    this.adjustContainerHeight();
    window.addEventListener("resize", this.adjustContainerHeight);
  },

  async destroy() {
    await this.$store.dispatch(ERepairActions.CLEAN);
  },

  methods: {
    ...mapActions([
      "createQuotation",
      "putWorkInPause",
      "putWorkInRepair",
      "putWorkToDelivery",
      "putWorkInTransit",
      "getWork",
    ]),

    ...mapActions("entities", {
      fetchEntities: ACTION_ENTITY_FETCH,
    }),

    sendToWarranty() {
      this.warrantyVisibility = true;
    },

    /**
     *
     */
    arrivedMat(row) {
      const requestEntry = this.matRequests.find(item => item.material === row.material);

      if (!requestEntry || !requestEntry.arrivedDate) {
        return "-";
      }

      return format(new Date(requestEntry.arrivedDate), DATE_DEFAULT_NUMERIC);
    },

    /**
     * return material data arrived
     */
    arrivedDate() {
      this.repair.materials.forEach(async entry => {
        const { materialrequest } = await this.$stellar.action("getMaterialrequestByRepair", {
          repair: this.repair.id,
          material: entry.material,
        });

        if (materialrequest) {
          this.matRequests.push(materialrequest);
        }
      });
    },

    async sendToWarrantyEntity(entity) {
      if (entity === null) {
        return this.$message.info("Deve escolher a entidade para enviar o equipamento");
      }

      this.loading = true;
      this.innerRepair.state = "waiting_warranty_dispatch";
      this.innerRepair.warrantyEntity = entity;

      await this.$stellar.action("editRepair", this.innerRepair);

      this.loading = false;
      this.warrantyVisibility = false;

      this.$message.success("Equipamento enviado para a entidade externa");

      this.$store.dispatch(ERepairActions.RELOAD);
    },

    /**
     * open a dialog with client attributs
     */
    seeClient(client) {
      this.client = client;
      this.seeClientDialog = true;
    },

    /**
     * Create a new quotation.
     */
    createQuotationHandler() {
      // enable loading
      this.loading = true;

      // hide the dialog
      this.quotation.visibility = false;

      // if user is the external technician the quotation must be performade by
      // phone
      if (this.isExternalTechnician) {
        this.quotation.data.byPhone = true;
      }

      // update the repair information
      this.createQuotation([this.repair.id, this.quotation.data]).then(_ => {
        // disable loading
        this.loading = false;

        // show a success message
        this.$message.success("O cliente irá ser contactado");

        // redirect the technician to the list of work
        this.$router.push({ name: "work.list" });
      });
    },

    /**
     * Reload the repair data.
     */
    async reloadRepairData(newRepair) {
      this.innerRepair = clone(newRepair);

      try {
        if (typeof this.innerRepair.technician === "string") {
          const { technician } = await this.$stellar.action("getTechnician", {
            id: this.innerRepair.technician,
          });

          this.innerRepair.technician = technician;
        }
      } catch (_) {
        this.$message({
          message: "Impossivel obter informações do tecnico",
          type: "error",
        });
      }

      try {
        if (typeof this.innerRepair.clientEquipment === "string") {
          const { clientequipment } = await this.$stellar.action("getClientequipment", {
            id: this.innerRepair.clientEquipment,
          });
          this.innerRepair.clientEquipment = clientequipment;
        }
      } catch {
        this.$message({
          message: "Impossivel obter informações do equipamento",
          type: "error",
        });
      }

      try {
        const { equipment } = await this.$stellar.action("getEquipmentType", {
          id: this.innerRepair.clientEquipment.equipmentType,
        });
        this.equipmentType = equipment;
      } catch (_) {
        this.$message({
          message: "Impossivel obter informações do tipo de equipamento",
          type: "error",
        });
      }
    },

    /**
     * Adjust the container height.
     */
    adjustContainerHeight() {
      // destroy any previours timer
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }

      // ignore when the data isn't ready
      if (!this.storeRepair) {
        return;
      }

      // create a new timeout
      resizeTimer = setTimeout(_ => {
        const container = document.querySelector(".tp-RepairView__container");

        if (!container) {
          return;
        }

        const header = document.querySelector(".ui-header_content");
        container.style.height = document.documentElement.clientHeight - header.clientHeight + "px";
      }, 400);
    },

    /**
     * Only reload the material.
     *
     * @return [type] [description]
     */
    fetchRepairMaterials() {
      // enter in loading mode
      this.loading = true;

      // get the repair
      this.$stellar.action("getRepair", { id: this.innerRepair.id }).then(({ repair }) => {
        // save materials
        this.innerRepair.materials = repair.materials;

        // disable loading
        this.loading = false;
      });
    },

    /**
     * Open the finish form to end the repair.
     */
    finishHandler() {
      if (this.innerRepair.quotationApproved === false && this.innerRepair.materials.length > 0) {
        this.$message.warning("Tem que devolver o material!");
        return;
      }

      if (this.innerRepair.quotationApproved === false || this.innerRepair.state === "problematic") {
        return this.$confirm("Quer mesmo fechar a reparação?", "Fechar reparação", {
          confirmButtonText: "Continuar",
        })
          .then(_ => this.finishRepairHandler())
          .catch(_ => {});
      }

      // ask to the user if the equipment was repared
      this.$confirm("O problema ficou resolvido?", "Fechar Reparação", {
        confirmButtonText: "Ficou",
        cancelButtonText: "Não",
        type: "warning",
      })
        .then(_ => {
          // reset the data
          this.finishForm.data.fixed = true;
          this.finishForm.data.observation = this.innerRepair.repairObservation;
          if (this.innerRepair.technician.external) {
            this.finishForm.data.value = this.innerRepair.quotationApproved !== false ? this.innerRepair.value : 0;
          } else {
            this.finishForm.data.value = this.innerRepair.quotationApproved !== false ? this.innerRepair.quotation : 0;
          }
          this.finishForm.data.withoutPermission = false;
          // show the form
          this.finishForm.visibility = true;

          if (this.innerRepair.materials.length > 0) {
            this.$stellar.action("createSalesFromRepair", {
              materials: this.innerRepair.materials,
            });
          }
        })

        // show a dialog to select the reason
        .catch(_ => {
          if (this.innerRepair.materials.length > 0) {
            return this.$message.warning("Tem que devolver o material");
          }

          this.toProblematicVisibility = true;
          this.finishForm.data.fixed = true;
        });
    },

    /**
     * Finish the repair.
     */
    async finishRepairHandler() {
      if (this.innerRepair.state === "problematic") {
        this.finishForm.data.observation = this.innerRepair.repairObservation;

        try {
          await this.$confirm("Quer notificar o cliente?", "Notificar cliente", {
            confirmButtonText: "Notificar",
            cancelButtonText: "Não",
          });

          this.finishForm.data.sendNotification = true;
        } catch (e) {
          this.finishForm.data.sendNotification = false;
        }
      }

      const isObservationRequired = !["remove_code", "unlock_sim", "remove_google_account", "software_update"].includes(
        this.innerRepair.attributes.problem,
      );
      const isObservationFieldEmpty = this.finishForm.data.observation.trim().length === 0;

      if (isObservationRequired && isObservationFieldEmpty && this.finishForm.visibility) {
        return this.$message.warning("Deve prencher a observação para o cliente.");
      }

      // enable loading
      this.finishForm.loading = true;

      // put the repair to be delivered
      let prevWork = null;
      if (this.isExternalTechnician === true) {
        prevWork = this.putWorkInTransit([this.innerRepair.id, this.finishForm.data]);
      } else {
        prevWork = this.putWorkToDelivery([this.innerRepair.id, this.finishForm.data]);
      }

      // after the execution disable loading, show a success message and
      // redirect to the work list
      prevWork.then(_ => {
        // disable loading
        this.finishForm.loading = false;

        // show a success message
        this.$message.success("Reparação finalizada");
        if (this.innerRepair.storeBox) {
          this.$alert("O dispositivo deve ser colocado na caixa  " + this.innerRepair.storeBox, {
            confirmButtonText: "Feito!",
          });
        }
        if (this.innerRepair.state === "problematic") {
          this.$router.go(-1);
        }

        // redirect to the work list
        this.$router.push({ name: "work.list" });
      });
    },

    /**
     * Handler to return material.
     *
     * @param {object} entry  Material entry.
     */
    async returnMaterialHandler(entry) {
      /**
       * Make an API call to remove the material from the repair.
       *
       * @param  {string} material  Material identifier
       * @param  {int} quantity     Quantity to return
       * @param  {string} type      Type to return
       */
      const returnMaterialInternal = async (material, quantity = 1, type) => {
        // enable loading
        this.loading = true;

        // make an API call to return the material.
        try {
          await this.$stellar.action("returnRepairMaterial", {
            repair: this.repair.id,
            material,
            quantity,
            type,
          });
        } catch (e) {
          // disable loading
          this.loading = false;

          // show error message
          return this.$message.error("Operação invalida!");
        }

        // update the repair materials
        this.fetchRepairMaterials();

        // disable loading
        this.loading = false;

        // show a success message
        this.$alert(`Deve repor o material na caixa: ${entry.storeBox}`, "Devolução de Material", {
          confirmButtonText: "Ok",
        });
      };

      // if the selected material as move then one pieces we ask the user, how
      // many he wants return.
      if (entry.quantity > 1) {
        const { value } = await this.$prompt("Indique a quantidade a devolver", "Devolução de Material", {
          confirmationButtonText: "Devolver",
          cancelButtonText: "Cancelar",
          inputPattern: /^[\d]*$/,
          inputErrorMessage: "Valor invalido",
        });

        return returnMaterialInternal(entry.material, value, entry.type);
      }

      // otherwise, return just one piece
      return returnMaterialInternal(entry.material, 1, entry.type);
    },

    /**
     * move material do damage material
     * @type {[type]}
     */
    returnDamagedMaterialHandler(entry) {
      this.$confirm("Quer devolver o material como defeituoso", "Material com defeito", {
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        type: "warning",
      }).then(_ => {
        this.$prompt("Indique o defeito do material", "Defeito de Material", {
          confirmationButtonText: "Devolver",
          inputPattern: /.*/,
          cancelButtonText: "Cancelar",
          inputErrorMessage: "Valor invalido",
        }).then(async ({ value }) => {
          this.loading = true;
          // update the value on the API
          await this.$stellar.action("createDamagedmaterial", {
            material: entry.material,
            repair: this.innerRepair.id,
            technician: this.innerRepair.technician,
            observation: value,
          });
          // disable loading
          this.loading = false;

          // re-fetch materials
          this.fetchRepairMaterials();

          // success message
          this.$message.success("Material devolvido.");
        });
      });
    },

    /**
     * move material do damage material
     * @type {[type]}
     */
    returnBrokenMaterialHandler(entry) {
      this.$confirm("Quer devolver o material como partido", "Material com partido", {
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        type: "warning",
      }).then(_ => {
        this.$prompt("Indique como partiu o material", "Material partido", {
          confirmationButtonText: "Devolver",
          inputPattern: /.*/,
          cancelButtonText: "Cancelar",
          inputErrorMessage: "Valor invalido",
        }).then(async ({ value }) => {
          this.loading = true;
          // update the value on the API
          await this.$stellar.action("createBrokenMaterial", {
            material: entry.material,
            repair: this.innerRepair.id,
            technician: this.innerRepair.technician,
            observation: value,
          });
          // disable loading
          this.loading = false;

          // re-fetch materials
          this.fetchRepairMaterials();

          // success message
          this.$message.success("Material devolvido.");
        });
      });
    },

    // add problem to observation
    problemAdd() {
      if (this.finishForm.data.observation === "") {
        this.finishForm.data.observation += this.finishForm.data.problem;
      } else {
        this.finishForm.data.observation += "\n" + this.finishForm.data.problem;
      }
    },

    /**
     * Print the receipt.
     */
    async printReceipt() {
      this.imprimeValor = false;

      if (this.innerRepair.state === "delivered") {
        try {
          await this.$confirm("Quer o valor da reparação no talão?", "Talão com valor", {
            type: "warning",
            cancelButtonText: "Sem valor",
            confirmButtonText: "Com valor",
          });

          this.imprimeValor = true;
        } catch (e) {
          // Do nothing
        }
      }

      this.$nextTick(() => {
        this.$refs.receipt.printReceipt();
      });
    },

    /**
     * Handle the click on the internal observation.
     */
    internalObservationHandler() {
      // update the form data with the latest data on store and show the modal
      this.internalObsForm.value = this.storeRepair.internalObservation;
      this.internalObsForm.visibility = true;
    },

    /**
     * Save the internal observations changes.
     */
    async saveInternalObservationHandler() {
      this.internalObsForm.loading = true;

      await this.$store.dispatch(ERepairActions.UPDATE, {
        internalObservation: this.internalObsForm.value,
      });

      this.internalObsForm.loading = false;

      // close the dialog and show a success message
      this.internalObsForm.visibility = false;
      this.$message.success("Observações atualizadas.");
    },

    async storeIdentifierEditHandler() {
      this.modalsVisibility.changeStoreNumber = true;
      await this.$nextTick();
      this.$refs.changeStoreNumberModal.show();
    },

    async repairPriorityEditHandler() {
      this.modalsVisibility.changeRepairPriority = true;
      await this.$nextTick();
      this.$refs.changeRepairPriorityModal.show();
    },

    async editInformationToClientHandler() {
      this.modalsVisibility.changeInformationToClient = true;
      await this.$nextTick();
      this.$refs.changeInformationToClientModal.show();
    },
    async ValueQuotationEditHandler() {
      this.modalsVisibility.changeValueQuotation = true;
      await this.$nextTick();
      this.$refs.changeValueQuotationModal.show();
    },

    async getPrevRepair() {
      this.loading = true;
      this.prevRepairVisibility = true;
      this.previousRepair = await this.$stellar.action("getRepair", {
        id: this.repair.prevRepair,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.tp-RepairView {
  font-size: 11pt;
  height: 100%;

  /* variables */
  --repair-bar-bg: #fff;
  --repair-border-color: #e5e5e5;

  &__clientNote {
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
  }

  /** top Bar */
  &__topBar {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    order: 0;
    width: 100%;
    height: 45px;

    background: var(--repair-bar-bg);
    border-bottom: 1px solid var(--repair-border-color);

    /** each action */
    &__action {
      position: relative;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      border-right: 1px solid var(--repair-border-color);

      &:last-child {
        border-right: none;
      }

      /** icon */
      .icon {
        display: inline-block;

        color: #000;
        margin-right: 5px;
        font-size: 1.3em;
      }

      /** content **/
      .content {
        display: inline-block;

        font-size: 1.05em;
      }
    }
  }

  &__content {
    /* configure flex */
    flex: 1 1 auto;
    order: 1;

    /* enable scroll */
    overflow-y: auto;

    position: relative;
    width: 100%;
    height: 100%;
  }

  /** main section **/
  &__mainSection {
    overflow-y: scroll;
  }

  &__priority {
    &.is-normal {
      color: rgb(139, 215, 126);
    }

    &.is-urgent,
    ontime {
      color: rgb(247, 117, 117);
    }
  }

  /** bottom bar */
  &__bottomBar {
    /* config flex */
    flex: 0 0 45px;
    order: 2;

    display: flex;
    flex-direction: row-reverse;

    position: relative;
    width: 100%;
    padding: 10px;

    background: var(--repair-bar-bg);
    border-top: 1px solid var(--repair-border-color);

    & > div,
    & > .el-button {
      margin-left: 10px;
    }

    .noBlock {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }

  &__entry {
    position: relative;
    display: block;
    border-radius: 2px;
    padding: 25px 40px;
    margin-bottom: 10px;

    /* enable vertical scroll */
    overflow: hidden;
    overflow-y: scroll;

    background-color: white;
    color: #3d4a5a;
    box-shadow: 0 1px 5px #d7e4ed, 0 1px 10px rgba(215, 228, 237, 0.77);

    .title {
      font-size: 1.1em;
      margin-bottom: 20px;
      display: block;

      color: #303e49;
    }

    .content {
      width: 100%;
      display: block;
    }

    &.is-problem {
      span {
        display: inline-block;
      }
    }

    &.is-internalObservation {
      display: flex;
      justify-content: space-between;
      max-width: 97%;
      margin: 20px auto;
      padding: 25px 40px;

      & > div {
        width: 50%;

        &:first-child {
          width: calc(50% - 20px);
          margin-right: 20px;

          word-break: break-word;
        }
      }
    }

    &.is-material {
      max-width: 97%;
      margin: 20px auto;
      padding: 25px 40px;

      .no-items {
        width: 100%;
        padding: 10px;
        text-align: center;
        border-radius: 2px;

        color: var(--sidebar-color);
        background: var(--sidebar-active-color);
        border: 1px solid var(--sidebar-color);

        p {
          margin: 10px 0;
        }
      }
    }

    &.is-history {
      max-width: 97%;
      margin: 20px auto;
      padding: 25px 40px;
    }
  }

  &__serialNumber {
    display: block;
    margin-bottom: 10px;

    .title {
      font-size: 14px;
      color: #48576a;
      line-height: 1;
      margin-bottom: 3px;
    }

    .value {
      color: #000;
    }
  }
}

@media (max-width: 768px) {
  .tp-RepairView {
    &__content {
      display: block;
    }

    &__history {
      height: auto;
    }
  }
}

.tp_receipt {
  display: none;
}

.reject_quotation_delivery {
  color: #da1e35;
}
</style>
