export const printHTML = (content, printStyle = "", title = document.title) => {
  const iframeName = "frame-to-print";

  const iframe = document.createElement("iframe");
  iframe.name = iframeName;
  iframe.style.position = "absolute";
  iframe.style.top = "-100000px";
  document.body.appendChild(iframe);

  const frameDoc = iframe.contentWindow
    ? iframe.contentWindow
    : iframe.contentDocument.document
      ? iframe.contentDocument.document
      : iframe.contentDocument;

  frameDoc.document.open();
  frameDoc.document.write(`<html><head><title>${title}</title>`);
  frameDoc.document.write(`<style>${printStyle}</style>`);

  frameDoc.document.write("</head><body >");
  frameDoc.document.write(`<h1>${title}</h1>`);
  frameDoc.document.write(content);
  frameDoc.document.write("</body></html>");

  frameDoc.document.close();

  setTimeout(() => {
    window.frames[iframeName].focus();
    window.frames[iframeName].print();

    document.body.removeChild(iframe);
  }, 400);
};

export default printHTML;
