/* global localStorage */

import auth from "@/permissions";
import {getRouter} from "@/services/router/router";
import stellar from "@/services/stellar";

export const AUTH_CHANGE = "AUTH_CHANGE";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_EXTERNAL_TEC = "SET_EXTERNAL_TEC";

const state = {
  user: null,
  token: null,
  error: null,
  external: false,
};

const mutations = {
  [AUTH_CHANGE](state, { token, user }) {
    state.user = user;
    state.token = token;

    // set the permissions and the role on the authentication system
    if (user) {
      auth.setUser(
        user.role.permissions.concat(user.permissions),
        user.role.slug,
      );
    }
  },

  [AUTH_ERROR](state, error) {
    state.error = error;
  },

  [AUTH_LOGOUT](state) {
    state.user = null;
    state.error = null;
    state.token = null;

    // clean the user information on the authentication system
    auth.clean();
  },

  [SET_EXTERNAL_TEC](state, value) {
    state.external = value;
  },
};

const actions = {
  login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      // the email and password fields must be present
      if (data.email === "" || data.password === "") {
        commit(AUTH_ERROR, "invalid_credentials");
        return reject();
      }

      // reset the error
      commit(AUTH_ERROR, null);

      // make a call to the API to make the login
      stellar
        .action("auth.login", data)
        .then(response => {
          // commit the new data
          commit(AUTH_CHANGE, response);

          // save the auth token in the local storage
          localStorage.setItem("token", response.token);

          // save the user id info on the local storage (we only do this to speed
          // up the app startup without much code)
          localStorage.setItem("user", JSON.stringify(response.user));

          // resolve the promise
          resolve(response.user);

          // check if is a technician an is a external one
          return dispatch("checkTecExternal");
        })
        .catch(response => {
          if (response.error === "The user are disable") {
            commit(AUTH_ERROR, "account_disabled");
            reject(state.error);
            return;
          }

          commit(AUTH_ERROR, "invalid_credentials");

          reject(state.error);
        });
    });
  },

  async checkTecExternal({ commit, state }) {
    // ignore when the user isn't a technician
    if (state.user.role.slug !== "technician") {
      return commit(SET_EXTERNAL_TEC, false);
    }

    // get the technician data
    const { technician } = await stellar.action("getTechnician", {
      id: state.user.personId,
    });

    // update the value
    commit(SET_EXTERNAL_TEC, technician.external);
  },

  /**
   * This will make an API call to check the session state of the token.
   */
  async sessionState({ commit, dispatch }) {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    // if the token don't exists on the local storage reject now, and the user
    // must be redirected to the login page.
    if (token === null || user == null) {
      return;
    }

    try {
      commit(AUTH_CHANGE, { token, user: JSON.parse(user) });
    } catch {
      // Do nothing!
    }

    try {
      // call the API to check the session state
      const response = await stellar.action("auth.checkSession", { token });

      // update the user data
      commit(AUTH_CHANGE, { user: response.user, token });

      // check if is a technician an is a external one
      dispatch("checkTecExternal");
    } catch (response) {
      // perform a log out
      if (response.error.code && response.error.code === "expired_token") {
        dispatch("logout");
      }
    }
  },

  /**
   * Perform a logout.
   *
   * This remove the user info and the token from vuex store and from the local
   * storage.
   */
  async logout({ commit }) {
    // commit the logout changes
    commit("AUTH_LOGOUT");

    // remove the data from the localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    // redirect to the login page
    const router = await getRouter();
    router.push({ name: "login" });
  },
};

const getters = {
  user: state => state.user,
  isExternalTechnician: state => state.external,
};

// append the token in every request using the stellar client library
stellar.interceptors.push((params, next) => {
  // get the token from the local storage
  const token = localStorage.getItem("token");

  // whither the token exists we must append it to the params. The token should
  // already exists on the request params, otherwise we don't append anything.
  if (token !== null && params.token === undefined) {
    params.token = token;
  }

  next();
});

export default {
  actions,
  getters,
  state,
  mutations,
};
