<template>
  <section class="tp-Ongoing">
    <div
      v-if="readyToDeliveryData.length > 0"
      class="tp-ongoing__section">
      <h2 class="tp-Ongoing__title">Pronto para entrega</h2>

      <toDelivery-table
        :data="readyToDeliveryData"
        @update="fetchPage" />
    </div>

    <!-- search -->
    <div class="tp-ongoing__section">
      <el-input
        v-model="searchQuery"
        :width="70"
        class="tp-Ongoing__imeiSearch"
        placeholder="Pesquise por IMEI/SN..." />

      <div
        v-if="searchQuery.length > 0"
        class="tp-Ongoing__results-section">
        <h2 class="tp-Ongoing__title">Resultado da pesquisa</h2>

        <div
          v-if="searchData.loading && searchData.data.length === 0"
          class="loading-panel">
          <span>A carregar...</span>
        </div>

        <ongoing-table
          v-if="searchData.data.length > 0"
          v-loading="searchData.loading"
          :data="searchData.data"
          @update="fetchPage" />

        <!-- pagination -->
        <el-pagination
          :current-page="searchData.page"
          :total="searchData.total"
          :page-size="20"
          class="tp-Ongoing__pagination"
          layout="prev, pager, next"
          @current-change="searchPageChangeHandler" />
      </div>
    </div>

    <div class="tp-ongoing__section">
      <h2 class="tp-Ongoing__title">Em progresso</h2>

      <ongoing-table
        v-if="data.length > 0"
        v-loading="loading"
        :data="data"
        @update="fetchPage" />

      <!-- pagination -->
      <el-pagination
        :current-page="currentPage"
        :total="total"
        :page-size="20"
        class="tp-Ongoing__pagination"
        layout="prev, pager, next"
        @current-change="currentPageChange" />
    </div>
  </section>
</template>

<script>
import TableOngoingCmp from "./TableOngoing.vue";
import TableToDeliveryCmp from "./TableToDelivery.vue";

import { mapGetters, mapActions } from "vuex";

let timerIdentifier = null;

export default {
  components: {
    "ongoing-table": TableOngoingCmp,
    "toDelivery-table": TableToDeliveryCmp,
  },

  data() {
    return {
      loading: false,
      data: [],
      currentPage: 0,
      readyToDeliveryData: [],
      originalData: null,
      total: 0,

      searchQuery: "",
      searchResult: false,
      resultData: [],
      deliveredRepairs: [],

      // approve quotation
      approveQuotationData: {
        visibility: false,
        value: 0,
      },

      searchData: {
        data: [],
        page: 0,
        total: 0,
        loading: false,
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  watch: {
    async searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchData.page = 1;
      }

      this.performRepairSearch();
    },
  },

  /**
   * Operation to be performed when the component is created.
   */
  async created() {
    // fetch the first page
    this.fetchPage();

    // fetch all ready to delivery repairs
    this.fetchReadyToDelivery();
  },

  methods: {
    ...mapActions(["approveQuotation", "rejectQuotation"]),

    searchPageChangeHandler(newPage) {
      this.searchData.page = newPage;
      this.performRepairSearch();
    },

    performRepairSearch() {
      if (timerIdentifier !== null) {
        clearTimeout(timerIdentifier);
        timerIdentifier = null;
      }

      if (this.searchQuery.length < 3) {
        this.searchData = {
          page: 0,
          data: [],
          total: 0,
          loading: false,
        };
        return;
      }

      this.searchData.loading = true;

      timerIdentifier = setTimeout(async () => {
        const response = await this.$stellar.action("paginateRepairs", {
          page: this.searchData.page,
          limit: 20,
          query: this.searchQuery,
          client: this.user.personId,
          withLogs: true,
        });

        this.searchData = {
          data: response.data,
          page: this.searchData.page,
          total: response.total,
          loading: false,
        };
      }, 400);
    },

    /**
     * Change the current page for the new one and fetch
     */
    currentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchPage(newPage);
    },

    /**
     * Fetch all repairs readdy to be delivery.
     */
    async fetchReadyToDelivery() {
      // enable loading mode
      this.loading = true;

      // make an API call to get the repairs ready to be delivered
      const { data } = await this.$stellar.action("paginateRepairs", {
        limit: 100,
        client: this.user.personId,
        states: ["waiting_delivery"],
      });

      // store the fetched data
      this.readyToDeliveryData = data;

      // disabled loading mode
      this.loading = false;
    },

    /**
     * Fetch one page of the repairs for the logged client.
     *
     * @type {int} page Page to fetch
     */
    fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // request the page to the API
      this.$stellar
        .action("paginateRepairs", {
          page,
          limit: 20,
          client: this.user.personId,
          statesMode: false,
          states: ["waiting_delivery", "delivered"],
          withLogs: true,
        })
        .then(({ data, total }) => {
          // save the response data
          this.data = data;
          this.total = total;
          this.currentPage = page;

          // disable loading
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-Ongoing {
    width: 98%;
    margin: 20px auto;

    &__title {
      font-size: 1.3em;
      font-weight: 300;
      margin: 15px 0;
      padding-left: 5px;
    }

    &__imeiSearch {
      max-width: 240px;
    }
  }
}

.tp-ongoing__section {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
