<template>
  <el-dialog
    :visible.sync="dialogVisibility"
    title="Dar Entrada">
    <el-form label-position="top">
      <el-form-item label="Nota para o Cliente">
        <el-input
          v-model="formData.observation"
          type="textarea"
          row="6" />
      </el-form-item>

      <el-form-item label="Nota Interna">
        <el-input
          v-model="formData.internalObservation"
          type="textarea"
          row="6" />
      </el-form-item>

      <el-form-item label="Valor da Reparação">
        <el-input
          v-model="formData.value"
          :min="0"
          placeholder="Nota"
          type="number">
          <template slot="append">€</template>
        </el-input>
      </el-form-item>
    </el-form>

    <template
      slot="footer"
      class="dialog-footer">
      <el-button
        :loading="loading"
        @click="onClickCancelHandler">
        Cancelar
      </el-button>

      <el-button
        :loading="loading"
        type="primary"
        @click="entranceHandler">
        Dar Entrada
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "WarrantyEntranceForm",

  props: {
    repair: {
      type: Object,
      default: null,
    },
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dialogVisibility: false,
      loading: false,

      formData: {
        observation: "",
        internalObservation: "",
        value: 0,
      },
    };
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.dialogVisibility = newVal;
    },

    dialogVisibility(newValue) {
      this.$emit("update:visibility", newValue);
    },
  },

  methods: {
    async entranceHandler() {
      this.loading = true;

      await this.$stellar.action("performWarrantyEntrance", {
        repair: this.repair.id,
        repairObservation: this.formData.internalObservation,
        clientObservation: this.formData.observation,
        value: this.formData.value,
      });

      this.$emit("update:visibility", false);
      this.$emit("update");

      this.loading = false;
    },

    onClickCancelHandler() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style>
</style>
