<template>
  <div class="tp_receipt">
    <div class="tp_receipt__header">
      <!-- repair number -->
      <div class="tp_receipt__header__entry is-repairNumber">
        # {{ repair.repairNumber }}
      </div>

      <!-- box -->
      <div
        v-if="repair.storeBox"
        class="tp_receipt__header__entry is-storeBox">
        (Cx. {{ repair.storeBox }})
      </div>

      <div class="tp_receipt__header__entry">
        {{ repair.createdAt | dateFormat('yyyy-MM-dd HH:mm') }}
      </div>
    </div>

    <!-- client information -->
    <div class="tp_receipt__repairInfo">
      <div class="tp_receipt__repairInfo__entry">
        <span class="title">Cliente: </span>
        <span class="content">{{ client.name }}</span>
      </div>

      <div class="tp_receipt__repairInfo__entry">
        <span class="title">Nº Serie / IMEI: </span>
        <span
          v-if="equipment.attributes && equipment.attributes.imei"
          class="content">{{ equipment.attributes.imei }}</span>
        <span
          v-else
          class="content">{{ equipment.serialNumber }}</span>
      </div>

      <div class="tp_receipt__repairInfo__entry">
        <span class="title">Equipamento: </span>
        <span class="content">{{ equipment.brand }} {{ equipment.model }}</span>
      </div>

      <div class="tp_receipt__repairInfo__entry is-big">
        <span class="title">Problema</span>
        <span class="content">{{ problemName }}; {{ repair.problemDescription }}</span>
      </div>
      <!-- observation from gtechniciaan when close repair -->
      <div
        v-if="repair.state==='delivered'"
        class="tp_receipt__repairInfo__entry is-big">
        <span class="title">Interveção no dispositivo: </span>
        <span class="content">{{ repair.repairObservation || '-' }}</span>
      </div>

      <!-- date of print -->
      <div class="tp_receipt__repairInfo__entry">
        <span class="title">Data: </span>
        <span class="content">{{ new Date() | dateFormat('yyyy-MM-dd HH:mm') }}</span>
      </div>

      <!-- option to print value -->
      <div v-if="printValue">
        <div class="tp_receipt__repairInfo__entry">
          <span class="title">Valor: </span>
          <span class="content">{{ repair.value | money }}</span>
        </div>
      </div>

      <!-- TODO
    <div class="tp_receipt__repairInfo__entry is-big">
      <span class="title">Acessorios</span>
      <span class="content">
        <ul>
          <li>Carregador</li>
          <li>Bateria</li>
        </ul>
      </span>
    </div> -->

      <div
        v-if="repair.quotation !== 0"
        class="tp_receipt__repairInfo__entry is-big">
        <span class="title">Orçamento</span>
        <span class="content">Orçamento acordado de {{ repair.quotation | money }}</span>
      </div>
    </div>

    <!-- divider -->
    <hr>

    <!-- note -->
    <div class="tp_receipt__note">
      <h2>*** ATENÇÃO ***</h2>

      <p>
        <b>Após aviso de REPARADO tem 3 MESES para efetuar o LEVANTAMENTO do equipamento.</b>
      </p>

      <p>A partir desse período reservamos o direito de propriedade ou exigir um suplemento de pagamento.</p>

      <hr>

      <p>Não nos responsabilizamos pela perda de dados ou aplicações não oficiais, durante o processo de reparação. É da responsabilidade do cliente guardar os dados do equipamento previamente à entrega do mesmo para reparação. A reparação do equipamento será sempre efetuada se o seu valor não ultrapassar 20,00€, contrariamente, será comunicado o valor do orçamento. Caso o orçamento seja recusado será cobrado o valor de 7,50€.</p>
    </div>
  </div>
</template>

<script>
import { printHTML } from "@/utils/print-html";

export default {
  props: {
    repair: {
      type: Object,
      default: () => null,
    },
    client: {
      type: Object,
      default: () => null,
    },
    equipment: {
      type: Object,
      default: () => null,
    },
    equipmentType: {
      type: Object,
      default: () => null,
    },
    printValue: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    problemName() {
      const problemValue = this.repair.attributes.problem;
      const problem = this.equipmentType.attributes
        .find(a => a.name === "problem")
        .values.find(o => o.value === problemValue);
      return problem.title;
    },
  },

  methods: {
    printReceipt() {
      const printStyle = `
        body {
          font-size: 13px !important;
          font-family: Arial, sans-serif;

          width: 300px !important;
          height: auto;

          -webkit-print-color-adjust: exact;
        }

        .tp_receipt {
          width: 300px !important;
          padding: 20px;
          box-sizing: border-box;
        }

        .tp_receipt * {
          box-sizing: border-box !important;
        }

        .tp_receipt__header {
          text-align: center;
          display: block;
          margin-bottom: 20px !important;
        }

        .tp_receipt__header__entry {
          margin-bottom: 6px !important;
          font-weight: bold;
          font-size: 15pt !important;
        }

        .tp_receipt__header__entry.is-repairNumber {
          font-size: 17pt !important;
        }

        .tp_receipt__repairInfo {
          margin-bottom: 20px !important;
        }

        .tp_receipt__repairInfo__entry {
          margin-bottom: 6px !important;
        }

        .tp_receipt__repairInfo__entry .title {
          font-weight: bold;
        }

        .tp_receipt__repairInfo__entry.is-big .title {
          display: block;
        }

        .tp_receipt__repairInfo__entry.is-big .content {
          padding: 10px 0 5px 0;
          display: block;
        }

        .tp_receipt__repairInfo__entry ul {
          margin-top: 0;
          margin-bottom: 0;
        }

        .tp_receipt__note h2 {
          text-align: center;
        }

        .tp_receipt__note p {
          text-align: justify;
        }

        .tp_receipt__note hr {
          border: none;
          border-top: 1px dotted black;
        }

        .tp_receipt__footer {
          margin-top: 20px;
        }
        .tp_receipt__footer p {
          margin-top: 0;
          margin-bottom: 2px;
        }
        .tp_receipt__footer p:last-child {
          margin-bottom: 0;
        }
      `;

      const contents = document.querySelector(".tp_receipt").innerHTML;

      printHTML(contents, printStyle, "");
    },
  },
};
</script>
