import { IBaseState } from "@/services/store/types/base-state.interface";
import { ILog } from "@/modules/repairs/types/log.interface";

export interface ILogsState extends IBaseState {
  logs: Array<ILog>;
}

export enum ELogsAction {
  FETCH = "repair/logs/fetch",
}

export enum ELogsMutation {
  SET_LOGS = "SET_LOGS",
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
}
