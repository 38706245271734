
<div class="tp-RepairForm">
  <div class="tp-repairForm_container">
    <!-- current step header -->
    <el-steps
      :space="150"
      :active="currentStep"
      finish-status="success">
      <el-step title="Dispositivo"/>
      <el-step title="Cliente"/>
      <el-step title="Equipamento"/>
      <el-step title="Reparação"/>
      <el-step title="Concluido"/>
    </el-steps>

    <!-- error alert -->
    <el-alert
      v-if="errorMessage !== null"
      :title="errorMessage"
      type="error"/>

    <!-- STEP 1 -->
    <step1
      v-if="currentStep === 0"
      :repair.sync="repair"
      :equipment.sync="selectedEquipment"
      :client.sync="selectedClient"
      @goStep="value => currentStep = value"
      @setEquipmentType="setEquipmentType"
      @setIdentifier="v => tempIdentifier = v"
      @latestRepair="v => prevRepair = v"/>

    <!-- STEP 2 -->
    <div v-if="currentStep === 1">
      <step2
        :client.sync="selectedClient"
        :repair.sync="repair"
        :equipment="selectedEquipment"
        @goStep="value => currentStep = value"/>

      <!-- create a complaint -->
      <div
        v-if="prevRepair"
        class="tp-RepairForm__complaint">
        <el-button
          type="danger"
          @click="makeComplaint">Reclamar</el-button>
      </div>
    </div>

    <!-- STEP 3 -->
    <step3
      v-if="currentStep === 2"
      :temp-identifier="tempIdentifier"
      :repair.sync="repair"
      :equipment-type.sync="selectedEquipmentType"
      :equipment.sync="selectedEquipment"
      :step.sync="currentStep"
      :client.sync="selectedClient"
      :types="equipmentTypes"/>

    <!-- STEP 4 -->
    <step4
      v-if="currentStep === 3"
      :client="selectedClient"
      :repair.sync="repair"
      :equipment-type="selectedEquipmentType"
      @created="repairCreated"/>

    <!-- STEP 5 -->
    <step5
      v-if="currentStep === 4"
      :client="selectedClient"
      :equipment-type="selectedEquipmentType"
      :equipment="selectedEquipment"
      :repair="repair"
      :repairs-list="repairsList"
      @newRepair="newRepairSameClientHandler"/>
  </div>
</div>
