const COMMON_SET_TITLE = "COMMON_SET_TITLE";

const state = {
  title: "Tecniphone",
};

const mutations = {
  [COMMON_SET_TITLE](state, title) {
    state.title = title;
  },
};

const actions = {
  setTitle({ commit }, title) {
    commit(COMMON_SET_TITLE, title);
  },
};

const getters = {
  title(state) {
    return state.title;
  },
};

export default {
  actions,
  getters,
  mutations,
  state,
};
