<template>
  <section class="tp-technician_wrap">

    <!-- commissions --------------------------------------------------------- -->

    <technician-commissions ref="technicianCommission"/>

    <!-- edit panel ---------------------------------------------------------- -->

    <technician-form
      ref="technicianForm"
      @updated="reloadCurrentPage"
      @created="reloadCurrentPage"/>

    <!-- technician list ----------------------------------------------------- -->

    <ui-list
      :loading="loading"
      :data="technicians"
      :total="total"
      :creation-method="createHandler"
      :remote-search="searchHandler"
      paginate
      allow-creation
      searchable
      @current-changed="fetchPage"
      @mode-changed="modeChangeHandler">
      <ui-list-item
        v-for="technician in orderedTechnicians"
        :key="technician.id">
        <!-- avatar -->
        <div slot="image">
          <img
            :src="getAvatar(technician.email)"
            class="tp-technician_avatar img-circle"
            alt="Avatar" >
        </div>

        <!-- name -->
        <span class="name">{{ technician.name }}</span>

        <!-- email -->
        <span slot="details">{{ technician.email }}</span>

        <!-- actions -->
        <div slot="actions">
          <!-- comissions of one Technician -->
          <el-button
            v-if="technician.type === 'commission'"
            :plain="true"
            type="primary"
            size="small"
            @click="seeCommissions(technician)">Comissões</el-button>

          <!-- edit Technician -->
          <el-button
            type="info"
            size="small"
            @click="editTechnician(technician)">Editar</el-button>

        <!-- TODO: implemnetar a função de desativar -->
        <!-- <el-button type="danger" @click.native="removeTechnician(technician)">Desativar</el-button> -->
        </div>
      </ui-list-item>
    </ui-list>
  </section>
</template>

<script>
// TODO: deve ser possivel filtrar a lista (ativos)
import _ from "lodash";
import gravatar from "../../filters/gravatar";

import TechnicianFormCmp from "./TechnicianForm.vue";
import TechnicianCommissionsCmp from "../commissions/TechnicianCommissions.vue";

// var to store the currentPage
let currentPage = 1;

export default {
  components: {
    "technician-form": TechnicianFormCmp,
    technicianCommissions: TechnicianCommissionsCmp,
  },

  data() {
    return {
      technicians: [],
      loading: false,
      total: 0,
    };
  },

  computed: {
    orderedTechnicians() {
      return _.orderBy(this.technicians, "name");
    },
  },

  created() {
    // fetch first page
    this.fetchPage();
  },

  methods: {
    /**
     * Fetch a page of technicians.
     *
     * @type {number} page
     */
    fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // get the data from the API
      this.$stellar
        .action("paginateTechnicians", {
          page,
          limit: 10,
          query: this.query,
        })
        .then(({ technicians, total }) => {
          // update the list data
          this.total = total;
          this.technicians = technicians;

          // store the current page
          currentPage = page;

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Handle the suppliers search.
     *
     * @param query search query
     */
    searchHandler(query) {
      this.query = query;
      this.fetchPage();
    },

    modeChangeHandler(mode) {
      if (mode === "normal") {
        this.query = "";
      }
    },

    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    /**
     * Show a form to create a new technician.
     */
    createHandler() {
      this.$refs.technicianForm.show();
    },

    /**
     * Set an technician to be edited and show the edit panel.
     *
     * @param  {Object} technician  Technicial to be eddited.
     */
    editTechnician(technician) {
      // get the edit panel
      const panel = this.$refs.technicianForm;

      // set the technician to be edited
      panel.setTechnician(technician);

      // show edit panel
      panel.show();
    },

    /**
     * Set an technician to see his commissions.
     *
     * @param  {Object} technician  Technician who we want to see commissions.
     */
    seeCommissions(technician) {
      // get the commissions panel
      const panel = this.$refs.technicianCommission;

      // set the technician to be edited
      panel.setTechnician(technician);

      // show edit panel
      panel.show();
    },

    // removeTechnician (technician) {
    //   this.$stellar.action('removeTechnician', { id: technician.id }, response => {
    //     if (response.$error) { return this.$refs.error.show() }
    //   })
    //   this.technicians.pop(technician)
    // },

    /**
     * Reload the current page.
     */
    reloadCurrentPage() {
      this.fetchPage(currentPage);
    },
  },
};
</script>
