/* global Audio */

import { Notification } from "element-ui";
import {getStore} from "../index";
import stellar from "@/services/stellar";

// number of elements to get on each fetch
const limit = 10;

// available commits
const MURAL_ADD_MESSAGE = "MURAL_ADD_MESSAGE";
const MURAL_SET_MESSAGES = "MURAL_SET_MESSAGES";
const MURAL_APPEND_OLD_MESSAGES = "MURAL_APPEND_OLD_MESSAGES";
const MURAL_SET_LOADING = "MURAL_SET_LOADING";
const MURAL_SET_CURRENT_PAGE = "MURAL_SET_CURRENT_PAGE";
const MURAL_SET_LOADING_TO_BOTTOM = "MURAL_SET_LOADING_TO_BOTTOM";

// module state
const state = {
  messages: [],
  currentPage: 0,
  stopLoading: false,
  scrollToBottom: false,
};

const mutations = {
  [MURAL_SET_MESSAGES](state, messages) {
    state.messages = messages;
  },

  [MURAL_SET_LOADING_TO_BOTTOM](state, value) {
    state.scrollToBottom = value;
  },

  [MURAL_ADD_MESSAGE](state, message) {
    state.messages.push(message);
    state.scrollToBottom = true;
  },

  [MURAL_APPEND_OLD_MESSAGES](state, messages) {
    // NOTE: the less expensive way to add the new messages to the begin of
    // array is using the `.unshift` method instead of `.concat`

    for (const message of messages) {
      state.messages.unshift(message);
    }
  },

  [MURAL_SET_LOADING](state, value) {
    state.stopLoading = value;
  },

  [MURAL_SET_CURRENT_PAGE](state, value) {
    state.currentPage = value;
  },
};

const actions = {
  /**
   * Append a new message.
   *
   * We need to check if the message is for this user (for now, Stellar doesn't
   * support the send of individual messages).
   */
  addMuralMessage({ getters, commit }, message) {
    const thisUserCanSee = message =>
      message.sender.id === getters.user.id ||
      message.receivers.find(item => item.to.id === getters.user.id);

    // if this user can't receive the message this returns now
    if (!thisUserCanSee(message)) {
      return;
    }

    // append the new message
    commit(MURAL_ADD_MESSAGE, message);

    // a song must be reproduced when a new message arrives
    if (message.sender.id === getters.user.id) {
      return;
    }
    const notificationSong = new Audio("static/new_message.mp3");
    notificationSong.play();

    // show a notification with the message content and with the sender info
    Notification({
      title: `Nova mensagem de ${message.sender.shortName}`,
      message: message.content,
    });
  },

  /**
   * Get the next page of messages.
   *
   * This action loads pages of messages in a linear way, this doesn't load an
   * specific page of all messages.
   */
  muralFetchNextPage({ state, getters, commit }) {
    // when we are loading a page or there is no more messages to load we need
    // stop right here
    if (state.stopLoading) {
      return;
    }

    // block the loading
    commit("MURAL_SET_LOADING", true);

    // get the current logged user id
    const userId = getters.user.id;

    // get the current fetched page and increment one to get the next page of
    // messages
    const page = getters.muralCurrentPage + 1;

    // update the current page
    commit("MURAL_SET_CURRENT_PAGE", page);

    // call the API to get the requested page of messages
    return stellar
      .action("getMuralMessages", { userId, page, limit })
      .then(({ messages }) => {
        // append the messages to the current array
        commit("MURAL_APPEND_OLD_MESSAGES", messages);

        // whether there is no messages or the length is less than 10 we stop
        // loading pages. There is no more messages
        if (messages.length === limit) {
          commit("MURAL_SET_LOADING", false);
        }
      });
  },

  /**
   * Send a new mural message to the server.
   */
  sendMuralMessage(store, messagePackage) {
    // make an API call to create a new mural message
    return stellar.action("sendMuralMessage", messagePackage);
  },
};

const getters = {
  muralMessages: state => state.messages,
  muralCurrentPage: state => state.currentPage,
  muralScrollToBottom: state => state.scrollToBottom,
};

// handle the arrive of new messages
stellar.from("mural").on("newMessage", async (data) => {
  const store = await getStore();
  store.dispatch("addMuralMessage", data);
});

export default {
  actions,
  getters,
  mutations,
  state,
};
