
<section class="all-requests-list">
  <div class="material-requests-list material-requests-list--client">
    <el-table
      v-loading.body="loading"
      :data="data"
      stripe>

      <!-- material description -->
      <el-table-column label="Descrição">
        <template slot-scope="scope">
          {{ scope.row.material.brand }} {{ scope.row.material.model }}, {{ scope.row.material.description }}
        </template>
      </el-table-column>

      <!-- quantity -->
      <el-table-column
        :width="70"
        property="total"
        label="Qtn."
        align="center"/>



      <!-- box -->
      <el-table-column
        :width="80"
        label="Caixa"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.material.storeBox || '-' }}
        </template>
      </el-table-column>

      <!-- current price -->
      <el-table-column
        :width="150"
        align="center"
        label="Preço unidade">
        <template slot-scope="scope">
          {{ scope.row.price || scope.row.material.price | money }}
        </template>
      </el-table-column>

      <!-- request observation-->
      <el-table-column label="Observação">
        <template slot-scope="scope">
          <span v-if="scope.row.observation"> {{ scope.row.observation }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</section>
