
<div id="tp-suppliers_wrap">
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="saveHandler">
        Guardar
      </el-button>
      <el-button @click="cancelHandler">Cancelar</el-button>
    </div>

    <el-alert
      v-if="showError"
      type="error"
      title="Existem erros no formulario." />

    <el-form
      ref="resource"
      :model="resource"
      :rules="rules"
      label-position="top" >
      <el-row :gutter="10">
        <el-col :span="21">
          <el-form-item
            label="Nome"
            prop="name">
            <el-input
              v-model="resource.name"
              placeholder="Nome" />
          </el-form-item>
        </el-col>

        <el-col :span="3">
          <el-form-item label="Activo">
            <el-switch v-model="resource.active" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Responsavel">
            <el-select
              v-model="resource.responsable"
              clearable>
              <el-option
                v-for="technician in technicians"
                :key="technician.id"
                :value="technician.id"
                :label="technician.name" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Atributos">
            <el-input
              v-model="attributesString"
              :autosize="{
                minRows: 2,
                maxRows: 7
              }"
              type="textarea"
              placeholder="Descrição" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
