<template>
  <el-dialog
    v-loading.fullscreen="isLoading"
    :visible.sync="visibility"
    title="Alterar Prioridade"
    size="tiny">
    <p class="repair-priority-modal__description">Alterar a prioridade da reparação para:</p>
    <el-select
      v-model="repairPriority"
      placeholder="select">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>

    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        :loading="isLoading"
        @click="closeHandler">
        Cancelar
      </el-button>
      <el-button
        :loading="isLoading"
        type="primary"
        @click="updateRepairPriorityHandler">
        Alterar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

export default {
  name: "ChangeRepairPriorityModal",

  data() {
    return {
      visibility: false,
      repairPriority: "",
      options: [
        {
          value: "normal",
          label: "Normal",
        },
        {
          value: "ontime",
          label: "Agora",
        },
        {
          value: "urgent",
          label: "Urgente",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("repair", ["repair"]),
    ...mapState("repair", {
      isLoading: state => state.loading,
    }),
  },

  created() {
    this.repairPriority = this.repair.priority;
  },

  methods: {
    show() {
      this.visibility = true;
    },

    closeHandler() {
      this.visibility = false;
      this.$emit("closed");
    },

    /**
     * Update the store identifier.
     */
    async updateRepairPriorityHandler() {
      await this.$store.dispatch(ERepairActions.UPDATE, {
        priority: this.repairPriority,
      });
      this.closeHandler();
      this.$message.success("Prioridade atualizada.");
    },
  },
};
</script>
