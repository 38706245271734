
<section class="tp-RepairForm__step3">
  <!-- types of equipment ------------------------------------------------ -->
  <transition name="fade">
    <el-row
      v-show="!equipmentType"
      :gutter="10">
      <el-col
        v-for="type in types"
        :key="type.id"
        :span="3">
        <el-card
          :body-style="{ padding: '0px' }"
          class="tp-RepairForm__step3__card"
          @click.native="$emit('update:equipmentType', type)">
          <el-tooltip
            :content="type.name"
            effect="dark"
            placement="top">
            <img
              :src="getEquipmentTypeImgUrl(type.id)"
              :alt="type.name"
              class="image">
          </el-tooltip>
        </el-card>
      </el-col>
    </el-row>
  </transition>

  <!-- create a new equipment for the client ----------------------------- -->
  <transition name="fade">
    <el-form label-position="top">
      <!-- title -->
      <h2
        v-if="equipmentType"
        class="tp-RepairForm__step3__titleType">
        {{ equipmentType.name }}
        <el-button
          type="text"
          @click="$emit('update:equipmentType', null)">
          Alterar
        </el-button>
      </h2>

      <el-card
        v-if="equipmentType"
        class="tp-repairForm_createNewEquipment">
        <!-- header -->
        <div
          slot="header"
          class="clearfix">
          <h2>Novo equipamento</h2>
        </div>

        <el-row :gutter="10">
          <!-- model -->
          <el-col :span="12">
            <el-form-item label="Modelo">
              <el-select
                v-model="tempBrand"
                :remote-method="equipmentRemoteMethod"
                :loading="search.loading"
                class="tp-RepairForm__newBrandField"
                placeholder="Modelo"
                default-first-option
                filterable
                allow-create
                remote
                @change="modelChange">
                <el-option
                  v-for="equip in equipments"
                  :key="equip.id"
                  :label="equip.model"
                  :value="equip.id">
                  <span>{{ equip.brand + ' ' + equip.model }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- brand -->
          <el-col :span="12">
            <el-form-item label="Marca">
              <el-input
                v-model="selectedEquipment.brand"
                type="text"
                placeholder="Marca"/>
            </el-form-item>
          </el-col>

          <!-- serial number -->
          <el-col :span="24">
            <el-form-item label="Numero de Serie">
              <el-input
                v-model="selectedEquipment.serialNumber"
                type="text"
                placeholder="Numero de Serie"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- dynamic view -->
        <dynamic-attributes
          v-model="selectedEquipment.attributes"
          :equipment="equipmentType"
          moment="normal"/>

        <!-- actions -->
        <el-row
          type="flex"
          justify="end">
          <!-- create button -->
          <el-button
            type="primary"
            @click="createClientEquipment">
            Criar
          </el-button>
        </el-row>
      </el-card>
    </el-form>
  </transition>
</section>
