
<div class="tp-ContactRequest">
  <ui-bottom-sheet
    ref="sheet"
    actions>

    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="lock"
        type="primary"
        @click="sendMessage">Enviar</el-button>
      <el-button @click="show(false)">Cancelar</el-button>
    </div>

    <el-form>
      <el-row
        v-if = "data.client && data.client.isStore"
        :gutter="10">
        <el-col :span="24">
          <el-form-item label="Descreva o porque do contacto">
            <el-input
              v-model="data.message"
              type="textarea"
              placeholder="Mensagem"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-else>
        <el-col :span="24">
          <el-form-item label="Solicitar ao cliente:">
            <el-select
              v-model="data.message"
              filterable
              allow-create
              placeholder="Escolha ou escreva a mensagem">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row></el-form>
  </ui-bottom-sheet>
</div>
