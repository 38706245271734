
<el-dialog
  :visible.sync="isVisible"
  title="Tecnicos">
  <div class="elements-wrap">
    <el-table :data="technicians" style="width: 100%">
      <el-table-column label="Nome" prop="name"/>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button
            :loading="isLoading"
            class="selecteButton"
            type="success"
            size="small"
            @click="onSelectHandler(scope.row)">
            {{ buttonsText }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</el-dialog>
