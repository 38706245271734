<template>
  <div
    v-if="visible"
    class="tp-QuickPanel">
    <div class="tp-QuickPanel__panel">
      <!-- input -->
      <input
        ref="input"
        v-model="query"
        class="tp-QuickPanel__input"
        type="text"
        placeholder="Nome da secção..."
        @keyup.enter="handleEnter">

      <!-- list of results -->
      <div
        class="tp-QuickPanel__results"
        @click="hide">
        <router-link
          v-for="result in results"
          :key="result.route"
          :to="{ name: result.route }"
          class="tp-QuickPanel__results__entry"
          exact>
          <span>{{ result.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickPanel",

  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
      query: "",
      results: [],
    };
  },

  watch: {
    query(newVal) {
      this.results = this.sections.filter(item =>
        item.title.match(new RegExp(`${newVal}.*`, "i")),
      );
    },
  },

  created() {
    this.results = this.sections;

    document.onkeydown = this.handleKeypress;
  },

  methods: {
    hide() {
      this.visible = false;
      this.query = "";
    },

    handleEnter() {
      if (this.results.length > 0) {
        // navigate to the first result
        this.$router.push({ name: this.results[0].route });

        // hide this
        this.hide();
      }
    },

    handleKeypress(event) {
      // ESC
      if (event.keyCode === 27 && this.visible) {
        return this.hide();
      }

      // check if it's a CTRL+E
      if (!(event.key === "e" && event.ctrlKey)) {
        return true;
      }

      // prevent defaults
      event.preventDefault();

      if (this.visible) {
        return this.hide();
      }

      // make this visible
      this.visible = true;

      // focus input
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
};
</script>

<style lang="scss">
.tp-QuickPanel {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(71, 55, 78, 0.8);
  transition: opacity 0.3s 0s, visibility 0s 0.3s;

  padding-top: 100px;

  &__panel {
    display: block;
    width: 80%;
    max-width: 450px;
    max-height: 400px;
    background: white;

    /* center on the middle */
    margin: 0 auto;

    /* hide overflow */
    overflow: hidden;

    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s;
  }

  &__input {
    width: 100%;
    border: none;
    padding: 10px 10px 10px 30px;
    font-size: 1.3em;
    font-weight: 300;
    border-bottom: 1px solid #50d3ff;
    outline: none;
  }

  &__results {
    background: #f0f0f0;
    overflow: scroll;
    max-height: 250px;

    &__entry {
      display: block;
      padding: 10px 15px;
      color: #0e0e0e;

      &:hover {
        background: #22a7f0;
        color: white;
        text-decoration: none;
      }
    }
  }
}
</style>
