<template>
  <div class="tp-ClientContacts">

    <!-- Client pendent contacts ---------------------------------------------------- -->
    <div
      v-if="clientPendentContacts.length > 0"
      class="tp-ClientContacts__pendent">
      <h2 class="pageTitle">Pendentes</h2>

      <!-- client list -->
      <div v-if="clientPendentContacts.length > 0">
        <h5>Clientes</h5>
        <contact-table
          :data="clientPendentContacts"
          @update="fetchData"/>
      </div>
    </div>

    <!-- no contacts to make ------------------------------------------------- -->
    <div
      v-if="noContacts"
      class="tp-ClientContacts__noContacts">
      <i class="fa fa-thumbs-o-up"/>
      <span>Sem contactos a fazer!</span>
    </div>

    <!-- urgent contacts ----------------------------------------------------- -->

    <div
      v-if="urgentContacts.length > 0"
      class="tp-ClientContacts__urgent">
      <h2 class="pageTitle">Urgentes</h2>
      <contact-table
        :data="urgentContacts"
        @update="fetchData" />
    </div>

    <!-- not answered -------------------------------------------------------- -->

    <div
      v-if="notAnswered.length > 0"
      class="tp-ClientContacts__notAnswered">
      <h2 class="pageTitle">Não atendidas</h2>
      <contact-table
        :data="notAnswered"
        @update="fetchData" />
    </div>

    <!-- waiting answer ------------------------------------------------------ -->

    <div
      v-if="waitingAnswer.length > 0"
      class="tp-ClientContacts_wating_answer">
      <h2 class="pageTitle">À espera de Resposta</h2>
      <contact-table
        :data="waitingAnswer"
        @update="fetchData" />
    </div>

    <!-- store pendent contacts ---------------------------------------------------- -->
    <div
      v-if="storePendentContacts.length > 0"
      class="tp-ClientContacts__pendent">
      <h2 class="pageTitle">Pendentes</h2>

      <!-- store list -->
      <div v-if="storePendentContacts.length > 0">
        <h5>Loja</h5>
        <contact-table
          :data="storePendentContacts"
          @update="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import ContactTableCmp from "./client_contacts/table.vue";

// save the loading instance
let loadingInstance = null;

export default {
  name: "ClientContacts",

  components: {
    "contact-table": ContactTableCmp,
  },

  data() {
    return {
      contacts: [],
      repairVisible: false,
      selectedRepair: {},
      clientReason: "",
    };
  },

  computed: {
    notAnswered() {
      return this.contacts.filter(item => {
        return item.missedCall === true && item.processed === false;
      });
    },
    storePendentContacts() {
      return this.contacts.filter(item => {
        return (
          item.urgent === false &&
          item.processed === false &&
          item.waitingAnswer === false &&
          item.missedCall === false &&
          item.client.isStore
        );
      });
    },
    clientPendentContacts() {
      return this.contacts.filter(item => {
        return (
          item.urgent === false &&
          item.processed === false &&
          item.waitingAnswer === false &&
          item.missedCall === false &&
          !item.client.isStore
        );
      });
    },
    urgentContacts() {
      return this.contacts.filter(item => {
        return item.urgent === true && item.processed === false;
      });
    },
    waitingAnswer() {
      return this.contacts.filter(item => {
        return item.processed === false && item.waitingAnswer === true;
      });
    },

    noContacts() {
      return (
        this.notAnswered.length === 0 &&
        this.storePendentContacts.length === 0 &&
        this.clientPendentContacts.length === 0 &&
        this.urgentContacts.length === 0 &&
        this.waitingAnswer.length === 0
      );
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      loadingInstance = Loading.service({ fullscreen: true });

      const { contacts } = await this.$stellar.action(
        "getClientNotificationsByPreference",
        {
          type: "phone",
          withLogs: true,
        },
      );

      this.contacts = contacts.filter(
        item =>
          !item.repair ||
          !["delivered", "waiting_delivery"].includes(item.repair.state),
      );

      loadingInstance.close();
    },
  },
};
</script>

<style lang="scss">
@import "./var.css";

.tp {
  &-ClientContacts {
    max-width: 98%;
    margin: 0 auto 20px auto;
    position: relative;

    &__noContacts {
      display: flex;
      flex-flow: column;
      align-items: center;

      margin-top: 10%;
      color: var(--sidebar-color);

      i {
        font-size: 4em;
        margin-bottom: 10px;
      }

      span {
        font-size: 1.5em;
      }
    }

    &__dialogInfo {
      .title {
        font-weight: bold;
        display: block;
      }

      .value {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .pageTitle {
      font-weight: 300;
      margin: 15px 0;
    }

    h5 {
      margin: 10px 0;
    }
  }
}
</style>
