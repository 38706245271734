
<div class="tp-DailyReport">
  <ui-bottom-sheet
    ref="editSheet"
    actions>

    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        @click="show(false)">Fechar</el-button>
      <el-button @click="printElem">Imprimir</el-button>
    </div>

    <!-- field to select the date -->
    <el-date-picker
      v-model="selectedDate"
      type="date"
      placeholder="Escolha um dia"/>


    <div id="toPrint">
      <div v-if="newSales.length > 0">
        <h2 class="tp-DailyReport__title">Material Novo</h2>

        <el-table
          v-loading="loading"
          :data="newSales"
          stripe>
          <el-table-column
            :width="80"
            label="Qnt.">

            <template slot-scope="scope">
              <span v-if="scope.row.quantity >= 0">{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Descrição">
            <template slot-scope="scope">
              <span v-if="scope.row.material.description">{{ formatMaterialName(scope.row.material) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :width="80"
            label="Preço">
            <template slot-scope="scope">
              <span v-if="scope.row.material.price">{{ scope.row.material.price * 1.23 * profit | money }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :width="80"
            label="stock">
            <template slot-scope="scope">
              <span v-if="scope.row.material.newStock">{{ scope.row.material.newStock }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-if="usedSales.length > 0">
        <!-- lista de material usad o(stock usado) -->
        <h2 class="tp-DailyReport__title">Material Usado</h2>
        <el-table
          v-loading="loading"
          :data="usedSales"
          stripe>

          <el-table-column
            :width="80"
            label="QTD">

            <template slot-scope="scope">
              <span v-if="scope.row.quantity >= 0">{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Descrição">
            <template slot-scope="scope">
              <span v-if="scope.row.material.description">{{ scope.row.material.description }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :width="80"
            label="Preço">
            <template slot-scope="scope">
              <span
                v-if="scope.row.material.price"
                onchange="setTwoNumberDecimal">{{ (scope.row.material.price * 1.23 * profit) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :width="80"
            label="stock">
            <template slot-scope="scope">
              <span v-if="scope.row.material.usedStock">{{ scope.row.material.usedStock }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </ui-bottom-Sheet>
</div>
