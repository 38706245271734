<template>
  <div
    :class="`is-${state}`"
    class="tp-repair-state">
    <span class="tp-repair-state__text">{{ state | repairState }}</span>
  </div>
</template>

<script>
export default {
  name: "RepairState",

  props: {
    state: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
$blueItems: in_tests waiting_repair waiting_material paused in_transit;
$orangeItems: waiting_attribution contact_client closed_without_permission
  in_avaluation;
$redItems: problematic waiting_complaint_avaluation;
$greenItems: in_repair delivered;
$greyItems: waiting_quotation_approve waiting_delivery waiting_answer;

@mixin repair-state-generator($list, $color) {
  @each $entry in $list {
    &.is-#{$entry} {
      .tp-repair-state__text {
        color: $color;
      }

      .tp-repair-state__text::before {
        background-color: $color;
        box-shadow: 0 0 4px rgba($color, 0.5);
      }
    }
  }
}

.tp-repair-state {
  display: block;

  &__text {
    font-size: 14px;
  }

  &__text::before {
    position: relative;
    top: 1px;
    display: inline-block;
    margin-right: 4px;
    width: 12px;
    height: 12px;

    background: black;
    border-radius: 50%;

    content: "";
  }

  @include repair-state-generator($blueItems, $repairStateBlue);
  @include repair-state-generator($orangeItems, $repairStateOrange);
  @include repair-state-generator($redItems, $repairStateRed);
  @include repair-state-generator($greenItems, $repairStateGreen);
  @include repair-state-generator($greyItems, $repairStateGrey);
}
</style>
