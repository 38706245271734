/**
 * Start and configure the Stellar library and export a singleton.
 */

// @ts-ignore
const stellar: StellarClient = new StellarClient({
  url: import.meta.env.VITE_API_URL,
});

stellar.on("connected", () => {
  stellar.join("mural");
  stellar.join("notifications");
  stellar.join("updates");
});

// connect Stellar with the server (we will need some real-time connection so we
// gonna use WebSocket)
stellar.connect();

export default stellar;
