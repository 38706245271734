<template>
  <transition
    name="fade"
    @after-enter="adjustSize">
    <div
      v-show="innerShow"
      class="ui-BottomSheet__mask is-middle">
      <transition name="slide-bottom">
        <div
          v-show="innerShow"
          class="ui-BottomSheet__container">
          <!-- actions -->
          <div
            v-show="actions"
            class="ui-BottomSheet__actions">
            <slot name="actions"/>
          </div>

          <!-- main slot -->
          <div class="ui-BottomSheet__content">
            <slot/>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    actions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerShow: false,
    };
  },

  created() {
    // add an event to adjust the size in a resize event
    window.addEventListener("resize", this.adjustSize);
  },

  destroyed() {
    window.removeEventListener("resize", this.adjustSize);
  },

  methods: {
    /**
     * Adjust the bottom sheet height.
     */
    adjustSize() {
      const contents = document.querySelectorAll(".ui-BottomSheet__content");
      const maxHeightPossible = window.innerHeight * 0.95 - 50;

      // for each conent div do some calculations
      for (const contentDiv of contents) {
        if (contentDiv.scrollHeight > maxHeightPossible) {
          contentDiv.style.height = maxHeightPossible + "px";
        }
      }
    },

    /**
     * Change the visibility of the bottom sheet.
     */
    show(val = true) {
      this.innerShow = val;

      if (val) {
        // block the scroll on the document
        document.body.classList.add("noscroll");
      } else {
        document.body.classList.remove("noscroll");
      }
    },
  },
};
</script>

<style lang="scss">
.ui {
  &-BottomSheet {
    &__mask {
      position: fixed;
      z-index: 1500;
      top: 0;
      left: 0;
      width: 100%;
      width: 100vw;
      height: 100%;
      height: 100vh;

      display: flex;
      justify-content: center;
      align-items: flex-end;

      background-color: rgba(0, 0, 0, 0.3);

      &.is-middle {
        align-items: center;

        .ui-BottomSheet__container {
          border-radius: 2px;
        }
      }
    }

    &__container {
      min-width: 400px;
      width: 90%;
      max-width: 90%;
      max-height: 95vh;
      height: auto;
      position: relative;
      display: block;

      /* enable scroll on the div overflow-y: scroll; */

      background-color: white;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
        0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
    }

    &__actions {
      & > div {
        width: 100%;
        height: 50px;

        display: flex;
        align-items: center;

        background-color: #f4f4f4;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;

        &.right {
          justify-content: flex-end;
          padding-right: 10px;

          & > * {
            margin-left: 10px;
          }
        }
      }
    }

    &__content {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding: 1.5em 1em;
      overflow-y: auto;
    }
  }
}

/** large screen */
@media (min-width: 992px) {
  .ui-BottomSheet__container {
    max-width: 900px;
  }
}
</style>
