
<section
  v-loading="loading"
  class="tp-DalyCommissions">
  <div class="header">
    <el-form label-positon="top">
      <el-form-item label="Mês de Comissões">
        <el-date-picker
          v-model="selectedDate"
          :clearable="false"
          disabled
          type="month"
          placeholder="Selecione um mês"
          class="tp-DaylyCommissions__select" />
      </el-form-item>
    </el-form>

    <div class="tp-DaylyCommissions__day-commissions">
      <span class="title">Comissões diárias</span>
      <span class="total-day"> {{ todayRepairs }} €</span>
    </div>
  </div>

  <!-- warning for when there is no commissions for the selected mounth -->
  <el-alert
    v-if="commissions.length === 0"
    :closable="false"
    title="Não existem comissões para o mês selecionado."
    show-icon
    type="info" />

  <div
    v-if="commissions.length > 0"
    id="toPrint">
    <!-- name -->
    <div class="tp-DaylyCommissions__name">
      {{ technician.name }}
    </div>

    <!-- date -->
    <div class="tp-DaylyCommissions__date">
      Data: {{ selectedDate | dateFormat('yyyy/MM') }}
    </div>

    <el-table
      :data="commissions"
      stripe
      class="tp-DaylyCommissions__table"
      style="width: 100%">
      <el-table-column
        :width="100"
        label="#REP">
        <template slot-scope="scope">
          {{ scope.row.repair.repairNumber }}
        </template>
      </el-table-column>
      <el-table-column label="Equipamento">
        <template slot-scope="scope">
          {{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}
        </template>
      </el-table-column>
      <el-table-column label="Valor da Reparação">
        <template slot-scope="scope">
          {{ scope.row.repairValue | money }}
        </template>
      </el-table-column>
      <el-table-column label="Material">
        <template slot-scope="scope">
          {{ scope.row.totalMaterial | money }}
        </template>
      </el-table-column>
      <el-table-column label="A receber">
        <template slot-scope="scope">
          {{ scope.row.value | money }}
        </template>
      </el-table-column>
    </el-table>

    <span>Valor total de reparações: <b>{{ totalRepairs | money }}</b></span>
    <br>
    <span>Valor total em material s/iva: <b> {{ totalMat | money }} </b> Total com IVA = <b>{{ totalMat * 1.23 | money }}</b></span>
    <br>
    <div v-if="numberOfCommissions <= 80">
      <span>Fixo (1,5€ por reparação até 80 reparações com o valor de =) <b> {{ numberOfCommissions }} </b> * 1.5 = <b>{{ numberOfCommissions * 1.5 | money }}</b> </span>
    </div>
    <div v-if="numberOfCommissions > 80">
      <span>Fixo (1,5 € por reparações até 80 reparações com o valor de =) <b> 120 €</b></span>
    </div>
    <br>
    <span>Penalizações: <b>  A calcular </b></span>
    <br>
    <span>Valor das comissões a deduzir por devolução:  <b> {{ (totalDevolution * -1) | money }} </b></span>
    <br>
    <div v-if="totalCommissions <= 80">
      <span>Valor da comissão:<b> {{ total | money }}</b></span>
    </div>
    <br>
    <span>Valor final: {{ total | money }}  {{ totalDevolution }} = <b> {{ total + totalDevolution | money }} </b></span>
    <br>
    <span>O valor da comissão é calculado usando a seguinte formula: (TotalReparações - ValorMaterial * 1.23 - Valor fixo) *  <b>{{ commission }} %</b></span>
    <span>Ao valor final da comissão poderão ser retiradas as penalizações por reclamações e devoluções.</span>
    <span>As reclamações em que a responsabilidade está por confirmar poderão transitar para o mês em que for definida a responsabilidade.</span>
  </div>
</section>
