<template>
  <div class="tp-InTransit">
    <ui-list
      :loading="loading"
      :data="repairs"
      selectable
      @selection-change="selectionChangeHandler">
      <span slot="actions">
        <!-- delivery selection -->
        <el-button
          :pain="true"
          type="primary"
          @click="onClickDeliverySelectionHandler">
          Entrar em Loja
        </el-button>
      </span>

      <ui-list-item
        v-for="item in repairs"
        :key="item.id"
        :data="item">
        <!-- name -->
        <span>#{{ item.repairNumber }} - {{ item.clientEquipment.brand }} {{ item.clientEquipment.model }}</span>

        <!-- decription (equipment) -->
        <span slot="details">Caixa {{ item.storeBox }}</span>

        <!-- actions -->
        <div slot="actions">
          <!-- see -->
          <el-button
            type="info"
            @click="showRepairDetails(item)">Ver</el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // auxiliar properties
      loading: false,

      // repair number to searching for
      repairNumber: "",

      // selected repair to view the details
      selectedRepair: null,

      // list of repairs to be delivered
      repairs: [],

      // List of selected items
      selectedItems: [],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      // enable loading
      this.loading = true;

      // make an API request
      this.$stellar
        .action("getRepairByState", { state: "in_transit" })
        .then(({ repairs }) => {
          // save the found repairs
          this.repairs = repairs;

          // disable loading
          this.loading = false;

          // if there is no repairs we show an alert an stay in the same page
          if (repairs.length === 0) {
            this.$message.info("Sem reparações em transito.");
          }
        });
    },

    /**
     * Delivery just the selected items.
     */
    onClickDeliverySelectionHandler() {
      if (this.selectedItems.length === 0) {
        return this.$alert(
          "Não existem reparações selecionadas.",
          "Nenhuma Seleção",
        );
      }

      let ids = [];

      // confirm the action
      this.$confirm(
        "Pretende dar entrada das reparações em loja?",
        "Confirmação",
        {
          confirmButtonText: "Sim",
        },
      )
        .then(_ => {
          // enable loading
          this.loading = true;

          // get just the repair identifiers
          ids = this.selectedItems.map(item => item.id);

          // call the API action
          return this.$stellar.action("putMultipleRepairsToWaitingDelivery", {
            repairsIds: ids,
          });
        })
        .then(_ => {
          // disable loading
          this.loading = false;

          // remove selected items from the list
          for (const repair of ids) {
            const index = this.repairs.findIndex(item => item.id === repair);
            this.repairs.splice(index, 1);
          }

          // show a success message
          this.$message.success("Reparações validadas.");
        })
        .catch(_ => {});
    },

    selectionChangeHandler(newSelection) {
      this.selectedItems = newSelection;
    },

    /**
     * Go to the repair view to see the repair details.
     *
     * @param  {object} repair Repair object.
     */
    showRepairDetails(repair) {
      // navigate to the repair single view
      this.$router.push({ name: "repair.single", params: { id: repair.id } });
    },
  },
};
</script>
