<template>
  <section class="tp-suppliers_wrap">

    <!-- edit panel -------------------------------------------------------- -->

    <edit-form
      ref="editForm"
      @updated="fetchPage"
      @created="fetchPage"/>

    <!-- suppliers list ------------------------------------------------- -->
    <ui-list
      :total="total"
      :data="suppliers"
      :loading="loading"
      :creation-method="add"
      :remote-search="searchSuppliersHandler"
      allow-creation
      searchable
      paginate
      @current-changed="fetchPage"
      @mode-changed="modeChangeHandler">
      <ui-list-item
        v-for="item in orderedData"
        :key="item.id">
        <!-- avatar -->
        <div slot="image">
          <img
            :src="getAvatar(item.email)"
            class="tp-suppliers_avatar img-circle"
            alt="Avatar" >
        </div>

        <!-- name -->
        <span class="name">{{ item.name }}</span>

        <!-- email -->
        <span slot="details">{{ item.email }}</span>

        <!-- actions -->
        <div slot="actions">
          <el-button
            v-show="!item.disabled"
            type="danger"
            @click.native="disable(item)">Desativar</el-button>
          <el-button
            v-show="item.disabled"
            type="success"
            @click.native="activate(item)">Ativar</el-button>

          <!-- edit button -->
          <el-button
            type="info"
            @click.native="edit(item)">Editar</el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </section>
</template>

<script>
// TODO: deve ser possivel filtrar a lista (ativos)
import _ from "lodash";
import gravatar from "../../filters/gravatar";

import SuppliersFormCmp from "./SuppliersForm.vue";

export default {
  components: {
    "edit-form": SuppliersFormCmp,
  },

  data() {
    return {
      suppliers: [],
      total: 0,
      loading: false,
    };
  },

  computed: {
    orderedData() {
      return _.orderBy(this.suppliers, "name");
    },
  },

  created() {
    // fetch the first page of data
    this.fetchPage();
  },

  methods: {
    modeChangeHandler(mode) {
      if (mode === "normal") {
        this.query = "";
      }
    },

    /**
     * Fetch a page of suppliers.
     *
     * @type {number} page
     */
    fetchPage(page) {
      page = this.$route.query.page || 1;

      // enable loading
      this.loading = true;

      // get the data from the API
      this.$stellar
        .action("paginateSuppliers", {
          page,
          limit: 10,
          query: this.query,
        })
        .then(({ suppliers, total }) => {
          // update the list data
          this.total = total;
          this.suppliers = suppliers;

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Handle the suppliers search.
     *
     * @param query search query
     */
    searchSuppliersHandler(query) {
      this.query = query;
      this.fetchPage();
    },

    getAvatar(email) {
      return gravatar(email);
    },

    add() {
      this.$refs.editForm.show();
    },

    edit(resource) {
      // get the edit panel
      const panel = this.$refs.editForm;

      // set the resource to be edited
      panel.setResource(resource);

      // show edit panel
      panel.show();
    },

    /**
     * Handler for the resource creation.
     *
     * After a the insertion we need to add the new resource to the array list.
     */
    afterCreation(resource) {
      this.suppliers.push(resource);
    },

    disable(resource) {
      this.$confirm(
        "Tem a certeza que pretende desativar o fornecedor?",
        "Desativar fornecedor",
        {
          type: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Desativar",
        },
      )
        .then(() =>
          this.$stellar.action("disableSupplier", { id: resource._id }),
        )
        .then(response => {
          this.suppliers.find(el => el._id === resource._id).disabled = true;

          this.$message({
            type: "success",
            message: "O fornecedor foi desativado",
          });
        });
    },

    activate(resource) {
      this.$confirm(
        "Tem a certeza que pretende ativar o fornecedor?",
        "Ativar fornecedor",
        {
          type: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Ativar",
        },
      )
        .then(() =>
          this.$stellar.action("activateSupplier", { id: resource._id }),
        )
        .then(response => {
          this.suppliers.find(el => el._id === resource._id).disabled = false;

          this.$message({
            type: "success",
            message: "O fornecedor foi ativado",
          });
        });
    },
  },
};
</script>
