
<el-row
  :gutter="10"
  class="ui-ImageSelect">
  <!-- list of options -->
  <el-col
    v-for="item in data"
    :key="item.value"
    :span="2">
    <el-card
      :class="[
        'ui-ImageSelect__card',
        { 'is-selected': item.value === selected }
      ]"
      body-style="{ padding: '0px' }"
      @click.native="changeSelection(item)">
      <el-tooltip
        :content="item.title"
        effect="dark"
        placement="top">
        <!-- imagem -->
        <img
          :src="generateImageUrl(item)"
          :alt="item.title"
          class="ui-ImageSelect__image">
      </el-tooltip>
    </el-card>
  </el-col>
</el-row>
