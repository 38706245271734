
<transition
  name="fade"
  @after-enter="adjustSize">
  <div
    v-show="innerShow"
    class="ui-BottomSheet__mask is-middle">
    <transition name="slide-bottom">
      <div
        v-show="innerShow"
        class="ui-BottomSheet__container">
        <!-- actions -->
        <div
          v-show="actions"
          class="ui-BottomSheet__actions">
          <slot name="actions"/>
        </div>

        <!-- main slot -->
        <div class="ui-BottomSheet__content">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</transition>
