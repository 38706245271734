import Vue from "vue";
import { formatRelative } from "date-fns";

/**
 * Filter to format the given date directly on Vue templates.
 */
Vue.filter("dateCalendar", (value: Date | string, targetDate: Date = new Date()) => {
  const dateToFormat = typeof value === "string" ? new Date(value) : value;
  return formatRelative(dateToFormat, targetDate);
});
