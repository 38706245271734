
<section class="tp-repairWatingAtributionList_wrap">
  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler" />

  <!-- add material to the request -->
  <material-to-repair
    ref="materialAdd"
    :repair="selectedRepair"
    @requests="addRequest"/>

  <div
    v-if="selectedTechnician"
    class="tp-RepairWatingAtributionList__technician">
    <el-alert
      :title="'Os trabalhos aqui atribuidos refere-se ao tecnico ' + selectedTechnician.name + '.'"
      :closable="false"
      type="error"/>
  </div>

  <!-- send equipment to warranty -->
  <el-dialog
    v-loading.fullscreen="loading"
    :visible.sync="warrantyVisibility"
    class="tp-SendtoWarrantyDialog"
    title="Enviar para garantia"
    size="tiny">
    <el-select
      v-model="entity"
      placeholder="Entidade">
      <el-option
        v-for="e in entities"
        :key="e.value"
        :label="e.name"
        :value="e.id" />
    </el-select>

    <el-button
      type="success"
      size="small"
      @click="sendToWarrantyEntity(entity)">
      Enviar
    </el-button>
  </el-dialog>

  <!-- List os equipments -------------------------------------------------- -->
  <div class="tp-Screening__content" >
    <el-table
      v-loading.body="loading"
      :data="repairs"
      class="tp-Screening__table"
      stripe>
      <el-table-column
        :width="70"
        prop="repairNumber"
        label="#"/>
      <!-- client -->
      <el-table-column
        label="Cliente"
        width="200">
        <template slot-scope="scope">
          <div class="tp-Screening-client">
            <span class="tp-Screening-client__name">
              <el-tooltip
                v-if="isNewClient(scope.row)"
                effect="dark"
                content="Novo cliente"
                placement="top">
                <div class="tp-Screening-client__circle"/>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.complaint"
                effect="dark"
                content="Reclamação"
                placement="top">
                <div class="tp-Screening-complaint__circle"/>
              </el-tooltip>
              {{ scope.row.client.name }}
            </span>
          </div>
        </template>
      </el-table-column>

      <!-- clientEquiment -->
      <el-table-column
        label="Equipamento"
        width="200">
        <template slot-scope="{row}">
          <span>{{ row.clientEquipment.brand }} {{ row.clientEquipment.model }}</span>
        </template>
      </el-table-column>

      <!-- problem -->
      <el-table-column
        label="Problema"
        width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.problemDescription || scope.row.problemName }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Caixa"
        width="60"
        align="center">
        <template slot-scope="scope">
          <span> {{ scope.row.storeBox || '-' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Orçamento">
        <template slot-scope="scope">
          {{ scope.row.quotation | money }}
        </template>
      </el-table-column>

      <el-table-column
        property="priority"
        label="Prioridade"/>

      <!-- actions -->
      <el-table-column
        :width="260"
        label="">
        <template slot-scope="scope">
          <el-button-group class="repairWaitAtribList-buttonsGroup">
            <el-button
              type="info"
              size="mini"
              @click="showRepairDetails(scope.row)">Ver</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="atribTech(scope.row)">Atribuir</el-button>
            <el-button
              type="warning"
              size="mini"
              @click="purchase(scope.row)">Material</el-button>
            <el-button
              type="success"
              size="mini"
              @click="openWarrantyModal(scope.row)">
              Garantia
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</section>
