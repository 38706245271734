<template>
  <div class="tp-DeliveryList">
    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler" />

    <!-- client dialog -->
    <el-dialog
      :visible.sync="seeClientDialog"
      title="Informação do cliente"
      size="small">
      <client-card :client="client"/>
    </el-dialog>

    <!-- list -------------------------------------------------------------- -->
    <div class="tp-RepairsList__tableContent">
      <el-table
        v-loading="loading"
        :data="problemRepairs"
        class="tp-RepairsList__table"
        stripe
        style="width: 100%">
        <!-- repair number -->
        <el-table-column
          :width="85"
          label="#">
          <template slot-scope="scope">
            <div class="tp-repairList_quotation">
              <span class="tp-RepairsList-approved">
                <el-tooltip
                  v-if="scope.row.quotationApproved === true"
                  effect="dark"
                  content="Orçamento aprovado"
                  placement="top">
                  <div class="tp-RepairsList-approved__circle"/>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.quotationApproved === false"
                  effect="dark"
                  content="Orçamento recusado"
                  placement="top">
                  <div class="tp-RepairsList-rejected__circle"/>
                </el-tooltip>
                {{ scope.row.repairNumber }}
              </span>
            </div>
          </template>

        </el-table-column>

        <!-- store number -->
        <el-table-column
          :width="100"
          label="Ficha Loja"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.storeRepairIdentifier || '-' }}
          </template>
        </el-table-column>

        <!-- show the box where the equipment is stored -->
        <el-table-column
          :width="80"
          align="center"
          label="Caixa">
          <template slot-scope="scope">
            {{ scope.row.storeBox || '-' }}
          </template>
        </el-table-column>

        <!-- equipment brand and model -->
        <el-table-column
          :width="150"
          label="Equipamento">
          <template slot-scope="scope">
            <span>{{ scope.row.clientEquipment.brand
            }} {{ scope.row.clientEquipment.model }}</span>
          </template>
        </el-table-column>

        <!-- client -->
        <el-table-column
          :width="200"
          label="Cliente">
          <template slot-scope="scope">
            <a @click="seeClient(scope.row.client)">
              <span
                v-if="scope.row.client.isStore">{{ scope.row.client.name | truncate(20)
                }}</span>
              <span v-else>{{ scope.row.client.name }}</span>
            </a>
          </template>
        </el-table-column>

        <!-- problem -->
        <el-table-column
          :width="350"
          label="Avaria"
          align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.problemName === 'Outro'">
              <span>{{ scope.row.problemDescription }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row.problemName }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :width="100"
          label="Entrada"
          align="center">
          <template slot-scope="scope">
            {{ diffedDuration(scope.row.createdAt) }}
          </template>
        </el-table-column>

        <!-- operations -->
        <el-table-column
          :width="190"
          :context="_self"
          label="Operações">
          <template slot-scope="scope">
            <!-- see repair details -->
            <el-button
              size="small"
              type="info"
              @click="showRepairDetails(scope.row)">Ver
            </el-button>

            <!-- change technician -->
            <el-button
              :loading="loading"
              :disabled="!canChangeTechnician(scope.row)"
              size="small"
              type="warning"
              @click="changeTechnician(scope.row)">Alterar Tecnico
            </el-button>
          </template>
        </el-table-column>

        <!-- technician -->
        <el-table-column
          :width="150"
          label="Tecnico">
          <template slot-scope="scope">
            <span v-if="scope.row.technician">{{ scope.row.technician.name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination ------------------------------------------------------ -->
      <el-pagination
        :current-page="currentPage"
        :page-size="20"
        :total="total"
        class="tp-RepairsList__pagination"
        layout="prev, pager, next"
        @current-change="currentPageChange"/>
    </div>
  </div>
</template>
<script>
import ClientCardCmp from "../client/ClientCard.vue";
import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";
import { now } from "@/services/fp/date";

export default {
  components: {
    "client-card": ClientCardCmp,
    SelectTechnicianModal,
  },

  data() {
    return {
      isSelectTechnicianVisible: false,

      problemRepairs: [],
      currentPage: 1,

      // loading
      loading: false,
      total: 0,

      // repair details
      selectedRepair: null,
      seeClientDialog: false,

      // client to fill dialog
      client: null,

      selectedClient: "",
    };
  },
  created() {
    // get all repairs in problematic state send in $route
    this.problemRepairs = this.$store.state.route.params.repairs;
  },
  methods: {
    /**
     * Handle the change of technician.
     */
    onSelectTechnicianHandler(technician) {
      this.$stellar
        .action("setTechnicianForRepair", {
          id: this.selectedRepair.id,
          technician: technician.id,
        })
        .then(({ repair }) => {
          // show a sucess message
          this.$message({
            message: "O tecnico foi alterado.",
            type: "success",
          });
          // this.loading = false
          // re-fetch the current page
          this.fetchPage(this.currentPage);
        });
    },
    /**
     * Change the current page for the new one and fetch
     */
    currentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchPage(newPage);

      // change the URL to store the current page
      this.updateUrlQuery({ page: newPage });
    },

    diffedDuration(createdAt) {
      const createdAtDate = new Date(createdAt);
      const days = differenceInDays(now(), createdAtDate);

      return days !== 0 ? `${days} dias` : dateFrom(createdAtDate);
    },

    seeClient(client) {
      this.client = client;
      this.seeClientDialog = true;
    },

    /**
     * Show the repair details.
     */
    showRepairDetails(repair) {
      this.$router.push({ name: "repair.single", params: { id: repair.id } });

      this.selectedRepair = repair;
    },

    /**
     * Check if the technician can be changed.
     */
    canChangeTechnician(repair) {
      const validStates = ["waiting_atribution", "problematic", "in_tests", "paused", "waiting_repair", "in_repair"];
      return validStates.includes(repair.state);
    },

    /**
     * Open the list of technicians.
     */
    changeTechnician(repair) {
      this.selectedRepair = repair;
      this.isSelectTechnicianVisible = true;
    },

    async fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // update the URL query
      this.updateUrlQuery(
        {
          filters: JSON.stringify(this.filters),
          technician: this.selectedTechnician,
          query: this.searchQuery,
          client: this.selectedClient,
          range: JSON.stringify(this.range),
          page,
        },
        true,
      );

      const options = {
        page,
        limit: 20,
        query: this.searchQuery,
        client: this.selectedClient,
        range: this.range,
        ...this.filters,
      };

      // request the page to the API
      const { data, total } = await this.$stellar.action("paginateRepairs", options);
      this.total = total;
      this.repairs = data;

      // disable loading
      this.loading = false;
    },
  },
};
</script>
