<template>
  <el-card
    v-if="client"
    class="tp-ClientCard">
    <div class="tp-ClientCard__content">
      <!-- avatar -->
      <img
        :src="getAvatar(client.email)"
        class="tp-ClientCard__avatar"
        alt="Avatar" >

      <div class="tp-ClientCard__info">
        <div class="tp-ClientCard__info__name">
          {{ client.name }}
        </div>

        <div class="tp-ClientCard__entry">
          <span class="title">NIF:</span>
          <span class="value">{{ client.taxNumber }}</span>
        </div>

        <div
          v-if="client.email"
          class="tp-ClientCard__entry">
          <span class="title">Email:</span>
          <a
            :href="emailHref"
            class="value">{{ client.email }}</a>
        </div>

        <div
          v-if="client.phone"
          class="tp-ClientCard__entry">
          <span class="title">Telefone:</span>
          <a
            :href="phoneHref"
            class="value">{{ client.phone }}</a>
        </div>
      </div>
    </div>

    <div class="tp-ClientCard__actions">
      <slot name="actions"/>
    </div>
  </el-card>
</template>

<script>
import gravatar from "gravatar";

export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    emailHref() {
      return `mailto:${this.client.email}`;
    },
    phoneHref() {
      return `tel:${this.client.phone}`;
    },
  },

  methods: {
    /**
     * Generate the gravatar image address by email.
     */
    getAvatar(email) {
      return gravatar.url(email, { s: "200" });
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-ClientCard {
    max-width: 600px;
    margin: 30px auto;

    .el-card__body {
      padding: 0;
    }

    &__content {
      display: flex;
      width: 100%;
    }

    &__info {
      width: 100%;
      padding: 10px 10px 10px 20px;

      &__name {
        font-size: 1.3em;
        font-weight: 300;
        margin: 5px 0 10px 0;
      }
    }

    &__entry {
      margin-top: 5px;

      .title {
        font-weight: 500;
        margin-right: 5px;
      }
    }

    &__actions {
      width: 100%;
      padding: 10px;
      background-color: #fafafa;
    }
  }
}
</style>
