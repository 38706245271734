import { ActionTree } from "vuex";
import { IUsersState, EUsersActions, EUsersMutations } from "./users.types";
import { IStoreRootState } from "../../types/store-root-state.interface";
import { getBaseName } from "../../utils/get-base-name";
import stellar from "@/services/stellar";

export const usersActions: ActionTree<IUsersState, IStoreRootState> = {
  async [getBaseName(EUsersActions.FETCH_USER)](
    { state, commit },
    usersIds: string | Array<string>,
  ) {
    if (!Array.isArray(usersIds)) {
      usersIds = [usersIds];
    }

    // Ignore users that were already fetched
    const currentIds = Object.keys(state.users);
    usersIds = usersIds.filter(entry => !currentIds.includes(entry));
    if (usersIds.length === 0) {
      return;
    }

    commit(EUsersMutations.SET_ERROR, null);
    commit(EUsersMutations.SET_ERROR, null);

    try {
      const { users } = await stellar.action("fetchUsers", { ids: usersIds });
      commit(EUsersMutations.SET_USERS, users);
    } catch (error) {
      commit(EUsersMutations.SET_ERROR, error);
    } finally {
      commit(EUsersMutations.SET_LOADING, false);
    }
  },
};
