<template>
  <div class="tp-RepairForm__step5">
    <el-card class="tp-RepairForm__step5">
      <!-- repair number -->
      <div
        slot="header"
        class="clearfix">
        <span style="line-height: 20px; font-weight: bold">
          #{{ repair.repairNumber }}
        </span>
      </div>

      <!-- for when there is a store box -->
      <div v-if="repair.storeBox && !isStoreUser">
        <h2>O equipamento deve ser colocado na caixa:</h2>
        <div class="box">{{ repair.storeBox }}</div>
      </div>

      <!-- an equipment without a store box -->
      <div
        v-if="!repair.storeBox"
        class="tp-RepairForm__step5__noBox">
        <h2>O equipamento deve ser colocado no local apropriado.</h2>
      </div>

      <!-- add a new repair for the same client -->
      <el-button
        type="primary"
        @click="$emit('newRepair')">
        Novo Equipamento
      </el-button>

      <!-- reset and go to the dashboard -->
      <el-button
        type="success"
        @click="finish">Terminar</el-button>
    </el-card>

    <!-- receipt -->
    <repair-receipt
      ref="receipt"
      :repair="repair"
      :client="client"
      :equipment="equipment"
      :equipment-type="equipmentType"/>

    <ui-list
      v-if="repairsList.length > 1"
      :data="repairsList">
      <ui-list-item
        v-for="rep in repairsList"
        :key="rep.id">
        <!-- brand and model -->
        <span>
          #{{ rep.repairNumber }} - {{ rep.clientEquipment.brand }} {{ rep.clientEquipment.model }}
        </span>

        <!-- details -->
        <div slot="details">
          <span>Caixa: {{ rep.storeBox || 'N/A' }}</span>
        </div>
      </ui-list-item>
    </ui-list>
  </div>
</template>

<script>
import RepairReceipt from "../Receipt.vue";

export default {
  components: {
    RepairReceipt,
  },

  props: {
    repair: {
      type: Object,
      default: () => ({}),
    },
    client: {
      type: Object,
      default: () => ({}),
    },
    equipment: {
      type: Object,
      default: () => ({}),
    },
    equipmentType: {
      type: Object,
      default: () => ({}),
    },
    repairsList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    user() {
      return this.$store.get("user");
    },

    isStoreUser() {
      return this.user.role.slug === "store";
    },

    targetRoute() {
      if (this.user.role.slug === "store") {
        return "store.ongoing";
      }

      return "dashboard";
    },
  },

  methods: {
    finish() {
      // if client is not Store print receipt
      if (!this.client.isStore) {
        this.$refs.receipt.printReceipt();
      }

      this.$router.push({ name: this.targetRoute });
    },
  },
};
</script>

<style lang="scss">
@import "../../var.css";

.tp {
  &-RepairForm {
    &__step5 {
      width: 100%;
      margin: 30px auto 0;
      max-width: 600px;

      &__noBox {
        text-align: center;
        margin-bottom: 20px;
      }

      .box {
        font-size: 3em;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .tp_receipt {
        display: none;
      }
    }
  }
}
</style>
