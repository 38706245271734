
<!-- technitians list -->
<ui-list :data="commissions" >
  <div
    v-for="commission in commissions"
    :key="commission.id">
    <el-col :span="24"><div class="grid-content bg-purple-dark"><span>{{ commission.technician.name }}</span></div></el-col>
    <el-table
      :data="commission.repairs"
      stripe
      style="width: 100%">
      <el-table-column
        prop="clientEquipment"
        label="Client"
        width="180"/>
      <el-table-column
        prop="quotation"
        label="Valor"
        width="160"/>
      <el-table-column
        prop="storeBox"
        label="Caixa"
        width="160"/>
    </el-table>
    <span> Total ............</span>
    <span>{{ commission.total }}</span>
  </div>
</ui-list>
