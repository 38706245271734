import { formatRelative } from "date-fns/esm";
import pt from "date-fns/locale/pt";

/**
 * Get current date.
 */
export function now(): Date {
  return new Date();
}

/**
 * Format date in string in relation to the base date.
 *
 * @param date
 * @param baseDate
 * @returns
 */
export function dateFrom(date: Date, baseDate: Date = now()): string {
  return formatRelative(date, baseDate, { locale: pt });
}
