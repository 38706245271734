import _ from "lodash";

import {
  ACTION_ENTITY_FETCH,
  MUTATION_ENTITY_SET,
  ACTION_ENTITY_CREATE,
  ACTION_ENTITY_EDIT,
  ACTION_ENTITY_DELETE,
} from "./entities-types";
import stellar from "@/services/stellar";

export default {
  /**
   * Fetch all entities.
   */
  async [ACTION_ENTITY_FETCH]({ commit, state }, opts) {
    opts = _.defaults(opts, {
      force: false,
      includeDisabled: false,
    });

    // when we already have data, just make the request when the last update
    // happened after 30 minutes or when is requested to make a force update
    if (opts.force === false || state.lastUpdate !== null) {
      const diff = Math.abs(new Date() - state.lastUpdate);
      if (Math.floor(diff / 1000 / 60) < 30) {
        return;
      }
    }

    commit(MUTATION_ENTITY_SET, {
      ...state,
      loading: true,
      error: null,
    });

    try {
      const { entities } = await stellar.action("getEntities", {
        includeDisabled: opts.includeDisabled,
      });
      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        lastUpdate: new Date(),
        entities,
      });
    } catch (error) {
      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        error,
      });
    }
  },

  /**
   * Create a new entity.
   */
  async [ACTION_ENTITY_CREATE]({ commit, state }, data) {
    commit(MUTATION_ENTITY_SET, {
      ...state,
      loading: true,
      error: null,
    });

    try {
      const { entity } = await stellar.action("createEntity", data);

      const newEntitiesArray = _.cloneDeep(state.entities);
      newEntitiesArray.unshift(entity);

      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        entities: newEntitiesArray,
      });
    } catch (error) {
      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        error,
      });
    }
  },

  /**
   * Perform a update to a entity.
   */
  async [ACTION_ENTITY_EDIT]({ commit, state }, data) {
    commit(MUTATION_ENTITY_SET, {
      ...state,
      loading: true,
      error: null,
    });

    try {
      const { entity } = await stellar.action("editEntity", data);

      const newEntities = _.cloneDeep(state.entities);
      const index = newEntities.findIndex(item => item.id === entity.id);
      newEntities.splice(index, 1, entity);

      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        entities: newEntities,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("[ENTITIES]", error);
      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        error,
      });
    }
  },

  /**
   * Delete the requested entity.
   */
  async [ACTION_ENTITY_DELETE]({ commit, state }, id) {
    commit(MUTATION_ENTITY_SET, {
      ...state,
      loading: true,
      error: null,
    });

    try {
      await stellar.action("deleteEntity", { id });

      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        entities: state.entities.find(item => item.id !== id),
      });
    } catch (error) {
      commit(MUTATION_ENTITY_SET, {
        ...state,
        loading: false,
        error,
      });
    }
  },
};
