<template>
  <div class="ui-List__item">
    <!-- selection -->
    <div
      v-if="$parent.selectable"
      class="ui-List__item__selection">
      <el-checkbox v-model="selectionValue"/>
    </div>

    <!-- imagem -->
    <div
      v-if="this.$slots['image']"
      class="ui-List__item__image">
      <slot name="image"/>
    </div>

    <!-- content -->
    <div class="ui-List__item__content">
      <div class="ui-List__item__title">
        <!-- title (default) -->
        <slot/>
      </div>
      <div class="ui-List__item__details">
        <!-- details -->
        <slot name="details"/>
      </div>
    </div>

    <!-- actions -->
    <div class="ui-List__item__actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script>
export default {
  replace: true,

  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      selectionValue: false,
    };
  },

  computed: {
    selection() {
      return this.$parent.selection;
    },
  },

  watch: {
    selectionValue(newVal) {
      this.$parent.$emit("item-selection-change", newVal, this.data);
    },
  },

  created() {
    this.$parent.$on("clear-selection", () => {
      this.selectionValue = false;
    });
  },
};
</script>
