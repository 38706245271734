<template>
  <div class="ui-Notifications">
    <div class="ui-notifications_header">
      <h1>Notificações</h1>
    </div>

    <div
      v-if="notifications.length === 0"
      class="ui-notifications_empty">
      <i
        class="fa fa-thumbs-o-up"
        aria-hidden="true"/>
      <span>Sem notificações!</span>
    </div>

    <div class="ui-Notifications__box">
      <template v-if="notifications.length !== 0">
        <div
          v-for="entry in orderedNotifications"
          :key="entry.id"
          :class="{ notRead: !entry.read }"
          class="ui-notifications_entry"
          @click="markNotificationAsRead(entry)">
          <!-- image -->
          <i
            class="fa fa-cube image"
            aria-hidden="true"/>

          <div class="content">
            <!-- title -->
            <span class="title">{{ entry.title }}</span>

            <!-- content for a new repair -->
            <div v-if="entry.type === 'new_repair_wa'">
              <router-link :to="{ name: 'repair.waiting', params: { id: entry.content } }">Nova reparação à espera de triagem!</router-link>
            </div>

            <!-- client_request_response -->
            <div v-if="entry.type === 'client_request_response'">
              <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Uma reparação obteve resposta do cliente.</router-link>
            </div>

            <!-- material_available -->
            <div v-if="entry.type === 'material_available'">
              <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Uma reparação tem agora material disponível.</router-link>
            </div>

            <!-- content for work attribution -->
            <div v-if="entry.type === 'work_attribution'">
              <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Novo trabalho!</router-link>
            </div>

            <!-- -->
            <div v-if="entry.type === 'quotation_approve'">
              <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Orçamento aprovado para uma das reparações.</router-link>
            </div>

            <!-- new_urgent_repair -->
            <div v-if="entry.type === 'new_urgent_repair'">
              <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Tem um novo trabalho urgente.</router-link>
            </div>

            <!-- technician_has_little_work -->
            <div v-if="entry.type === 'technician_has_little_work'">
              <router-link :to="{ name: 'repair.waiting', query: { id: entry.content } }">Um tecnico está a ficar sem trabalho.</router-link>
            </div>

            <!-- new_contact -->
            <div v-if="entry.type === 'new_contact'">
              <router-link :to="{ name: 'clientContacts.list' }">Novo contacto para realizar.</router-link>
            </div>

            <!-- new_complaint_to_validate -->
            <div v-if="entry.type === 'new_complaint_to_validate'">
              <router-link :to="{ name: 'complaint.create' }">Tem uma nova reclamação para avalidar.</router-link>
            </div>

            <!-- material_over_value -->
            <div v-if="entry.type === 'material_over_value'">
              <router-link :to="{ name: 'repair.single' }">Valor da reparação inferior ao material.</router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

// flag to check if new notifications are in load
let loading = false;

// scroll position
let curScrollPos = 0;

export default {
  name: "Notifications",

  data() {
    return {
      isListening: false,
    };
  },

  computed: {
    ...mapGetters(["notifications"]),

    orderedNotifications() {
      return _.orderBy(this.notifications, "createdAt", "desc");
    },
  },

  watch: {
    notifications() {
      const notificationsBox = document.querySelector(".ui-Notifications__box");

      // adjust the scroll position
      this.$nextTick(() => {
        notificationsBox.scrollTop =
          notificationsBox.clientHeight - curScrollPos;
      });
    },
  },

  created() {
    // we only fetch the first page if the notificationsBox are empty
    if (this.notifications.length !== 0) {
      return;
    }

    // load the first page
    this.notificationsFetchNextPage();
  },

  mounted() {
    this.listeningForScroll();
  },

  methods: {
    ...mapActions(["notificationsFetchNextPage", "markNotificationAsRead"]),

    listeningForScroll() {
      if (this.isListening) {
        return;
      }

      const notificationsBox = document.querySelector(".ui-Notifications__box");

      if (notificationsBox) {
        notificationsBox.addEventListener(
          "scroll",
          this.loadNotificationsOnScroll,
        );
        this.isListening = true;
      }
    },

    loadNotificationsOnScroll() {
      // get the element reference
      const notificationsBox = document.querySelector(".ui-Notifications__box");

      // compute the current scroll position
      const curInnerScrollPost =
        notificationsBox.offsetHeight + notificationsBox.scrollTop;

      // save the current scroll position
      curScrollPos = notificationsBox.scrollTop;

      const fromBottom = notificationsBox.scrollHeight - curInnerScrollPost;

      if (!loading && fromBottom < 50) {
        // mark as loading
        loading = true;

        // load the next page of notifications
        this.notificationsFetchNextPage().then(() => {
          loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.ui {
  &-Notifications {
    &__box {
      height: 284px;
      overflow-y: scroll;
    }
  }
}

/** OLD **/

.ui-notifications_container.el-popover {
  padding: 0;
}

.ui-notifications_header {
  height: 40px;
  width: 100%;
  color: white;

  text-align: center;
  padding: 7px;

  border-bottom: 1px solid #d0dbe1;
}

.ui-notifications_header h1 {
  color: #747f89;
  font-size: 1.2em;

  margin: 0;
  padding-top: 5px;
}

.ui-notifications_entry {
  padding: 10px;
  display: flex;

  border-bottom: 1px solid #d0dbe1;
}

.ui-notifications_entry:last-child {
  border-bottom: none;
}

.ui-notifications_entry.notRead {
  background-color: #e9eff2;
}

.ui-notifications_entry .image {
  font-size: 2.4em;
  align-self: center;
}

.ui-notifications_entry .content {
  padding-left: 10px;
}

.ui-notifications_entry .title {
  font-weight: 500;
  font-size: 1em;
}

.ui-notifications_empty {
  padding: 10px 0;
  text-align: center;
  font-size: 1.1em;

  color: #c3ced4;
}

.ui-notifications_empty i {
  font-size: 1.7em;

  display: block;
  padding-bottom: 10px;
}
</style>
