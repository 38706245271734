import { Module } from "vuex";
import { ISpacesSate } from "@/services/store/modules/spaces/spaces-types";
import { IStoreRootState } from "@/services/store/types/store-root-state.interface";

import { state } from "./spaces-state";
import { mutations } from "./spaces-mutations";
import { actions } from "./spaces-actions";

export const spaceModule: Module<ISpacesSate, IStoreRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
