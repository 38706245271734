
<section class="tp-DamageMatList">
  <!-- reason -->
  <el-dialog
    :visible.sync="dialogVisibility"
    title="Avaliação da Peça"
    size="tiny">
    <el-form
      v-if="selectedEntry"
      label-position="top">
      <el-form-item label="Responsável">
        <el-select
          v-model="responsable"
          placeholder="Responsável">
          <el-option
            v-for="item in options"
            :key="item.responsable"
            :label="item.label"
            :value="item.responsable"/>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        @click="confirmReason">
        Avaliar
      </el-button>

      <!-- cancel -->
      <el-button
        :plain="true"
        @click="dialogVisibility = false">
        Cancelar
      </el-button>
    </el-form>
  </el-dialog>

  <el-tabs type="border-card">
    <el-tab-pane label="Material defeituoso">
      <DamagedMaterialTable
        :data="damagedData"
        :loading="loading"
        type="damaged"
        @evalute="onEvaluteEvent" />
    </el-tab-pane>

    <el-tab-pane label="Material Partido">
      <DamagedMaterialTable
        :data="brokenData"
        :loading="loading"
        type="broken"
        @evalute="onEvaluteEvent" />
    </el-tab-pane>
  </el-tabs>
</section>
