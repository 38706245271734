import { MutationTree } from "vuex";
import { IUsersState, EUsersMutations } from "./users.types";
import { getBaseName } from "../../utils/get-base-name";
import { IUser } from "../user-types";

export const usersMutations: MutationTree<IUsersState> = {
  [getBaseName(EUsersMutations.SET_USERS)](state, users: Array<IUser>) {
    users.forEach(user => {
      state.users[user.id] = user;
    });
    state.users = { ...state.users };
  },
};
