/* global localStorage */

/**
 * This file contains the subsystem for the router system.
 */

import { Message } from "element-ui";
import VueRouter, { Route, RouteRecord, RouteConfig } from "vue-router";

// @ts-ignore
import { getStore } from "@/services/store";

import Login from "@/components/Login.vue";
import Base from "@/components/Base.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import UsersList from "@/components/users/UsersList.vue";
import ClientList from "@/components/client/ClientList.vue";
import Configs from "@/components/Configs.vue";
import TechnicianList from "@/components/technician/TechnicianList.vue";
import SuppliersList from "@/components/suppliers/SuppliersList.vue";
import MaterialList from "@/components/material/MaterialList.vue";
import MaterialListReseller from "@/components/material/MaterialListReseller.vue";
import DamagedMatList from "@/components/material/DamagedMatList.vue";
import RepairForm from "@/components/repair/repairForm.vue";
import RepairExternalCreate from "@/components/repair/repair-external/RepairExternalCreate.view.vue";
import ComplaintForm from "@/components/complaint/complaintForm.vue";
import RepairWaitAtribList from "@/components/repair/repairWaitAtribList.vue";
import PurchasesList from "@/components/purchases/PurchasesList.vue";
import ClientContacts from "@/components/ClientContacts.vue";
import TypesList from "@/components/types/TypesList.vue";
import WorkList from "@/components/work/WorkList.vue";
import RepairSingle from "@/components/repair/RepairSingle.vue";
import DeliverList from "@/components/repair/DeliverList.vue";
import MaterialRequestList from "@/components/materialRequest/MaterialRequestList.vue";
import ContactsList from "@/components/contacts/List.vue";
import Ongoing from "@/components/client/Ongoing.vue";
import ClientDashHistory from "@/components/clientDash/history.vue";
import Event from "@/components/Event.vue";
import ClientEquipment from "@/components/Client_Equipment.vue";
import MissedCalls from "@/components/missedCalls/Missed_calls.vue";
import ClosedRepairs from "@/components/technician/ClosedRepairs.vue";
import RepairsList from "@/components/repair/RepairsList.vue";
import AllCommissions from "@/components/commissions/AllCommissions.vue";
import TechnicianCommissions from "@/components/commissions/TechnicianCommissions.vue";
import InTransit from "@/components/repair/InTransit.vue";
import Note from "@/components/notes/note.vue";
import DalyCommissions from "@/components/commissions/DalyCommissions.vue";
import ForgotPassword from "@/components/Forgot_password.vue";
import ProblematicRepairsList from "@/components/repair/ProblematicRepairsList.vue";
import Warranty from "@/components/warranty/warranty.vue";
import EntitiesList from "@/components/entities/entities-list.vue";
import { activeModules } from "@/services/utils";
import Profits from "@/components/profits/store-profits.vue";
import ClosedRepairsByTechnician from "@/components/repair/TodayClosedRepairsByTechnician.vue";
import PurchaseWaitnigMorethanThreeweeks from "@/modules/dashboard/views/dashboard-purchase-list.vue";
import TodayClosedRepairs from "@/modules/dashboard/views/closed-today-view.vue";
import { isNil } from "lodash";
import { registerNeedsAuthenticationMiddleware } from "./middleware/needs-authentication";
import { registerPageTitleMiddleware } from "./middleware/page-title";
import { registerNeedsPermissionMiddleware } from "./middleware/needs-permission";

/**
 * Singleton for the router instance.
 */
let routerSingleton: VueRouter | null = null;

// list of routes to be registered
const initialRoutes: Array<RouteConfig> = [
  {
    path: "/",
    meta: { auth: true },
    component: Base,

    children: [
      // dashboard
      {
        name: "dashboard",
        path: "/",
        meta: {
          title: "Dashboard",
          needPermissions: ["see_dashboard"],
        },
        component: Dashboard,
      },
      // users
      {
        path: "/users",
        name: "users.list",
        meta: {
          title: "Utilizadores",
          needRole: "admin",
        },
        component: UsersList,
      },
      // clients
      {
        path: "/client",
        name: "client.list",
        meta: {
          title: "Clientes",

          needPermissions: ["see_clients"],
        },
        component: ClientList,
      },
      // configs
      {
        path: "/configs",
        name: "configs",
        meta: {
          title: "Configurações",

          needPermissions: ["see_configs"],
        },
        component: Configs,
      },
      // technicians
      {
        path: "/technician",
        name: "technician.list",
        meta: {
          title: "Tecnicos",

          needRole: "admin",
        },
        component: TechnicianList,
      },
      // suppliers
      {
        path: "/suppliers",
        name: "supplier.list",
        meta: {
          title: "Fornecedores",

          needPermissions: ["see_suppliers"],
        },
        component: SuppliersList,
      },
      // material
      {
        path: "/material",
        name: "material.list",
        meta: {
          title: "Materiais",

          needPermissions: ["see_materials"],
        },
        component: MaterialList,
      },
      // material
      {
        path: "/reseller_material",
        name: "material_reseller.list",
        meta: {
          title: "Materiais",

          needPermissions: ["see_materials_reseller"],
        },
        component: MaterialListReseller,
      },
      // damaged material
      {
        path: "/damagedMaterial",
        name: "damagedMaterial.list",
        meta: {
          title: "Materiais Danificados",

          needPermissions: ["see_materials"],
        },
        component: DamagedMatList,
      },
      // create a new repair
      {
        path: "/repair_form",
        name: "repair.create",
        meta: {
          title: "Nova Reparação",

          needPermissions: ["create_repairs"],
        },
        component: RepairForm,
      },
      // Cerate a new repair by an external user
      {
        path: "/repair/external_create",
        name: "repair.externalCreate",
        meta: {
          title: "Nova Reparação",

          needPermission: ["create_external_repairs"],
        },
        component: RepairExternalCreate,
      },
      // create a new complaint
      {
        path: "/complaint_form",
        name: "complaint.create",
        meta: {
          title: "Reclamações",

          needPermissions: ["create_complaint"],
        },
        component: ComplaintForm,
      },
      // list of repairs who are waiting attribution
      {
        path: "/screening",
        name: "repair.waiting",
        meta: {
          title: "Triagem",

          needPermissions: ["see_screening"],
        },
        component: RepairWaitAtribList,
      },
      // purchases list
      {
        path: "/purchases",
        name: "purchases.list",
        meta: {
          title: "Compras",

          needPermissions: ["see_purchases"],
        },
        component: PurchasesList,
      },
      // client contacts
      {
        path: "/clientContacts",
        name: "clientContacts.list",
        meta: {
          title: "Contactar",

          needPermissions: ["see_clients"],
        },
        component: ClientContacts,
      },
      // Equipment Types
      {
        path: "/types",
        name: "types.list",
        meta: {
          title: "Tipos",

          role: "admin",
        },
        component: TypesList,
      },
      // Technician work
      {
        path: "/work_list",
        name: "work.list",
        meta: {
          title: "Lista de Trabalho",

          needPermissions: ["see_work"],
        },
        component: WorkList,
      },
      // repair single page
      {
        path: "/repair/:id",
        name: "repair.single",
        meta: {
          title: "Reparação",

          needPermissions: ["see_repair"],
        },
        component: RepairSingle,
      },
      {
        path: "/deliver",
        name: "deliver.list",
        meta: {
          title: "Entregas",

          needPermissions: ["see_delivers"],
        },
        component: DeliverList,
      },
      // validate material needs
      {
        path: "/requests_material",
        name: "materialRequests.list",
        meta: {
          title: "Pedidos",

          needPermissions: ["see_material_requests"],
        },
        component: MaterialRequestList,
      },

      // -- CONTACTS
      // --- list
      {
        path: "/contact/list",
        name: "contact.list",

        meta: {
          title: "Contactos",
          needPermissions: ["see_contacts"],
        },

        component: ContactsList,
      },

      // list of on going repairs
      {
        path: "/client_ongoing",
        name: "store.ongoing",

        meta: {
          title: "Em progresso",
          role: "store",
        },

        component: Ongoing,
      },

      // Client - Repair history
      {
        path: "/client_history",
        name: "client.history",

        meta: {
          title: "Histórico",
          role: "store",
        },

        component: ClientDashHistory,
      },

      {
        path: "/event",
        name: "event",
        component: Event,
      },
      {
        path: "/client_equipment",
        name: "client_equipment",
        component: ClientEquipment,
      },
      {
        path: "/missed_calls",
        name: "missed_calls",
        component: MissedCalls,
      },
      {
        path: "/closed_repairs",
        name: "closedRepairs",
        meta: { title: "Comissões a receber" },
        component: ClosedRepairs,
      },
      {
        path: "/repairs",
        name: "repairs.list",
        meta: { title: "Reparações" },
        component: RepairsList,
      },
      {
        path: "/all_commissions",
        name: "Allcommissions",
        meta: { title: "Lista de comissões", needRole: "admin" },
        component: AllCommissions,
      },
      {
        path: "/technician_commissions",
        name: "TechnicianCommission",
        meta: { title: "Comissões do tecnico", needRole: "admin" },
        component: TechnicianCommissions,
      },
      {
        path: "inTransit",
        name: "repair.inTransit",
        meta: {
          title: "Em trânsito",
          needPermissions: ["see_screening"],
        },
        component: InTransit,
      },
      {
        path: "notes",
        name: "notes.note",
        meta: { title: "Notas" },
        component: Note,
      },
      {
        path: "DalyCommissions",
        name: "commissions.dalyCommissions",
        meta: { title: "Comissões" },
        component: DalyCommissions,
      },
      {
        path: "/problematics",
        name: "repairs.problematic",
        meta: { title: "Reparações problematicas" },
        component: ProblematicRepairsList,
      },
      {
        path: "/warranty",
        name: "warranty.list",
        meta: {
          title: "Garantia",
        },
        component: Warranty,
      },
      {
        path: "/entities",
        name: "entities.list",
        meta: {
          title: "Entidades",
        },
        component: EntitiesList,
      },
      {
        name: "profits.storeProfits",
        path: "/profits",
        meta: {
          title: "Lucros Lojas",
          needRole: "admin",
        },
        component: Profits,
      },
      {
        name: "closedRepairsByTechnician",
        path: "/repair",
        meta: {
          title: "Reparações fechadas hoje, por tecnico",
          needRole: "admin",
        },
        component: ClosedRepairsByTechnician,
      },
      {
        name: "todayClosedRepairs",
        path: "/closed-today",
        meta: {
          title: "Fechadas hoje, por tecnico",
          needRole: "admin",
        },
        component: TodayClosedRepairs,
      },
      {
        name: "purchaseWaitnigMorethanThreeweeks",
        path: "/wainting-purchases",
        meta: {
          title: "Reparações à espera à mais de 3 semanas",
          needRole: "admin",
        },
        component: PurchaseWaitnigMorethanThreeweeks,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { guest: true },
    component: Login,
  },
  {
    path: "/forgot",
    name: "forgot",
    meta: { guest: true },
    component: ForgotPassword,
  },
  {
    path: "*",
    redirect: "/login",
  },
] as Array<RouteConfig>;

/**
 * Get list of dynamic routes.
 */
async function getDynamicRoutes(): Promise<Array<RouteConfig>> {
  let dynamicRoutes: Array<RouteConfig> = [];

  for (const moduleName of activeModules()) {
    try {
      const { defaultRoutes } = await import(`../../modules/${moduleName}/services/router/router.ts`);
      dynamicRoutes = [...dynamicRoutes, ...defaultRoutes];
    } catch (e) {
      // Do nothing!
    }
  }

  return dynamicRoutes;
}

/**
 * Initialize the router system.
 */
export async function initRouter(): Promise<void> {
  const dynamicRoutes = await getDynamicRoutes();
  const routes = [...initialRoutes, ...dynamicRoutes];

  routerSingleton = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior: (to, from, savedPosition = { x: 0, y: 0 }) => savedPosition,
  });

  // register router middleware
  registerNeedsAuthenticationMiddleware(routerSingleton);
  registerPageTitleMiddleware(routerSingleton);
  registerNeedsPermissionMiddleware(routerSingleton);
}

/**
 * Get router instance.
 */
export async function getRouter(): Promise<VueRouter> {
  if (isNil(routerSingleton)) {
    await initRouter();
  }

  return routerSingleton as VueRouter;
}
