<template>
  <section class="tp-materialListReseller_wrap">
    <!-- list -------------------------------------------------------------- -->
    <div class="tp-materialListReseller__content">

      <!-- action -->
      <div class="tp-materialListReseller__actions">
        <!-- search -->
        <el-input
          v-model="searchQuery"
          class="tp-materialListReseller__search"
          placeholder="Pesquise aqui por materiais..."/>
      </div>

      <el-table
        v-loading.body="loading"
        :data="orderedData"
        stripe>
        <el-table-column label="Descrição">
          <template slot-scope="scope">
            {{ scope.row.description }} , {{ scope.row.brand }} {{ scope.row.model }}
          </template>
        </el-table-column>

        <el-table-column
          :width="90"
          label="Preço"
          align="center">
          <template slot-scope="{ row }">
            <span v-if="row.price > 0">{{ row.price + resellerCommission | money }}</span>
            <span v-else>{{ row.price | money }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :width="150"
          label="Preço c/ IVA"
          align="center">
          <template slot-scope="{ row }">
            <span v-if="row.price > 0">{{ (row.price + resellerCommission) * 1.23 | money }}</span>
            <span v-else>{{ row.price | money }}</span>
          </template>
        </el-table-column>

        <!-- stock (new and used) -->
        <el-table-column
          :width="100"
          label="Stock"
          align="center">
          <template slot-scope="scope">
            <el-tag :type="getTagColor(scope.row)">{{ scope.row.newStock }}/{{ scope.row.usedStock }}</el-tag>
          </template>
        </el-table-column>

        <!-- purchase -->
        <el-table-column
          :width="100"
          label="Aguarda"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.requestedQuantity }}
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination ------------------------------------------------------ -->

      <el-pagination
        :current-page="currentPage"
        :total="total"
        :page-size="20"
        class="tp-materialListReseller__pagination"
        layout="prev, pager, next"
        @current-change="currentPageChange"/>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

// var to store the query timer
let searchTimer = null;

// var to store the current page
let currentPage = 0;

export default {
  data() {
    return {
      materials: [],
      currentPage: 1,
      total: 0,
      loading: false,

      searchQuery: "",

      // sell panel
      sellFormVisible: false,
      selectedMaterial: null,
      sellForm: {
        type: "new",
        quantity: 1,
      },
    };
  },

  computed: {
    ...mapGetters(["configs"]),

    resellerCommission() {
      const value = this.configs.find(
        item => item.key === "reseller_commission",
      ).value;

      return parseInt(value, 10);
    },

    orderedData() {
      return _.orderBy(this.materials, "description");
    },
  },

  watch: {
    searchQuery(newVal, oldVal) {
      // ignore queries with just one char
      if (newVal.length < 2 && oldVal.length > 1) {
        return this.fetchPage(this.currentPage);
      } else if (newVal.length < 2 && oldVal.length < 2) {
        return;
      }

      // if there is a timer remove it
      clearTimeout(searchTimer);

      // create a timeout to execute the query with a delay of 400 ms
      searchTimer = setTimeout(() => {
        this.fetchPage(1);
      }, 400);
    },
  },

  created() {
    // fetch the first page
    this.fetchPage();
  },

  methods: {
    getTagColor(material) {
      const totalStock = material.newStock + material.usedStock;

      if (totalStock === 0) {
        // danger
        return "danger";
      } else if (totalStock <= material.stockMin) {
        // warning
        return "warning";
      }

      return "default";
    },

    /**
     * Fetch a page of materials.
     *
     * @type {number} page
     */
    fetchPage(page = 1, query = "") {
      // enable loading
      this.loading = true;

      // make an API call to get the requested page
      this.$stellar
        .action("paginateMaterials", {
          page,
          limit: 20,
          withRequests: true,
          query: this.searchQuery,
        })
        .then(response => {
          this.total = response.total;
          this.materials = response.data;

          // store the current page
          currentPage = page;

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Reload the current page.
     */
    reloadCurrentPage() {
      this.fetchPage(currentPage);
    },

    /**
     * Change the current page for the new one and fetch
     */
    currentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchPage(newPage);
    },

    /**
     * Show a clean form to create a new material.
     */
    add() {
      this.$refs.editForm.show();
    },

    edit(resource) {
      // get the edit panel
      const panel = this.$refs.editForm;

      // set the resource to be edited
      panel.setResource(resource);

      // show edit panel
      panel.show();
    },

    /**
     * Handler for the sell button.
     */
    sellHandler(material) {
      this.selectedMaterial = material;
      this.sellFormVisible = true;

      // reset quantity and type
      this.sellForm = {
        quantity: 1,
        type: "new",
      };
    },

    performSellHandler() {
      // enable loading
      this.sellForm.loading = true;

      // check if there enouth quantity to precced with the sell
      if (
        this.sellForm.type === "new" &&
        this.selectedMaterial.newStock - this.sellForm.quantity < 0
      ) {
        // open the request
        this.$confirm(
          "Pretende criar um pedido de material?",
          "Não existe stock",
          {
            confirmButtonText: "Pedir",
            cancelButtonText: "Cancelar",
            type: "warning",
          },
        )
          .then(() => {
            return this.$stellar.action("createMaterialrequest", {
              requests: [
                {
                  type: "stock",
                  materialId: this.selectedMaterial.id,
                  quantity: this.sellForm.quantity,
                },
              ],
            });
          })
          // show success message
          .then(() => {
            this.$message.success("O pedido foi criado.");
          })
          .catch(() => {});

        // close the dialog
        this.sellFormVisible = false;

        return;
      } else if (
        this.sellForm.type === "used" &&
        this.selectedMaterial.usedStock - this.sellForm.quantity < 0
      ) {
        this.$message.error("Não existe stock suficiente!");

        // close the dialog
        this.sellFormVisible = false;

        return;
      }

      // close the dialog
      this.sellFormVisible = false;

      // enable loading
      this.loading = true;

      // make an API call to perform
      this.$stellar
        .action("performMaterialSale", {
          materialId: this.selectedMaterial.id,
          quantity: this.sellForm.quantity,
          type: this.sellForm.type,
        })
        .then(() => {
          // show a success message
          this.$alert(
            `Pode levandar ${this.sellForm.quantity} unidade(s) da caixa ${
              this.selectedMaterial.storeBox
            }.`,
            "Venda Efetuada",
            {
              confirmButtonText: "Feito!",
              type: "success",
            },
          );

          // in case of success update the current page
          this.fetchPage(currentPage);
        });
    },

    /**
     * Shows the daily report panel.
     */
    dailyReportHandler() {
      this.$refs.dailyList.show();
    },

    // removeMaterial: function (item) {
    //   this.$stellar.action('removeMaterial', { id: item.id }, response => {
    //     if (response.error) { return this.$refs.error.show() }
    //   })
    //   this.materials.pop(item)
    // },
  },
};
</script>

<style lang="scss">
.tp {
  &-materialListReseller {
    /** actions */
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: inherit;
      margin-top: 10px;

      /* place element side by side */
      & > * {
        display: inline-block;
      }
    }

    /** search bar ************************************************************/
    &__search {
      max-width: 300px;
      margin: 5px 0 15px 0;
    }

    &__content {
      max-width: 98%;
      margin: 0 auto;
    }

    &__addButton {
      margin-bottom: 10px !important;
    }

    &__reportButton {
      margin-bottom: 10px !important;
    }

    &__pagination {
      padding-left: 0 !important;
      margin: 8px 0;
    }
  }
}
</style>
