<template>
  <div class="ui-Pattern"/>
</template>

<script>
import PatternLock from "patternlock";

export default {
  props: {
    value: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      lock: null,
    };
  },

  mounted() {
    // load pattern lock
    this.lock = new PatternLock(".ui-Pattern", {
      enableSetPattern: true,
      onDraw: pattern => this.$emit("input", pattern),
    });

    // define pattern
    this.lock.setPattern(this.value);

    // disabled if needed
    if (this.disabled) {
      this.lock.disable();
    }
  },
};
</script>

<style lang="scss">
.patt-wrap {
  margin: auto;
  overflow: hidden;
}
.patt-wrap li {
  transition: all 0.4s ease-in-out 0s;
}
.patt-dots,
.patt-lines {
  transition: background 0.1s ease-in-out 0s;
}
.patt-circ {
  border: 3px solid transparent;
}
.patt-dots {
  background: rgba(255, 255, 255, 0.8);
}
.patt-lines {
  background: rgba(255, 255, 255, 0.4);
}
.patt-circ.hovered {
  border-color: #ddd;
  background: rgba(255, 255, 255, 0.2);
}
.patt-circ:nth-child(1),
.patt-circ:nth-child(2),
.patt-circ:nth-child(3) {
  animation: fadeInUp 0.4s;
}
.patt-circ:nth-child(4),
.patt-circ:nth-child(5),
.patt-circ:nth-child(6) {
  animation: fadeInUp 0.6s;
}
.patt-circ:nth-child(7),
.patt-circ:nth-child(8),
.patt-circ:nth-child(9) {
  animation: fadeInUp 0.8s;
}

/** direction **/
.patt-wrap {
  z-index: 10;
}
.patt-circ.hovered {
  background-color: #cde2f2;
  border: none;
}
.patt-circ.hovered .patt-dots {
  display: none;
}
.patt-circ.dir {
  background-image: url("./arrow.png");
  background-position: center;
  background-repeat: no-repeat;
}
.patt-circ.e {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.patt-circ.s-e {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.patt-circ.s {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.patt-circ.s-w {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.patt-circ.w {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.patt-circ.n-w {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.patt-circ.n {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.patt-circ.n-e {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.ui {
  &-Pattern {
    margin: auto !important;
    background-color: #5c7080 !important;

    .patt-circ.hovered {
      border-color: #ffffff !important;
    }
  }
}
</style>
