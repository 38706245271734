<template>
  <section class="tp-DamageMatList">
    <!-- reason -->
    <el-dialog
      :visible.sync="dialogVisibility"
      title="Avaliação da Peça"
      size="tiny">
      <el-form
        v-if="selectedEntry"
        label-position="top">
        <el-form-item label="Responsável">
          <el-select
            v-model="responsable"
            placeholder="Responsável">
            <el-option
              v-for="item in options"
              :key="item.responsable"
              :label="item.label"
              :value="item.responsable"/>
          </el-select>
        </el-form-item>

        <el-button
          type="primary"
          @click="confirmReason">
          Avaliar
        </el-button>

        <!-- cancel -->
        <el-button
          :plain="true"
          @click="dialogVisibility = false">
          Cancelar
        </el-button>
      </el-form>
    </el-dialog>

    <el-tabs type="border-card">
      <el-tab-pane label="Material defeituoso">
        <DamagedMaterialTable
          :data="damagedData"
          :loading="loading"
          type="damaged"
          @evalute="onEvaluteEvent" />
      </el-tab-pane>

      <el-tab-pane label="Material Partido">
        <DamagedMaterialTable
          :data="brokenData"
          :loading="loading"
          type="broken"
          @evalute="onEvaluteEvent" />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import DamagedMaterialTable from "./damaged-material-table.vue";

export default {
  components: {
    DamagedMaterialTable,
  },

  data() {
    return {
      loading: false,
      damagedData: [],
      brokenData: [],

      selectedEntry: null,
      responsable: "",

      options: [
        {
          responsable: "technician",
          label: "Tecnico",
        },
        {
          responsable: "manufacturing_defect",
          label: "Fornecedor",
        },
      ],

      dialogVisibility: false,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$stellar
        .action("getDamagedmaterialToList", { list: "damaged" })
        .then(({ damagedmaterials }) => {
          this.damagedData = damagedmaterials;
        });

      this.$stellar
        .action("getBrokenmaterials", { onlyNotEvaluated: true })
        .then(({ brokenmaterials }) => {
          this.brokenData = brokenmaterials;
        });
    },

    async confirmReason() {
      this.loading = true;
      this.dialogVisibility = false;

      try {
        await this.$stellar.action("editDamagedmaterial", {
          id: this.selectedEntry.id,
          responsable: this.selectedEntry.responsable,
        });
      } catch (_) {
        this.dialogVisibility = true;
      } finally {
        this.loading = false;
      }

      this.$message.success("Avaliado");
      this.responsable = "";

      this.fetchData();
    },

    /**
     * Show the dialog to evalute the broken material.
     */
    evaluateHandler(entry) {
      this.dialogVisibility = true;
      this.selectedEntry = entry;
    },

    /**
     * Handle evaluation request for broken material.
     */
    async brokenEvaluateHandler(entry) {
      this.loading = true;
      this.selectedEntry = entry;

      await this.$stellar.action("editBrokenmaterial", {
        id: this.selectedEntry.id,
        evaluated: true,
      });

      this.loading = false;
      this.$message.success("Avaliado");
      this.fetchData();
    },

    onEvaluteEvent(type, entry) {
      if (type === "broken") {
        return this.brokenEvaluateHandler(entry);
      }

      this.evaluateHandler(entry);
    },
  },
};
</script>


<style lang="scss">
.tp {
  &-DamagedMaterial {
    &__table {
      width: 98%;
      margin: 10px auto;
    }
  }
}
</style>
