<template>
  <div
    v-if="requests.length"
    class="tp-RepairView__entry is-materialRequests">
    <h2 class="title">Material Pedido</h2>

    <div class="content">
      <el-table :data="requests">
        <el-table-column
          :width="70"
          prop="quantity"
          label="Qtn."/>

        <el-table-column
          :with="250"
          label="Descrição">
          <template slot-scope="scope">
            <span>{{ scope.row.material | formatMaterialName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Estado">
          <template slot-scope="scope">
            <span>{{ scope.row.state | formatRequestState }}</span>
          </template>
        </el-table-column>

        <!-- request date -->
        <el-table-column
          label="Data Pedido"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.createdAt | dateFormat("dd 'de' MMMM 'de' yyyy")
            }}</span>
          </template>
        </el-table-column>

        <!-- pruchase date -->
        <el-table-column
          label="Data Compra"
          align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.purchase">{{ scope.row.purchase.createdAt | dateFormat("dd 'de' MMMM 'de' yyyy")
              }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Previsão"
          align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.purchase">{{ estimateDelivery(scope.row.purchase)
              }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { addDays, addWeeks, format } from "date-fns/esm/fp";
import { DATE_DAY_FROM_MONTH } from "@/services/formatters";

// time margins for each origin
const dateMarginForEurope = addDays(4);
const dateMarginWordStart = addWeeks(2);
const dateMarginWordEnd = addWeeks(6);

export default {
  name: "MaterialRequests",

  props: {
    repair: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      requests: [],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      // get material requests for a given repair
      const { requests } = await this.$stellar.action("materialRequestsForRepair", {
        repair: this.repair,
      });

      // save the requests
      this.requests = requests;
    },
    /**
     * Format the estimated delivery date interval.
     */
    estimateDelivery(purchase) {
      let startDate = null;
      let endDate = null;

      if (purchase.origin === "europe") {
        startDate = new Date(purchase.createdAt);
        endDate = dateMarginForEurope(new Date(purchase.createdAt));
      } else {
        startDate = dateMarginWordStart(new Date(purchase.createdAt));
        endDate = dateMarginWordEnd(new Date(purchase.createdAt));
      }

      // format date
      const dateFormatter = format(DATE_DAY_FROM_MONTH);
      startDate = dateFormatter(startDate);
      endDate = dateFormatter(endDate);

      return `(${startDate} - ${endDate})`;
    },
  },
};
</script>

<style lang="scss">
.tp-RepairView__entry.is-materialRequests {
  max-width: 97%;
  margin: 20px auto;
  padding: 25px 40px;
}
</style>
