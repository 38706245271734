<template>
  <section class="tp-RepairForm__step1">
    <el-card class="tp-RepairForm__step1__card">
      <el-row :gutter="10">
        <el-col :span="20">
          <span class="tp-RepairForm__step1__description">Numero de Serie ou IMEI</span>

          <el-input
            v-model="identifier"
            type="text"
            placeholder="Numero de Serie/IMEI"
            @keyup.enter.native="checkEquipment"/>
        </el-col>

        <el-col :span="4">
          <el-button
            :loading="loading"
            class="tp-RepairForm__step1__button"
            type="primary"
            @click="checkEquipment">
            <span v-if="identifier === ''">Continuar</span>
            <span v-if="identifier !== ''">Usar</span>
          </el-button>
        </el-col>
      </el-row>
    </el-card>
  </section>
</template>

<script>
export default {
  props: {
    repair: {
      type: Object,
      default: () => {},
    },
    equipment: {
      type: Object,
      default: () => {},
    },
    client: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      identifier: "",
      loading: false,
    };
  },

  methods: {
    reset() {
      this.loading = false;
      this.identifier = "";
    },

    async checkEquipment() {
      if (this.identifier === "") {
        return this.$emit("goStep", 1);
      }

      // enable loading
      this.loading = true;

      // try find the equipment by serial number or IMEI
      const { match } = await this.$stellar.action(
        "findEquipmentBySerialNumber",
        {
          value: this.identifier,
        },
      );

      if (match === null) {
        this.$message.info("Equipamento não encontrado.");

        // set identifier
        this.$emit("setIdentifier", this.identifier);

        // reset component
        this.reset();

        return this.$emit("goStep", 1);
      }

      // check if the equipment exists
      const { exists, latestRepair } = await this.$stellar.action(
        "existsOpenRepair",
        { clientEquipment: match._id },
      );

      // inform the user if there is an open repair for the equipment
      if (exists) {
        // reset component
        this.reset();

        // reset prev repair
        this.$emit("latestRepair", null);

        return this.$alert(
          "Existe uma reparação aberta para este equipamento.",
          "Impossivel criar reparação!",
          {
            confirmButtonText: "OK",
          },
        );
      }

      // emit the latest repair
      this.$emit("latestRepair", latestRepair);

      // create a private repair copy
      const _repair = this.repair;

      // set the clientEquipment
      _repair.clientEquipment = match._id;

      // set selectedEquipmentType
      this.$emit("setEquipmentType", match.equipmentType);

      // store the equipment to use later
      this.$emit("update:equipment", match);

      // get client information
      const { clientequipment } = await this.$stellar.action(
        "getClientequipment",
        {
          id: match._id,
        },
      );

      // set phone number
      _repair.phone = clientequipment.client.phone;

      // set client
      this.$emit("update:client", clientequipment.client);

      // ask to update the repair
      this.$emit("update:repair", _repair);

      // go to the client step
      this.$emit("goStep", 1);

      // reset component
      this.reset();
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-RepairForm {
    &__step1 {
      margin-top: 70px;

      &__card {
        max-width: 600px;
        margin: 0 auto;
        padding-right: 15px;
      }

      &__description {
        display: block;
        font-size: 0.9em;
        margin-bottom: 10px !important;
      }

      &__button {
        margin-top: 31px !important;
      }
    }
  }
}
</style>
