
<header class="navbar ui-header_navbar">
  <div class="ui-header_container container-fluid">
    <div class="ui-header_content">
      <!-- notifications popover -->
      <el-popover
        ref="notificationPopover"
        v-model="notificationsVisible"
        placement="bottom"
        width="350"
        popper-class="ui-notifications_container">
        <notifications @click.native="$refs.notificationIcon.click()"/>
      </el-popover>

      <!-- right content -->
      <div class="ui-header_right">
        <ul class="ui-header_nav">
          <!-- notifications -->
          <li class="notifications">
            <span
              ref="notificationIcon"
              v-popover:notificationPopover
              :class="{ with: notificationUnreadNumber }">
              {{ notificationUnreadNumber }}
            </span>
          </li>

          <!-- user -->
          <li class="userSection">
            <el-dropdown>
              <span class="el-dropdown-link">
                <!-- avatar image -->
                <img
                  :src="avatar"
                  class="userAvatar"
                  alt="Avatar" >

                <!-- first name -->
                <span class="userFirstName">Olá, {{ firstName }}</span>

                <!-- arrow -->
                <i class="el-icon-caret-bottom el-icon-right"/>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="logout">Terminar Sessão</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>

      <!-- title -->
      <h1 class="title">{{ title }}</h1>
    </div>
  </div>
</header>
