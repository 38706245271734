
<div class="tp-RepairView__entry is-history">
  <h4>Historico</h4>

  <el-table
    :data="orderedLogs"
    stripe
    class="tp-RepairView__tableHistory">
    <el-table-column label="Evento">
      <template slot-scope="{ row }">
        <b>{{ row.type | repairLogType }}</b>
        <br>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <i v-html="row.message"/>
      </template>
    </el-table-column>

    <!-- date -->
    <el-table-column
      :width="150"
      label="Data">
      <template slot-scope="scope">
        {{ scope.row.date | dateFormat('dd/MM/yyyy HH:mm') }}
      </template>
    </el-table-column>

    <!-- hour -->
    <el-table-column
      :width="130"
      label="Hora">
      <template slot-scope="scope">
        {{ scope.row.date | dateFormat('HH:mm') }}
      </template>
    </el-table-column>

    <!-- user -->
    <el-table-column
      :width="170"
      label="Por">
      <template slot-scope="{row}">
        <span v-if="row.context.user && users[row.context.user]">{{ users[row.context.user].name }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
  </el-table>
</div>
