
<el-dialog
  v-loading.fullscreen="loading"
  :visible.sync="visibility"
  class="tp-prev-repair"
  title="Reparação prévia"
  size="large">
  <template v-if="previousRepair">
    <div class="tp-prev-repair__head">
      <div class="tp-prev-repair__field repairNumber">
        #{{ previousRepair.repair.repairNumber }}
      </div>
      <div class="tp-prev-repair__field technician">
        <span class="tp-prev-repair__field-title">Tecnico</span>
        <span class="tp-prev-repair__field-value">{{ previousRepair.repair.technician.name }}</span>
      </div>
      <div class="tp-prev-repair__field quotVal">
        <span class="tp-prev-repair__field-title">Valor pago: </span>
        <span class="tp-prev-repair__field-value">{{ previousRepair.repair.value || previousRepair.repair.quotation }}€</span>
      </div>
      <div class="tp-prev-repair__field state">
        <span class="tp-prev-repair__field-title">Reparação:</span>
        <span
          v-if="previousRepair.repair.fixed === true"
          class="tp-prev-repair__field-value">
          Com êxito
        </span>
        <span v-else>Não efectuada</span>
      </div>
      <div class="tp-prev-repair__field warranty">
        <span class="tp-prev-repair__field-title">Garantia</span>
        <span
          v-if="previousRepair.repair.warranty"
          class="tp-prev-repair__field-title">
          SIM
        </span>
        <span
          v-else
          class="tp-prev-repair__field-value">
          NÃO
        </span>
      </div>
      <div class="tp-prev-repair__field complaint">
        <span class="tp-prev-repair__field-title">Reclamação</span>
        <span
          v-if="previousRepair.repair.complaint"
          class="tp-prev-repair__field-value">
          SIM
        </span>
        <span
          v-else
          class="tp-prev-repair__field-value">
          NÃO
        </span>
      </div>
    </div>

    <div class="tp-prev-repair__field equipment">
      <span class="tp-prev-repair__field-title">Equipamento:</span>
      <span class="tp-prev-repair__field-value">{{ previousRepair.repair.clentequipment }}</span>
    </div>
    <div class="tp-prev-repair__field problem">
      <span class="tp-prev-repair__field-title">Problema:</span>
      <span class="tp-prev-repair__field-title">{{ previousRepair.repair.problemName }}</span>
    </div>
    <div class="tp-prev-repair__field stateObs">
      <span class="tp-prev-repair__field-title">Estado do dispositivo:</span>
      <span class="tp-prev-repair__field-value">{{ previousRepair.repair.stateObservation }}</span>
    </div>
    <div class="tp-prev-repair__field problemDescription">
      <span class="tp-prev-repair__field-title">Descrição do problema:</span>
      <span class="tp-prev-repair__field-value">{{ previousRepair.repair.problemDescription }}</span>
    </div>
    <div class="tp-prev-repair__field observation">
      <span class="tp-prev-repair__field-title">Observação:</span>
      <span class="tp-prev-repair__field-value">{{ previousRepair.repair.repairObservation }}</span>
    </div>
    <div class="tp-prev-repair__field internalObs">
      <span class="tp-prev-repair__field-title">Observação interna:</span>
      <span class="tp-prev-repair__field-value">{{ previousRepair.repair.internalObservation }}</span>
    </div>

    <div
      v-if="previousRepair.repair.materials.length > 0"
      class="tp-prev-repair__field material">
      <span>Lista de materiais utilizados</span>
      <el-table
        v-loading="loading"
        :data="previousRepair.repair.materials"
        stripe>
        <el-table-column
          :width="70"
          property="quantity"
          label="Qtn." />

        <el-table-column label="Descrição">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <!-- show price without taxas -->
        <el-table-column
          :width="100"
          label="Preço"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.price | money }}
          </template>
        </el-table-column>

        <!-- show price with taxes -->
        <el-table-column
          :width="150"
          label="Preço C/ IVA"
          align="center">
          <template slot-scope="scope">
            {{ (scope.row.price * 1.23) | money }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-else
      class="no-items">
      <p>Não foi usado material!</p>
    </div>
  </template>
  <template v-else>
    <span>Sem Reparação prévia</span>
  </template>

  <el-button
    type="success"
    @click="$emit('update:visibility', false)">
    Sair
  </el-button>
</el-dialog>
