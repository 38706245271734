
<div class="tp-allCommissions_wrap">
  <!-- actions -->
  <div
    slot="actions"
    class="right">
    <el-button @click="show(false)">Cancelar</el-button>
    <el-button @click="printElem">Imprimir</el-button>
  </div>

  <div id="toPrint">
    <el-table
      :data="commissions"
      stripe
      style="width: 100%">
      <el-table-column
        prop="_id"
        label="Client"
        width="180"/>
      <el-table-column
        prop="repairs.length"
        label="Client"
        width="180"/>
      <el-table-column
        prop="value"
        label="Orçamento"
        width="180"/>
      <el-table-column
        prop="totalMaterial"
        label="Material"
        width="180"/>
    </el-table>
  </div>
</div>
