/**
 * Gives the environment here this APP is running.
 */
export const getEnv = (): string => import.meta.env.MODE as string;

/**
 * Check if it's running in development mode.
 */
export const isDevelopment = (): boolean => getEnv() === "development";

/**
 * Gives the API url.
 */
export const baseUrl = (): string => import.meta.env.API_URL as string;

/**
 * Returns the list of active modules.
 */
export const activeModules = (): Array<string> => {
  const listOfModules = import.meta.env.VITE_ACTIVE_MODULES as string;
  return listOfModules.split(",");
};
