
<section class="tp-materialList_wrap">
  <!-- edit panel -------------------------------------------------------- -->
  <edit-form
    ref="editForm"
    @updated="reloadCurrentPage"
    @created="reloadCurrentPage"/>

  <add-mat
    ref="addMat"
    @updated="reloadCurrentPage"
    @created="reloadCurrentPage"/>

  <!-- List of material used on a day ------------------------------------ -->
  <report-list ref="dailyList"/>

  <!-- sell dialog ------------------------------------------------------- -->
  <el-dialog
    :visible.sync="sellFormVisible"
    title="Venda de Material"
    size="tiny">
    <!-- form -->
    <el-form
      :model="sellForm"
      label-position="top">
      <el-form-item label="Quantidade">
        <el-input-number
          v-model="sellForm.quantity"
          :style="{ width: '100%' }"
          :min="1"/>
      </el-form-item>

      <el-form-item label="Retirar do material">
        <el-select
          v-model="sellForm.type"
          :style="{ width: '100%' }"
          placeholder="Please select a zone">
          <el-option
            label="Novo"
            value="new"/>
          <el-option
            label="Usado"
            value="used"/>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- footer -->
    <span
      slot="footer"
      class="dialog-footer">
      <el-button @click="sellFormVisible = false">Cancelar</el-button>
      <el-button
        type="primary"
        @click="performSellHandler">
        Vender
      </el-button>
    </span>
  </el-dialog>

  <!-- list -------------------------------------------------------------- -->
  <div class="tp-MaterialList__content">
    <!-- action -->
    <div class="tp-MaterialList__actions">
      <div class="tp-MaterialList__actions__right">
        <!-- add button -->
        <el-button
          class="tp-MaterialList__addButton"
          size="medium"
          type="success"
          @click="add">
          Adicionar
        </el-button>
      </div>

      <div class="left-menu">
        <el-dropdown>
          <el-button icon="el-icon-more" type="info"/>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="dailyReportHandler">Relatorio Diario</el-dropdown-item>
            <el-dropdown-item v-if="user.role.slug === 'admin'" @click.native="printStock">Existências</el-dropdown-item>
            <el-dropdown-item @click.native="onClickMaterialGroupHandler">Grupos de Materiais</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- search -->
        <el-input
          v-model="searchQuery"
          class="tp-MaterialList__search"
          placeholder="Pesquise aqui por materiais..."/>
      </div>
    </div>

    <el-table
      v-loading.body="loading"
      :data="orderedData"
      stripe>
      <el-table-column
        :width="100"
        label="Cod.">
        <template slot-scope="scope">
          {{ scope.row.identifier }}
        </template>
      </el-table-column>

      <el-table-column label="Descrição">
        <template slot-scope="scope">
          {{ scope.row.description }} , {{ scope.row.brand }} {{ scope.row.model }}
        </template>
      </el-table-column>

      <el-table-column
        :width="80"
        property="storeBox"
        label="Caixa"/>

      <el-table-column
        :width="90"
        label="Preço"
        align="center">
        <template slot-scope="{ row }">
          <span>{{ row.price | money }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :width="150"
        label="Preço c/ IVA"
        align="center">
        <template slot-scope="{row}">
          <span>{{ row.price * 1.23 | money }}</span>
        </template>
      </el-table-column>

      <!-- stock (new and used) -->
      <el-table-column
        :width="70"
        label="Stock"
        align="center">
        <template slot-scope="scope">
          <el-tag
            :type="getTagColor(scope.row)"
            size="medium">
            {{ scope.row.newStock }}/{{ scope.row.usedStock }}
          </el-tag>
        </template>
      </el-table-column>

      <!-- purchase -->
      <el-table-column
        :width="100"
        label="Aguarda"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.requestedQuantity }}
        </template>
      </el-table-column>

      <el-table-column
        :width="270"
        label="">
        <template slot-scope="scope">
          <!-- edit button -->
          <el-button
            type="info"
            size="mini"
            @click="edit(scope.row)">
            Editar
          </el-button>

          <!-- sell button -->
          <el-button
            type="warning"
            size="mini"
            @click="sellHandler(scope.row)">
            Vender
          </el-button>

          <!-- request button -->
          <el-button
            type="success"
            size="mini"
            @click="requestMaterial(scope.row)">
            Requisitar
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- pagination ------------------------------------------------------ -->

    <el-pagination
      :current-page="currentPage"
      :total="total"
      :page-size="20"
      class="tp-MaterialList__pagination"
      layout="prev, pager, next"
      @current-change="currentPageChange"/>
  </div>

  <div
    v-show="false"
    id="stockToPrint">
    <table>
      <thead>
        <th>Cod.</th>
        <th>Descrição</th>
        <th>Caixa</th>
        <th>Stock</th>
      </thead>

      <tbody>
        <tr
          v-for="material in materialsToPrint"
          :key="material.identifier">
          <td style="width: 110px">{{ material.identifier }}</td>
          <td>{{ material.name }}</td>
          <td>{{ material.box }}</td>
          <td>{{ material.newStock }}/{{ material.usedStock }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
