import Vue from "vue";

const formatter = new Intl.NumberFormat("pt-PT", {
  style: "currency",
  currency: "EUR",
});

export const moneyFormatter = value => {
  return formatter.format(value);
};

// install the filter on the Vue
Vue.filter("money", moneyFormatter);

// export the formatter function
export default moneyFormatter;
