
<div class="tp-DeliveryList">
  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler" />

  <!-- client dialog -->
  <el-dialog
    :visible.sync="seeClientDialog"
    title="Informação do cliente"
    size="small">
    <client-card :client="client"/>
  </el-dialog>

  <!-- list -------------------------------------------------------------- -->
  <div class="tp-RepairsList__tableContent">
    <el-table
      v-loading="loading"
      :data="problemRepairs"
      class="tp-RepairsList__table"
      stripe
      style="width: 100%">
      <!-- repair number -->
      <el-table-column
        :width="85"
        label="#">
        <template slot-scope="scope">
          <div class="tp-repairList_quotation">
            <span class="tp-RepairsList-approved">
              <el-tooltip
                v-if="scope.row.quotationApproved === true"
                effect="dark"
                content="Orçamento aprovado"
                placement="top">
                <div class="tp-RepairsList-approved__circle"/>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.quotationApproved === false"
                effect="dark"
                content="Orçamento recusado"
                placement="top">
                <div class="tp-RepairsList-rejected__circle"/>
              </el-tooltip>
              {{ scope.row.repairNumber }}
            </span>
          </div>
        </template>

      </el-table-column>

      <!-- store number -->
      <el-table-column
        :width="100"
        label="Ficha Loja"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.storeRepairIdentifier || '-' }}
        </template>
      </el-table-column>

      <!-- show the box where the equipment is stored -->
      <el-table-column
        :width="80"
        align="center"
        label="Caixa">
        <template slot-scope="scope">
          {{ scope.row.storeBox || '-' }}
        </template>
      </el-table-column>

      <!-- equipment brand and model -->
      <el-table-column
        :width="150"
        label="Equipamento">
        <template slot-scope="scope">
          <span>{{ scope.row.clientEquipment.brand
          }} {{ scope.row.clientEquipment.model }}</span>
        </template>
      </el-table-column>

      <!-- client -->
      <el-table-column
        :width="200"
        label="Cliente">
        <template slot-scope="scope">
          <a @click="seeClient(scope.row.client)">
            <span
              v-if="scope.row.client.isStore">{{ scope.row.client.name | truncate(20)
              }}</span>
            <span v-else>{{ scope.row.client.name }}</span>
          </a>
        </template>
      </el-table-column>

      <!-- problem -->
      <el-table-column
        :width="350"
        label="Avaria"
        align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.problemName === 'Outro'">
            <span>{{ scope.row.problemDescription }}</span>
          </div>
          <div v-else>
            <span>{{ scope.row.problemName }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :width="100"
        label="Entrada"
        align="center">
        <template slot-scope="scope">
          {{ diffedDuration(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <!-- operations -->
      <el-table-column
        :width="190"
        :context="_self"
        label="Operações">
        <template slot-scope="scope">
          <!-- see repair details -->
          <el-button
            size="small"
            type="info"
            @click="showRepairDetails(scope.row)">Ver
          </el-button>

          <!-- change technician -->
          <el-button
            :loading="loading"
            :disabled="!canChangeTechnician(scope.row)"
            size="small"
            type="warning"
            @click="changeTechnician(scope.row)">Alterar Tecnico
          </el-button>
        </template>
      </el-table-column>

      <!-- technician -->
      <el-table-column
        :width="150"
        label="Tecnico">
        <template slot-scope="scope">
          <span v-if="scope.row.technician">{{ scope.row.technician.name }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- pagination ------------------------------------------------------ -->
    <el-pagination
      :current-page="currentPage"
      :page-size="20"
      :total="total"
      class="tp-RepairsList__pagination"
      layout="prev, pager, next"
      @current-change="currentPageChange"/>
  </div>
</div>
