
<section class="tp-materialRequestList_wrap">
  <el-dialog
    ref="dailyRepairList"
    :visible.sync="requestVisibility"
    title="Pedido"
    size="large">
    <div class="right">
      <el-button @click="requestVisibility=false">Cancelar</el-button>
      <el-button @click.native="printElem">Imprimir</el-button>
    </div>

    <div id="toPrint">
      <el-table
        :data="clientRequests"
        stripe
        style="width: 100%">
        <el-table-column
          :width="100"
          label="#Pedido"
          align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.requestNumber">{{ scope.row.requestNumber }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="client.name"
          label="Cliente"
          width="200" />
        <el-table-column
          prop="material.description"
          label="Material"
          width="200" />
        <el-table-column
          prop="quantity"
          label="Qnt."
          width="80" />
        <el-table-column
          prop="price"
          label="Preço"
          width="80" />
      </el-table>
    </div>
  </el-dialog>

  <!-- purchase form ----------------------------------------------------- -->
  <purchase-form
    ref="purchases"
    @created="afterPurchaseCreationHandler"/>

  <!-- request panel ----------------------------------------------------- -->
  <request-form
    ref="materialRequests"
    @created="afterPurchaseCreationHandler"/>

  <!-- client dialog ----------------------------------------------------- -->
  <el-dialog
    :visible.sync="seeClientDialog"
    title="Informação do cliente"
    size="small">
    <client-card :client="client"/>
  </el-dialog>

  <!-- requests list ----------------------------------------------------- -->
  <div class="tp-MaterialRequestList__content">
    <!-- create a request -->
    <el-button
      custom-class="test"
      class="tp-MaterialRequestList__newBtn"
      type="success"
      @click="createRequestHandler">
      Criar
    </el-button>

    <!-- search bar  -->
    <el-input
      v-if="activeTab !== 'allRequestsTab'"
      v-model="searchQuery"
      class="tp-MaterialRequestList__reqSearch"
      placeholder="pedido..."/>
  </div>
  <div class="page page-requestList">
    <el-tabs v-model="activeTab">
      <el-tab-pane
        label="Pedidos"
        name="allRequestsTab">
        <AllRequestsList
          :data="allRequests"
          placeholder="pedido..."/>
      </el-tab-pane>
      <el-tab-pane
        label="Pedidos cliente"
        name="clientRequestsTab">
        <ClientRequestsList
          v-if="activeTab === 'clientRequestsTab'"
          :data="clientRequests"
          :loading="loading"
          destination-label="Cliente"
          class="page-section"
          @process-request="processRequestHandler"
          @remove-request="removeRequest"
          @deliver-material="deliverMaterialhandler"
          @put-material-on-stock="putMaterialOnStockHandler"/>
      </el-tab-pane>
      <el-tab-pane
        label="Pedidos stock"
        name="stockRequestsTab">
        <StockRequestsList
          v-if="activeTab === 'stockRequestsTab'"
          :data="stockRequest"
          :loading="loading"
          destination-label="Cliente"
          class="page-section"
          @process-request="processRequestHandler"
          @remove-request="removeRequest"
          @deliver-material="deliverMaterialhandler"
          @put-material-on-stock="putMaterialOnStockHandler"/>
      </el-tab-pane>
      <el-tab-pane
        label="Pedidos Reparação"
        name="repairRequestsTab">
        <RepairRequestsList
          v-if="activeTab === 'repairRequestsTab'"
          :data="repairRequests"
          :loading="loading"
          destination-label="Cliente"
          class="page-section"
          @process-request="processRequestHandler"
          @remove-request="removeRequest"
          @deliver-material="deliverMaterialhandler"
          @put-material-on-stock="putMaterialOnStockHandler"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</section>
