
<!-- eslint-disable vue/no-v-html -->
<section class="tp-ContactTable">
  <!-- client dialog -->
  <el-dialog
    :visible.sync="seeClientDialog"
    title="Informação do cliente"
    size="small">
    <client-card :client="client"/>
  </el-dialog>

  <!-- approve quotation dialog -------------------------------------------- -->
  <el-dialog
    :visible.sync="approveQuotationData.visibility"
    title="Aprovar orçamento"
    size="tiny">
    <el-form label-position="top">
      <!-- value -->
      <el-form-item label="Valor">
        <el-input v-model="approveQuotationData.value">
          <template slot="append">€</template>
        </el-input>
      </el-form-item>
    </el-form>

    <!-- actions -->
    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="approveQuotationData.visibility = false">
        Cancelar
      </el-button>
      <el-button
        type="primary"
        @click="approveQuotationBtnHandler">
        Aprovar
      </el-button>
    </div>
  </el-dialog>

  <!-- table --------------------------------------------------------------- -->
  <el-table
    v-loading.body="loading"
    :data="data"
    stripe>
    <el-table-column
      :width="85"
      label="#">
      <template slot-scope="scope">
        <span v-if="scope.row.repair">{{ scope.row.repair.repairNumber }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column
      :width="70"
      label="Loja">
      <template slot-scope="scope">
        <span v-if="scope.row.repair && scope.row.client.isStore">{{ scope.row.repair.storeRepairIdentifier }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- client name -->
    <el-table-column label="Cliente">
      <template slot-scope="scope">
        <a
          v-if="scope.row.client"
          @click="seeClient(scope.row.client)">
          {{ scope.row.client.name }}
        </a>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column
      :width="130"
      label="Contato">
      <template slot-scope="scope">
        <span v-if="scope.row.client">{{ scope.row.client.phone }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column
      :width="170"
      label="Equipamento">
      <template slot-scope="scope">
        <span v-if="scope.row.repair">{{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- this must show the message field from the contact request. By when is a
  quotation should show the log entry -->
    <el-table-column label="Mensagem">
      <template slot-scope="scope">
        <span
          v-if="scope.row.isQuotation"
          v-html="showQuotationMessage(scope.row)"/>
        <span v-else>{{ scope.row.message }}</span>
      </template>
    </el-table-column>

    <!-- actions -->
    <el-table-column
      :width="165"
      label="">
      <template slot-scope="scope">
        <!-- button to see the repair -->
        <el-button
          size="small"
          type="info"
          @click="showRepairHandler(scope.row)">Ver</el-button>

        <!-- actions that can be performed on the contact -->
        <el-dropdown>
          <el-button
            type="primary"
            size="small">
            Ações<i class="el-icon-caret-bottom el-icon--right"/>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="!scope.row.missedCall"
              @click.native="markAsNotAnswered(scope.row)">Não Atendeu</el-dropdown-item>
            <el-dropdown-item
              v-if="!scope.row.waitingAnswer"
              @click.native="markAsWaitingResponse(scope.row)">Aguarda resposta</el-dropdown-item>
            <el-dropdown-item
              v-if="!scope.row.isQuotation"
              @click.native="markAsProcessed(scope.row)">Resposta</el-dropdown-item>

            <!-- quotation -->
            <el-dropdown-item
              v-if="scope.row.isQuotation && !isToSetRepairValue(scope.row)"
              @click.native="approveQuotationHandler(scope.row)">Aprovar</el-dropdown-item>
            <el-dropdown-item
              v-if="scope.row.isQuotation && !isToSetRepairValue(scope.row)"
              @click.native="rejectQuotationHandler(scope.row)">Recusar</el-dropdown-item>

            <!-- set the repair value (for external repairs) -->
            <el-dropdown-item
              v-if="isToSetRepairValue(scope.row)"
              @click.native="setRepairValueHandler(scope.row)">Definir Valor</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</section>
