<template>
  <section class="tp-History">
    <el-table
      v-loading="loading"
      :data="repairs"
      stripe
      class="tp-History__table">
      <!-- store repair identifier -->
      <el-table-column
        :width="75"
        label="#Loja">
        <template slot-scope="scope">
          <span
            v-if="scope.row.storeRepairIdentifier"> {{ scope.row.storeRepairIdentifier }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- repair number -->
      <el-table-column
        :width="90"
        label="#Rep">
        <template slot-scope="scope">
          <span v-if="scope.row.repairNumber"> {{ scope.row.repairNumber
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- Date -->
      <el-table-column
        :width="115"
        label="Entrada">
        <template slot-scope="scope">
          <span
            v-if="scope.row.updatedAt">
            {{ scope.row.updatedAt | dateFormat('yyyy/MM/dd') }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- equipment -->
      <el-table-column
        :width="160"
        label="Equipamento">
        <template slot-scope="scope">
          <span
            v-if="scope.row.clientEquipment">{{ scope.row.clientEquipment.brand
            }} {{ scope.row.clientEquipment.model }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- IMEI -->
      <el-table-column
        :width="160"
        label="IMEI/SN">
        <template slot-scope="scope">
          <span
            v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.attributes.serialNumber)">{{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.attributes.serialNumber
            }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- Problem -->
      <el-table-column label="Problema">
        <template slot-scope="scope">
          <span
            v-if="scope.row.problemDescription">{{ scope.row.problemDescription
            }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- observation message -->
      <el-table-column
        :width="200"
        label="Intervenção">
        <template slot-scope="scope">
          <span
            v-if="scope.row.state==='delivered'">{{ scope.row.repairObservation
            }}</span>
          <span v-else>Orçamento aprovado</span>
        </template>
      </el-table-column>

      <!-- actions -->
      <el-table-column
        :width="180"
        label="Estado">
        <template slot-scope="scope">
          <span v-if="scope.row.fixed === false">Sem reparação</span>
          <span v-else>Reparado</span>
        </template>
      </el-table-column>

      <!-- price -->
      <el-table-column
        :width="200"
        label="Preço">
        <template slot-scope="scope">
          <span v-if="scope.row.fixed === false">
            -
          </span>
          <span v-else>{{ scope.row.value | money }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- pagination ------------------------------------------------------ -->
    <el-pagination
      :current-page="curPage"
      :total="total"
      :page-size="20"
      class="tp-History__pagination"
      layout="prev, pager, next"
      @current-change="fetchPage"/>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      repairs: [],
      total: 0,
      curPage: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    // fetch first page
    this.fetchPage();
  },
  methods: {
    logs(log) {
      const entry = log.filter(item => item.title === "Novo orçamento")[0];

      // when there is no quotation return an hiphen
      if (!entry) {
        return "-";
      }

      const exp = /.*-\s(.*)\s-.*/;

      try {
        return entry.content.match(exp)[1];
      } catch (e) {
        return "-";
      }
    },
    async fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // cal the API to fetch data
      const { total, data } = await this.$stellar.action("paginateRepairs", {
        client: this.user.personId,
        page,
        limit: 20,
        states: ["delivered"],
      });

      // disable loading
      this.loading = false;

      // store data
      this.total = total;
      this.repairs = data;
    },
  },
};
</script>

<style lang="scss">
.tp-History {
  padding: 10px 0;

  &__table {
    max-width: 98%;
    margin: 0 auto;
  }

  &__value {
    font-size: 1.5rem;
  }

  &__pagination {
    margin: 8px 0 8px 9px;
  }
}
</style>
