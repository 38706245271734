
<div class="page page-entities">
  <UiList
    :creation-method="addClientHandler"
    :loading="isLoading"
    :data="entities"
    allow-creation>
    <UiListItem
      v-for="entity in entities"
      :key="entity.id">
      <span>{{ entity.name }}</span>
      <span slot="details">{{ entity.description }}</span>
      <div slot="actions">
        <el-button
          type="primary"
          @click="editHandler(entity)">
          Editar
        </el-button>

        <el-button
          :type="entity.enabled ? 'danger' : 'success'"
          @click="toogleEnableHandler(entity)">
          <template v-if="!entity.enabled">Ativar</template>
          <template v-else>Desativar</template>
        </el-button>
      </div>
    </UiListItem>
  </UiList>

  <el-dialog
    :visible.sync="dialogFormVisible"
    title="Criar Entidade">
    <EntitiesForm
      @close="dialogFormVisible = false" />
  </el-dialog>

  <el-dialog
    :visible.sync="dialogEditFormVisibility"
    title="Editar Entidade">
    <EntitiesForm
      :entity="selectedEntity"
      @close="dialogEditFormVisibility = false" />
  </el-dialog>
</div>
