<template>
  <section class="tp-materialRequestList_wrap">
    <el-dialog
      ref="dailyRepairList"
      :visible.sync="requestVisibility"
      title="Pedido"
      size="large">
      <div class="right">
        <el-button @click="requestVisibility=false">Cancelar</el-button>
        <el-button @click.native="printElem">Imprimir</el-button>
      </div>

      <div id="toPrint">
        <el-table
          :data="clientRequests"
          stripe
          style="width: 100%">
          <el-table-column
            :width="100"
            label="#Pedido"
            align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.requestNumber">{{ scope.row.requestNumber }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="client.name"
            label="Cliente"
            width="200" />
          <el-table-column
            prop="material.description"
            label="Material"
            width="200" />
          <el-table-column
            prop="quantity"
            label="Qnt."
            width="80" />
          <el-table-column
            prop="price"
            label="Preço"
            width="80" />
        </el-table>
      </div>
    </el-dialog>

    <!-- purchase form ----------------------------------------------------- -->
    <purchase-form
      ref="purchases"
      @created="afterPurchaseCreationHandler"/>

    <!-- request panel ----------------------------------------------------- -->
    <request-form
      ref="materialRequests"
      @created="afterPurchaseCreationHandler"/>

    <!-- client dialog ----------------------------------------------------- -->
    <el-dialog
      :visible.sync="seeClientDialog"
      title="Informação do cliente"
      size="small">
      <client-card :client="client"/>
    </el-dialog>

    <!-- requests list ----------------------------------------------------- -->
    <div class="tp-MaterialRequestList__content">
      <!-- create a request -->
      <el-button
        custom-class="test"
        class="tp-MaterialRequestList__newBtn"
        type="success"
        @click="createRequestHandler">
        Criar
      </el-button>

      <!-- search bar  -->
      <el-input
        v-if="activeTab !== 'allRequestsTab'"
        v-model="searchQuery"
        class="tp-MaterialRequestList__reqSearch"
        placeholder="pedido..."/>
    </div>
    <div class="page page-requestList">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          label="Pedidos"
          name="allRequestsTab">
          <AllRequestsList
            :data="allRequests"
            placeholder="pedido..."/>
        </el-tab-pane>
        <el-tab-pane
          label="Pedidos cliente"
          name="clientRequestsTab">
          <ClientRequestsList
            v-if="activeTab === 'clientRequestsTab'"
            :data="clientRequests"
            :loading="loading"
            destination-label="Cliente"
            class="page-section"
            @process-request="processRequestHandler"
            @remove-request="removeRequest"
            @deliver-material="deliverMaterialhandler"
            @put-material-on-stock="putMaterialOnStockHandler"/>
        </el-tab-pane>
        <el-tab-pane
          label="Pedidos stock"
          name="stockRequestsTab">
          <StockRequestsList
            v-if="activeTab === 'stockRequestsTab'"
            :data="stockRequest"
            :loading="loading"
            destination-label="Cliente"
            class="page-section"
            @process-request="processRequestHandler"
            @remove-request="removeRequest"
            @deliver-material="deliverMaterialhandler"
            @put-material-on-stock="putMaterialOnStockHandler"/>
        </el-tab-pane>
        <el-tab-pane
          label="Pedidos Reparação"
          name="repairRequestsTab">
          <RepairRequestsList
            v-if="activeTab === 'repairRequestsTab'"
            :data="repairRequests"
            :loading="loading"
            destination-label="Cliente"
            class="page-section"
            @process-request="processRequestHandler"
            @remove-request="removeRequest"
            @deliver-material="deliverMaterialhandler"
            @put-material-on-stock="putMaterialOnStockHandler"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import { printHTML } from "@/utils/print-html";

import ClientCardCmp from "../client/ClientCard.vue";
import RequestFormCmp from "./MaterialRequest.vue";
import PurchasesFormCmp from "../purchases/PurchasesForm.vue";

import moneyFormatter from "../../filters/money";

import AllRequestsList from "./all-requests-list.vue";
import ClientRequestsList from "./client-requests-list.vue";
import StockRequestsList from "./stock-requests-list.vue";
import RepairRequestsList from "./repair-requests-list.vue";

export default {
  components: {
    "client-card": ClientCardCmp,
    "request-form": RequestFormCmp,
    "purchase-form": PurchasesFormCmp,
    AllRequestsList,
    ClientRequestsList,
    StockRequestsList,
    RepairRequestsList,
  },

  data() {
    return {
      activeTab: "allRequestsTab",
      requestVisibility: false,
      onotherRequestVisibility: false,
      loading: false,
      requests: [],

      seeClientDialog: false,
      client: null,
      clientType: "null",
      clientRequests: [],
      repairRequests: [],
      stockRequest: [],
      searchQuery: "",
      foundRequests: [],
      allRequests: [],
      aux: [],
      clientTemp: [],
      repairTemp: [],
      stockTemp: [],
    };
  },

  watch: {
    searchQuery(newValue) {
      this.searchRequests(newValue);
      // TODO: check if this is necessary
      // this.foundRequests = this.requests.filter(requests => requests.requestNumber === parseInt(newValue))
    },
  },
  created() {
    // fetch the data
    this.fetchData();
  },

  methods: {
    cleanSearch() {
      this.foundRequests = [];
      this.searchQuery = "";
    },

    searchRequests(newValue) {
      this.foundRequests = [];
      if (this.activeTab === "clientRequestsTab") {
        this.aux = this.clientTemp;
      }
      if (this.activeTab === "repairRequestsTab") {
        this.aux = this.repairTemp;
      }
      if (this.activeTab === "stockRequestsTab") {
        this.aux = this.stockTemp;
      }

      // TODO: simplify this loop
      for (const item in this.aux) {
        if (this.aux[item].requestNumber && newValue !== "") {
          if (this.aux[item].requestNumber.toString().includes(newValue)) {
            this.foundRequests.push(this.aux[item]);

            if (this.activeTab === "clientRequestsTab") {
              this.clientRequests = this.foundRequests;
            }

            if (this.activeTab === "repairRequestsTab") {
              this.repairRequests = this.foundRequests;
            }

            if (this.activeTab === "stockRequestsTab") {
              this.stockRequest = this.foundRequests;
            }
          }
        } else {
          this.clientRequests = this.clientTemp;
          this.stockRequest = this.stockTemp;
          this.repairRequests = this.repairTemp;
        }
      }
    },
    seeClient(client) {
      this.client = client;
      this.seeClientDialog = true;
    },

    fetchData() {
      this.loading = true;
      // get requests from database
      this.$stellar
        .action("getMaterialrequestsGroupedByMaterial", {
          state: ["waiting_purchase", "waiting_delivery"],
        })
        .then(({ allRequests }) => {
          this.allRequests = allRequests;
        });

      // get requests from database
      this.$stellar
        .action("getMaterialrequestsGrouped", {
          state: ["waiting_purchase", "waiting_delivery"],
        })
        .then(({ requests }) => {
          // save the requests
          this.requests = requests;
          this.clientRequests = requests.filter(
            request => request.client && !request.repair,
          );
          this.repairRequests = requests.filter(
            request => !request.client && request.repair,
          );
          this.stockRequest = requests.filter(
            request => !request.client && !request.repair,
          );

          this.clientTemp = this.clientRequests;
          this.repairTemp = this.repairRequests;
          this.stockTemp = this.stockRequest;
          // disable loading
          this.loading = false;
        });
    },

    /**
     * We must reload the list of requests after the purchase creation.
     */
    afterPurchaseCreationHandler(row) {
      // re-fetch the data
      this.fetchData();
    },

    /**
     * Open the form to create a new request.
     */
    async createRequestHandler() {
      if (!this.$auth.can("view_client")) {
        return this.$refs.materialRequests.show(true, false);
      }

      try {
        await this.$confirm(
          "Qual o destino do pedido a efetuar",
          "Destino do pedido",
          {
            confirmButtonText: "Cliente",
            cancelButtonText: "Stock",
            customClass: "test",
            type: "success",
          },
        );
        this.$refs.materialRequests.show(true, true);
      } catch (e) {
        this.$refs.materialRequests.show(true, false);
      }
    },

    /**
     * Validate a request.
     *
     * @param {object} row
     */
    async processRequestHandler(row) {
      const doPurchase = quantity => {
        // set the material
        row.material.id = row.material._id;
        this.$refs.purchases.setMaterial(row.material, quantity, row.id);
        // show the purchase form
        this.$refs.purchases.show();
      };

      // when is a stock reinforcement we do the purchase right now
      if (!row.client && !row.repair) {
        return doPurchase(row.quantity);
      }

      // enable loading
      this.loading = true;

      // set the id
      row.id = row._id;

      // make an API call to validate the request
      const { needsPurchase, purchaseQuantity } = await this.$stellar.action(
        "processRequests",
        row,
      );

      // check if we need create a new purchase
      if (needsPurchase) {
        doPurchase(purchaseQuantity);

        // disable loading
        this.loading = false;

        return;
      }

      // show success message when the validation occurs
      this.$message.success("O pedido foi validado.");

      // re-fetch the data
      this.fetchData();
      this.cleanSearch();

      // disable loading
      this.loading = false;
    },

    /**
     * Remove material request.
     */
    removeRequest(materialRequest) {
      this.$confirm(
        "Tem a certeza que pretende remover o pedido?",
        "Remover pedido",
        {
          type: "warning",
          confirmButtonText: "Remover",
        },
      )
        .then(_ => {
          // enter in loading mode
          this.loading = true;

          // remove material request from requests
          return this.$stellar.action("removeMaterialrequest", {
            id: materialRequest._id,
          });
        })
        .then(_ => {
          // show a success message
          this.$message.success("O pedido foi removido.");

          // refetch the data
          this.fetchData();
          this.cleanSearch();
          // exit loading mode
          this.loading = false;
        })
        .catch(_ => {});
    },

    /**
     * Deliver the material to th client.
     */
    deliverMaterialhandler(entry) {
      // request to the user to confirm his action.
      this.$confirm("Tem certeza que pretende entregar?", "Entregar", {
        type: "warning",
        confirmButtonText: "Entregar",
      })
        .then(_ => {
          // enable loading
          this.loading = true;

          // make an API call to update the material request status
          return this.$stellar.action("editMaterialrequest", {
            id: entry._id,
            state: "delivered",
          });
        })

        // show a success message
        .then(_ => {
          // disable loading
          this.loading = false;

          // show the place here the material must be placed
          this.$alert(
            `O material encontra-se na caixa ${
              entry.storeBox
            }. E o preço (c/ IVA) é de ${moneyFormatter(entry.price * 1.23)}.`,
          );

          // re-fetch the data
          this.fetchData();
          this.cleanSearch();
        })
        .catch(_ => {});
    },

    /**
     * Put material into stock.
     */
    async putMaterialOnStockHandler(entry) {
      try {
        await this.$confirm(
          "Tem certeza que pretende colocar o material em stock?",
          "Colocar em Stock",
          {
            type: "warning",
            confirmButtonText: "Colocar",
          },
        );
      } catch (_) {
        return;
      }

      let destinationBox = entry.material.storeBox || null;

      // If there is no store box defined on the material
      // we ask the user to insert a one
      if (!destinationBox) {
        const { value } = await this.$prompt(
          "Por favor, indique a caixa do material.",
          "Caixa do Material",
        );

        destinationBox = value;
      }

      this.loading = true;

      await this.$stellar.action("putMaterialRequestIntoStock", {
        request: entry._id,
        destinationBox,
      });

      this.loading = false;

      this.$alert(
        `A peça deve ser colocada na caixa ${destinationBox}.`,
        "Colocar no Stock",
        {
          type: "success",
        },
      );

      // re-fetch the data
      this.fetchData();
      this.cleanSearch();
    },

    printElem() {
      this.requestVisibility = false;

      const content = document.getElementById("toPrint").innerHTML;
      printHTML(content);
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-MaterialRequestList {
    &__content {
      max-width: 98%;
      margin: 0 auto;
    }

    &__newBtn {
      margin: 10px 0 !important;
    }

    &__reqSearch {
      width: 100px;
    }
  }
  .test div button i {
    display: none;
  }
}

.material-request-list__table-container {
  padding: 10px 0;

  &:first-child {
    padding-top: 0;
  }
}

.material-request-list__table-container h3 {
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 8px;
}
</style>
