<template>
  <div class="tp-login_root">
    <div class="tp-login_wrap">
      <div class="tp-login_form">
        <!-- title -->
        <h1>Login</h1>

        <!-- info text -->
        <p class="is-info">
          Para iniciar sessão, deve fazer uso das credencias fornecedidas pela TecniPhone.
        </p>
        <!-- error alert -->
        <el-alert
          v-if="errorMessage !== null"
          ref="alert"
          :title="errorMessage"
          class="tp-login_alert"
          type="error"/>

        <!-- form -->
        <el-form
          v-loading.body="loading"
          :model="data"
          label-position="top"
          class="tp-login_formContainer">
          <!-- username -->
          <el-form-item>
            <el-input
              v-model="data.email"
              placeholder="Email"/>
          </el-form-item>

          <!-- password -->
          <el-form-item>
            <el-input
              v-model="data.password"
              type="password"
              placeholder="Password"
              @keyup.enter.native="login"/>
          </el-form-item>

          <!-- actions -->
          <div class="tp-login_actions">
            <!-- login button -->
            <el-form-item>
              <el-button
                type="primary"
                @click.native.prevent="login">Login</el-button>
            </el-form-item>

          <!-- todo: convert this to a link -->
          <!-- <button v-on:click="forgot">Forgot account?</buttom> -->
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      data: {
        email: "",
        password: "",
      },

      loading: false,
      errorMessage: null,
    };
  },

  methods: {
    invalidCredentials() {
      this.errorMessage = "As credenciais são invalidas.";
    },

    accountDisabled() {
      this.errorMessage = "A sua conta está inativa.";
    },

    login() {
      if (this.data.email === "" || this.data.password === "") {
        return this.invalidCredentials();
      }

      // if the login isn't an email we need to append '@tecniphone.pt'
      const regex = /@.+\..+$/g;
      if (!regex.test(this.data.email)) {
        this.data.email += "@tecniphone.pt";
      }

      // enter in loading mode
      this.loading = true;

      // call the correspondent action to make the login
      this.$store
        .dispatch("login", this.data)
        .then(user => {
          // disable loading
          this.loading = true;

          // if the user was redirect to this page we back to the same page
          if (this.$route.query.redirectTo) {
            return this.$router.push(this.$route.query.redirectTo);
          }

          // redirect to the default page depending on the user role
          if (user.role && user.role.options && user.role.options.homepage) {
            return this.$router.go({ name: user.role.options.homepage });
          }

          if (user.role.slug === "store") {
            this.$router.go({ name: "store.ongoing" });
          } else {
            this.$router.go({ name: "dashboard" });
          }
        })
        .catch(error => {
          // exit loading mode
          this.loading = false;

          if (error === "account_disabled") {
            return this.accountDisabled();
          }

          this.invalidCredentials();
        });
    },
  },
};
</script>

<style>
@import "./var.css";

.tp-login_root {
  height: 100%;
  height: 100vh;
  position: absolute;

  background-size: cover;
  background-image: url("./assets/login_bg.jpeg");
}

.tp-login_wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50vw;
  height: 100vh;
  margin-left: 50vw;
  background-color: white;

  box-shadow: -2px 0px 12px 0px rgba(0, 0, 0, 0.83);
}

.tp-login_alert {
  margin-top: 30px;
}

.tp-login_form {
  width: 300px;
}

.tp-login_form > h1 {
  font-weight: 200;
  color: var(--colors-primary);
}

.tp-login_formContainer {
  margin-top: 30px;
}

.tp-login_actions {
  position: relative;
  margin-top: 30px;
  text-align: right;
}

/** small screens */
@media (max-width: 768px) {
  /** todo: add a top border with the back image */
  .tp-login_wrap {
    width: 100vw;
    height: 80vh;
    margin-left: 0;
    margin-top: 10vh;
  }
}
</style>
