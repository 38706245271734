import { IBaseState } from "@/services/store/types/base-state.interface";

/**
 * Interface that represents a menu items.
 */
export interface INavigationMenuItem {
  /**
   * Item title.
   */
  title: string;

  /**
   * Target route name.
   */
  route: string;

  /**
   * Menu icon.
   */
  icon: string;

  /**
   * Optional user permission validation.
   */
  permission?: string;

  /**
   * Optional user role validation.
   */
  role?: string;

  /**
   * Optional advanced menu item filter.
   */
  filter?: () => boolean;
}

/**
 * This interface represents the navigation module state.
 */
export interface INavigationState extends IBaseState {
  /**
   * List of menu items.
   */
  menuItems: Array<INavigationMenuItem>;
}

/**
 * Enumeration with all available module actions.
 */
export enum ENavigationActions {
  ADD_MENU_ITEMS = "navigation/addMenuItems",
}

/**
 * Enumeration with all available module mutations.
 */
export enum ENavigationMutations {
  SET_MENU_ITEMS = "setMenuItems",
}
