<template>
  <el-table
    :data="commissions"
    class="tp-comissionsTable"
    stripe
    style="width: 100%">
    <el-table-column label="#">
      <template slot-scope="scope">
        {{ scope.row.repair.repairNumber }}
      </template>
    </el-table-column>
    <el-table-column label="Equipamento">
      <template slot-scope="scope">
        {{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}
      </template>
    </el-table-column>
    <el-table-column label="Valor da Reparação">
      <template slot-scope="scope">
        {{ scope.row.repairValue | money }}
      </template>
    </el-table-column>
    <el-table-column label="Material">
      <template slot-scope="scope">
        {{ scope.row.totalMaterial | money }}
      </template>
    </el-table-column>
    <el-table-column label="A receber">
      <template slot-scope="scope">
        {{ scope.row.value | money }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "CommissionsTable",

  props: {
    commissions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.tp-commissionsTable {
  margin-bottom: 10px;
}
</style>
