<template>
  <section class="tp-materialList_wrap">
    <!-- edit panel -------------------------------------------------------- -->
    <edit-form
      ref="editForm"
      @updated="reloadCurrentPage"
      @created="reloadCurrentPage"/>

    <add-mat
      ref="addMat"
      @updated="reloadCurrentPage"
      @created="reloadCurrentPage"/>

    <!-- List of material used on a day ------------------------------------ -->
    <report-list ref="dailyList"/>

    <!-- sell dialog ------------------------------------------------------- -->
    <el-dialog
      :visible.sync="sellFormVisible"
      title="Venda de Material"
      size="tiny">
      <!-- form -->
      <el-form
        :model="sellForm"
        label-position="top">
        <el-form-item label="Quantidade">
          <el-input-number
            v-model="sellForm.quantity"
            :style="{ width: '100%' }"
            :min="1"/>
        </el-form-item>

        <el-form-item label="Retirar do material">
          <el-select
            v-model="sellForm.type"
            :style="{ width: '100%' }"
            placeholder="Please select a zone">
            <el-option
              label="Novo"
              value="new"/>
            <el-option
              label="Usado"
              value="used"/>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- footer -->
      <span
        slot="footer"
        class="dialog-footer">
        <el-button @click="sellFormVisible = false">Cancelar</el-button>
        <el-button
          type="primary"
          @click="performSellHandler">
          Vender
        </el-button>
      </span>
    </el-dialog>

    <!-- list -------------------------------------------------------------- -->
    <div class="tp-MaterialList__content">
      <!-- action -->
      <div class="tp-MaterialList__actions">
        <div class="tp-MaterialList__actions__right">
          <!-- add button -->
          <el-button
            class="tp-MaterialList__addButton"
            size="medium"
            type="success"
            @click="add">
            Adicionar
          </el-button>
        </div>

        <div class="left-menu">
          <el-dropdown>
            <el-button icon="el-icon-more" type="info"/>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="dailyReportHandler">Relatorio Diario</el-dropdown-item>
              <el-dropdown-item v-if="user.role.slug === 'admin'" @click.native="printStock">Existências</el-dropdown-item>
              <el-dropdown-item @click.native="onClickMaterialGroupHandler">Grupos de Materiais</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- search -->
          <el-input
            v-model="searchQuery"
            class="tp-MaterialList__search"
            placeholder="Pesquise aqui por materiais..."/>
        </div>
      </div>

      <el-table
        v-loading.body="loading"
        :data="orderedData"
        stripe>
        <el-table-column
          :width="100"
          label="Cod.">
          <template slot-scope="scope">
            {{ scope.row.identifier }}
          </template>
        </el-table-column>

        <el-table-column label="Descrição">
          <template slot-scope="scope">
            {{ scope.row.description }} , {{ scope.row.brand }} {{ scope.row.model }}
          </template>
        </el-table-column>

        <el-table-column
          :width="80"
          property="storeBox"
          label="Caixa"/>

        <el-table-column
          :width="90"
          label="Preço"
          align="center">
          <template slot-scope="{ row }">
            <span>{{ row.price | money }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :width="150"
          label="Preço c/ IVA"
          align="center">
          <template slot-scope="{row}">
            <span>{{ row.price * 1.23 | money }}</span>
          </template>
        </el-table-column>

        <!-- stock (new and used) -->
        <el-table-column
          :width="70"
          label="Stock"
          align="center">
          <template slot-scope="scope">
            <el-tag
              :type="getTagColor(scope.row)"
              size="medium">
              {{ scope.row.newStock }}/{{ scope.row.usedStock }}
            </el-tag>
          </template>
        </el-table-column>

        <!-- purchase -->
        <el-table-column
          :width="100"
          label="Aguarda"
          align="center">
          <template slot-scope="scope">
            {{ scope.row.requestedQuantity }}
          </template>
        </el-table-column>

        <el-table-column
          :width="270"
          label="">
          <template slot-scope="scope">
            <!-- edit button -->
            <el-button
              type="info"
              size="mini"
              @click="edit(scope.row)">
              Editar
            </el-button>

            <!-- sell button -->
            <el-button
              type="warning"
              size="mini"
              @click="sellHandler(scope.row)">
              Vender
            </el-button>

            <!-- request button -->
            <el-button
              type="success"
              size="mini"
              @click="requestMaterial(scope.row)">
              Requisitar
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination ------------------------------------------------------ -->

      <el-pagination
        :current-page="currentPage"
        :total="total"
        :page-size="20"
        class="tp-MaterialList__pagination"
        layout="prev, pager, next"
        @current-change="currentPageChange"/>
    </div>

    <div
      v-show="false"
      id="stockToPrint">
      <table>
        <thead>
          <th>Cod.</th>
          <th>Descrição</th>
          <th>Caixa</th>
          <th>Stock</th>
        </thead>

        <tbody>
          <tr
            v-for="material in materialsToPrint"
            :key="material.identifier">
            <td style="width: 110px">{{ material.identifier }}</td>
            <td>{{ material.name }}</td>
            <td>{{ material.box }}</td>
            <td>{{ material.newStock }}/{{ material.usedStock }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import MaterialFormCmp from "./MaterialForm.vue";
import DailyReportCmp from "./DailyReport.vue";
import RequestFormCmp from "../materialRequest/MaterialRequest.vue";

import printHTML from "@/utils/print-html";
import { STOCK_ROUTE_MATERIAL_GROUP_LIST } from "../../modules/stock/services/router/routes-names";

// var to store the query timer
let searchTimer = null;

// var to store the current page
let currentPage = 0;

export default {
  components: {
    "edit-form": MaterialFormCmp,
    "report-list": DailyReportCmp,
    addMat: RequestFormCmp,
  },

  data() {
    return {
      materials: [],
      currentPage: 1,
      total: 0,
      loading: false,

      searchQuery: "",

      materialsToPrint: [],

      // sell panel
      sellFormVisible: false,
      selectedMaterial: null,
      sellForm: {
        type: "new",
        quantity: 1,
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),

    /**
     * Return the table data ordered by description.
     */
    orderedData() {
      return _.orderBy(this.materials, "description");
    },
  },

  watch: {
    searchQuery(newVal, oldVal) {
      // ignore queries with just one char
      if (newVal.length < 2 && oldVal.length > 1) {
        return this.fetchPage(this.currentPage);
      } else if (newVal.length < 2 && oldVal.length < 2) {
        return;
      }

      // if there is a timer remove it
      clearTimeout(searchTimer);

      // create a timeout to execute the query with a delay of 400 ms
      searchTimer = setTimeout(() => {
        this.fetchPage(1);
      }, 400);
    },
  },

  created() {
    this.fetchPage();
  },

  methods: {
    onClickMaterialGroupHandler() {
      this.$router.push({ name: STOCK_ROUTE_MATERIAL_GROUP_LIST });
    },

    getTagColor(material) {
      const totalStock = material.newStock + material.usedStock;

      if (totalStock === 0) {
        // danger
        return "danger";
      } else if (totalStock <= material.stockMin) {
        // warning
        return "warning";
      }

      return "success";
    },

    /**
     * Fetch a page of materials.
     *
     * @type {number} page
     */
    async fetchPage(page = 1, query = "") {
      this.loading = true;

      // make an API call to get the requested page
      const { total, data } = await this.$stellar.action("paginateMaterials", {
        page,
        limit: 20,
        withRequests: true,
        query: this.searchQuery,
      });

      this.total = total;
      this.materials = data;

      currentPage = page;
      this.loading = false;
    },

    /**
     * Reload the current page.
     */
    reloadCurrentPage() {
      this.fetchPage(currentPage);
    },

    /**
     * Change the current page for the new one and fetch
     */
    currentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchPage(newPage);
    },

    /**
     * Show a clean form to create a new material.
     */
    add() {
      this.$refs.editForm.show();
    },

    edit(resource) {
      const panel = this.$refs.editForm;
      panel.setResource(resource);
      panel.show();
    },

    /**
     * Handler for the sell button.
     */
    sellHandler(material) {
      this.selectedMaterial = material;
      this.sellFormVisible = true;

      // reset quantity and type
      this.sellForm = {
        quantity: 1,
        type: "new",
      };
    },

    performSellHandler() {
      // enable loading
      this.sellForm.loading = true;

      // check if there enouth quantity to precced with the sell
      if (
        this.sellForm.type === "new" &&
        this.selectedMaterial.newStock - this.sellForm.quantity < 0
      ) {
        // open the request
        this.$confirm(
          "Pretende criar um pedido de material?",
          "Não existe stock",
          {
            confirmButtonText: "Pedir",
            cancelButtonText: "Cancelar",
            type: "warning",
          },
        )
          .then(() => {
            return this.$stellar.action("createMaterialrequest", {
              requests: [
                {
                  type: "stock",
                  material: this.selectedMaterial.id,
                  price: this.selectedMaterial.price,
                  quantity: this.sellForm.quantity,
                },
              ],
            });
          })
          // show success message
          .then(() => {
            this.$message.success("O pedido foi criado.");
          })
          .catch(() => {});

        this.sellFormVisible = false;

        return;
      } else if (
        this.sellForm.type === "used" &&
        this.selectedMaterial.usedStock - this.sellForm.quantity < 0
      ) {
        this.$message.error("Não existe stock suficiente!");
        this.sellFormVisible = false;

        return;
      }

      this.sellFormVisible = false;
      this.loading = true;

      // make an API call to perform
      this.$stellar
        .action("performMaterialSale", {
          materialId: this.selectedMaterial.id,
          quantity: this.sellForm.quantity,
          type: this.sellForm.type,
        })
        .then(() => {
          // show a success message
          this.$alert(
            `Pode levandar ${this.sellForm.quantity} unidade(s) da caixa ${
              this.selectedMaterial.storeBox
            }.`,
            "Venda Efetuada",
            {
              confirmButtonText: "Feito!",
              type: "success",
            },
          );

          // in case of success update the current page
          this.fetchPage(currentPage);
        });
    },

    /**
     * Shows the daily report panel.
     */
    dailyReportHandler() {
      this.$refs.dailyList.show();
    },
    /**
     * Add material to stock
     * @param material
     */
    requestMaterial(material) {
      this.$refs.addMat.setMaterial(material);
      this.$refs.addMat.show();
    },

    async printStock() {
      try {
        await this.$confirm("Quer imprimir as existências?", "Existências", {
          confirmButtonText: "Sim",
          cancelButtonText: "Agora Não",
          type: "info",
        });
      } catch (_) {
        return;
      }

      const { data } = await this.$stellar.action("getStock");
      this.materialsToPrint = data;

      await this.$nextTick();

      const contents = document.getElementById("stockToPrint").innerHTML;
      printHTML(contents, "", "Existências");
    },
  },
};
</script>

<style lang="scss" scoped>
.left-menu {
  display: flex !important;
  align-items: row;

  & > * {
    margin-left: 15px;
  }
}

.tp {
  &-MaterialList {
    /** actions */
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: inherit;
      margin-top: 10px;

      /* place element side by side */
      & > * {
        display: inline-block;
      }
    }

    /** search bar ************************************************************/
    &__search {
      width: 245px;
    }

    &__content {
      max-width: 98%;
      margin: 0 auto;
    }

    &__addButton {
      margin-bottom: 10px !important;
    }

    &__reportButton {
      margin-bottom: 10px !important;
    }

    &__pagination {
      padding-left: 0 !important;
      margin: 8px 0;
    }
  }
}
</style>
