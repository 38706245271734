
<section class="tp-store-profits">
  <div class="tp-store-profits-filter">
    <span class="tp-store-profits-filter__description">
      Filtrar por mês e ano:
    </span>
    <el-date-picker
      v-model="selectedDate"
      type="month"
      placeholder="Escolha um Mês"/>
  </div>

  <h2>Lucros do mês de <b>{{ mounth }}</b></h2>
  <ProfitsTable
    :loading="loading"
    :profits="data.mounthProf" />

  <h2>Lucros do ano <b>{{ year }}</b></h2>
  <ProfitsTable
    :loading="loading"
    :profits="data.yearProf" />
</section>
