import Vue from "vue";
import Gravatar from "gravatar";

const gravatarWrapper = (value, options = { d: "identicon" }) => {
  return Gravatar.url(value, options, "https");
};

// install the gravatar filter on the Vue instance
Vue.filter("gravatar", gravatarWrapper);

export default gravatarWrapper;
