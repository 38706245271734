
<div class="tp-ClientContacts">

  <!-- Client pendent contacts ---------------------------------------------------- -->
  <div
    v-if="clientPendentContacts.length > 0"
    class="tp-ClientContacts__pendent">
    <h2 class="pageTitle">Pendentes</h2>

    <!-- client list -->
    <div v-if="clientPendentContacts.length > 0">
      <h5>Clientes</h5>
      <contact-table
        :data="clientPendentContacts"
        @update="fetchData"/>
    </div>
  </div>

  <!-- no contacts to make ------------------------------------------------- -->
  <div
    v-if="noContacts"
    class="tp-ClientContacts__noContacts">
    <i class="fa fa-thumbs-o-up"/>
    <span>Sem contactos a fazer!</span>
  </div>

  <!-- urgent contacts ----------------------------------------------------- -->

  <div
    v-if="urgentContacts.length > 0"
    class="tp-ClientContacts__urgent">
    <h2 class="pageTitle">Urgentes</h2>
    <contact-table
      :data="urgentContacts"
      @update="fetchData" />
  </div>

  <!-- not answered -------------------------------------------------------- -->

  <div
    v-if="notAnswered.length > 0"
    class="tp-ClientContacts__notAnswered">
    <h2 class="pageTitle">Não atendidas</h2>
    <contact-table
      :data="notAnswered"
      @update="fetchData" />
  </div>

  <!-- waiting answer ------------------------------------------------------ -->

  <div
    v-if="waitingAnswer.length > 0"
    class="tp-ClientContacts_wating_answer">
    <h2 class="pageTitle">À espera de Resposta</h2>
    <contact-table
      :data="waitingAnswer"
      @update="fetchData" />
  </div>

  <!-- store pendent contacts ---------------------------------------------------- -->
  <div
    v-if="storePendentContacts.length > 0"
    class="tp-ClientContacts__pendent">
    <h2 class="pageTitle">Pendentes</h2>

    <!-- store list -->
    <div v-if="storePendentContacts.length > 0">
      <h5>Loja</h5>
      <contact-table
        :data="storePendentContacts"
        @update="fetchData" />
    </div>
  </div>
</div>
