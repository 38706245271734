
<section class="tp-suppliers_wrap">

  <!-- edit panel -------------------------------------------------------- -->

  <edit-form
    ref="editForm"
    @updated="fetchPage"
    @created="fetchPage"/>

  <!-- suppliers list ------------------------------------------------- -->
  <ui-list
    :total="total"
    :data="suppliers"
    :loading="loading"
    :creation-method="add"
    :remote-search="searchSuppliersHandler"
    allow-creation
    searchable
    paginate
    @current-changed="fetchPage"
    @mode-changed="modeChangeHandler">
    <ui-list-item
      v-for="item in orderedData"
      :key="item.id">
      <!-- avatar -->
      <div slot="image">
        <img
          :src="getAvatar(item.email)"
          class="tp-suppliers_avatar img-circle"
          alt="Avatar" >
      </div>

      <!-- name -->
      <span class="name">{{ item.name }}</span>

      <!-- email -->
      <span slot="details">{{ item.email }}</span>

      <!-- actions -->
      <div slot="actions">
        <el-button
          v-show="!item.disabled"
          type="danger"
          @click.native="disable(item)">Desativar</el-button>
        <el-button
          v-show="item.disabled"
          type="success"
          @click.native="activate(item)">Ativar</el-button>

        <!-- edit button -->
        <el-button
          type="info"
          @click.native="edit(item)">Editar</el-button>
      </div>
    </ui-list-item>
  </ui-list>
</section>
