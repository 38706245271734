<template>
  <section class="tp-store-profits">
    <div class="tp-store-profits-filter">
      <span class="tp-store-profits-filter__description">
        Filtrar por mês e ano:
      </span>
      <el-date-picker
        v-model="selectedDate"
        type="month"
        placeholder="Escolha um Mês"/>
    </div>

    <h2>Lucros do mês de <b>{{ mounth }}</b></h2>
    <ProfitsTable
      :loading="loading"
      :profits="data.mounthProf" />

    <h2>Lucros do ano <b>{{ year }}</b></h2>
    <ProfitsTable
      :loading="loading"
      :profits="data.yearProf" />
  </section>
</template>

<script>
import ProfitsTable from "./profits-table.vue";

export default {
  components: {
    ProfitsTable,
  },

  data() {
    return {
      data: {
        mounthProf: [],
        yearProf: [],
      },

      loading: false,
      selectedDate: new Date(),
    };
  },

  computed: {
    /**
     * Current selected month.
     */
    mounth() {
      const locale = "pt-pt";
      const mounth = this.selectedDate.toLocaleString(locale, {
        month: "long",
      });
      return mounth.charAt(0).toUpperCase() + mounth.slice(1);
    },

    /**
     * Current selected year.
     */
    year() {
      return this.selectedDate.getFullYear();
    },
  },

  watch: {
    selectedDate() {
      this.fetchPage();
    },
  },

  created() {
    this.fetchPage();
  },

  methods: {
    async fetchPage() {
      this.loading = true;
      this.data = await this.$stellar.action("storeProfits", {
        year: this.selectedDate.getFullYear(),
        mounth: this.selectedDate.getMonth(),
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tp-store-profits {
  padding: 10px 15px;
}

.tp-store-profits-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0 10px;

  &__description {
    margin-right: 10px;
  }
}
</style>
