<template>
  <el-form
    label-position="top"
    class="tp-RepairForm__step4">
    <el-card>
      <!-- header -->
      <div
        slot="header"
        class="clearfix">
        <h2>Dados da Reparação</h2>
      </div>

      <el-row :gutter="10">
        <!-- warranty -->
        <el-col :span="3">
          <el-form-item label="Garantia">
            <el-switch
              v-model="innerRepair.warranty"
              on-text="sim"
              off-text="não"/>
          </el-form-item>
        </el-col>

        <!-- complaint -->
        <el-col :span="3">
          <el-form-item label="Reclamação">
            <el-switch
              v-model="innerRepair.complaint"
              on-text="sim"
              off-text="não"/>
          </el-form-item>
        </el-col>

        <!-- contact -->
        <el-col v-if="!isStoreUser" :span="5">
          <el-form-item label="Contacto">
            <el-tooltip
              class="item"
              effect="dark"
              content="Para onde irá ser notificado."
              placement="right">
              <el-input
                v-model="innerRepair.phone"
                placeholder="Numero de Contacto"/>
            </el-tooltip>
          </el-form-item>
        </el-col>

        <!-- store's repair identifier -->
        <el-col
          v-if="client.isStore || isStoreUser"
          :span="5">
          <el-form-item label="Ficha da Loja">
            <el-input
              v-model="innerRepair.storeRepairIdentifier"
              placeholder="Identificador"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- dynamic view -->
      <dynamic-attributes
        ref="dynamicViewRepair"
        v-model="innerRepair.attributes"
        :equipment="equipmentType"
        moment="repair"/>

      <el-row :gutter="10">
        <!-- equipment state observation -->
        <el-col :span="12">
          <el-form-item label="Estado do equipamento">
            <el-select
              v-model="innerRepair.stateObservation"
              :style="{ width: '100%' }"
              placeholder="Sem riscos">
              <el-option
                v-for="item in statesObser"
                :key="item"
                :label="item"
                :value="item"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- problem description -->
        <el-col :span="12">
          <el-form-item label="Descrição da avaria">
            <el-input
              v-model="innerRepair.problemDescription"
              :rows="5"
              type="textarea"
              placeholder="Problema do Equipamento"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="!isStoreUser" :gutter="10">
        <el-col :span="12">
          <el-form-item label="Prioridade">
            <el-radio-group v-model="innerRepair.priority">
              <el-radio label="normal">Normal</el-radio>
              <el-radio label="urgent">Urgente </el-radio>
              <el-radio label="ontime">Na Hora</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Orçamento">
            <el-input
              v-model="innerRepair.quotation"
              :min="0"
              type="number"
              placeholder="Orçamento">
              <template slot="append">€</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <!-- technician -->
        <el-col
          v-if="innerRepair.priority === 'ontime' || innerRepair.priority === 'urgent'"
          :span="7">
          <el-form-item label="Técnico">
            <el-select
              v-model="innerRepair.technician"
              :style="{ width: '100%' }"
              placeholder="Técnico"
              filterable>
              <el-option
                v-for="item in technicians"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- materials -->
        <el-col
          v-if="innerRepair.priority === 'ontime' || innerRepair.priority === 'urgent'"
          :span="24">

          <!-- table of materials -->
          <el-table
            v-if="innerRepair.materials.length > 0"
            :data="innerRepair.materials"
            class="tp-RepairForm__materialTable">
            <el-table-column
              :width="100"
              label="Qnt."
              prop="quantity"/>
            <el-table-column
              label="Material"
              prop="name"/>
            <el-table-column :width="100">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="warning"
                  @click="returnMaterialHandler(scope.row)">Remover
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-row :gutter="10">
            <el-col :span="17">
              <el-form-item label="Material (apenas novo)">
                <el-select
                  v-model="materialsToRequest.materialId"
                  :style="{ width: '100%' }"
                  :remote-method="remoteMethod"
                  :loading="search.loading"
                  filterable
                  remote
                  placeholder="Material">
                  <el-option
                    v-for="item in search.materials"
                    :key="item.id"
                    :label="item.brand + ' ' + item.model + ', ' + item.description"
                    :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="Quantidade">
                <el-input-number
                  v-model="materialsToRequest.quantity"
                  :min="1"/>
              </el-form-item>
            </el-col>

            <el-col :span="3">
              <el-button
                :loading="loading"
                class="tp-RepairForm__addMaterialBtn"
                type="primary"
                @click="addMaterialToRequestHandler">
                Adicionar
              </el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <!-- conclusion button -->
      <el-row
        type="flex"
        justify="end">
        <el-button
          :loading="loading"
          type="primary"
          @click="addRepair">
          Criar
        </el-button>
      </el-row>
    </el-card>
  </el-form>
</template>

<script>
import DynamicAttributes from "../EquipmentDynamicForm.vue";
import { ETechnicianActions } from "../../../modules/technician/services/store/technician/technician.types";

// timer for the equipments search
let searchTimer = null;

export default {
  components: {
    DynamicAttributes,
  },

  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
    repair: {
      type: Object,
      default: () => ({}),
    },
    equipmentType: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,

      // repair information
      innerRepair: {
        clientEquipment: null,
        warranty: false,
        complaint: false,
        stateObservation: "Sem riscos",
        problemDescription: "",
        repairObservation: "",
        internalObservation: "",
        technician: null,
        priority: "normal",
        attributes: {},
        materials: [],
        log: [],
        quotation: 0,
        storeRepairIdentifier: null,
      },

      materialsToRequest: {
        quantity: 1,
        material: null,
        materialId: "",
      },

      statesObser: ["Sem riscos", "Com riscos", "Com mossa", "Com riscos e mossas"],

      technician: {
        technician: "",
      },

      search: {
        materials: [],
        loading: false,
      },
    };
  },

  computed: {
    technicians() {
      return this.$store.get("technician/technicians");
    },

    isStoreUser() {
      return this.$store.get("user").role.slug === "store";
    },
  },

  watch: {
    "materialsToRequest.materialId"(newVal) {
      this.materialsToRequest.material = this.search.materials.find(item => item.id === newVal);
    },
  },

  async created() {
    // copy repair data into the inner repair
    this.innerRepair = this.repair;

    // fetch the list of technicians
    this.$store.dispatch(ETechnicianActions.FETCH_ALL);
  },

  methods: {
    /**
     * Remove one material from the list.
     */
    returnMaterialHandler(item) {
      const index = this.repair.materials.findIndex(e => e.material === item.material);
      this.repair.materials.splice(index, 1);
    },

    /**
     * Search for materials on the API.
     *
     * @param query query to search for
     */
    async remoteMethod(query) {
      // clear timeout
      clearTimeout(searchTimer);

      if (query.length < 2) {
        this.search.materials = [];
        return;
      }

      searchTimer = setTimeout(async () => {
        // enable loading
        this.search.loading = true;

        // search for materials
        const { materials } = await this.$stellar.action("searchMaterials", {
          query,
        });

        // disable loading
        this.search.loading = false;

        // save the found materials
        this.search.materials = materials;
      }, 300);
    },

    /**
     * Add the material to the request.
     */
    addMaterialToRequestHandler() {
      // get the selected material
      const material = this.materialsToRequest.material;

      // function to create a well formated material name
      const formatMaterialName = materialToFormat => {
        let out = materialToFormat.description;

        if (materialToFormat.brand.length > 0) {
          out += `, ${materialToFormat.brand}`;
        }

        if (materialToFormat.model.length > 0) {
          out += ` ${materialToFormat.model}`;
        }

        return out;
      };

      // check if the material is selected
      if (!material) {
        return this.$message.error("Nenhum material selecionado.");
      }

      // try find selected material into the array of materials
      const index = this.innerRepair.materials.findIndex(item => item.material === material.id);
      const foundQuantity = index >= 0 ? this.innerRepair.materials[index].quantity : 0;

      // check if there is enough stock
      if (material.newStock - (this.materialsToRequest.quantity + foundQuantity) >= 0) {
        // append quantity for prev entry
        if (index >= 0) {
          this.innerRepair.materials[index].quantity += this.materialsToRequest.quantity;
        } else {
          // push it to the set of materials to stock to be requested
          this.innerRepair.materials.push({
            material: material.id,
            name: formatMaterialName(material),
            quantity: this.materialsToRequest.quantity,
          });
        }

        // reset search data
        this.materialsToRequest.material = null;
        this.materialsToRequest.materialId = "";
        this.materialsToRequest.quantity = 1;

        return;
      }

      this.$message.error("Não existe stock suficiente!");
    },

    async handleComplaintCreation() {
      let isTechnicianFault = false;

      if (typeof this.innerRepair.prevRepair === "string") {
        try {
          await this.$confirm("Consegue saber se a responsabilidade é do técnico?", "Atribuição de Responsabilidade", {
            confirmButtonText: "Tecnico",
            cancelButtonText: "Outro",
            type: "info",
            closeOnPressEscape: false,
            closeOnClickModal: false,
          });
          isTechnicianFault = true;
        } catch (e) {
          // Do nothing!
        }
      }

      // create a new complaint
      const repair = this.innerRepair;

      const response = await this.$stellar.action("createComplaint", {
        repair,
        isTechnicianFault,
      });

      // show a success message
      this.$message.success("Reclamação criada.");

      // show a informational message to the user
      if (!isTechnicianFault) {
        this.$message.info("A reclamaçao espera atribuição de responsabilidade");
      }

      return response;
    },

    async addRepair() {
      // hide the error alert
      this.errorMessage = null;

      // check if the state observation was filed in
      if (this.innerRepair.stateObservation === "") {
        return this.$message.error("Necessita de inserir o estado do equipamento.");
      }

      // check if was selected a problem
      if (!this.innerRepair.attributes.problem || this.repair.attributes.problem === "") {
        return this.$message.error("Não foi selecionado nenhum problema!");
      }

      // enable loading
      this.loading = true;

      try {
        // validate dynamic form
        await this.$refs.dynamicViewRepair.validate();

        // check if is a complaint
        let response = null;

        // call the API to create a new repair/complaint
        if (this.innerRepair.complaint === true) {
          response = await this.handleComplaintCreation();
        } else {
          response = await this.$stellar.action("createRepair", this.innerRepair);
        }

        // disable loading
        this.loading = false;

        // save the response
        this.innerRepair = response.repair;

        // update repair on the parent
        this.$emit("update:repair", this.innerRepair);

        // notify the parent that the repair was created
        this.$emit("created", this.innerRepair);
      } catch (e) {
        // disable loading
        this.loading = false;

        // show an error message
        this.$message.error("Valide o formulario antes de continua.");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../var.css";

.tp {
  &-RepairForm {
    &__step4 {
      width: 100%;
      margin: 30px auto 0;
      max-width: 90%;
    }

    /** material **/
    &__materialTable {
      margin-bottom: 15px;
    }

    &__addMaterialBtn {
      margin-top: 31px !important;
    }
  }
}
</style>
