<template>
  <div class="tp-DailyReport">
    <ui-bottom-sheet
      ref="editSheet"
      actions>

      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          @click="show(false)">Fechar</el-button>
        <el-button @click="printElem">Imprimir</el-button>
      </div>

      <!-- field to select the date -->
      <el-date-picker
        v-model="selectedDate"
        type="date"
        placeholder="Escolha um dia"/>


      <div id="toPrint">
        <div v-if="newSales.length > 0">
          <h2 class="tp-DailyReport__title">Material Novo</h2>

          <el-table
            v-loading="loading"
            :data="newSales"
            stripe>
            <el-table-column
              :width="80"
              label="Qnt.">

              <template slot-scope="scope">
                <span v-if="scope.row.quantity >= 0">{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Descrição">
              <template slot-scope="scope">
                <span v-if="scope.row.material.description">{{ formatMaterialName(scope.row.material) }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :width="80"
              label="Preço">
              <template slot-scope="scope">
                <span v-if="scope.row.material.price">{{ scope.row.material.price * 1.23 * profit | money }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :width="80"
              label="stock">
              <template slot-scope="scope">
                <span v-if="scope.row.material.newStock">{{ scope.row.material.newStock }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div v-if="usedSales.length > 0">
          <!-- lista de material usad o(stock usado) -->
          <h2 class="tp-DailyReport__title">Material Usado</h2>
          <el-table
            v-loading="loading"
            :data="usedSales"
            stripe>

            <el-table-column
              :width="80"
              label="QTD">

              <template slot-scope="scope">
                <span v-if="scope.row.quantity >= 0">{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Descrição">
              <template slot-scope="scope">
                <span v-if="scope.row.material.description">{{ scope.row.material.description }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :width="80"
              label="Preço">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.material.price"
                  onchange="setTwoNumberDecimal">{{ (scope.row.material.price * 1.23 * profit) }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :width="80"
              label="stock">
              <template slot-scope="scope">
                <span v-if="scope.row.material.usedStock">{{ scope.row.material.usedStock }}</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </div>
    </ui-bottom-Sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      newSales: [],
      usedSales: [],

      loading: false,

      selectedDate: new Date(),
    };
  },

  computed: {
    ...mapGetters(["configs"]),

    profit() {
      const value = this.configs.find(item => item.key === "profit").value;
      return parseFloat(value);
    },
  },

  watch: {
    selectedDate: {
      immediate: true,
      handler(selectedDate) {
        // enable loading
        this.loading = true;

        this.$stellar
          .action("salesByDay", {
            selectedDate,
            onlyBilled: true,
          })
          .then(res => {
            this.newSales = res.new;
            this.usedSales = res.used;

            // disabled loading
            this.loading = false;

            // recompute the bootom sheet size
            this.$nextTick(_ => {
              this.$refs.editSheet.adjustSize();
            });
          });
      },
    },
  },

  methods: {
    /**
     * function to create a well formated material name
     */
    formatMaterialName(material) {
      let out = material.description;

      if (material.brand.length > 0) {
        out += `, ${material.brand}`;
      }

      if (material.model.length > 0) {
        out += ` ${material.model}`;
      }

      return out;
    },

    /**
     * Change the visibility of the component.
     *
     * @type {[type]}
     */
    show(val = true) {
      this.$refs.editSheet.show(val);
    },
    /** @argument
     * print daly report
     */
    printElem() {
      this.requestVisibility = false;
      const mywindow = window.open("", "PRINT", "height=400,width=600");
      mywindow.document.write(
        "<html><head><title>" + document.title + "</title>",
      );

      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(document.getElementById("toPrint").innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-DailyReport {
    &__title {
      font-size: 1.3em;
      margin: 10px 0;
    }
  }
}
</style>
