
<div class="tp-DeliveryList">

  <!-- state 0 - select client -->
  <div
    v-if="currentStep === 0"
    class="step1">
    <el-card class="tp-DeliveryList__initial-card">
      <el-form
        v-loading.full="loading"
        class="tp-DeliveryList__clientForm">
        <!-- select the client -->
        <el-select
          v-model="selectedClientTemp"
          :remote-method="searchForClients"
          class="tp-DeliveryList__clientSelect"
          filterable
          remote
          placeholder="Escolha o Cliente">
          <el-option
            v-for="item in clients"
            :key="item.id"
            :label="item.name"
            :value="item.id"/>
        </el-select>

        <p class="tp-DeliveryList__selectionDivider">- OU -</p>

        <!-- select repair number -->
        <div class="tp-DeliveryList__repairNumber">
          <el-input
            v-model="repairNumber"
            class="tp-DeliveryList__repairNumber__field"
            placeholder="Numero reparação/Numero de loja"
            @keyup.enter.native="searchRepair"/>

          <el-button
            class="tp-DeliveryList__repairNumber__button"
            type="primary"
            @click="searchRepair">
            Procurar
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
  <!-- stage 1 - select the  -->
  <div
    v-if="currentStep === 1"
    class="">
    <div class="tp-DeliveryList__deliveryAll">

      <div
        id="toPrint"
        style="display: none">
        <div class="header">
          <p class="name">
            <b>Cliente: </b>
            {{ client.name }}
          </p>
          <p class="phone">
            <b>Contacto: </b>
            {{ client.phone }}
          </p>
        </div>
        <table
          v-if="repairs.length>0"
          class="table">
          <thead>
            <tr>
              <th>F.Tec</th>
              <th>Equipamento</th>
              <th>S/N IMEI</th>
              <th>F.Loja</th>
              <th>Cx</th>
              <th>Descrição</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody v-if="selectedItems">
            <tr
              v-for="repair in selectedItems"
              :key="repair.id">
              <td>{{ repair.repairNumber }}</td>
              <td>{{ repair.clientEquipment.model }}</td>
              <td v-if="repair.clientEquipment.attributes && repair.clientEquipment.attributes.imei">{{ repair.clientEquipment.attributes.imei }}</td>
              <td v-else>{{ repair.clientEquipment.serialNumber }}</td>
              <td>{{ repair.storeRepairIdentifier || '-' }}</td>
              <td>{{ repair.storeBox || '-' }}</td>
              <td>{{ repair.repairObservation || repair.problemName }}</td>
              <td>{{ repair.value | money }}</td>
            </tr>
          </tbody>
        </table>
        <!-- total -->
        <p
          v-if="repairs.length>0 && requests.requests.length>0"
          class="total">SubTotal: {{ repairTotal | money }}</p>
        <hr>
        <table
          v-if="requests.requests.length>0"
          class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Material</th>
              <th>Preço</th>
            </tr>
          </thead>
          <tbody v-if="selectedRequestedItems">
            <tr
              v-for="request in selectedRequestedItems"
              :key="request.id">
              <td>{{ request.requestNumber }}</td>
              <td>{{ request.material.brand }} - {{ request.material.model }}</td>
              <td>{{ request.quotation | money }}</td>
            </tr>
          </tbody>
        </table>
        <!-- total -->
        <p
          v-if="repairs.length>0 && requests.requests.length>0"
          class="total">SubTotal: {{ totalRequests | money }}</p>
        <p class="total">Total: {{ repairTotal + totalRequests | money }}</p>
      </div>
    </div>

    <span
      v-if="repairs.length>0"
      class="tp-DeliveryList__title">Reparações</span>
    <ui-list
      ref="list"
      :loading="loading"
      :data="repairs"
      selectable
      @selection-change="selectionChangeHandler">
      <span slot="actions">
        <!-- delivery selection -->
        <el-button
          :plain="true"
          type="primary"
          @click="deliverySelectionHandler">
          Entregar Selecionados
        </el-button>

        <!-- delivery all -->
        <el-button
          :disabled="repairs.length === 0 && requests.requests.length === 0"
          type="success"
          @click="deliveryAll">
          Entregar Todos
        </el-button>
      </span>
      <template v-if="repairs.length > 0">
        <ui-list-item
          v-for="item in repairs"
          :key="item.id"
          :data="item">
          <!-- name -->
          <span>#{{ item.repairNumber }} - {{ item.clientEquipment.brand }} {{ item.clientEquipment.model }}</span>

          <!-- decription (equipment) -->
          <span slot="details">Caixa {{ item.storeBox }}</span>

          <!-- decription (equipment) -->
          <span slot="details"> - Valor {{ item.value | money }}</span>

          <!-- actions -->
          <div slot="actions">
            <!-- see -->
            <el-button
              type="info"
              @click="showRepairDetails(item)">Ver</el-button>

            <!-- deliver -->
            <el-button
              type="success"
              @click="markDelivered(item)">Entregar</el-button>
          </div>
        </ui-list-item>
      </template>
    </ui-list>
    <div v-if="repairs.length>0 && requests.requests.length>0">
      <hr>
      <span
        slot="subtotal"
        class="subtotal">SubTotal {{ repairTotal | money }}</span>
      <hr>
    </div>

    <span
      v-if="requests.requests.length>0"
      class="tp-DeliveryList__title">Pedidos de material</span>
    <div v-if="requests.requests">
      <ui-list
        v-if="requests.requests.length>0"
        ref="requestsList"
        :loading="loading"
        :data="requests.requests"
        selectable
        @selection-change="requestSelectionChangeHandler">

        <ui-list-item
          v-for="item in requests.requests"
          :key="item.id"
          :data="item">
          <span>#{{ item.requestNumber }} - {{ item.material | formatMaterialName }}</span>
          <span slot="details">Caixa {{ item.material.storeBox }}</span>
          <div slot="actions">
            <el-button
              type="success"
              @click="markRequestDelivered(item)">
              Entregar
            </el-button>
          </div>
        </ui-list-item>
      </ui-list>
      <div v-if="repairs.length>0 && requests.requests.length>0">
        <hr>
        <span
          slot="subtotal"
          class="subtotal">SubTotal {{ totalRequests | money }}</span>
        <hr>
      </div>
    </div>
    <span
      slot="total"
      class="subtotal"> Total {{ totalRequests + repairTotal |money }}</span>
  </div>
</div>
