<template>
  <!-- eslint-disable vue/no-v-html -->
  <section class="tp-ContactTable">
    <!-- client dialog -->
    <el-dialog
      :visible.sync="seeClientDialog"
      title="Informação do cliente"
      size="small">
      <client-card :client="client"/>
    </el-dialog>

    <!-- approve quotation dialog -------------------------------------------- -->
    <el-dialog
      :visible.sync="approveQuotationData.visibility"
      title="Aprovar orçamento"
      size="tiny">
      <el-form label-position="top">
        <!-- value -->
        <el-form-item label="Valor">
          <el-input v-model="approveQuotationData.value">
            <template slot="append">€</template>
          </el-input>
        </el-form-item>
      </el-form>

      <!-- actions -->
      <div
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="approveQuotationData.visibility = false">
          Cancelar
        </el-button>
        <el-button
          type="primary"
          @click="approveQuotationBtnHandler">
          Aprovar
        </el-button>
      </div>
    </el-dialog>

    <!-- table --------------------------------------------------------------- -->
    <el-table
      v-loading.body="loading"
      :data="data"
      stripe>
      <el-table-column
        :width="85"
        label="#">
        <template slot-scope="scope">
          <span v-if="scope.row.repair">{{ scope.row.repair.repairNumber }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        :width="70"
        label="Loja">
        <template slot-scope="scope">
          <span v-if="scope.row.repair && scope.row.client.isStore">{{ scope.row.repair.storeRepairIdentifier }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- client name -->
      <el-table-column label="Cliente">
        <template slot-scope="scope">
          <a
            v-if="scope.row.client"
            @click="seeClient(scope.row.client)">
            {{ scope.row.client.name }}
          </a>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        :width="130"
        label="Contato">
        <template slot-scope="scope">
          <span v-if="scope.row.client">{{ scope.row.client.phone }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        :width="170"
        label="Equipamento">
        <template slot-scope="scope">
          <span v-if="scope.row.repair">{{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- this must show the message field from the contact request. By when is a
    quotation should show the log entry -->
      <el-table-column label="Mensagem">
        <template slot-scope="scope">
          <span
            v-if="scope.row.isQuotation"
            v-html="showQuotationMessage(scope.row)"/>
          <span v-else>{{ scope.row.message }}</span>
        </template>
      </el-table-column>

      <!-- actions -->
      <el-table-column
        :width="165"
        label="">
        <template slot-scope="scope">
          <!-- button to see the repair -->
          <el-button
            size="small"
            type="info"
            @click="showRepairHandler(scope.row)">Ver</el-button>

          <!-- actions that can be performed on the contact -->
          <el-dropdown>
            <el-button
              type="primary"
              size="small">
              Ações<i class="el-icon-caret-bottom el-icon--right"/>
            </el-button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="!scope.row.missedCall"
                @click.native="markAsNotAnswered(scope.row)">Não Atendeu</el-dropdown-item>
              <el-dropdown-item
                v-if="!scope.row.waitingAnswer"
                @click.native="markAsWaitingResponse(scope.row)">Aguarda resposta</el-dropdown-item>
              <el-dropdown-item
                v-if="!scope.row.isQuotation"
                @click.native="markAsProcessed(scope.row)">Resposta</el-dropdown-item>

              <!-- quotation -->
              <el-dropdown-item
                v-if="scope.row.isQuotation && !isToSetRepairValue(scope.row)"
                @click.native="approveQuotationHandler(scope.row)">Aprovar</el-dropdown-item>
              <el-dropdown-item
                v-if="scope.row.isQuotation && !isToSetRepairValue(scope.row)"
                @click.native="rejectQuotationHandler(scope.row)">Recusar</el-dropdown-item>

              <!-- set the repair value (for external repairs) -->
              <el-dropdown-item
                v-if="isToSetRepairValue(scope.row)"
                @click.native="setRepairValueHandler(scope.row)">Definir Valor</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { mapActions } from "vuex";

import ClientCardCmp from "../client/ClientCard.vue";

import moneyFormat from "../../filters/money";

export default {
  components: {
    "client-card": ClientCardCmp,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedNotification: null,
      loading: false,

      // approve quotation
      approveQuotationData: {
        visibility: false,
        value: 0,
      },

      // client info
      seeClientDialog: false,
      client: null,
    };
  },

  methods: {
    ...mapActions(["approveQuotation", "rejectQuotation"]),

    seeClient(client) {
      this.client = client;
      this.seeClientDialog = true;
    },

    /**
     * Nativate to the repair view to show the details.
     */
    showRepairHandler({ repair }) {
      this.$router.push({ name: "repair.single", params: { id: repair.id } });
    },

    /**
     * Mark a message as processed.
     */
    markAsProcessed(item) {
      // this function is responsable to mark the contact as processed
      const callTheApi = (message = null) => {
        // enable loading
        this.loading = true;

        this.$stellar
          .action("markClientNotificationsAsProcessed", {
            id: item.id,
            message,
          })
          .then(_ => {
            // inform update
            this.$emit("update");

            // show success message
            this.$message.success("Mensagem processada!");

            // disable loading
            this.loading = false;
          });
      };

      // show a prompt asking for a
      if (item.isContactRequest === true && item.repair) {
        this.$prompt("Qual foi a resposta do cliente?", "Resposta", {
          confirmButtonText: "Processar",
          cancelButtonText: "Cancelar",
        })
          .then(({ value }) => {
            callTheApi(value);
          })
          .catch(_ => {});
      } else {
        callTheApi();
      }
    },

    /**
     * This used to mark the notification as waiting client answere.
     */
    async markAsWaitingResponse(item) {
      this.loading = true;

      await this.$stellar.action("markContactAsWaitingResponse", item);

      this.loading = false;
      this.$emit("update");
    },

    /**
     * This is used to mark the noticifaction as not answered.
     */
    async markAsNotAnswered(item) {
      // enable loading
      this.loading = true;

      try {
        // make an API call to save the change
        await this.$stellar.action("markContactAsNotAnswered", { id: item.id });

        // inform the operation
        this.$emit("update");

        // disable local loading
        this.loading = false;
      } catch (e) {
        // TODO: implement this
      }
    },

    /**
     * Open a dialog to approve the quotation.
     */
    approveQuotationHandler(contact) {
      this.selectedNotification = contact;

      // set the quotation value
      this.approveQuotationData.value =
        contact.repair.value || contact.repair.quotation;

      // show the dialog
      this.approveQuotationData.visibility = true;
    },

    /**
     * Approve the quotation.
     */
    approveQuotationBtnHandler() {
      // enter in loading mode
      this.loading = true;

      // close the dialog
      this.approveQuotationData.visibility = false;

      // call the service to store the data permanently
      this.approveQuotation([
        this.selectedNotification.repair.id,
        this.approveQuotationData.value,
      ]).then(_ => {
        // exit from the loading mode
        this.loading = false;

        // inform the action
        this.$emit("update");

        // show a success message
        this.$message.success("Orçamento aprovado!");
      });
    },

    /**
     * Reject quotation.
     */
    rejectQuotationHandler(contact) {
      // request confirmation to the user
      this.$confirm("Pretende recusar o orçamento?", "Confirmação")
        .then(_ => {
          // enter in loading mode
          this.loading = true;

          // call the service to store the data permanently
          return this.rejectQuotation(contact.repair.id);
        })
        .then(_ => {
          // exit from the loading mode
          this.loading = false;

          // inform the action
          this.$emit("update");

          // show a success message
          this.$message.success("Orçamento recusado!");
        })
        .catch(_ => {});
    },

    /**
     * Check if we need set the quotation first.
     */
    isToSetRepairValue(entry) {
      return (
        entry.repair &&
        entry.repair.state === "waiting_quotation_approve" &&
        !entry.repair.value &&
        entry.repair.technician &&
        entry.repair.technician.external
      );
    },

    /**
     * Set the repair value field, for the exerternal technician repairs.
     */
    setRepairValueHandler(entry) {
      return this.$prompt("Por favor, insira o valor.", "Alterar Valor", {
        inputValue: entry.repair.value,
      })
        .then(({ value }) => {
          // update value of repair
          entry.repair.value = value;

          // enable loading
          this.loading = true;

          // update value of repair
          entry.repair.value = value;

          // make an API call
          return this.$stellar.action("editRepair", {
            id: entry.repair.id,
            value: entry.repair.value,
          });
        })
        .then(_ => {
          // disable loading
          this.loading = false;

          // inform the action
          this.$emit("update");

          // show a success message
          this.$message.success("Valor da reparação alterado.");
        })
        .catch(_ => {});
    },

    /**
     * Get last quotation message.
     */
    showQuotationMessage(entry) {
      if (!entry.repair) {
        return "-";
      }

      // const value = entry.repair.value || entry.repair.quotation
      let value = null;
      if (entry.repair.value) {
        value = entry.repair.value;
      } else {
        value = entry.repair.quotation;
      }

      if (!entry.repair.logs) {
        return "Logs invalidos!";
      }

      // get the last quotation entry from the logs
      const quotationLogEntry = entry.repair.logs.find(
        logEntry => logEntry.type === "repair.quotation",
      );
      if (!quotationLogEntry) {
        return "-";
      }

      return entry.repair.technician.external
        ? `[${moneyFormat(value)}] ${quotationLogEntry.message}`
        : quotationLogEntry.message;
    },
  },
};
</script>
