
<div
  v-loading="workLoading"
  class="tp-WorkList">

  <div class="tp-WorkList_waiting_list">
    <el-switch
      v-model="WaitingRepair"
      class="tp-WorkList_waiting_list_switch"
      active-color="#13ce66"
      inactive-color="#ff4949"/>
    <span>Espera de material</span>

  </div>


  <!-- urgent message -->
  <div class="tp-WorkList__urgentMessage">
    <el-alert
      v-if="workHasUrgent"
      :closable="false"
      type="warning"
      title="Existem reparações urgentes para resolver."
      show-icon/>
  </div>

  <!-- current working repair -->
  <div
    v-if="currentRepair"
    class="tp-WorkList-active">
    <RepairListEntry :repair="currentRepair" />
  </div>

  <!-- rejected quotation repairs -->
  <RepairList
    v-if="rejectedRepairs.length > 0"
    :data="rejectedRepairs"
    class="tp-WorkList-list" />

  <!-- Approved quotation repairs -->
  <RepairList
    v-if="approvedRepairs.length > 0"
    :data="approvedRepairs"
    class="tp-WorkList-list" />

  <!-- ontime repairs -->
  <RepairList
    v-if="onTimeRepairs.length > 0"
    :data="onTimeRepairs"
    class="tp-WorkList-list" />
  <!-- complaint repairs -->
  <RepairList
    v-if="complaintRepairs.length > 0"
    :data="complaintRepairs"
    class="tp-WorkList-list" />

  <!-- urgent repairs -->
  <RepairList
    v-if="urgentRepairs.length > 0"
    :data="urgentRepairs"
    class="tp-WorkList-list" />



  <!-- repairs with avalable material -->
  <RepairList
    v-if="repairsWithAvalableMaterial.length > 0"
    :data="repairsWithAvalableMaterial"
    class="tp-WorkList-list" />

  <!-- paused repairs -->
  <RepairList
    v-if="pausedRepairs.length > 0"
    :data="pausedRepairs"
    class="tp-WorkList-list" />

  <!-- repairs list -->
  <RepairList :data="remainingRepairs" />

  <!-- repairs waiting material-->
  <RepairList
    v-if="waitingMaterialRepairs.length > 0 && WaitingRepair"
    :data="waitingMaterialRepairs"
    class="tp-WorkList-list" />

</div>
