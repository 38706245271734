
<section class="tp-Ongoing">
  <div
    v-if="readyToDeliveryData.length > 0"
    class="tp-ongoing__section">
    <h2 class="tp-Ongoing__title">Pronto para entrega</h2>

    <toDelivery-table
      :data="readyToDeliveryData"
      @update="fetchPage" />
  </div>

  <!-- search -->
  <div class="tp-ongoing__section">
    <el-input
      v-model="searchQuery"
      :width="70"
      class="tp-Ongoing__imeiSearch"
      placeholder="Pesquise por IMEI/SN..." />

    <div
      v-if="searchQuery.length > 0"
      class="tp-Ongoing__results-section">
      <h2 class="tp-Ongoing__title">Resultado da pesquisa</h2>

      <div
        v-if="searchData.loading && searchData.data.length === 0"
        class="loading-panel">
        <span>A carregar...</span>
      </div>

      <ongoing-table
        v-if="searchData.data.length > 0"
        v-loading="searchData.loading"
        :data="searchData.data"
        @update="fetchPage" />

      <!-- pagination -->
      <el-pagination
        :current-page="searchData.page"
        :total="searchData.total"
        :page-size="20"
        class="tp-Ongoing__pagination"
        layout="prev, pager, next"
        @current-change="searchPageChangeHandler" />
    </div>
  </div>

  <div class="tp-ongoing__section">
    <h2 class="tp-Ongoing__title">Em progresso</h2>

    <ongoing-table
      v-if="data.length > 0"
      v-loading="loading"
      :data="data"
      @update="fetchPage" />

    <!-- pagination -->
    <el-pagination
      :current-page="currentPage"
      :total="total"
      :page-size="20"
      class="tp-Ongoing__pagination"
      layout="prev, pager, next"
      @current-change="currentPageChange" />
  </div>
</section>
