
<div
  v-if="requests.length"
  class="tp-RepairView__entry is-materialRequests">
  <h2 class="title">Material Pedido</h2>

  <div class="content">
    <el-table :data="requests">
      <el-table-column
        :width="70"
        prop="quantity"
        label="Qtn."/>

      <el-table-column
        :with="250"
        label="Descrição">
        <template slot-scope="scope">
          <span>{{ scope.row.material | formatMaterialName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Estado">
        <template slot-scope="scope">
          <span>{{ scope.row.state | formatRequestState }}</span>
        </template>
      </el-table-column>

      <!-- request date -->
      <el-table-column
        label="Data Pedido"
        align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt | dateFormat("dd 'de' MMMM 'de' yyyy")
          }}</span>
        </template>
      </el-table-column>

      <!-- pruchase date -->
      <el-table-column
        label="Data Compra"
        align="center">
        <template slot-scope="scope">
          <span
            v-if="scope.row.purchase">{{ scope.row.purchase.createdAt | dateFormat("dd 'de' MMMM 'de' yyyy")
            }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Previsão"
        align="center">
        <template slot-scope="scope">
          <span
            v-if="scope.row.purchase">{{ estimateDelivery(scope.row.purchase)
            }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
