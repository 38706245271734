
<script>
import { clone } from "ramda";

// --- Generators --------------------------------------------------------------

function generateLabel(data, children, createElement) {
  if (!Array.isArray(children)) {
    children = [children];
  }

  return createElement(
    "el-form-item",
    {
      props: {
        label: data.label,
        prop: data.name,
      },
    },
    children,
  );
}

function setDefaultValue(data, type) {
  let defValue = "";

  if (this.values[data.name] !== undefined) {
    return;
  }

  if (type === "boolean") {
    defValue = false;
  }

  // set the default value
  this.$set(this.values, data.name, data.default ? data.default : defValue);
}

function generateInputField(data, createElement) {
  // set a new reactive property for the new field
  setDefaultValue.call(this, data, "text");

  const generated = createElement("el-input", {
    props: {
      placeholder: data.label,
      disabled: this.readOnly,
      value: this.values[data.name],
    },
    on: {
      input: value => {
        this.updatedValueHandler(data.name, value);
      },
    },
  });

  return generateLabel(data, generated, createElement);
}

function generateLongTextField(data, createElement) {
  // set a new reactive property for the new field
  setDefaultValue.call(this, data, "text");

  const generated = createElement("el-input", {
    props: {
      placeholder: data.label,
      disabled: this.readOnly,
      value: this.values[data.name],
    },
    domProps: {
      type: "textarea",
    },
    on: {
      input: value => {
        this.updatedValueHandler(data.name, value);
      },
    },
  });

  return generateLabel(data, generated, createElement);
}

function generateSwitchField(data, createElement) {
  // set a new reactive property for the new field
  setDefaultValue.call(this, data, "boolean");

  const generated = createElement("el-switch", {
    props: {
      placeholder: data.label,
      disabled: this.readOnly,
      value: this.values[data.name],
    },
    on: {
      input: value => {
        this.updatedValueHandler(data.name, value);
      },
    },
  });

  // generate a label
  return generateLabel(data, generated, createElement);
}

function generateSelectField(data, createElement) {
  // set a new reactive property for the new field
  setDefaultValue.call(this, data, "text");

  // generate all select items
  const optionEl = this._l(data.values, item => {
    return createElement("el-option", {
      key: item.value,
      props: {
        label: item.title,
        value: item.value,
      },
    });
  });

  // generate the select component
  const generated = createElement(
    "el-select",
    {
      props: {
        placeholder: data.label,
        disabled: this.readOnly,
        value: this.values[data.name],
      },
      on: {
        input: value => {
          this.updatedValueHandler(data.name, value);
        },
      },
    },
    [optionEl],
  );

  // generate a label
  return generateLabel(data, generated, createElement);
}

function generateColContainer(data, generatedElement, createElement) {
  return createElement(
    "el-col",
    {
      props: {
        span: data.size || 24,
      },
    },
    [generatedElement],
  );
}

function generateImageSelectField(data, createElement) {
  // set a new reative property for the new field
  setDefaultValue.call(this, data, "text");

  const generated = createElement("ui-image-select", {
    props: {
      data: data.values,
      selected: this.values[data.name],
      disabled: this.readOnly,
    },
    on: {
      input: value => {
        this.updatedValueHandler(data.name, value);
      },
    },
  });

  // generate a label
  return generateLabel(data, generated, createElement);
}

function generatePatternField(data, createElement) {
  // set a new reactive property for the new field
  setDefaultValue.call(this, data, "text");

  const generated = createElement("ui-pattern", {
    props: {
      disabled: this.readOnly,
      value: this.values[data.name],
    },
    on: {
      input: value => {
        this.updatedValueHandler(data.name, value);
      },
    },
  });

  // generate a label
  return generateLabel(data, generated, createElement);
}

// hash with all available generators
const generators = {
  text: generateInputField,
  switch: generateSwitchField,
  select: generateSelectField,
  longtext: generateLongTextField,
  imageSelect: generateImageSelectField,
  pattern: generatePatternField,
};

// --- Component ---------------------------------------------------------------

let rules = {};

export default {
  name: "EquipmentDynamicForm",

  replace: true,

  props: {
    equipment: {
      type: Object,
      default: () => null,
    },
    moment: {
      type: String,
      default: "normal",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: {},
    };
  },

  watch: {
    /**
     * When the prop value change update the internal values.
     */
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.values = clone(newVal);
      },
    },

    readOnly() {
      this.$refs.form.resetFields();
    },
  },

  methods: {
    getField(name) {
      return this.equipment.attributes.find(item => item.name === name);
    },

    updatedValueHandler(name, value) {
      this.$set(this.values, name, value);

      // Inform the data change to the outside world
      this.$emit("input", this.values);

      // check if we need to execute any action
      const field = this.getField(name);
      try {
        const action = field.values.find(item => item.value === value).action;
        // tslint:disable-next-line
        const fn = new Function(action);
        fn.call(this);
      } catch (_) {
        // Do nothing
      }
    },

    /**
     * Validate the values using the rules array.
     */
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(error => {
          if (!error) {
            return reject(error);
          }

          resolve();
        });
      });
    },

    /**
     * Get a field name.
     *
     * @param fieldName
     */
    getValue(fieldName) {
      return this.values[fieldName];
    },

    /**
     * Process the visibility attribute.
     *
     * @param attribute
     * @returns {*}
     */
    processVisibility(attribute) {
      // ignore undefined field, this ones always are shown
      if (attribute.visible === undefined) {
        return true;
      }

      // when boolean
      if (typeof attribute.visible === "boolean") {
        return attribute.visible === false;
      }

      // FIXME: fix the issues with the following line
      // tslint:disable-next-line
      const fn = new Function(attribute.visible);
      return fn.call(this);
    },
  },

  render(createElement) {
    if (!this.equipment) {
      return createElement("p", "Selecione um tipo de equipamento!");
    }

    // reset rules
    rules = {};

    // array of children
    const children = [];

    // filter the attributes by momment
    const attributes = (this.equipment.attributes || []).filter(item => item.moment === this.moment);

    // iterate all elements
    let generatedField = null;

    for (const index in attributes) {
      if (!attributes.hasOwnProperty(index)) {
        continue;
      }

      // get the attribite object
      const attribute = attributes[index];

      // if we are in readOnly mode we need to check if we must need to build
      // the component
      if (this.readOnly && attribute.showInReadOnly === false) {
        continue;
      }

      // ignore field when visible is equals to false
      if (this.processVisibility(attribute) === false) {
        continue;
      }

      // check if the generator exists
      if (generators[attribute.type] === undefined) {
        // eslint-disable-next-line
        console.error(`There is no generator for the ${attribute.type} type`);
        continue;
      }

      // append require validation is requested
      if (!this.readOnly && attribute.required === true) {
        rules[attribute.name] = {
          required: true,
          message: "Campo obrigatorio",
          trigger: "blur",
        };
      }

      // generate the correspondent element
      generatedField = generators[attribute.type].call(this, attribute, createElement);

      // wrap the element arrond a el-row
      generatedField = generateColContainer.call(this, attribute, generatedField, createElement);

      // push the new element to the list of elements
      children.push(generatedField);
    }

    // create a row element
    const row = createElement(
      "el-row",
      {
        props: { gutter: 10 },
      },
      children,
    );

    return createElement(
      "el-form",
      {
        props: {
          rules,
          model: this.values,
        },
        ref: "form",
      },
      [row],
    );
  },
};
</script>
