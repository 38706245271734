/**
 * This vuex module implements all the actions that are related with the
 * technician work.
 */

import stellar from "@/services/stellar";
import { getStore } from "../index";

// errors
const ERROR_ALREADY_OPEN = "Já existe uma reparação aberta.";

// mutations
const WORK_SET_LIST = "WORK_SET_LIST";
const WORK_SET_ERROR = "WORK_SET_ERROR";
const WORK_SET_LOADING = "WORK_SET_LOADING";
const WORK_UPDATE_SINGLE = "WORK_UPDATE_SINGLE";
const WORK_REMOVE = "WORK_REMOVE";
const WORK_SET_LAST_UPDATE = "workSetLastUpdate";

const state = {
  error: null,
  loading: false,
  work: [],
  lastUpdate: null,
};

const mutations = {
  [WORK_SET_LOADING](state, value) {
    state.loading = value;
  },

  [WORK_SET_LIST](state, list) {
    state.work = list;
  },

  [WORK_SET_ERROR](state, value) {
    state.error = value;
  },

  [WORK_UPDATE_SINGLE](state, repair) {
    // find the repair index
    const index = state.work.findIndex(item => item.id === repair.id);

    if (index === undefined) {
      // eslint-disable-next-line
      console.error("[WORK_UPDATE_SINGLE] invalid state");
      return;
    }

    // update the repair
    const work = state.work;
    work[index] = repair;

    // HACK: force reload on VUEX
    state.work = JSON.parse(JSON.stringify(work));
  },

  [WORK_REMOVE](state, id) {
    // find the repair index
    const index = state.work.findIndex(item => item.id === id);

    // remove work from the list
    state.work.splice(index, 1);
  },

  [WORK_SET_LAST_UPDATE](state, date) {
    state.lastUpdate = date;
  },
};

const actions = {
  /**
   * Get the logged technician work.
   *
   * When the force parameter is true the work array must be cleaned and filled
   * again.
   *
   * @type {boolean} force When this is true we force the work request.
   */
  async getWork({ state, commit }, force = false) {
    // if the force parameter is false and the work is already filled, return
    if (state.loading || (!force && state.lastUpdate !== null)) {
      return state.work;
    }

    commit(WORK_SET_LOADING, true);

    try {
      const { repairs } = await stellar.action("getLoggedTechnicianWork");
      commit(WORK_SET_LIST, repairs);
      commit(WORK_SET_LAST_UPDATE, new Date());
      commit(WORK_SET_LOADING, false);

      return repairs;
    } catch (error) {
      // TODO: handle this correctly
    }

    commit(WORK_SET_LOADING, false);
  },

  /**
   * Put a single repair into a pause state.
   *
   * @param {string} workId work identifier
   */
  async putWorkInPause({ commit, state }, workId) {
    commit(WORK_SET_ERROR, null);
    commit(WORK_SET_LOADING, true);

    if (!workId) {
      workId = state.work.find(item => item.state === "in_repair");
      if (!workId) {
        throw new Error("Invalid function call.");
      }

      workId = workId.id;
    }

    try {
      const { repair } = await stellar.action("putRepairInPause", {
        repair: workId,
      });
      commit(WORK_UPDATE_SINGLE, repair);
    } catch (error) {
      throw error;
    } finally {
      commit(WORK_SET_LOADING, false);
    }
  },

  /**
   * Put a single work in repair.
   *
   * @param {string} workId work identifier
   */
  async putWorkInRepair({ commit, getters }, workId) {
    let force = false;
    if (Array.isArray(workId)) {
      force = workId[1];
      workId = workId[0];
    }

    commit(WORK_SET_ERROR, null);

    // check if there is already a repair in execution
    if (getters.workHasOpenRepair) {
      commit(WORK_SET_ERROR, ERROR_ALREADY_OPEN);
      throw new Error(ERROR_ALREADY_OPEN);
    }

    commit(WORK_SET_LOADING, true);

    const { repair } = await stellar.action("putRepairInRepair", {
      repair: workId,
      force,
    });
    commit(WORK_UPDATE_SINGLE, repair);
    commit(WORK_SET_LOADING, false);
  },

  putWorkToDelivery({ commit, getters }, [workId, data]) {
    // clean any error
    commit(WORK_SET_ERROR, null);

    // enter in loading mode
    commit(WORK_SET_LOADING, true);

    // make an API call to put the work into delivery state
    return (
      stellar
        .action("putRepairForDelivery", {
          repair: workId,
          repairObservation: data.observation,
          value: data.value,
          withoutPermission: data.withoutPermission,
          sendNotification: data.sendNotification,
          fixed: data.fixed,
        })

        // after the action execution remove it from the work list
        .then(_ => {
          // remove work
          commit(WORK_REMOVE, workId);

          // finish the loading state
          commit(WORK_SET_LOADING, false);
        })
    );
  },

  async putWorkInTransit({ commit, getters }, [workId, data]) {
    commit(WORK_SET_ERROR, null);
    commit(WORK_SET_LOADING, true);

    // make an API call to put the work into delivery state
    await stellar.action("editRepair", {
      id: workId,
      repairObservation: data.observation,
      value: data.value,
      state: "in_transit",
    });

    // after the action execution remove it from the work list
    commit(WORK_REMOVE, workId);
    commit(WORK_SET_LOADING, false);
  },

  /**
   * Close a repair without resolution.
   *
   * @param  {object} data Data.
   * @return {Promise}
   */
  closeWithoutRepair({ commit }, data) {
    // enter in loading mode
    commit(WORK_SET_LOADING, true);

    // make an API call to update the data on the server
    stellar.action("closedWithoutRepair", data).then(_ => {
      // remove work
      commit(WORK_REMOVE, data.id);

      // finish the loading state
      commit(WORK_SET_LOADING, false);
    });
  },

  /**
   * Create a new contact request.
   */
  createContactRequest({ commit }, data) {
    // enter in loading mode
    commit(WORK_SET_LOADING, true);

    return stellar.action("createClientContactRequest", data).then(_ => {
      // remove the work from the technician list
      commit(WORK_REMOVE, data.repair);

      // finish the loading state
      commit(WORK_SET_LOADING, false);
    });
  },

  /**
   * Create a quotation for the given repair.
   *
   * @param {string} repair Repair identifier
   * @param {object} data Quotation data
   */
  async createQuotation({ commit }, [repair, data]) {
    commit("WORK_SET_LOADING", true);

    await stellar.action("createQuotation", {
      repair,
      ...data,
    });

    commit(WORK_REMOVE, repair);
    commit(WORK_SET_LOADING, false);
  },

  putWorkInTests({ commit }, repairId) {
    // enter in loading mode
    commit("WORK_SET_LOADING", true);

    return stellar.action("putRepairInTests", { repair: repairId }).then(({ repair }) => {
      // update a single work
      commit("WORK_UPDATE_SINGLE", repair);

      // finish the loading state
      commit(WORK_SET_LOADING, false);
    });
  },

  /**
   * Approve a repair quotation.
   *
   * @deprecated use repair store instead
   * @param {string} repair - Repair with the quotation to approve.
   */
  async approveQuotation({ commit, dispatch }, [repair, value]) {
    // enter in loading mode
    commit("WORK_SET_LOADING", true);

    await stellar.action("approveQuotation", {
      repair,
      value,
    });

    commit(WORK_SET_LOADING, false);
  },

  /**
   * Reject a repair quotation.
   *
   * @param {string} repair - Repair identifier.
   */
  rejectQuotation({ commit }, repair) {
    // enter in loading mode
    commit("WORK_SET_LOADING", true);

    return stellar.action("rejectQuotation", { repair }).then(({ repair }) => {
      // finish the loading state
      commit(WORK_SET_LOADING, false);
    });
  },

  /**
   * Remove a repair from the work list.
   *
   * @param {VuexStore} store
   * @param {*} repairId Identifier of the repair to be removed.
   */
  removeWork({ commit }, repairId) {
    commit(WORK_REMOVE, repairId);
  },
};

const getters = {
  /**
   * Check if there is a open repair.
   */
  workHasOpenRepair(state) {
    return state.work && state.work.find(repair => repair.state === "in_repair") !== undefined;
  },

  workList: state => state.work,
  workError: state => state.error,
  workLoading: state => state.loading,
  workHasUrgent(state) {
    return state.work && state.work.filter(item => item.priority === "urgent").length > 0;
  },
  workLastUpdate: state => state.lastUpdate,
};

// Catch work remove events
stellar.from("updates").on("removeRepairFromWork", async data => {
  const store = await getStore();
  const user = store.getters.user;

  if (!user || data.technician !== user.personId) {
    return;
  }

  store.dispatch("removeWork", data.repair);
});

export default {
  actions,
  getters,
  mutations,
  state,
};
