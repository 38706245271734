<template>
  <div class="tp-clientForm_wrap">
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="saveClientHandler">
          Guardar
        </el-button>

        <el-button @click="dismiss">Cancelar</el-button>
      </div>

      <!-- error alert ------------------------------------------------------- -->
      <el-alert
        v-if="showError"
        class="tp-clientForm_alert"
        type="error"
        title="Existem erros no formulario."/>

      <!-- error alert phone ------------------------------------------------- -->
      <el-alert
        v-if="errorMessage"
        :title="errorMessage"
        type="error"/>

      <!-- form -------------------------------------------------------------- -->
      <el-form
        ref="client"
        :model="client"
        :rules="rules"
        label-position="top">
        <el-row :gutter="20">
          <!-- name -->
          <el-col :span="12">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="client.name"
                placeholder="Nome"/>
            </el-form-item>
          </el-col>

          <!-- tax-number -->
          <el-col :span="9">
            <el-form-item
              label="NIF"
              prop="taxNumber">
              <el-input
                v-model="client.taxNumber"
                type="number"
                placeholder="NIF"/>
            </el-form-item>
          </el-col>

          <!-- isStore -->
          <el-col :span="3">
            <el-form-item label="Loja" >
              <el-switch
                v-model="client.isStore"
                on-text=""
                off-text=""/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- street -->
          <el-col :span="12">
            <el-form-item label="Rua">
              <el-input
                v-model="client.street"
                placeholder="Rua"/>
            </el-form-item>
          </el-col>

          <!-- city -->
          <el-col :span="12">
            <el-form-item label="Cidade">
              <el-input
                v-model="client.city"
                placeholder="Cidade"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- zip-code -->
          <el-col :span="12">
            <el-form-item
              label="Código Postal (CP)"
              prop="zipCode">
              <el-input
                v-model="client.zipCode"
                placeholder="Código Postal"/>
            </el-form-item>
          </el-col>

          <!-- location -->
          <el-col :span="12">
            <el-form-item label="Localidade">
              <el-input
                v-model="client.location"
                placeholder="Localidade"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- contacts -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Telemovel"
              prop="phone">
              <el-input
                v-model="client.phone"
                placeholder="Telemovel" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email">
              <el-input
                v-model="client.email"
                placeholder="Email"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- contact preference -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Contacto Preferencial">
              <el-select v-model="client.contactPreference">
                <el-option
                  label="Chamada"
                  value="phone"/>
                <el-option
                  label="SMS"
                  value="sms"/>
                <el-option
                  label="Email"
                  value="email"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Intervalo de Preferencia">
              <el-col :span="5">
                <el-time-select
                  v-model="client.contactHourStart"
                  :picker-options="{
                    start: '07:00',
                    step: '00:15',
                    end: '22:45'
                  }"
                  placeholder="Inicio"/>
              </el-col>

              <el-col
                :span="8"
                class="tp-clientForm_line">-</el-col>

              <el-col :span="5">
                <el-time-select
                  v-model="client.contactHourEnd"
                  :picker-options="{
                    start: '07:00',
                    step: '00:15',
                    end: '23:00',
                    minTime: client.contactHourStart
                  }"
                  placeholder="Fim"/>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Group de clientes" >
              <el-select v-model="client.customerGroup" placeholder="Group" class="customerGroup">
                <el-option
                  v-for="item in groups"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Preferencias do cliente" >
              <el-select
                v-model="client.labels"
                class="clientLabels"
                filterable
                multiple
                allow-create
                default-first-option
                placeholder="Labels">
                <el-option
                  v-for="item in customerLabels"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import pipe from "ramda/src/pipe";
import clone from "ramda/src/clone";
import dissoc from "ramda/src/dissoc";

import { ECustomerGroupActions } from "@/modules/clients/services/store/customer-groups/customer-groups.types";
import { ELabelActions } from "@/modules/common/services/store/labels/labels.types";
import { doFormValidation } from "@/utils/form-validation";

// TODO: add form validation
export default {
  data() {
    return {
      loading: false,
      client: {
        id: null,
        name: "",
        street: "",
        location: "",
        zipCode: "",
        city: "",
        taxNumber: 999999999,
        isStore: false,
        phone: "",
        email: "",
        contactHourStart: "09:00",
        contactHourEnd: "19:00",
        contactPreference: "sms",
        customerGroup: "",
        labels: [],
      },

      rules: {
        name: [
          {
            required: true,
            message: "Introduza o nome do cliente",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            pattern: /\d{9}.*/,
            message: "O telefone de contacto é obrigatório",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Verifique o formato do email inserido",
            trigger: "blur",
          },
        ],
        zipCode: [
          {
            pattern: /\d{4}-\d{3}/,
            message: `O codigo postal deve obdecer ao formato "1234-123"`,
            trigger: "blur",
          },
        ],
        taxNumber: [
          {
            pattern: /\d{9}/,
            message: "O NIF deve ter 9 exatamente digitos",
            trigger: "blur",
          },
        ],
      },
      showError: false,

      errorMessage: null,

      edit: false,
    };
  },

  computed: {
    minTime() {
      const parts = this.client.contactHourStart.split(":");
      return parts[0] + ":" + parts[1] + 15;
    },
    groups: get("customerGroups/groups"),
    customerLabels: get("labels/labels"),
  },

  watch: {
    "client.phone"(newVal, oldVal) {
      if (this.client.contactPreference === "email") {
        return;
      }

      if (newVal.length === 0) {
        this.client.contactPreference = "sms";
        return;
      }

      if (["3", "2"].includes(newVal[0])) {
        this.client.contactPreference = "phone";
      }
    },
  },

  created() {
    this.$store.dispatch(ECustomerGroupActions.FETCH_ALL);
    this.$store.dispatch(ELabelActions.FETCH_ALL);
  },

  methods: {
    /**
     * Receives a value and assign to the fill that is must compatible with.
     */
    setData(value) {
      // only set the phone number if the value is a valid number
      if (!value || isNaN(parseInt(value, 10))) {
        return;
      }

      this.client.phone = String(value);
    },

    phoneValidate() {
      this.errorMessage = null;

      this.$nextTick(() => {
        this.errorMessage =
          "O numero de telefone precisa de um minimo 9 digitos";
      });
    },
    dismiss() {
      this.show(false);
      this.$emit("dismiss", false);
    },
    /**
     * Define the client's data to be edited.
     *
     * @type {Object} client client data
     */
    setClient(client) {
      this.client = JSON.parse(JSON.stringify(client));
      this.edit = true;
    },

    /**
     * Define the visibility.
     *
     * @type {Boolean} val component visibility.
     */
    show(val = true) {
      this.innerShow = val;
      this.$refs.editSheet.show(val);

      if (!val) {
        this.edit = false;

        // reset the model data
        this.reset();
      }
    },

    /**
     * Cick handler for the save button.
     */
    async saveClientHandler() {
      this.loading = true;
      this.showError = false;

      try {
        await doFormValidation(this.$refs.client);
      } catch (_) {
        this.loading = false;
        this.showError = true;
        return false;
      }

      if (!this.edit) {
        this.addClient();
      } else {
        this.saveChangesClient();
      }
    },

    /**
     * Save changes in a client.
     */
    async saveChangesClient() {
      const updatedClient = {
        ...this.client,
        id: this.client.id || this.client._id,
      };

      try {
        const { client } = await this.$stellar.action(
          "editClient",
          updatedClient,
        );

        this.$emit("updated", client);
        this.show(false);
        this.$message.success("Client atualizado");
      } catch ({ error }) {
        // check if is a duplicated error
        if (error === "duplicated_client") {
          return this.onDuplicateError();
        }

        this.showError = true;
      } finally {
        this.loading = false;
      }
    },

    /**
     * Create a new client.
     */
    async addClient() {
      this.loading = true;

      const newClient = pipe(
        clone,
        dissoc("id"),
      )(this.client);

      try {
        const response = await this.$stellar.action("createClient", newClient);

        this.$emit("created", response.client);
        this.show(false);
        this.$message.success("Client criado");
      } catch (response) {
        // check if is a duplicated error
        if (response.error === "duplicated_client") {
          return this.onDuplicateError();
        }

        this.showError = true;
      } finally {
        this.loading = false;
      }
    },

    /**
     * Show a error message to inform the user that are creating a duplicated
     * client.
     */
    onDuplicateError() {
      this.$alert(
        "Existe um cliente com os mesmos dados de contacto.",
        "Duplicado",
        {
          confirmButtonText: "OK",
        },
      );
    },

    /**
     * Reset the form data.
     */
    reset() {
      this.client = {
        name: "",
        street: "",
        location: "",
        zip_code1: 0,
        zip_code2: 0,
        city: "",
        taxNumber: null,
        type: "",
        phone: 0,
        email: "",
        contactHourStart: "09:00",
        contactHourEnd: "19:00",
        contactPreference: "sms",
        customerGroup: null,
        labels: "",
        loading: false,
      };

      // reset other vars
      this.loading = false;
      this.showError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tp-clientForm_line {
  text-align: center;
}

.tp-clientForm_wrap .tp-clientForm_alert {
  margin-bottom: 20px;
}

.customerGroup,
.clientLabels {
  display: block;
  width: 100%;
}
</style>
