
<div class="tp-Complaint">
  <!-- Atribute Responsability --------------------------------------------- -->
  <el-dialog
    ref="complaintResponsability"
    :visible.sync="dialogVisible"
    title="Atribuição de responsabilidade"
    size="tiny">
    <el-select
      v-model="value"
      :style="{ width: '100%' }">
      <el-option
        v-for="item in responsability"
        :key="item.id"
        :label="item.label"
        :value="item.value"/>
    </el-select>

    <span
      slot="footer"
      class="dialog-footer">
      <!-- cancel button -->
      <el-button @click="dialogVisible = false">Cancelar</el-button>

      <!-- set resonsability -->
      <el-button
        type="primary"
        @click="takeResponsability()">
        Avaliar
      </el-button>
    </span>
  </el-dialog>

  <div class="tp-Complaint">
    <el-button
      class="tp-Complaint__button"
      @click="handleButtonClick">
      Reclamações Abertas
    </el-button>

    <el-table
      v-loading="loading"
      :data="repairs"
      stripe
      style="width: 100%">
      <!-- repair number -->
      <el-table-column
        :width="85"
        prop="repairNumber"
        label="#"/>

      <!-- store number -->
      <el-table-column
        :width="120"
        label="Ficha Loja"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.storeRepairIdentifier || '-' }}
        </template>
      </el-table-column>

      <!-- show the box where the equipment is stored -->
      <el-table-column
        :width="80"
        align="center"
        label="Caixa">
        <template slot-scope="scope">
          {{ scope.row.storeBox || '-' }}
        </template>
      </el-table-column>

      <!-- equipment brand and model -->
      <el-table-column
        :width="150"
        label="Equipamento">
        <template slot-scope="scope">
          <span>{{ scope.row.clientEquipment.brand }} {{ scope.row.clientEquipment.model }}</span>
        </template>
      </el-table-column>

      <!-- client -->
      <el-table-column
        :width="200"
        label="Cliente">
        <template slot-scope="scope">
          <a @click="seeClient(scope.row.client)">
            <span v-if="scope.row.client.isStore">{{ scope.row.client.name | truncate(20) }}</span>
            <span v-else>{{ scope.row.client.name }}</span>
          </a>
        </template>
      </el-table-column>

      <!-- repair state -->
      <el-table-column
        :width="150"
        label="Orçamento">
        <template slot-scope="scope">
          <span>{{ scope.row.quotation | money }}</span>
        </template>
      </el-table-column>

      <!-- problem -->
      <el-table-column
        :width="200"
        label="Avaria"
        align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.problemName }}</span>
        </template>
      </el-table-column>

      <!-- operations -->
      <el-table-column
        :width="190"
        :context="_self"
        label="Operações">
        <template slot-scope="scope">
          <!-- actions -->
          <el-button
            type="info"
            size="small"
            @click="seeRepairHandler(scope.row)">
            Ver
          </el-button>

          <!-- set responsability -->
          <el-button
            v-if="$auth.is('admin')"
            type="success"
            size="small"
            @click="showAtribResponsabilityHandler(scope.row)">
            Avaliar
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
