
<section class="tp-Container">
  <Sidebar :items="menuItemsOrdered" />

  <!-- main area -->
  <div class="tp-dashboard_content">
    <!-- application header -->
    <Header/>

    <!-- dynamic view -->
    <router-view/>
  </div>

  <!-- quick panel --------------------------------------------------------- -->
  <quick-panel :sections="menuItemsOrdered"/>

  <SpacePortal name="baseFooter" />
</section>
