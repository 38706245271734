<template>
  <section
    v-loading="loading"
    class="tp-DalyCommissions">
    <div class="header">
      <el-form label-positon="top">
        <el-form-item label="Mês de Comissões">
          <el-date-picker
            v-model="selectedDate"
            :clearable="false"
            disabled
            type="month"
            placeholder="Selecione um mês"
            class="tp-DaylyCommissions__select" />
        </el-form-item>
      </el-form>

      <div class="tp-DaylyCommissions__day-commissions">
        <span class="title">Comissões diárias</span>
        <span class="total-day"> {{ todayRepairs }} €</span>
      </div>
    </div>

    <!-- warning for when there is no commissions for the selected mounth -->
    <el-alert
      v-if="commissions.length === 0"
      :closable="false"
      title="Não existem comissões para o mês selecionado."
      show-icon
      type="info" />

    <div
      v-if="commissions.length > 0"
      id="toPrint">
      <!-- name -->
      <div class="tp-DaylyCommissions__name">
        {{ technician.name }}
      </div>

      <!-- date -->
      <div class="tp-DaylyCommissions__date">
        Data: {{ selectedDate | dateFormat('yyyy/MM') }}
      </div>

      <el-table
        :data="commissions"
        stripe
        class="tp-DaylyCommissions__table"
        style="width: 100%">
        <el-table-column
          :width="100"
          label="#REP">
          <template slot-scope="scope">
            {{ scope.row.repair.repairNumber }}
          </template>
        </el-table-column>
        <el-table-column label="Equipamento">
          <template slot-scope="scope">
            {{ scope.row.repair.clientEquipment.brand }} {{ scope.row.repair.clientEquipment.model }}
          </template>
        </el-table-column>
        <el-table-column label="Valor da Reparação">
          <template slot-scope="scope">
            {{ scope.row.repairValue | money }}
          </template>
        </el-table-column>
        <el-table-column label="Material">
          <template slot-scope="scope">
            {{ scope.row.totalMaterial | money }}
          </template>
        </el-table-column>
        <el-table-column label="A receber">
          <template slot-scope="scope">
            {{ scope.row.value | money }}
          </template>
        </el-table-column>
      </el-table>

      <span>Valor total de reparações: <b>{{ totalRepairs | money }}</b></span>
      <br>
      <span>Valor total em material s/iva: <b> {{ totalMat | money }} </b> Total com IVA = <b>{{ totalMat * 1.23 | money }}</b></span>
      <br>
      <div v-if="numberOfCommissions <= 80">
        <span>Fixo (1,5€ por reparação até 80 reparações com o valor de =) <b> {{ numberOfCommissions }} </b> * 1.5 = <b>{{ numberOfCommissions * 1.5 | money }}</b> </span>
      </div>
      <div v-if="numberOfCommissions > 80">
        <span>Fixo (1,5 € por reparações até 80 reparações com o valor de =) <b> 120 €</b></span>
      </div>
      <br>
      <span>Penalizações: <b>  A calcular </b></span>
      <br>
      <span>Valor das comissões a deduzir por devolução:  <b> {{ (totalDevolution * -1) | money }} </b></span>
      <br>
      <div v-if="totalCommissions <= 80">
        <span>Valor da comissão:<b> {{ total | money }}</b></span>
      </div>
      <br>
      <span>Valor final: {{ total | money }}  {{ totalDevolution }} = <b> {{ total + totalDevolution | money }} </b></span>
      <br>
      <span>O valor da comissão é calculado usando a seguinte formula: (TotalReparações - ValorMaterial * 1.23 - Valor fixo) *  <b>{{ commission }} %</b></span>
      <span>Ao valor final da comissão poderão ser retiradas as penalizações por reclamações e devoluções.</span>
      <span>As reclamações em que a responsabilidade está por confirmar poderão transitar para o mês em que for definida a responsabilidade.</span>
    </div>
  </section>
</template>
<script>
import { isSameDay } from "date-fns";
import { mapGetters } from "vuex";
import { now } from "@/services/fp/date";

export default {
  data() {
    return {
      loading: false,
      selectedDate: new Date(),

      technician: "",
      commissions: [],
      total: 0,
      totalCommissions: 0,
      percentageCommission: [],
    };
  },

  computed: {
    ...mapGetters(["user"]),

    commission() {
      return this.percentageCommission[0].value;
    },

    getTechnician() {
      this.technician = this.user.personId; // eslint-disable-line
      return this.technician;
    },
    totalRepairs() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalRepairs, row) => {
        if (row.value <= 0) {
          return totalRepairs;
        }

        return totalRepairs + row.repairValue;
      }, 0);
    },
    totalMat() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalMat, row) => totalMat + row.totalMaterial, 0);
    },
    numberOfCommissions() {
      return this.commissions.filter(item => item.value > 0).length;
    },
    totalDevolution() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalRepairs, row) => {
        if (row.value > 0) {
          return totalRepairs;
        }

        return totalRepairs + row.value;
      }, 0);
    },
    todayRepairs() {
      const today = new Date();
      const commissions = this.commissions;

      // get today commissions
      const todayCommissions = commissions.filter(item => isSameDay(new Date(item.createdAt), now()));
      return todayCommissions.reduce((totalDay, row) => totalDay + row.value, 0);
    },

    /**
     * Get the last day of the year.
     */
    lastDayOfMounth() {
      return new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    },
  },

  watch: {
    selectedDate(newVal) {
      this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    /**
     * Fetch the data for the selected date.
     */
    async fetchData() {
      const configs = await this.$stellar.action("getConfigs");
      this.percentageCommission = configs.configs.filter(config => config.key === "commission.percentage");

      this.technician = this.getTechnician;
      this.loading = true;

      // get first day of the selected mounth
      const firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);

      // Request the data to the API
      const response = await this.$stellar.action("getCommissionForTechnician", {
        technician: this.technician,
        start: firstDay,
        end: this.lastDayOfMounth,
      });

      this.commissions = response.repairs;
      this.total = response.total;
      this.totalCommissions = this.commissions.length;

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tp {
  &-DalyCommissions {
    &__select {
      width: 100% !important;
    }

    &__name {
      font-size: 1.5em;
      margin-bottom: 5px;
    }

    &__date {
      margin-bottom: 10px;
    }

    &__table {
      margin-bottom: 10px;
    }
  }
}

.tp-DalyCommissions {
  position: relative;
  left: 50%;
  padding-bottom: 20px;
  max-width: 1140px;

  transform: translateX(-50%);
}

.tp-DaylyCommissions__day-commissions {
  margin-left: 30px;
}

.tp-DaylyCommissions__day-commissions .title {
  position: relative;
  display: block;
  margin-bottom: 7px;
  padding: 1px 12px 0 0;

  font-size: 14px;
  line-height: 36px;
  color: #48576a;
}

.total-day {
  position: relative;
  display: block;
  padding: 7px 10px;

  font-size: 14px;
  text-align: center;
  color: #757575;

  border: 1px solid #54c688;
  border-radius: 2px;
  background-color: rgb(244, 255, 249);
}

.header {
  widows: auto;
  height: auto;
  display: inline-flex;
}

.tp-DaylyCommissions__table {
  margin-bottom: 15px;
}
</style>
