
<section class="tp-store-profits-table">
  <el-table
    v-loading="loading"
    :data="profits"
    style="width: 100%">

    <el-table-column
      prop="_id"
      label="Loja"/>

    <el-table-column
      :width="180"
      prop="numRepairs"
      label="Num.Rep" />

    <el-table-column
      :width="180"
      label="Material s/Iva">
      <template slot-scope="{ row }">
        {{ row.totalMaterial | money }}
      </template>
    </el-table-column>

    <el-table-column
      :width="180"
      label="Reparação c/Iva">
      <template slot-scope="{ row }">
        {{ row.totalRepair | money }}
      </template>
    </el-table-column>

    <el-table-column
      :width="180"
      label="Lucro">
      <template slot-scope="{ row }">
        {{ row.profit | money }}
      </template>
    </el-table-column>
  </el-table>

  <div class="tp-store-profits-table__total">
    <strong>Lucro total:</strong> {{ calcTotal() | money }}
  </div>
</section>
