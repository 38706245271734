<template>
  <el-dialog
    :visible.sync="isVisible"
    title="Tecnicos">
    <div class="elements-wrap">
      <el-table :data="technicians" style="width: 100%">
        <el-table-column label="Nome" prop="name"/>
        <el-table-column align="right">
          <template slot-scope="scope">
            <el-button
              :loading="isLoading"
              class="selecteButton"
              type="success"
              size="small"
              @click="onSelectHandler(scope.row)">
              {{ buttonsText }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { get } from "vuex-pathify";
import { ETechnicianActions } from "../../modules/technician/services/store/technician/technician.types";
import { ITechnician } from "../../modules/technician/types/technician.interface";

export default Vue.extend({
  name: "SelectTechnicianModal",

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    buttonsText: {
      type: String,
      default: "Selecionar",
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    technicians(): Array<ITechnician> {
      return this.$store.get("technician/technicians");
    },

    isLoading(): boolean {
      return this.$store.get("technician/loading");
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      // prevent infinity loops
      if (newVal === oldVal) {
        return;
      }

      this.isVisible = newVal;
    },

    isVisible(newVal) {
      const notInSync = this.visibility !== newVal;
      // update the outside world if needed
      if (notInSync) {
        this.$emit("update:visibility", newVal);
      }

      // emit a dismiss event when the user doesn't select a technician
      if (notInSync && !newVal) {
        this.$emit("dismiss");
      }
    },
  },

  async created() {
    await this.$store.dispatch(ETechnicianActions.FETCH_ALL);
  },

  methods: {
    onSelectHandler(technician: ITechnician) {
      this.isVisible = false;
      this.$emit("selected", technician);
    },
  },
});
</script>


<style lang="scss" scoped>
.elements-wrap {
  display: flex;
}

.selectElement {
  display: block;
  width: 100%;
}

.selecteButton {
  margin-left: 10px;
}
</style>
