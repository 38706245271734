<template>
  <div class="tp-allCommissions_wrap">
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button @click="show(false)">Cancelar</el-button>
      <el-button @click="printElem">Imprimir</el-button>
    </div>

    <div id="toPrint">
      <el-table
        :data="commissions"
        stripe
        style="width: 100%">
        <el-table-column
          prop="_id"
          label="Client"
          width="180"/>
        <el-table-column
          prop="repairs.length"
          label="Client"
          width="180"/>
        <el-table-column
          prop="value"
          label="Orçamento"
          width="180"/>
        <el-table-column
          prop="totalMaterial"
          label="Material"
          width="180"/>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      commissions: [],
    };
  },
  created() {
    // get date
    const today = new Date();
    // get first day of this month
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    this.$stellar
      .action("getCommissionAllTechnicians", { start: firstDay, end: today })
      .catch(response => {
        // TODO: handle the error
      })
      .then(response => {
        this.commissions = response.commissions;
      });
  },
  methods: {
    printElem() {
      const mywindow = window.open("", "PRINT", "height=400,width=600");
      mywindow.document.write(
        "<html><head><title>" + document.title + "</title>",
      );

      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(document.getElementById("toPrint").innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    },
  },
};
</script>
