<template>
  <el-dialog
    v-loading.fullscreen="loading"
    :visible.sync="visibility"
    class="tp-CloseWithoutReapirDialog"
    title="Fechar reparação">
    <el-form
      ref="form"
      :rules="rules"
      :model="data"
      label-position="top">
      <!-- internal observation -->
      <el-form-item
        label="Observações Internas"
        prop="internalObservation">
        <el-input
          v-model="data.internalObservation"
          type="textarea"
          row="6"/>
      </el-form-item>

      <!-- observation for client -->
      <el-form-item
        label="Observações para o cliente"
        prop="clientObservation">
        <el-input
          v-model="data.clientObservation"
          type="textarea"
          row="6"/>
      </el-form-item>
    </el-form>

    <!-- footer -->
    <span
      slot="footer"
      class="dialog-footer">
      <!-- cancel button -->
      <el-button @click="$emit('cancel')">Cancelar</el-button>

      <!-- save button -->
      <el-button
        type="primary"
        @click="withoutRepairHandler">Fechar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    repair: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,

      data: {
        clientObservation: "",
        internalObservation: "",
      },

      messages: [
        "Não compensa reparar",
        "Sem reparação",
        "Não se arranja material",
        "Outro",
      ],

      rules: {
        clientObservation: {
          required: true,
          message: "Introduza uma observação",
          trigger: "blur",
        },
        internalObservation: {
          required: true,
          message: "Introduza uma observação",
          trigger: "blur",
        },
      },
    };
  },

  watch: {
    visibility(newVal) {
      if (newVal) {
        this.data.reason = "";
        this.data.internalObservation = this.repair.internalObservation;
        this.data.clientObservation = this.repair.repairObservation;
      }
    },
  },

  methods: {
    ...mapActions(["closeWithoutRepair"]),

    /**
     * close repair witout reparation.
     */
    withoutRepairHandler() {
      // apply a trim to the form fields
      this.data.internalObservation = this.data.internalObservation.trim();
      this.data.clientObservation = this.data.clientObservation.trim();

      // validate the forms
      this.$refs.form.validate(valid => {
        if (!valid) {
          return;
        }

        // enable loading
        this.loading = true;

        this.closeWithoutRepair({
          id: this.repair.id,
          ...this.data,
        }).then(_ => {
          // show a success message
          this.$message.success("Reparação fechada.");

          // redirect to the list of repairs
          this.$router.push({ name: "work.list" });

          // disbale loading
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-CloseWithoutReapirDialog {
  }
}
</style>
