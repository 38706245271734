<template>
  <section class="tp-missedCalls_wrap">

    <el-alert
      v-if="errorMessage !== null"
      :title="errorMessage"
      type="error"/>

    <el-table
      :data="calls"
      stripe>
      <el-table-column
        property="origin"
        label="Contacto"/>
      <el-table-column
        property="type"
        label="Tipo Contacto"/>
      <el-table-column
        property="status"
        label="Estado do contacto"/>
      <el-table-column
        property="status"
        label="Descrição"/>

      <div>
        <el-button
          type="info"
          size="small"
          @click.native="addContact()">Adicionar</el-button>
      </div>
    </el-table>

    <!-- form -->
    <el-form label-position="top" >

      <el-form-item label="Tipo de contactos:">
        <el-radio-group
          v-model="type"
          @click.native="errorClean()">
          <el-radio label="telefone">Telefone</el-radio>
          <el-radio label="telemovel">Telemóvel</el-radio>
          <el-radio label="email">Email</el-radio>
        </el-radio-group>
      </el-form-item>



      <el-form-item
        v-if="type === 'telefone'"
        label="Número de Telefone:">
        <el-input
          v-model="origin"
          type="Number"
          class="form-control"
          placeholder="num.telefone"
          @click="errorClean"/>
      </el-form-item>

      <el-form-item
        v-if="type === 'telemovel'"
        label="Número de Telemóvel:">
        <el-input
          v-model="origin"
          type="Number"
          class="form-control"
          placeholder="num.telemóvel"
          @click="errorClean"/>
      </el-form-item>

      <el-form-item
        v-if="type === 'email'"
        label="Email:">
        <el-input
          v-model="origin"
          type="text"
          class="form-control"
          placeholder="email"
          @click="errorClean"/>
      </el-form-item>

      <el-form-item label="Estado do contactos:">
        <el-radio-group
          v-model="status"
          @click.native="errorClean()">
          <el-radio label="done">Efectuado</el-radio>
          <el-radio label="pendent">Pendente</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Relatório:">
        <el-input
          v-model="reason"
          type="textarea"
          rows="5"
          placeholder="Descrição do contacto "
          @click="errorClean"/>
      </el-form-item>

      <div class="button">
        <button
          class="btn btn-info"
          @click="addList">Sair</button>
        <button
          class="btn btn-primary"
          @click="addContact">adicionar</button>
      </div>

    </el-form>
  </section>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      calls: [],
      type: "",
      status: "",
      origin: "",
      reason: "",
      call: "",
      errorMessage: null,
    };
  },
  methods: {
    errorClean() {
      this.$refs.error.show(false);
    },
    addContact() {
      if (
        this.type === "" ||
        this.status === "" ||
        this.origin === "" ||
        this.reason === ""
      ) {
        return this.$refs.error.show();
      }

      this.calls.push({
        type: this.type,
        status: this.status,
        origin: this.origin,
        reason: this.reason,
      });

      this.reason = "";
      this.origin = "";
      this.type = "";
      this.status = "";
    },
    addList() {
      this.$stellar
        .action("createMissedcalls", { calls: this.calls })
        .catch(response => {
          this.$refs.error.show();
        });
      this.calls = [];
    },
  },
};
</script>

<style>
#tittle {
  margin-left: 550px;

  text-orientation: center;
}

.entry {
  width: 50%;
  margin-left: 10px;
}

#title {
  text-align: center;
}
</style>
