
<el-dialog
  v-loading.fullscreen="isLoading"
  :visible.sync="visibility"
  class="change-value-and-quotation-modal"
  title="Editar Valor e/ou Orçamento">
  <el-form label-position="top">
    <el-row>
      <el-col :span="24">
        <el-form-item label="Orçamento">
          <el-input
            v-model="tempQuotation"
            class="change-value-and-quotation-modal__brand"
            type="text"
            placeholder="Orçamento"/>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item
          label="Valor">
          <el-input
            v-model="tempValue"
            v-loading.fullscreen="isLoading"
            class="change-value-and-quotation-modal__model"
            placeholder="Valor"/>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>

  <div
    slot="footer"
    class="dialog-footer">
    <el-button
      v-loading.fullscreen="isLoading"
      @click="closeHandler">
      Cancelar
    </el-button>
    <el-button
      v-loading.fullscreen="isLoading"
      type="primary"
      @click="updateValueQuotationHandler">
      Alterar
    </el-button>
  </div>
</el-dialog>
