<template>
  <section class="tp-store-profits-table">
    <el-table
      v-loading="loading"
      :data="profits"
      style="width: 100%">

      <el-table-column
        prop="_id"
        label="Loja"/>

      <el-table-column
        :width="180"
        prop="numRepairs"
        label="Num.Rep" />

      <el-table-column
        :width="180"
        label="Material s/Iva">
        <template slot-scope="{ row }">
          {{ row.totalMaterial | money }}
        </template>
      </el-table-column>

      <el-table-column
        :width="180"
        label="Reparação c/Iva">
        <template slot-scope="{ row }">
          {{ row.totalRepair | money }}
        </template>
      </el-table-column>

      <el-table-column
        :width="180"
        label="Lucro">
        <template slot-scope="{ row }">
          {{ row.profit | money }}
        </template>
      </el-table-column>
    </el-table>

    <div class="tp-store-profits-table__total">
      <strong>Lucro total:</strong> {{ calcTotal() | money }}
    </div>
  </section>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    profits: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    calcTotal() {
      let numRepairs = 0;
      let valRepairs = 0;
      let valMaterial = 0;

      for (const item of this.profits) {
        valRepairs += item.totalRepair;
        numRepairs += item.numRepairs;
        valMaterial += item.totalMaterial;
      }

      return (valRepairs - valMaterial * 1.23) * 0.4;
    },
  },
};
</script>

<style lang="scss" scoped>
.tp-store-profits-table {
  margin-bottom: 10px;

  &__total {
    display: block;
    padding: 10px 20px;

    font-size: 1.2rem;
    text-align: right;
  }
}
</style>
