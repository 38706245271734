import { activeModules, getEnv, isDevelopment } from "@/services/utils";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Vue from "vue";

// When in development no errors must be sent to Bugsnag
if (!isDevelopment()) {
  Bugsnag.start({
    apiKey: "cebd859ad83f207f329211fde3637afc",
    releaseStage: getEnv(),

    plugins: [new BugsnagPluginVue()],
  });
}

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import App from "./components/App.vue";
import UiComponents from "./components/ui/index";
import SpacePortal from "./components/space-portal.vue";

// ---------------------------------------------------------------------------- [Router]

import VueRouter from "vue-router";
Vue.use(VueRouter);

import { getRouter, initRouter } from "@/services/router/router";
initRouter();

// ---------------------------------------------------------------------------- [Stellar]

import stellar from "@/services/stellar";

// link Stellar client to the Vue
Vue.use(_Vue => {
  Object.defineProperty(_Vue.prototype, "$stellar", {
    get() {
      return stellar;
    },
  });
});

// ---------------------------------------------------------------------------- [Authentication System]

import auth from "./permissions";

Vue.use(_Vue => {
  Object.defineProperty(_Vue.prototype, "$auth", {
    get() {
      return auth;
    },
  });
});

// ---------------------------------------------------------------------------- [Before Init]

import { sync } from "vuex-router-sync";
import { getStore } from "./services/store";

// load global filters
import "./filters";

// install Element
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import locale from "element-ui/lib/locale/lang/pt";
Vue.use(ElementUI, { locale });

// install our custom UI components
Vue.use(UiComponents);

// Register global components
Vue.component("SpacePortal", SpacePortal);

/**
 * Load dynamic modules in series.
 */
async function loadDynamicModules() {
  for (const moduleName of activeModules()) {
    try {
      await import(`./modules/${moduleName}/manifest.ts`);
    } catch (_) {
      // Do nothing! This means the modules doesn't have a manifest file.
    }
  }
}

async function loadSequentially() {
  // Store must be initialized first otherwise it will force mutations outside the initial store state definition
  const store = await getStore();
  await loadDynamicModules();

  // enable router sync
  const router = await getRouter();
  sync(store, router);

  // load user preferences
  store.dispatch("loadUserPrefs");

  // check session state before start
  await store.dispatch("sessionState");

  // load configs
  try {
    await store.dispatch("loadConfigs");
  } catch (e) {
    // Do noting!
  }

  // create and mount the root instance
  /* eslint-disable no-new */
  new Vue({
    el: "#app",
    components: { App },
    render: (h: any) => h(App),
    router,
    store,
  } as any);
}

// load the the components that need to requested sequentially
loadSequentially();
