
<section class="tp-Purchases">
  <el-table
    v-loading.body="loading"
    :data="purchases"
    :row-class-name="tableRowClassName"
    strip>
    <!-- material description -->
    <el-table-column label="Descrição">
      <template slot-scope="scope">
        {{ scope.row.material.brand }} {{ scope.row.material.model }}, {{ scope.row.material.description }}
      </template>
    </el-table-column>

    <!-- quantity -->
    <el-table-column
      :width="70"
      property="quantity"
      label="Qtn."
      align="center"/>

    <!-- origin -->
    <el-table-column
      :width="100"
      label="Origem"
      align="center">
      <template slot-scope="scope">
        <span v-if="scope.row.origin === 'europe'">Europa</span>
        <span v-if="scope.row.origin === 'other'">Fora da Europa</span>
      </template>
    </el-table-column>

    <!-- creation date -->
    <el-table-column
      :width="250"
      label="Compra"
      align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.createdAt | dateFormat('dd/MM/yy') }}</span>
      </template>
    </el-table-column>
    <!-- delivery prevision -->
    <el-table-column
      :width="250"
      label="Previsão"
      align="center">
      <template slot-scope="scope">
        <span>{{ estimateDelivery(scope.row) }}</span>
      </template>
    </el-table-column>

    <!-- link -->
    <el-table-column
      :width="70"
      label="Link"
      align="center">
      <template slot-scope="scope">
        <a
          v-if="scope.row.link"
          :href="scope.row.link"
          target="_blank">Link</a>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <!-- price -->
    <el-table-column
      :width="100"
      align="center"
      label="Preço">
      <template slot-scope="scope">
        {{ scope.row.price || scope.row.material.price | money }}
      </template>
    </el-table-column>

    <!-- Actions -->
    <el-table-column
      :width="200"
      align="right"
      label="">
      <template slot-scope="scope">
        <!-- problem resolution -->
        <!-- <el-button type="warning" @click="handleProblemResolution(item)" v-if="showFixBtn(item)">Resolver</el-button> -->

        <!-- mark as arraived -->
        <el-button
          type="success"
          size="small"
          @click="handleConfirmation(scope.row)">Efetivar</el-button>

        <!-- cancel purchase -->
        <el-button
          type="danger"
          size="small"
          @click="handleCancel(scope.row)">
          <i class="fa fa-trash"/>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</section>
