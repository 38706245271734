<template>
  <div class="tp-RepairForm">
    <div class="tp-repairForm_container">
      <!-- current step header -->
      <el-steps
        :space="150"
        :active="currentStep"
        finish-status="success">
        <el-step title="Dispositivo"/>
        <el-step title="Cliente"/>
        <el-step title="Equipamento"/>
        <el-step title="Reparação"/>
        <el-step title="Concluido"/>
      </el-steps>

      <!-- error alert -->
      <el-alert
        v-if="errorMessage !== null"
        :title="errorMessage"
        type="error"/>

      <!-- STEP 1 -->
      <step1
        v-if="currentStep === 0"
        :repair.sync="repair"
        :equipment.sync="selectedEquipment"
        :client.sync="selectedClient"
        @goStep="value => currentStep = value"
        @setEquipmentType="setEquipmentType"
        @setIdentifier="v => tempIdentifier = v"
        @latestRepair="v => prevRepair = v"/>

      <!-- STEP 2 -->
      <div v-if="currentStep === 1">
        <step2
          :client.sync="selectedClient"
          :repair.sync="repair"
          :equipment="selectedEquipment"
          @goStep="value => currentStep = value"/>

        <!-- create a complaint -->
        <div
          v-if="prevRepair"
          class="tp-RepairForm__complaint">
          <el-button
            type="danger"
            @click="makeComplaint">Reclamar</el-button>
        </div>
      </div>

      <!-- STEP 3 -->
      <step3
        v-if="currentStep === 2"
        :temp-identifier="tempIdentifier"
        :repair.sync="repair"
        :equipment-type.sync="selectedEquipmentType"
        :equipment.sync="selectedEquipment"
        :step.sync="currentStep"
        :client.sync="selectedClient"
        :types="equipmentTypes"/>

      <!-- STEP 4 -->
      <step4
        v-if="currentStep === 3"
        :client="selectedClient"
        :repair.sync="repair"
        :equipment-type="selectedEquipmentType"
        @created="repairCreated"/>

      <!-- STEP 5 -->
      <step5
        v-if="currentStep === 4"
        :client="selectedClient"
        :equipment-type="selectedEquipmentType"
        :equipment="selectedEquipment"
        :repair="repair"
        :repairs-list="repairsList"
        @newRepair="newRepairSameClientHandler"/>
    </div>
  </div>
</template>

<script>
import Step1 from "./creation/Step1.vue";
import Step2 from "./creation/Step2.vue";
import Step3 from "./creation/Step3.vue";
import Step4 from "./creation/Step4.vue";
import Step5 from "./creation/Step5.vue";

// TODO: add form validation
export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },

  data() {
    return {
      // auxiliar properties
      currentStep: 0,
      loading: false,
      errorMessage: null,

      // selected client's information
      selectedClient: null,

      // available types of equipments
      equipmentTypes: [],

      // selected equipment
      tempIdentifier: "",
      selectedEquipmentType: null,
      selectedEquipment: {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      },

      // repair informations
      repair: {
        clientEquipment: null,
        warranty: false,
        complaint: false,
        stateObservation: "Sem riscos",
        problemDescription: "",
        repairObservation: "",
        internalObservation: "",
        technician: null,
        priority: "normal",
        attributes: {},
        materials: [],
        log: [],
        quotation: 0,
        storeRepairIdentifier: null,
      },

      prevRepair: null,

      searchEquipments: {
        brands: [],
        models: [],
        loading: false,
      },

      // array to keep the created repairs
      repairsList: [],
    };
  },

  watch: {
    selectedEquipmentType(newVal) {
      if (newVal.name === "Telemóvel") {
        this.selectedEquipment.attributes = {
          imei: this.selectedEquipment.serialNumber,
        };
        this.selectedEquipment.serialNumber = "";
      }
    },
  },

  created() {
    // fetch the list of equipments
    // TODO: move to VUEX
    this.fetchEquipmentTypes();
  },

  methods: {
    /**
     * Get all the equipment types.
     *
     * @returns {Promise.<void>}
     */
    async fetchEquipmentTypes() {
      // get the list of fetched equipments
      const { equipments } = await this.$stellar.action("getEquipmentTypes");

      // store them into an array
      this.equipmentTypes = equipments;
    },

    setEquipmentType(id) {
      this.selectedEquipmentType = this.equipmentTypes.find(
        item => item.id === id,
      );
    },

    /**
     * Update the selected client data.
     */
    afterClientUpdate(updatedClient) {
      this.selectedClient = updatedClient;
    },

    /**
     * Go back to the equipment type selection, keep the same client and reset
     * the repair information.
     */
    newRepairSameClientHandler() {
      // save the current contact information
      const repairContact = this.repair.phone;

      // reset the equipment type selection and information
      this.selectedEquipmentType = null;
      this.tempBrand = null;
      this.tempIdentifier = "";
      this.selectedEquipment = {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      };

      // reset the repair form
      this.repair = {
        clientEquipment: null,
        warranty: false,
        complaint: false,
        stateObservation: "",
        problemDescription: "",
        repairObservation: "",
        internalObservation: "",
        technician: null,
        priority: "normal",
        attributes: {},
        materials: [],
        log: [],
        quotation: 0,
        storeRepairIdentifier: null,
        phone: repairContact,
      };

      // go back to step 3
      this.currentStep = 2;
    },

    async repairCreated(repair) {
      // create a copy of repair
      const newRepairToPush = Object.assign({}, repair);

      // append the clientEquipment
      newRepairToPush.clientEquipment = this.selectedEquipment;

      // add the repair to equipmentList Array
      this.repairsList.push(JSON.parse(JSON.stringify(newRepairToPush)));

      // go to the next step
      this.currentStep++;
    },

    async makeComplaint() {
      // enable loading
      this.loading = false;

      // copy the needed data for the new repair
      const dataToCopy = [
        "client",
        "stateObservation",
        "problemDescription",
        "attributes",
      ];
      dataToCopy.forEach(key => {
        this.repair[key] = this.prevRepair[key];
      });

      // set prev repair and complaint to true
      this.repair.prevRepair = this.prevRepair.id;
      this.repair.complaint = true;
      this.repair.clientEquipment = this.prevRepair.clientEquipment.id;

      // go to step 4
      this.currentStep = 3;
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.tp-RepairForm__complaint {
  max-width: 600px;
  margin: 0 auto;

  .el-button {
    width: 100%;
  }
}

.tp-repairForm_container {
  padding: 20px;
}

/** Card header */

.tp-RepairForm .el-card h2 {
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 0;
}

h2 {
  font-size: 1.3em;
  margin-bottom: 1em;
}
</style>
