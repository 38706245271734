
<el-table
  v-loading="loading"
  :data="data"
  stripe
  class="damaged-material-table">

  <el-table-column label="Descrição">
    <template slot-scope="scope">
      <span> {{ formatMaterialName(scope.row.material) }}</span>
    </template>
  </el-table-column>

  <el-table-column
    :with="50"
    label="stock N/U">
    <template slot-scope="scope">
      <span v-if="scope.row.material.newStock | scope.row.material.newStock"> {{ scope.row.material.newStock || '-' }} // {{ scope.row.material.usedstock || '-' }}</span>
    </template>
  </el-table-column>

  <el-table-column
    :with="50"
    label="#">
    <template slot-scope="scope">
      <span>{{ scope.row.repair.repairNumber || '-' }}</span>
    </template>
  </el-table-column>

  <el-table-column label="Tecnico">
    <template slot-scope="scope">
      <span> {{ scope.row.technician.name || '-' }}</span>
    </template>
  </el-table-column>

  <el-table-column
    :with="50"
    label="Data">
    <template slot-scope="scope">
      <span v-if="scope.row.createdAt" >{{ scope.row.createdAt | dateFormat("dd 'de' MMMM 'de' yyyy") }}</span>
    </template>
  </el-table-column>

  <el-table-column
    :with="50"
    label="Observação">
    <template slot-scope="scope">
      <span v-if="scope.row.observation" >{{ scope.row.observation }}</span>
    </template>
  </el-table-column>

  <el-table-column
    :width="100"
    label="Acções">
    <template slot-scope="scope">
      <el-button
        type="primary"
        size="small"
        @click="evaluateHandler(scope.row)">
        Avaliar
      </el-button>
    </template>
  </el-table-column>
</el-table>
