
<div id="tp-suppliers_wrap">
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="saveHandler">Guardar</el-button>
      <el-button @click="cancelHandler">Cancelar</el-button>
    </div>

    <!-- error alert -->
    <el-alert
      v-if="showError"
      type="error"
      title="Existem erros no formulario."/>

    <!-- form -->
    <el-form
      ref="resource"
      :model="resource"
      :rules="rules"
      label-position="top">
      <el-row :gutter="20">
        <!-- name -->
        <el-col :span="15">
          <el-form-item
            label="Nome"
            prop="name">
            <el-input
              v-model="resource.name"
              placeholder="Nome"/>
          </el-form-item>
        </el-col>

        <!-- tax-number -->
        <el-col :span="9">
          <el-form-item label="NIF">
            <el-input
              v-model="resource.taxNumber"
              placeholder="NIF"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- descrição -->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Descrição">
            <el-input
              v-model="resource.description"
              type="textarea"
              placeholder="Descrição"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- contacts -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Telemovel">
            <el-input
              v-model="resource.phone"
              placeholder="Telemovel"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="resource.email"
              placeholder="Email"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- street -->
        <el-col :span="12">
          <el-form-item label="Rua">
            <el-input
              v-model="resource.street"
              placeholder="Rua"/>
          </el-form-item>
        </el-col>

        <!-- city -->
        <el-col :span="12">
          <el-form-item label="Cidade">
            <el-input
              v-model="resource.city"
              placeholder="Cidade"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- zip-code -->
        <el-col :span="8">
          <el-form-item label="Código Postal (CP)">
            <el-input
              v-model="resource.zipCode"
              placeholder="Código Postal"/>
          </el-form-item>
        </el-col>

        <!-- location -->
        <el-col :span="8">
          <el-form-item label="Localidade">
            <el-input
              v-model="resource.location"
              placeholder="Localidade"/>
          </el-form-item>
        </el-col>

        <!-- country -->
        <el-col :span="8">
          <el-form-item label="Pais">
            <el-input
              v-model="resource.country"
              placeholder="Pais"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
