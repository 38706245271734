
<div class="tp-RepairForm__step5">
  <el-card class="tp-RepairForm__step5">
    <!-- repair number -->
    <div
      slot="header"
      class="clearfix">
      <span style="line-height: 20px; font-weight: bold">
        #{{ repair.repairNumber }}
      </span>
    </div>

    <!-- for when there is a store box -->
    <div v-if="repair.storeBox && !isStoreUser">
      <h2>O equipamento deve ser colocado na caixa:</h2>
      <div class="box">{{ repair.storeBox }}</div>
    </div>

    <!-- an equipment without a store box -->
    <div
      v-if="!repair.storeBox"
      class="tp-RepairForm__step5__noBox">
      <h2>O equipamento deve ser colocado no local apropriado.</h2>
    </div>

    <!-- add a new repair for the same client -->
    <el-button
      type="primary"
      @click="$emit('newRepair')">
      Novo Equipamento
    </el-button>

    <!-- reset and go to the dashboard -->
    <el-button
      type="success"
      @click="finish">Terminar</el-button>
  </el-card>

  <!-- receipt -->
  <repair-receipt
    ref="receipt"
    :repair="repair"
    :client="client"
    :equipment="equipment"
    :equipment-type="equipmentType"/>

  <ui-list
    v-if="repairsList.length > 1"
    :data="repairsList">
    <ui-list-item
      v-for="rep in repairsList"
      :key="rep.id">
      <!-- brand and model -->
      <span>
        #{{ rep.repairNumber }} - {{ rep.clientEquipment.brand }} {{ rep.clientEquipment.model }}
      </span>

      <!-- details -->
      <div slot="details">
        <span>Caixa: {{ rep.storeBox || 'N/A' }}</span>
      </div>
    </ui-list-item>
  </ui-list>
</div>
