
<div class="tp-clientForm_wrap">
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="saveClientHandler">
        Guardar
      </el-button>

      <el-button @click="dismiss">Cancelar</el-button>
    </div>

    <!-- error alert ------------------------------------------------------- -->
    <el-alert
      v-if="showError"
      class="tp-clientForm_alert"
      type="error"
      title="Existem erros no formulario."/>

    <!-- error alert phone ------------------------------------------------- -->
    <el-alert
      v-if="errorMessage"
      :title="errorMessage"
      type="error"/>

    <!-- form -------------------------------------------------------------- -->
    <el-form
      ref="client"
      :model="client"
      :rules="rules"
      label-position="top">
      <el-row :gutter="20">
        <!-- name -->
        <el-col :span="12">
          <el-form-item
            label="Nome"
            prop="name">
            <el-input
              v-model="client.name"
              placeholder="Nome"/>
          </el-form-item>
        </el-col>

        <!-- tax-number -->
        <el-col :span="9">
          <el-form-item
            label="NIF"
            prop="taxNumber">
            <el-input
              v-model="client.taxNumber"
              type="number"
              placeholder="NIF"/>
          </el-form-item>
        </el-col>

        <!-- isStore -->
        <el-col :span="3">
          <el-form-item label="Loja" >
            <el-switch
              v-model="client.isStore"
              on-text=""
              off-text=""/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- street -->
        <el-col :span="12">
          <el-form-item label="Rua">
            <el-input
              v-model="client.street"
              placeholder="Rua"/>
          </el-form-item>
        </el-col>

        <!-- city -->
        <el-col :span="12">
          <el-form-item label="Cidade">
            <el-input
              v-model="client.city"
              placeholder="Cidade"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- zip-code -->
        <el-col :span="12">
          <el-form-item
            label="Código Postal (CP)"
            prop="zipCode">
            <el-input
              v-model="client.zipCode"
              placeholder="Código Postal"/>
          </el-form-item>
        </el-col>

        <!-- location -->
        <el-col :span="12">
          <el-form-item label="Localidade">
            <el-input
              v-model="client.location"
              placeholder="Localidade"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- contacts -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="Telemovel"
            prop="phone">
            <el-input
              v-model="client.phone"
              placeholder="Telemovel" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="client.email"
              placeholder="Email"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- contact preference -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Contacto Preferencial">
            <el-select v-model="client.contactPreference">
              <el-option
                label="Chamada"
                value="phone"/>
              <el-option
                label="SMS"
                value="sms"/>
              <el-option
                label="Email"
                value="email"/>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Intervalo de Preferencia">
            <el-col :span="5">
              <el-time-select
                v-model="client.contactHourStart"
                :picker-options="{
                  start: '07:00',
                  step: '00:15',
                  end: '22:45'
                }"
                placeholder="Inicio"/>
            </el-col>

            <el-col
              :span="8"
              class="tp-clientForm_line">-</el-col>

            <el-col :span="5">
              <el-time-select
                v-model="client.contactHourEnd"
                :picker-options="{
                  start: '07:00',
                  step: '00:15',
                  end: '23:00',
                  minTime: client.contactHourStart
                }"
                placeholder="Fim"/>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Group de clientes" >
            <el-select v-model="client.customerGroup" placeholder="Group" class="customerGroup">
              <el-option
                v-for="item in groups"
                :key="item.name"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Preferencias do cliente" >
            <el-select
              v-model="client.labels"
              class="clientLabels"
              filterable
              multiple
              allow-create
              default-first-option
              placeholder="Labels">
              <el-option
                v-for="item in customerLabels"
                :key="item.name"
                :label="item.name"
                :value="item.name"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
