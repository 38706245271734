
<script lang="ts">
import Vue from "vue";
import {
  ISpacesSate,
  ISpaceEntry,
  ISpaces,
} from "@/services/store/modules/spaces/spaces-types";

export default Vue.extend({
  name: "SpacePortal",

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    componentsToRender(): Array<string> {
      const spaces = (this.$store as any).get("spaces/spaces") as ISpaces;
      const arrayKey = this.name as string;

      if (!spaces[arrayKey]) {
        return [];
      }

      const space = spaces[arrayKey] as Array<ISpaceEntry>;
      return [...space]
        .sort((a, b) => b.order - a.order)
        .map(entry => entry.name);
    },
  },

  render(createElement) {
    const children = (this as any).componentsToRender.map(
      (componentName: string) => {
        return createElement(componentName);
      },
    );

    return createElement("div", {}, children);
  },
});
</script>
