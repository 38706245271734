import Vue from "vue";

Vue.filter("formatEquipmentName", value => {
  const equipment = value.clientEquipment ? value.clientEquipment : value;

  let result = "";
  if (equipment.brand && equipment.brand.length > 0) {
    result += `${equipment.brand} `;
  }

  result += equipment.model;
  return result;
});
