
<div class="tp-materialForm_wrap">
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="save">Guardar</el-button>
      <el-button
        :loading="loading"
        @click="cancelAction">Cancelar</el-button>
    </div>

    <!-- error alert -->
    <el-alert
      v-if="showError"
      type="error"
      title="Existem erros no formulario."/>

    <!-- form -->
    <el-form
      ref="resource"
      :model="resource"
      :rules="rules"
      label-position="top">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="Cod."
            prop="identifier">
            <el-input
              v-model="resource.identifier"
              placeholder="Cod." />
          </el-form-item>
        </el-col>

        <!-- model -->
        <el-col :span="8">
          <el-form-item
            label="Modelo"
            prop="model">
            <el-autocomplete
              v-model="resource.model"
              :style="{ width: '100%' }"
              :fetch-suggestions="materialRemoteMethodHandler"
              :trigger-on-focus="false"
              placeholder="Modelo"
              custom-item="brand-model-item"
              @select="modelChangeHandler"/>
          </el-form-item>
        </el-col>

        <!-- brand -->
        <el-col :span="8">
          <el-form-item
            label="Marca"
            prop="brand">
            <el-input
              v-model="resource.brand"
              placeholder="Marca"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- description -->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="Descrição"
            prop="description">
            <el-input
              ref="descriptionField"
              v-model="resource.description"
              type="textarea"
              :rows="1"
              placeholder="Descrição"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- only show this if simple mode is disabled -->
      <div v-if="isSimpleMode === false">
        <el-row :gutter="20">
          <!-- newStock -->
          <el-col :span="8">
            <el-form-item label="Numero de Peças Novas">
              <el-input-number
                v-model="resource.newStock"
                :style="{ width: '100%' }"
                placeholder="Numero de Peças Novas"/>
            </el-form-item>
          </el-col>

          <!-- usedStock -->
          <el-col :span="8">
            <el-form-item label="Numero de Peças Usadas">
              <el-input-number
                v-model="resource.usedStock"
                :style="{ width: '100%' }"/>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Stock Minimo">
              <el-input-number
                v-model="resource.stockMin"
                :style="{ width: '100%' }"
                placeholder="Stock Minimo"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- box -->
          <el-col :span="6">
            <el-form-item label="Caixa">
              <el-input
                v-model="resource.storeBox"
                placeholder="Caixa"/>
            </el-form-item>
          </el-col>

          <!-- price -->
          <el-col :span="6">
            <el-form-item label="Preço de Compra">
              <el-input
                v-model="resource.price"
                placeholder="Preço de Compra">
                <template slot="append">€</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Categoria" >
              <el-select v-model="resource.materialGroup" placeholder="Categoria" class="categoryField">
                <el-option
                  v-for="group in groups"
                  :key="group.name"
                  :label="group.name"
                  :value="group.id"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </ui-bottom-sheet>
</div>
