
<aside
  :class="{ 'is-close': !isSidebarOpen }"
  class="tp-Sidebar">
  <div class="tp-Sidebar__actions">
    <div
      class="tp-Sidebar__actions__item is-back"
      @click="$router.go(-1)">
      <div class="tp-Sidebar__actions__container">
        <i class="fa fa-arrow-left"/>
      </div>
    </div>

    <div
      class="tp-Sidebar__actions__item is-hamburger"
      @click="toggleSidebar">
      <div class="tp-Sidebar__actions__container">
        <i class="fa fa-bars"/>
      </div>
    </div>
  </div>

  <nav class="tp-Sidebar__menu">
    <router-link
      v-for="entry in items"
      :key="entry.route"
      :to="{ name: entry.route }"
      exact>
      <div class="icon">
        <i
          :class="[ 'fa', 'fa-' + entry.icon ]"
          aria-hidden="true"/>
      </div>

      <span>{{ entry.title }}</span>
    </router-link>
  </nav>

  <div class="tp-Sidebar__data">
    <span class="tp-Sidebar__data__hour">{{ date | dateFormat('HH:mm') }}</span>
    <span class="tp-Sidebar__data__day">{{ date | dateFormat('MMM, EEE dd') }}</span>
  </div>
</aside>
