export class AuthenticationSystem {
  /**
   * User permissions list.
   */
  private permissions: Array<string> = [];

  /**
   * user role.
   */
  private role: string | null = null;

  /**
   * Set the logged user role and permissions.
   */
  setUser(permissions: Array<string>, role: string) {
    this.permissions = permissions;
    this.role = role;
  }

  /**
   * Clean the user information.
   */
  clean() {
    this.permissions = [];
    this.role = null;
  }

  /**
   * Check if the user as the right permissions to a given resource.
   *
   * @type {string|array} permissions Permissions to check
   * @type {string} mode This define the operation mode, this can take the
   *       following values:
   *       'all' - the user must met all the passed permissions
   *       'one' - the user must have at least one of the given permissions
   */
  can(permissions: string | Array<string>, mode = "all") {
    // convert permissions to an array if it isn't already one
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }

    if (mode === "all") {
      // iterate all the permissions and return false find the user misses one
      for (const perm of permissions) {
        if (!this.permissions.includes(perm)) {
          return false;
        }
      }

      return true;
    } else if (mode === "one") {
      // iterate all the permissions and return true when find one
      for (const perm of permissions) {
        if (this.permissions.includes(perm)) {
          return true;
        }
      }

      return false;
    }

    throw new Error("The mode argument got an invalid value");
  }

  /**
   * Check if the user has the role and met the permissions requirements.
   *
   * @type {string} role
   * @type {string|array} permissions Permissions to check
   * @type {string} mode This define the operation mode, this can take the
   *       following values:
   *       'all' - the user must met all the passed permissions
   *       'one' - the user must have at least one of the given permissions
   */
  needsRoleAndPermission(role: string, permissions: string | Array<string>, mode = "all") {
    return this.is(role) && this.can(permissions, mode);
  }

  /**
   * Check if the user is a role.
   *
   * @type {string|array} role
   */
  is(role: string) {
    if (Array.isArray(role)) {
      return role.includes(this.role);
    } else if (typeof role === "string") {
      return this.role === role;
    }

    throw new Error("Role as an invalid state");
  }
}

const instance = new AuthenticationSystem();
export default instance;
