<template>
  <div class="repair-list-entry">
    <div class="repair-list-entry__device-info">
      <img
        :src="equipmentTypeImgUrl"
        class="repair-list-entry__device-type">
      <div class="repair-list-entry-info">
        <div
          class="repair-list-entry-info__number"
          @click="$router.push({ name: 'repair.single', params: { id: repair.id } })">
          {{ repair.repairNumber }}
        </div>
        <div class="repair-list-entry-info__device">
          {{ repair.clientEquipment.model }}
        </div>
      </div>
    </div>
    <div class="repair-list-entry__state">
      <RepairState :state="repair.state"/>
    </div>
    <div class="repair-list-entry__problem repair-list-entry-item">
      <div class="repair-list-entry-item__title">Problema</div>
      <div class="repair-list-entry-item__content">
        <span class="repair-list-entry__problem-name">({{ repair.problemName }}) </span>
        <span class="repair-list-entry__problem-description">{{ repair.problemDescription }}</span>
      </div>
    </div>
    <div class="repair-list-entry__client repair-list-entry-item">
      <div class="repair-list-entry-item__title">Cliente</div>
      <div class="repair-list-entry-item__content">
        {{ repair.client.name }}
      </div>
    </div>
    <div class="repair-list-entry__extra-info">

      <!-- material available -->
      <el-tooltip
        v-if="repair.materials.length > 0 && repair.state !== 'waiting_material'"
        content="Material disponivel"
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--green">
          <span class="repair-list-entry-tag__text is-box">Material disponivel</span>
        </div>
      </el-tooltip>

      <!-- material available -->
      <el-tooltip
        v-if="repair.materials.length > 0 && repair.state === 'waiting_material'"
        content="Espera Material "
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--grey">
          <span class="repair-list-entry-tag__text is-box">Espera material</span>
        </div>
      </el-tooltip>

      <!-- quotation rejected -->
      <el-tooltip
        v-if="repair.quotationApproved === false"
        content="Orçamento recusado"
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--red">
          <span class="repair-list-entry-tag__text is-euro">Orçamento recusado</span>
        </div>
      </el-tooltip>

      <!-- quotation approved -->
      <el-tooltip
        v-if="repair.quotationApproved === true"
        content="Orçamento aprovado"
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--green">
          <span class="repair-list-entry-tag__text is-euro">Orçamento aprovado</span>
        </div>
      </el-tooltip>

      <!-- urgent Repairs -->
      <el-tooltip
        v-if="repair.priority === 'urgent'"
        content="Urgente"
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--red">
          <span class="repair-list-entry-tag__text is-alert">Urgente</span>
        </div>
      </el-tooltip>

      <!-- complaint Repairs -->
      <el-tooltip
        v-if="repair.complaint === true"
        content="Reclamação"
        placement="top">
        <div
          class="repair-list-entry-tag repair-list-entry-tag--red">
          <span class="repair-list-entry-tag__text is-complaint">Reclamação</span>
        </div>
      </el-tooltip>
    </div>

    <div class="repair-list-entry__actions">
      <!-- view butotn -->
      <el-button
        class="tp-btn tp-btn--info repair-list-entry__view-btn"
        type="info"
        size="small"
        @click="$router.push({ name: 'repair.single', params: { id: repair.id } })">
        Ver
      </el-button>

      <!-- start button -->
      <el-button
        v-if="showStartButton"
        :loading="workLoading"
        :disabled="workHasOpenRepair"
        class="tp-btn tp-btn--success"
        type="success"
        size="small"
        @click="takeRepairHandler">
        Iniciar
      </el-button>

      <!-- pause button -->
      <el-button
        v-if="repair.state === 'in_repair'"
        :loading="workLoading"
        class="tp-btn tp-btn--warning"
        type="warning"
        size="small"
        @click="stopRepairHandler">
        Pausar
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import RepairState from "@/components/ui/repair-state.vue";

export default {
  name: "RepairListEntry",

  components: {
    RepairState,
  },

  props: {
    /**
     * Repair data.
     */
    repair: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["workLoading", "workHasOpenRepair"]),

    /**
     * Build the url for the equipment type image.
     */
    equipmentTypeImgUrl() {
      return `${import.meta.env.VITE_API_URL}/equipment_types/${
        this.repair.clientEquipment.equipmentType
      }.jpg`;
    },

    showStartButton() {
      return ["waiting_repair", "paused", "in_tests"].includes(
        this.repair.state,
      );
    },
  },

  methods: {
    ...mapActions(["putWorkInPause", "putWorkInRepair"]),

    async takeRepairHandler() {
      await this.putWorkInRepair(this.repair.id);
      this.$router.push({
        name: "repair.single",
        params: { id: this.repair.id },
      });
    },

    async stopRepairHandler() {
      // TODO: use the store loading
      this.loading = true;

      try {
        await this.putWorkInPause(this.repair.id);
        this.$message.success("Reparação pausada.");
      } catch (exception) {
        if (exception.error === "max_repairs_paused") {
          this.$message.error("Não pode ter mais reparações em pausa.");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.repair-list-entry {
  display: grid;
  grid-template-areas:
    "info info state"
    "problem problem problem"
    "client client client"
    "extraInfo extraInfo extraInfo"
    "actions actions actions";
  grid-template-columns: 54px auto 146px;
  padding: 12px 18px;
  margin: 0 20px 10px;

  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 3px;

  transition: background, box-shadow 0.3s ease-out;
  will-change: box-shadow;

  @include media-breakpoint-up("md") {
    grid-template-areas:
      "info problem client extraInfo actions"
      "info problem client state actions";
    grid-template-columns: 219px auto 317px 158px 90px;

    min-height: 85px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $greyLight;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  }

  &__device-info {
    grid-area: info;
    padding-bottom: 15px;

    @include media-breakpoint-up("md") {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding-right: 16px;
      padding-bottom: 0;
    }
  }

  &__state {
    grid-area: state;

    text-align: right;

    @include media-breakpoint-up("md") {
      padding-right: 16px;

      text-align: left;
    }
  }

  &__problem {
    grid-area: problem;
    padding-bottom: 15px;

    font-size: 14px;

    @include media-breakpoint-up("md") {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-right: 16px;
      padding-bottom: 0;
    }
  }

  &__problem .repair-list-entry-item__title {
    @include media-breakpoint-up("md") {
      display: none;
    }
  }

  &__client {
    grid-area: client;

    font-size: 14px;

    @include media-breakpoint-up("md") {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-right: 16px;
    }
  }

  &__client .repair-list-entry-item__title {
    @include media-breakpoint-up("md") {
      display: none;
    }
  }

  &__extra-info {
    display: flex;
    flex-direction: row;
    grid-area: extraInfo;
    padding-bottom: 12px;

    @include media-breakpoint-up("md") {
      padding-bottom: 0;
    }

    & > div:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__actions {
    grid-area: actions;

    padding-top: 12px;

    text-align: right;
    border-top: 1px solid #e5e5e5;

    @include media-breakpoint-up("md") {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding-top: 0;
      border-top: none;
    }
  }

  &__device-info {
    display: flex;
    flex-direction: row;
  }

  &__device-type {
    width: 41px;
    height: 41px;
    margin-right: 13px;

    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  }

  &__problem-name {
    color: #4a4a4a;
  }

  &__view-btn {
    @include media-breakpoint-up("md") {
      display: none;
    }
  }
}

.repair-list-entry-info {
  &__number {
    font-size: 17px;
    font-weight: bold;
    color: #4a4a4a;

    transition: color 0.2s ease-out;

    &:hover {
      color: #666;
      cursor: pointer;
    }
  }

  &__device {
    font-size: 14px;
    color: #95989a;
  }
}

.repair-list-entry-item {
  &__title {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
  }

  &__content {
    color: #95989a;
  }
}

.repair-list-entry-tag {
  position: relative;
  display: block;
  height: 38px;
  padding-top: 12px;

  &__text {
    font-size: 14px;

    @include media-breakpoint-up("md") {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;

      font-size: 0;
    }
  }

  &__text::before {
    position: relative;
    top: 1px;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    content: "";

    background: black;
    border-radius: 50%;

    @include media-breakpoint-up("md") {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }
  }

  &__text::after {
    position: absolute;
    top: 50%;
    left: 50%;

    display: none;
    width: 14px;
    height: 14px;
    content: "";

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    transform: translate(-50%, -50%);

    @include media-breakpoint-up("md") {
      display: block;
    }
  }
}

.repair-list-entry-tag__text::after {
  background-image: url("/static/icons/box.svg");
}

.repair-list-entry-tag__text.is-euro::after {
  background-image: url("/static/icons/euro.svg");
}

.repair-list-entry-tag__text.is-alert::after {
  background-image: url("/static/icons/exclamation-mark.svg");
}
.repair-list-entry-tag__text.is-complaint::after {
  background-image: url("/static/icons/complaint.svg");
}

.repair-list-entry-tag--green {
  color: $green;

  .repair-list-entry-tag__text::before {
    background-color: $green;
    box-shadow: 0 0 4px rgba($green, 0.5);
  }
}

.repair-list-entry-tag--red {
  color: $red;

  .repair-list-entry-tag__text::before {
    background-color: $red;
    box-shadow: 0 0 4px rgba($red, 0.5);
  }
}

.repair-list-entry-tag--grey {
  color: $grey;

  .repair-list-entry-tag__text::before {
    background-color: $grey;
    box-shadow: 0 0 4px rgba($grey, 0.5);
  }
}
</style>
