<template>
  <div
    v-loading="workLoading"
    class="tp-WorkList">

    <div class="tp-WorkList_waiting_list">
      <el-switch
        v-model="WaitingRepair"
        class="tp-WorkList_waiting_list_switch"
        active-color="#13ce66"
        inactive-color="#ff4949"/>
      <span>Espera de material</span>

    </div>


    <!-- urgent message -->
    <div class="tp-WorkList__urgentMessage">
      <el-alert
        v-if="workHasUrgent"
        :closable="false"
        type="warning"
        title="Existem reparações urgentes para resolver."
        show-icon/>
    </div>

    <!-- current working repair -->
    <div
      v-if="currentRepair"
      class="tp-WorkList-active">
      <RepairListEntry :repair="currentRepair" />
    </div>

    <!-- rejected quotation repairs -->
    <RepairList
      v-if="rejectedRepairs.length > 0"
      :data="rejectedRepairs"
      class="tp-WorkList-list" />

    <!-- Approved quotation repairs -->
    <RepairList
      v-if="approvedRepairs.length > 0"
      :data="approvedRepairs"
      class="tp-WorkList-list" />

    <!-- ontime repairs -->
    <RepairList
      v-if="onTimeRepairs.length > 0"
      :data="onTimeRepairs"
      class="tp-WorkList-list" />
    <!-- complaint repairs -->
    <RepairList
      v-if="complaintRepairs.length > 0"
      :data="complaintRepairs"
      class="tp-WorkList-list" />

    <!-- urgent repairs -->
    <RepairList
      v-if="urgentRepairs.length > 0"
      :data="urgentRepairs"
      class="tp-WorkList-list" />



    <!-- repairs with avalable material -->
    <RepairList
      v-if="repairsWithAvalableMaterial.length > 0"
      :data="repairsWithAvalableMaterial"
      class="tp-WorkList-list" />

    <!-- paused repairs -->
    <RepairList
      v-if="pausedRepairs.length > 0"
      :data="pausedRepairs"
      class="tp-WorkList-list" />

    <!-- repairs list -->
    <RepairList :data="remainingRepairs" />

    <!-- repairs waiting material-->
    <RepairList
      v-if="waitingMaterialRepairs.length > 0 && WaitingRepair"
      :data="waitingMaterialRepairs"
      class="tp-WorkList-list" />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import RepairListEntry from "@/components/repair/repair-list/components/repair-list-entry.vue";
import RepairList from "../repair/repair-list/repair-list.vue";

export default {
  name: "WorkList",

  components: {
    RepairList,
    RepairListEntry,
  },

  data() {
    return {
      loading: false,
      listedReps: [],
      WaitingRepair: false,
    };
  },

  computed: {
    ...mapGetters(["workList", "workLoading", "workHasUrgent"]),

    /**
     * Give the current working repair.
     */
    currentRepair() {
      return this.workList.find(item => item.state === "in_repair");
    },

    /**
     * Repairs with rejected quotation.
     */
    rejectedRepairs() {
      return this.workList.filter(item => {
        return item.quotationApproved === false && item.state !== "in_repair";
      });
    },

    /**
     * Repairs with approved quotation.
     */
    approvedRepairs() {
      const withMaterialIds = this.repairsWithAvalableMaterial.map(
        item => item.id,
      );

      return (
        this.workList
          .filter(item => {
            return (
              item.quotationApproved === true &&
              item.state === "waiting_repair" &&
              !["urgent", "ontime"].includes(item.priority) &&
              item.complaint !== true
            );
          })

          // remove repairs with material
          .filter(item => !withMaterialIds.includes(item.id))
      );
    },

    /**
     * onTime repairs.
     */
    onTimeRepairs() {
      return this.workList.filter(item => {
        return (
          !["in_repair", "waiting_material"].includes(item.state) &&
          item.priority === "ontime" &&
          item.quotationApproved !== false &&
          item.complaint !== true
        );
      });
    },

    /**
     * Complaint repairs.
     */
    complaintRepairs() {
      return this.workList.filter(item => {
        return (
          item.complaint === true &&
          item.state === "waiting_repair" &&
          item.state !== "in_repair"
        );
      });
    },

    /**
     * Urgent repairs.
     */
    urgentRepairs() {
      return this.workList.filter(item => {
        return (
          !["in_repair", "waiting_material"].includes(item.state) &&
          item.priority === "urgent" &&
          item.quotationApproved !== false &&
          item.complaint !== true
        );
      });
    },
    /**
     * Repairs with avalable material.
     */
    repairsWithAvalableMaterial() {
      return this.workList.filter(item => {
        return (
          !["urgent", "ontime"].includes(item.priority) &&
          item.materials.length > 0 &&
          item.quotationApproved !== false &&
          item.complaint !== true &&
          !["waiting_material", "in_repair", "paused"].includes(item.state)
        );
      });
    },

    /**
     * Repairs in paused state.
     */
    pausedRepairs() {
      const withMaterialIds = this.repairsWithAvalableMaterial.map(
        item => item.id,
      );

      return (
        this.workList
          .filter(item => {
            return (
              item.state === "paused" &&
              !["urgent", "ontime"].includes(item.priority) &&
              item.quotationApproved !== false &&
              item.complaint !== true
            );
          })

          // remove repairs with material
          .filter(item => !withMaterialIds.includes(item.id))
      );
    },

    // waiting material repairs
    waitingMaterialRepairs() {
      return this.workList.filter(item => {
        return item.state === "waiting_material";
      });
    },

    // Remaining repairs that aren´t in above lists
    remainingRepairs() {
      const ids = this.rejectedRepairs
        .concat(
          this.currentRepair || [],
          this.approvedRepairs,
          this.urgentRepairs,
          this.onTimeRepairs,
          this.repairsWithAvalableMaterial,
          this.pausedRepairs,
          this.complaintRepairs,
          this.waitingMaterialRepairs,
        )
        .map(item => item.id);

      return this.workList
        .filter(item => !ids.includes(item.id))
        .sort((a, b) => a.repairNumber - b.repairNumber);
    },
  },

  created() {
    this.getWork();
  },

  methods: {
    ...mapActions(["getWork"]),
  },
};
</script>

<style lang="scss">
.tp-WorkList {
  padding: 20px 0;

  &__urgentMessage {
    position: relative;
    padding: 0 20px 20px;
    margin: 0 auto;

    @include media-breakpoint-up("sm") {
      max-width: 500px;
    }
  }
}

.tp-WorkList-list {
  position: relative;
  padding-bottom: 10px;
}

.tp-WorkList-active {
  position: relative;
  padding-bottom: 25px;

  &::after {
    position: absolute;
    bottom: 11px;
    left: 50%;
    display: none;
    width: calc(100% - 100px);
    height: 1px;

    content: "";
    background-color: #e3e3e3;

    transform: translateX(-50%);

    @include media-breakpoint-up("md") {
      display: block;
    }
  }
}
.tp-WorkList_waiting_list {
  margin-left: 20px;
}
.tp-WorkList_waiting_list_switch {
  margin-right: 5px;
}
</style>
