import { IBaseState } from "@/services/store/types/base-state.interface";
import { IMaterialGroup } from "@/modules/stock/types/material-group.interface";

export interface IMaterialGroupStore extends IBaseState {
  groups: Array<IMaterialGroup>;
}

export enum EMaterialGroupActions {
  CLEAN = "materialGroups/clean",
  FETCH_ALL = "materialGroups/fetch",
  CREATE_OR_UPDATE = "materialGroups/createOrUpdate",
  DESTROY = "materialGroups/destroy",
}

export enum EMaterialGroupMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_GROUPS = "SET_GROUPS",
}
