
<section class="tp-technician_wrap">

  <!-- commissions --------------------------------------------------------- -->

  <technician-commissions ref="technicianCommission"/>

  <!-- edit panel ---------------------------------------------------------- -->

  <technician-form
    ref="technicianForm"
    @updated="reloadCurrentPage"
    @created="reloadCurrentPage"/>

  <!-- technician list ----------------------------------------------------- -->

  <ui-list
    :loading="loading"
    :data="technicians"
    :total="total"
    :creation-method="createHandler"
    :remote-search="searchHandler"
    paginate
    allow-creation
    searchable
    @current-changed="fetchPage"
    @mode-changed="modeChangeHandler">
    <ui-list-item
      v-for="technician in orderedTechnicians"
      :key="technician.id">
      <!-- avatar -->
      <div slot="image">
        <img
          :src="getAvatar(technician.email)"
          class="tp-technician_avatar img-circle"
          alt="Avatar" >
      </div>

      <!-- name -->
      <span class="name">{{ technician.name }}</span>

      <!-- email -->
      <span slot="details">{{ technician.email }}</span>

      <!-- actions -->
      <div slot="actions">
        <!-- comissions of one Technician -->
        <el-button
          v-if="technician.type === 'commission'"
          :plain="true"
          type="primary"
          size="small"
          @click="seeCommissions(technician)">Comissões</el-button>

        <!-- edit Technician -->
        <el-button
          type="info"
          size="small"
          @click="editTechnician(technician)">Editar</el-button>

      <!-- TODO: implemnetar a função de desativar -->
      <!-- <el-button type="danger" @click.native="removeTechnician(technician)">Desativar</el-button> -->
      </div>
    </ui-list-item>
  </ui-list>
</section>
