import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import createLogger from "vuex/dist/logger";
import pathify from "vuex-pathify";

import { getEnv, activeModules } from "@/services/utils";
import { IStoreRootState } from "@/services/store/types/store-root-state.interface";

// @ts-ignore
import auth from "./modules/auth";
// @ts-ignore
import common from "./modules/common";
// @ts-ignore
import config from "./modules/config";
// @ts-ignore
import mural from "./modules/mural";
// @ts-ignore
import notification from "./modules/notification";
// @ts-ignore
import work from "./modules/work";
// @ts-ignore
import user from "./modules/user";
// @ts-ignore
import entities from "./modules/entities/entities-module";
import { spaceModule } from "./modules/spaces/spaces-module";
import { navigationModule } from "@/services/store/modules/navigation/navigation-module";
import { usersModule } from "@/services/store/modules/users/users.module";
import { isNil } from "lodash";

// If the app isn't in production enable the debug
const debug = getEnv() !== "production";

Vue.use(Vuex);

// Build list of Vuex plugins
const plugins = [pathify.plugin];

if (debug) {
  plugins.unshift(createLogger({}));
}

/**
 * Singleton for the store instance.
 */
let storeSingleton: Store<IStoreRootState> | null = null;

/**
 * Get object with all dynamic modules.
 */
async function getDynamicModules() {
  const listOfActiveModules = activeModules();

  let dynamicModules = {};

  for (const moduleName of listOfActiveModules) {
    try {
      const { modules } = await import(`../../modules/${moduleName}/services/store/index.ts`);
      dynamicModules = {
        ...dynamicModules,
        ...modules,
      };
    } catch (_) {
      // Do nothing! This means that files doesn't exists.
    }
  }

  return dynamicModules;
}

/**
 * Initialize the store modules
 */
export async function initStore(): Promise<Store<IStoreRootState>> {
  const dynamicModules = await getDynamicModules();

  const storeOptions: StoreOptions<IStoreRootState> = {
    state: {
      version: "2.0.0",
    },
    modules: {
      auth,
      common,
      config,
      mural,
      notification,
      work,
      user,
      entities,
      spaces: spaceModule,
      navigation: navigationModule,
      users: usersModule,
      ...dynamicModules,
    },

    strict: debug,
    plugins,
  };

  return new Vuex.Store<IStoreRootState>(storeOptions);
}

/**
 * Get store instance.
 */
export async function getStore(): Promise<Store<IStoreRootState>> {
  if (!isNil(storeSingleton)) {
    return storeSingleton;
  }

  storeSingleton = await initStore();
  return storeSingleton;
}
