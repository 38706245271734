<template>
  <section class="tp-Container">
    <Sidebar :items="menuItemsOrdered" />

    <!-- main area -->
    <div class="tp-dashboard_content">
      <!-- application header -->
      <Header/>

      <!-- dynamic view -->
      <router-view/>
    </div>

    <!-- quick panel --------------------------------------------------------- -->
    <quick-panel :sections="menuItemsOrdered"/>

    <SpacePortal name="baseFooter" />
  </section>
</template>

<script lang="ts">
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

import { initialMenuItems } from "./base/menu-items";

import "./base/chat-sidebar.vue";

import Header from "@/components/base/header.vue";
import QuickPanel from "@/components/base/quick-panel.vue";
import Sidebar from "@/components/base/base-sidebar.vue";
import {
  INavigationState,
  INavigationMenuItem,
  ENavigationActions,
} from "@/services/store/modules/navigation/navigation-types";

let menuItemsLoaded = false;

export default Vue.extend({
  name: "Base",

  components: {
    Header,
    QuickPanel,
    Sidebar,
  },

  data() {
    return {
      // sidebar date
      date: new Date(),

      loggedTechnician: null,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    isComissionTechnician() {
      if ((this as any).loggedTechnician === null) {
        return false;
      }
      if ((this as any).loggedTechnician.technician.type === "salary") {
        return false;
      }
      return true;
    },

    /**
     * Get list of menu items on store.
     */
    sidebar(): Array<INavigationMenuItem> {
      const navigationState = this.$store.state.navigation as INavigationState;
      return navigationState.menuItems;
    },

    /**
     * Get list of alloawed menu items.
     */
    allowedMenuItems(): Array<INavigationMenuItem> {
      const originalItems = (this as any).sidebar as Array<INavigationMenuItem>;

      // filter by role
      const result = originalItems.filter(
        (item: INavigationMenuItem) =>
          !item.role || item.role === (this as any).user.role.slug,
      );

      // filter by permission
      const userPermissions = (this as any).user.role.permissions.concat(
        (this as any).user.permissions,
      );

      return result.filter(
        (item: INavigationMenuItem) =>
          !item.permission || userPermissions.indexOf(item.permission) >= 0,
      );
    },

    menuItemsOrdered(): Array<INavigationMenuItem> {
      const items = this.allowedMenuItems.filter(item => {
        if (!item.filter) {
          return true;
        }
        return item.filter();
      });

      // order menu items
      return _.orderBy(items, "title");
    },
  },

  async created() {
    const personId = (this as any).user.personId;

    if (personId) {
      this.loggedTechnician = await (this as any).$stellar.action(
        "getTechnician",
        {
          id: (this as any).user.personId,
        },
      );
    }

    if (!menuItemsLoaded) {
      menuItemsLoaded = true;
      this.$store.dispatch(ENavigationActions.ADD_MENU_ITEMS, initialMenuItems);
    }
  },
});
</script>

<style lang="scss">
@import "./var.css";

.tp-dashboard_content {
  position: relative;
  flex: 1;
  order: 1;

  overflow: auto;

  background-color: #fafcfc;
}

.tp {
  &-Container {
    display: flex;
    height: 100vh;
  }
}
</style>
