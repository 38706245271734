<template>
  <div class="tp-technicianForm_wrap">
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="saveHandler">
          Guardar
        </el-button>
        <el-button @click="cancelHandler">Cancelar</el-button>
      </div>

      <!-- error alert -->
      <el-alert
        v-if="showError"
        type="error"
        title="Ocorreu um error no servidor."/>

      <!-- form -->
      <el-form
        ref="technician"
        :model="technician"
        :rules="rules"
        label-position="top">
        <el-row :gutter="20">
          <!-- name -->
          <el-col :span="18">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="technician.name"
                placeholder="Nome"/>
            </el-form-item>
          </el-col>

          <!-- external -->
          <el-col :span="3">
            <el-form-item label="Externo">
              <el-switch
                v-model="technician.external"
                on-text=""
                off-text=""/>
            </el-form-item>
          </el-col>

          <!-- active -->
          <el-col :span="3">
            <el-form-item label="Activo">
              <el-switch
                v-model="technician.active"
                on-text=""
                off-text=""/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- type of remoneration -->
          <el-col :span="12">
            <el-form-item label="Tipo de Remuneração">
              <el-select
                v-model="technician.type"
                :style="{ width: '100%' }"
                placeholder="Tipo de Remuneração">
                <el-option
                  label="Salário"
                  value="salary"/>
                <el-option
                  label="Comissão"
                  value="commission"/>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- percentagem -->
          <el-col :span="12">
            <el-form-item label="Percentagem">
              <el-input-number
                v-model="technician.percentage"
                :style="{ width: '100%' }"
                :disabled="technician.type !== 'commission'"
                :min="1"
                :max="100"
                placeholder="percentagem"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- street -->
          <el-col :span="12">
            <el-form-item label="Rua">
              <el-input
                v-model="technician.street"
                placeholder="Rua"/>
            </el-form-item>
          </el-col>

          <!-- city -->
          <el-col :span="12">
            <el-form-item label="Cidade">
              <el-input
                v-model="technician.city"
                placeholder="Cidade"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- zip-code -->
          <el-col :span="12">
            <el-form-item
              label="Código Postal (CP)"
              prop="zipCode">
              <el-input
                v-model="technician.zipCode"
                placeholder="Código Postal"/>
            </el-form-item>
          </el-col>

          <!-- location -->
          <el-col :span="12">
            <el-form-item label="Localidade">
              <el-input
                v-model="technician.location"
                placeholder="Localidade"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- contacts -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Telemovel"
              prop="phone">
              <el-input
                v-model="technician.phone"
                placeholder="Telemovel"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email">
              <el-input
                v-model="technician.email"
                placeholder="Email"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      edit: false,
      showError: false,

      technician: {
        name: "",
        type: "salary",
        percentage: 40,
        active: true,
        external: false,
        street: "",
        location: "",
        zipCode: "",
        city: "",
        phone: "",
        email: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Introduza o nome do cliente",
            trigger: "blur",
          },
        ],
        phone: [
          {
            pattern: /\d{9}.*/,
            message: "Verifique o formato do numero inserido",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "Verifique o formato do email inserido",
            trigger: "blur",
          },
        ],
        zipCode: [
          {
            pattern: /\d{4}-\d{3}/,
            message: `O codigo postal deve obdecer ao formato "1234-123"`,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Set a technician for edition.
     */
    setTechnician(technician) {
      this.technician = JSON.parse(JSON.stringify(technician));
      this.edit = true;
    },

    /**
     * Change the component visibility.
     *
     * @type {[type]}
     */
    show(val = true) {
      this.$refs.editSheet.show(val);

      // when is to hide the form we must reset the form
      if (!val) {
        this.reset();
      }
    },

    /**
     * Reset the form state.
     */
    reset() {
      // reset form fields
      this.$refs.technician.resetFields();

      // reset form data
      this.technician = {
        name: "",
        type: "salary",
        percentage: 0,
        active: true,
        external: false,
        street: "",
        location: "",
        zipCode: "",
        city: "",
        phone: "",
        email: "",
      };

      // reset some other var
      this.loading = false;
      this.edit = false;
    },

    /**
     * Handle the cancel action.
     */
    cancelHandler() {
      this.show(false);
    },

    /**
     * This handles the save button action.
     */
    saveHandler() {
      // enter in loading mode
      this.loading = true;

      // reset the error message
      this.showError = false;

      // validate the form
      this.$refs.technician.validate(valid => {
        // when the form data is invalid we must return now
        if (!valid) {
          return false;
        }

        // edit/create the resource
        if (!this.edit) {
          this.createTechnician();
        } else {
          this.editTechnician();
        }
      });
    },

    /**
     * Create a new technician.
     */
    createTechnician() {
      // call the API to create a new technician
      this.$stellar
        .action("createTechnician", this.technician)
        .then(response => {
          // emit a 'created' event with the new technician
          this.$emit("created", this.technician);

          // close the panel
          this.show(false);

          // show a message to the user to inform the technician creation
          this.$message({
            type: "success",
            message: "O técnico foi criado",
          });
        })
        // if an error occurs show an error
        .catch(response => {
          this.showError = true;
        });
    },

    /**
     * Edit the technician.
     */
    editTechnician() {
      const dataToSend = { id: this.technician._id, ...this.technician };

      // call the API to store the technician changes
      this.$stellar
        .action("editTechnician", dataToSend)
        .then(response => {
          // emit an 'updated' event with the new technician
          this.$emit("updated", dataToSend);

          // close the panel
          this.show(false);

          // show a message to the user to inform the technician update
          this.$message({
            type: "success",
            message: "O técnico foi atualizado",
          });
        })
        // when an error occurs we show an error message
        .catch(response => {
          this.showError = true;
        });
    },
  },
};
</script>
