<template>
  <section class="tp-closed_today_wrap">
    <div
      v-for="item in data"
      :key="item.tech">
      <hr>
      <hr>
      <span> <b> {{ item.name }} </b> </span>
      <el-table
        :data="item.repairs">
        <el-table-column
          fixed
          label="#"
          width="80">
          <template slot-scope="{ row }">
            {{ row.repairNumber }}
          </template>
        </el-table-column>

        <el-table-column
          label="Cliente"
          width="380">
          <template slot-scope="{ row }">
            {{ row.client.name }}
          </template>
        </el-table-column>

        <el-table-column
          label="Caixa"
          width="80">
          <template slot-scope="{ row }">
            {{ row.storeBox }}
          </template>
        </el-table-column>

        <el-table-column
          label="Problema"
          width="450">
          <template slot-scope="{ row }">
            {{ row.problemDescription }}
          </template>
        </el-table-column>
        <el-table-column
          label="Valor da reparação"
          width="200">
          <template slot-scope="{ row }">
            {{ row.value | money }}
          </template>
        </el-table-column>

      </el-table>
    </div>

  </section>
</template>
  <script>
export default {
  name: "ClosedToday",

  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
