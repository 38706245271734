<template>
  <div id="tp-suppliers_wrap">
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="saveHandler">
          Guardar
        </el-button>
        <el-button @click="cancelHandler">Cancelar</el-button>
      </div>

      <el-alert
        v-if="showError"
        type="error"
        title="Existem erros no formulario." />

      <el-form
        ref="resource"
        :model="resource"
        :rules="rules"
        label-position="top" >
        <el-row :gutter="10">
          <el-col :span="21">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="resource.name"
                placeholder="Nome" />
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item label="Activo">
              <el-switch v-model="resource.active" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Responsavel">
              <el-select
                v-model="resource.responsable"
                clearable>
                <el-option
                  v-for="technician in technicians"
                  :key="technician.id"
                  :value="technician.id"
                  :label="technician.name" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Atributos">
              <el-input
                v-model="attributesString"
                :autosize="{
                  minRows: 2,
                  maxRows: 7
                }"
                type="textarea"
                placeholder="Descrição" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import { ETechnicianActions } from "@/modules/technician/services/store/technician/technician.types";
import { ITechnician } from "@/modules/technician/types/technician.interface.ts";

export default Vue.extend({
  name: "TypeForm",

  data() {
    return {
      loading: false,

      resource: {
        name: "",
        active: false,
        responsable: null,
        attributes: {},
      },

      attributesString: "{}",

      rules: {
        name: [
          {
            required: true,
            message: "O nome é obrigatorio",
            trigger: "blur",
          },
        ],
      },

      edit: false,
      showError: false,
    };
  },

  computed: {
    technicians(): Array<ITechnician> {
      return this.$store.get("technician/technicians");
    },
  },

  async created() {
    await this.$store.dispatch(ETechnicianActions.FETCH_ALL);
  },

  methods: {
    /**
     * Set a supplier for edit.
     */
    setResource(resource: any) {
      // fill the form with the supplier date
      this.resource = JSON.parse(JSON.stringify(resource));
      this.attributesString = JSON.stringify(this.resource.attributes, null, 4);

      // put the form in edit mode
      this.edit = true;
    },

    /**
     * Chnage the component visivility.
     */
    show(val = true) {
      (this.$refs.editSheet as any).show(val);

      // when we hide the form we must reset it
      if (!val) {
        this.reset();
      }
    },

    /**
     * Reset form data.
     */
    reset() {
      // reset some misellenious fields
      this.loading = false;
      this.edit = false;

      // reset form field
      (this.$refs.resource as any).resetFields();

      // reset form data
      this.resource = {
        name: "",
        active: false,
        attributes: {},
        responsable: null,
      };
    },

    /**
     * Save the resource.
     *
     * If the form is in edit mode we save the changes, otherwise we create a
     * new entry.
     */
    saveHandler() {
      // put it in loading
      this.loading = true;

      // reset error visivility
      this.showError = false;

      // validate attributes
      try {
        this.resource.attributes = JSON.parse(this.attributesString);
      } catch (e) {
        this.showError = true;
        return false;
      }

      // validate the form and save/create the resource
      const resource = this.$refs.resource as any;
      resource.validate((valid: boolean) => {
        // we must return if the data form is invalid
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.createResource();
        } else {
          this.editResource();
        }
      });
    },

    /**
     * Create a new supplier.
     */
    async createResource() {
      try {
        await this.$stellar.action("createEquipmentType", this.resource);
        this.$emit("created", this.resource);
        this.show(false);
        this.$message.success("Tipo criado.");
      } catch (_) {
        this.showError = true;
      }
    },

    /**
     * Save the changes on the resource.
     */
    async editResource() {
      try {
        await this.$stellar.action("editEquipmentType", this.resource);
        this.$emit("updated", this.resource);
        this.show(false);
        this.$message.success("Tipo atualizado.");
      } catch (_) {
        this.showError = true;
      }
    },

    /**
     * Cancel the current action.
     */
    cancelHandler() {
      this.show(false);
      this.$emit("canceled");
    },
  },
});
</script>
