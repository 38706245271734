import { IBaseState } from "@/services/store/types/base-state.interface";
import { IRepair } from "@/modules/repairs/types/repair.interface";

export interface IRepairState extends IBaseState {
  repair: IRepair | null;
  timesInStore: number;
}

export enum ERepairActions {
  CLEAN = "repair/clean",
  FETCH = "repair/fetch",
  RELOAD = "repair/reload",
  UPDATE = "repair/update",
  SET_TECHNICIAN = "repair/setTechnician",
  CANCEL = "repair/cancel",
  CANCEL_COMMISSION = "repair/cancelCommission",
  REJECT_QUOTATION = "repair/rejectQuotation",
  APPROVE_QUOTATION = "repair/approveQuotation",
  CHANGE_VALUE = "repair/changeValue",
  CHANGE_TO_COMPLAINT = "repair/changeToComplaint",
  DELIVER = "repair/deliver",
}

export enum ERepairMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_REPAIR = "SET_REPAIR",
  SET_TIMES_IN_STORE = "SET_TIMES_IN_STORE",
  RESET = "RESET",
}
