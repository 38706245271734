<template>
  <div
    :class="{'is-creation': canCreate}"
    class="tp-ClientSearch">
    <!-- form to create a new client -->
    <client-form
      ref="clientPanel"
      @created="afterClientCreation"/>

    <!-- input -->
    <el-autocomplete
      v-model="selectedClient"
      :fetch-suggestions="searchForClients"
      :trigger-on-focus="false"
      placeholder="Cliente"
      custom-item="client-item"
      class="tp-ClientSearch__input"
      @select="changeHandler"/>

    <!-- button to create a new client -->
    <el-button
      v-if="canCreate"
      class="tp-ClientSearch__btn"
      type="primary"
      @click="showClientCreationForm">
      Novo
    </el-button>
  </div>
</template>

<script>
import Vue from "vue";

import ClientForm from "./ClientForm.vue";

Vue.component("client-item", {
  functional: true,

  props: {
    item: { type: Object, required: true },
  },

  render(h, ctx) {
    // get the current item
    const item = ctx.props.item;

    return h("li", ctx.data, [
      h("div", { attrs: { class: "value" } }, [item.name]),
    ]);
  },
});

let clientSearchTimer = null;

export default {
  components: {
    ClientForm,
  },

  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedClient: null,
    };
  },

  methods: {
    reset() {
      this.selectedClient = null;
    },

    searchForClients(query = "", cb) {
      // cancel any timer
      clearTimeout(clientSearchTimer);

      if (query.length <= 2) {
        return cb([]);
      }

      clientSearchTimer = setTimeout(_ => {
        // make an API call to get the clients
        this.$stellar
          .action("searchForClients", { query })
          .then(({ clients }) => {
            this.clients = clients.map(item => {
              item.value = item.name;
              return item;
            });

            cb(clients);
          });
      }, 700);
    },

    changeHandler(client) {
      this.$emit("change", client);
    },

    /**
     * Open a new panel with the client form.
     */
    showClientCreationForm() {
      // get the client panel
      const panel = this.$refs.clientPanel;

      // if the filled data on the prev input field can be helpfull for the
      // client creation use it
      panel.setData(this.selectedClient);

      // open the panel
      panel.show();
    },

    /**
     * Set the client after the action.
     *
     * @param client Client to save.
     */
    afterClientCreation(client) {
      this.selectedClient = client.name;
      this.$emit("change", client);
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-ClientSearch {
    width: 100%;

    &__input {
      width: 100%;
    }

    &.is-creation {
      .tp-ClientSearch__input {
        width: calc(100% - 78px);
      }

      .tp-ClientSearch__btn {
        margin-left: 3px;
      }
    }
  }
}
</style>
