
<div class="tp-RepairView">
  <!-- see previous repair -->
  <PrevRepair
    :visibility.sync="prevRepairVisibility"
    :previous-repair="previousRepair"
    :loading="loading" />

  <!-- send equipment to warranty -->
  <el-dialog
    v-loading.fullscreen="loading"
    :visible.sync="warrantyVisibility"
    class="tp-SendtoWarrantyDialog"
    title="Enviar para garantia"
    size="tiny">
    <el-select
      v-model="entity"
      placeholder="Entidade">
      <el-option
        v-for="ent in entities"
        :key="ent.value"
        :label="ent.name"
        :value="ent.id" />
    </el-select>

    <el-button
      type="success"
      @click="sendToWarrantyEntity(entity)">
      Enviar
    </el-button>
  </el-dialog>

  <!-- internal observations ----------------------------------------------- -->
  <el-dialog
    v-loading.fullscreen="internalObsForm.loading"
    :visible.sync="internalObsForm.visibility"
    title="Observações Internas">
    <el-form label-position="top">
      <el-form-item>
        <el-input
          v-model="internalObsForm.value"
          placeholder="Observações"
          type="textarea"
          row="6"/>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="internalObsForm.visibility = false">Cancelar</el-button>
      <el-button
        type="primary"
        @click="saveInternalObservationHandler">Guardar</el-button>
    </span>
  </el-dialog>

  <!-- receipt ------------------------------------------------------------- -->
  <repair-receipt
    v-if="typeof innerRepair.clientEquipment === 'object' && equipmentType"
    ref="receipt"
    :repair="innerRepair"
    :client="innerRepair.client"
    :equipment="innerRepair.clientEquipment"
    :equipment-type="equipmentType"
    :print-value="imprimeValor"/>

  <!-- Create quotation ---------------------------------------------------- -->
  <el-dialog
    :visible.sync="quotation.visibility"
    title="Dar orçamento"
    size="tiny">
    <el-form label-position="top">
      <el-form-item label="Valor">
        <el-input v-model="quotation.data.value">
          <template slot="append">€</template>
        </el-input>
      </el-form-item>

      <!-- duration -->
      <el-form-item label="Duração da Reparação">
        <el-select
          v-model="quotation.data.duration"
          :style="{ width: '100%' }"
          placeholder="Duração">
          <el-option
            label="Um dia"
            value="Um dia"/>
          <el-option
            label="Uma semana"
            value="Uma semana"/>
          <el-option
            label="Duas semanas"
            value="Duas semanas"/>
          <el-option
            label="4 a 7 semanas"
            value="4 a 7 semanas"/>
        </el-select>
      </el-form-item>

      <el-form-item label="Descrição">
        <el-input
          v-model="quotation.data.description"
          type="textarea"/>
      </el-form-item>

      <el-checkbox
        v-if="!isExternalTechnician && innerRepair.client.isStore === false"
        v-model="quotation.data.byPhone">
        Contactar por telefone
      </el-checkbox>
    </el-form>

    <!-- actions -->
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="quotation.visibility = false">
        Cancelar
      </el-button>
      <el-button
        type="primary"
        @click="createQuotationHandler">
        Orçamentar
      </el-button>
    </span>
  </el-dialog>

  <!-- finalize dialog ----------------------------------------------------- -->

  <el-dialog
    v-loading.fullscreen="finishForm.loading"
    :visible.sync="finishForm.visibility"
    title="Finalizar Reparação">
    <el-form label-position="top">
      <!-- observation -->
      <el-form-item label="Nota para o Cliente">
        <el-select
          v-model="finishForm.data.problem"
          :style="{ width: '100%' }"
          class="tp-RepairView__clientNote"
          @change="problemAdd">
          <el-option
            v-for="item in finishForm.messages"
            :key="item"
            :label="item"
            :value="item"/>
        </el-select>

        <el-input
          v-model="finishForm.data.observation"
          type="textarea"
          row="6"/>
      </el-form-item>

      <!-- repair value -->
      <el-form-item
        v-if="innerRepair.technician && !innerRepair.technician.external"
        label="Valor da Reparação">
        <el-input
          v-model="finishForm.data.value"
          :min="0"
          placeholder="Nota"
          type="number">
          <template slot="append">€</template>
        </el-input>
      </el-form-item>

      <!-- close without permission -->
      <el-checkbox v-model="finishForm.data.withoutPermission">
        Fechado sem permissão
      </el-checkbox>
    </el-form>

    <!-- footer -->
    <span
      slot="footer"
      class="dialog-footer">
      <!-- cancel button -->
      <el-button @click="finishForm.visibility = false">Cancelar</el-button>
      <!-- save button -->
      <el-button
        type="primary"
        @click="finishRepairHandler">Terminar</el-button>
    </span>
  </el-dialog>

  <!-- Closed without repair ----------------------------------------------- -->

  <close-without-repair-dialog
    :repair="innerRepair"
    :visibility.sync="toProblematicVisibility"
    @cancel="toProblematicVisibility = false"/>

  <!-- client dialog -->
  <el-dialog
    :visible.sync="seeClientDialog"
    title="Informação do cliente"
    size="small">
    <client-card :client="client"/>
  </el-dialog>

  <!-- material request panel ---------------------------------------------- -->

  <material-to-repair
    ref="materialAdd"
    :repair="innerRepair"
    @added="fetchRepairMaterials"/>

  <!-- repair panel -------------------------------------------------------- -->

  <div
    v-if="storeRepair"
    class="tp-RepairView__container">
    <div class="tp-RepairView__topBar">
      <!-- repair number -->
      <div class="tp-RepairView__topBar__action">
        <div class="icon fa fa-hashtag"/>
        <div class="content">{{ storeRepair.repairNumber }}</div>
      </div>

      <!-- box -->
      <div class="tp-RepairView__topBar__action">
        <div class="icon fa fa-archive"/>
        <div class="content">{{ storeRepair.storeBox || '-' }}</div>
      </div>

      <!-- priority -->
      <div class="tp-RepairView__topBar__action">
        <div
          :class="[ 'is-' + storeRepair.priority ]"
          class="icon fa fa-circle tp-RepairView__priority"/>
        <div
          class="content">
          {{ storeRepair.priority | priority }}
        </div>
        <el-button
          v-if="storeRepair.state !== 'delivered'"
          icon="el-icon-edit"
          size="small"
          class="tp-ProblemDescription__entry__title__editBtn"
          @click="repairPriorityEditHandler" />
      </div>

      <!-- store entry number -->
      <div class="tp-RepairView__topBar__action">
        <div class="icon fa fa-building"/>
        <div class="content">{{ storeRepair.storeRepairIdentifier || '-' }}</div>
        <el-button
          v-if="storeRepair.state !== 'delivered'"
          icon="el-icon-edit"
          size="small"
          class="tp-ProblemDescription__entry__title__editBtn"
          @click="storeIdentifierEditHandler" />
      </div>

      <div class="tp-RepairView__topBar__action">
        <div class="icon fa fa-handshake-o"/>
        <div class="content">
          <span v-if="!storeRepair.complaint &&!storeRepair.quotation">
            -
          </span>

          <!-- when is an external technician that is viewing, always show the quotation -->
          <span v-if="!storeRepair.complaint && isExternalTechnician">
            {{ storeRepair.quotation | money }}
          </span>

          <!-- Isn't an external technician and is as complaint -->
          <span v-if="!isExternalTechnician && storeRepair.complaint">
            <span v-if="storeRepair.value">
              -
            </span>
            ({{ storeRepair.quotation | money }})
          </span>

          <!-- reject quotation -->
          <span
            v-if="storeRepair.state === 'waiting_delivery' && storeRepair.quotationApproved === false"
            class="reject_quotation_delivery">
            RECUSADO
          </span>

          <!-- only show's the quotation nothing more -->
          <span v-else>
            <template v-if="storeRepair.quotation">
              {{ storeRepair.quotation | money }}
            </template>
          </span>
          <el-button
            v-if="storeRepair.state !== 'delivered' && $auth.is('admin')"
            icon="el-icon-edit"
            size="small"
            class="tp-ProblemDescription__entry__title__editBtn"
            @click="ValueQuotationEditHandler" />
        </div>
      </div>

      <div class="tp-RepairView__topBar__action">
        <div class="icon fa fa-money"/>
        <div class="content">
          <span v-if="!storeRepair.complaint && !storeRepair.value">
            -
          </span>

          <!-- when is an external technician that is viewing, always show the quotation -->
          <span v-if="!storeRepair.complaint && isExternalTechnician">
            -
          </span>

          <!-- Isn't an external technician and is as complaint -->
          <span v-if="!isExternalTechnician && storeRepair.complaint">
            <span v-if="storeRepair.value">
              {{ storeRepair.value | money }}
            </span>
            -
          </span>

          <!-- only show's the value nothing more -->
          <span v-else>
            <template v-if="storeRepair.value">
              {{ storeRepair.value | money }}
            </template>
          </span>
        </div>
        <el-button
          v-if="storeRepair.state !== 'delivered' && $auth.is('admin')"
          icon="el-icon-edit"
          size="small"
          class="tp-ProblemDescription__entry__title__editBtn"
          @click="ValueQuotationEditHandler" />
      </div>
      <!-- state -->
      <div class="tp-RepairView__topBar__action">
        <RepairState :state="storeRepair.state"/>
        <span v-if="storeRepair.state==='in_warranty'">{{ entity }}</span>
      </div>
    </div>

    <!-- content -->
    <div class="tp-RepairView__content">
      <!-- main section content -->
      <div class="tp-RepairView__mainSection">
        <!-- basic information -->
        <basicInfo
          :equipment="equipment"
          :repair.sync="storeRepair"
          :equipment-type="equipmentType"
          :times-in-store="timesInStore"
          :loading="isLoading" />

        <!-- dynamic attributes -->
        <EquipmentDetails :repair="innerRepair" :equipmentType="equipmentType" />

        <!-- problem description -->
        <problem-description
          :repair="innerRepair"
          :loading.sync="loading"
          class="tp-RepairView__entry"/>

        <div class="tp-RepairView__entry is-internalObservation">
          <!-- internal observations -->
          <div>
            <h2 class="title">
              <span>Observações internas</span>
              <el-button
                v-if="storeRepair.state !== 'delivered'"
                icon="el-icon-edit"
                size="mini"
                class="tp-ProblemDescription__entry__title__editBtn"
                @click="internalObservationHandler"/>
            </h2>
            <div class="content">
              <p v-if="storeRepair.internalObservation">{{ storeRepair.internalObservation }}</p>
              <p v-else>-</p>
            </div>
          </div>

          <!-- info to the client -->
          <div>
            <h2 class="title">
              <span>Informação para o Cliente</span>
              <el-button
                v-if="canEditInformationToClient"
                icon="el-icon-edit"
                size="mini"
                class="tp-ProblemDescription__entry__title__editBtn"
                @click="editInformationToClientHandler" />
            </h2>
            <p v-if="storeRepair.repairObservation">{{ storeRepair.repairObservation }}</p>
            <p v-else>-</p>
          </div>
        </div>

        <!-- pendent requests -->
        <material-requests :repair="innerRepair.id"/>

        <!-- used material -->
        <div class="tp-RepairView__entry is-material">
          <h2 class="title">
            <span>Material Usado</span>
            <el-button
              v-if="storeRepair.quotationApproved !== false && storeRepair.state === 'in_repair' && userIsTechnician"
              :loading="loading"
              type="text"
              @click="() => this.$refs.materialAdd.show()">
              Adicionar
            </el-button>
          </h2>

          <div class="content">
            <div v-if="innerRepair.materials.length > 0">
              <el-table
                v-loading="loading"
                :data="innerRepair.materials"
                stripe>
                <el-table-column
                  :width="70"
                  property="quantity"
                  label="Qtn."/>

                <el-table-column label="Descrição">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>

                <!-- store box -->
                <el-table-column
                  :width="100"
                  property="storeBox"
                  label="Caixa"
                  align="center"/>

                <el-table-column label="Disponivel a">
                  <template slot-scope="scope">
                    {{ arrivedMat(scope.row) }}
                  </template>
                </el-table-column>

                <!-- show price without taxas -->
                <el-table-column
                  :width="100"
                  label="Preço"
                  align="center">
                  <template slot-scope="scope">
                    {{ scope.row.price | money }}
                  </template>
                </el-table-column>

                <!-- show price with taxes -->
                <el-table-column
                  :width="150"
                  label="Preço C/ IVA"
                  align="center">
                  <template slot-scope="scope">
                    {{ (scope.row.price * 1.23) | money }}
                  </template>
                </el-table-column>

                <!-- show button to return the material -->
                <el-table-column
                  :width="100"
                  label="">
                  <template slot-scope="scope">
                    <el-button
                      :disabled="isReturnBtnDisabled"
                      size="small"
                      type="warning"
                      @click="returnMaterialHandler(scope.row)">
                      Devolver
                    </el-button>
                  </template>
                </el-table-column>

                <!-- show button to return the material -->
                <el-table-column
                  :width="100"
                  label="">
                  <template slot-scope="scope">
                    <el-button
                      :disabled="isReturnBtnDisabled"
                      size="small"
                      type="danger"
                      @click="returnDamagedMaterialHandler(scope.row)">
                      Defeituoso
                    </el-button>
                  </template>
                </el-table-column>

                <!-- show button to return broken material -->
                <el-table-column
                  :width="100"
                  label="">
                  <template slot-scope="scope">
                    <el-button
                      :disabled="isReturnBtnDisabled"
                      size="small"
                      type="warning"
                      @click="returnBrokenMaterialHandler(scope.row)">
                      Partido
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <div style="margin-top: 10px; text-align: right">
                <span>Total de material usado: </span>
                <b>{{ matTotal | money }}</b>
                <b>/ {{ 1.23 * matTotal | money }}</b>
              </div>
            </div>
            <div
              v-else
              class="no-items">
              <p>Não foi usado material!</p>
            </div>
          </div>
        </div>

        <HistoryPanel :repair-id="repair.id" />
      </div>
    </div>

    <RepairBottomBar
      :isTechnicianUser="userIsTechnician"
      @printReceipt="printReceipt"
      @sendToWarranty="sendToWarranty"
      @showQuotation="quotation.visibility=true"
      @finish="finishHandler"
      @prevRepair="getPrevRepair" />
  </div>

  <ChangeStoreNumberModal
    v-if="modalsVisibility.changeStoreNumber"
    ref="changeStoreNumberModal"
    @closed="modalsVisibility.changeStoreNumber = false" />

  <ChangeRepairPriorityModal
    v-if="modalsVisibility.changeRepairPriority"
    ref="changeRepairPriorityModal"
    @closed="modalsVisibility.changeRepairPriority = false" />

  <ChangeInformationToClientModal
    v-if="modalsVisibility.changeInformationToClient"
    ref="changeInformationToClientModal"
    @closed="modalsVisibility.changeInformationToClient = false" />

  <ChangeValueQuotationModal
    v-if="modalsVisibility.changeValueQuotation"
    ref="changeValueQuotationModal"
    @closed="modalsVisibility.changeValueQuotation = false" />
</div>
