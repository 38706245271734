
<section class="tp-users_wrap">
  <user-edit
    ref="editUserPanel"
    @updated="afterUpdate"
    @created="afterCreation"/>

  <ui-list
    :loading="isLoading"
    :creation-method="add"
    :data="users"
    allow-creation>
    <template slot="rightActions">
      <el-radio-group v-model="selectedFilter">
        <el-radio-button :label="null">Todos</el-radio-button>
        <el-radio-button :label="true">Activos</el-radio-button>
        <el-radio-button :label="false">Inativos</el-radio-button>
      </el-radio-group>
    </template>

    <p slot="noElements">Não existem utilizadores!</p>

    <ui-list-item
      v-for="user in orderedUsers"
      :key="user.id">
      <div slot="image">
        <img
          :src="getAvatar(user.email)"
          class="tp-users_avatar img-circle"
          alt="Avatar" >
      </div>

      <span class="email">{{ user.name }}</span>

      <!-- description -->
      <div slot="details">
        <!-- user type -->
        <span v-if="user.role">{{ user.role.name }}</span>
        <span v-else>Sem grupo</span>
      </div>

      <div slot="actions">
        <el-button
          v-show="user.active"
          type="danger"
          @click.native.prevent="disableUser(user)">Desativar</el-button>
        <el-button
          v-show="!user.active"
          type="success"
          @click.native.prevent="activateUser(user)">Ativar</el-button>

        <el-button
          v-show="user.active"
          :plain="true"
          type="info"
          alt="Editar"
          @click.native="editUser(user)">Editar</el-button>
      </div>
    </ui-list-item>
  </ui-list>
</section>
