<template>
  <ui-bottom-sheet
    ref="editSheet"
    class="tp-TechnicianCommission"
    actions>
    <!-- actions ------------------------------------------------------------- -->
    <div
      slot="actions"
      class="right">
      <el-button @click="show(false)">Cancelar</el-button>
      <el-button
        :disabled="commissions.length === 0"
        type="primary"
        @click="printElem">
        Imprimir
      </el-button>
    </div>

    <el-form label-positon="top">
      <!-- form to select the mounth -->
      <el-form-item label="Mês de Comissões">
        <el-date-picker
          v-model="selectedDate"
          :clearable="false"
          type="month"
          placeholder="Selecione um mês"
          class="tp-TechnicianCommission__select"/>
      </el-form-item>
    </el-form>

    <!-- warning for when there is no commissions for the selected mounth -->
    <el-alert
      v-if="commissions.length === 0"
      :closable="false"
      title="Não existem comissões para o mês selecionado."
      show-icon
      type="info"/>

    <div
      v-if="commissions.length > 0"
      id="toPrint">
      <!-- name -->
      <div class="tp-TechnicianCommission__name">
        {{ technician.name }}
      </div>

      <!-- date -->
      <div class="tp-TechnicianCommission__date">
        Data: {{ selectedDate | dateFormat('yyyy/MM') }}
      </div>


      <commissionsTable :commissions="positiveCommissions" />

      <template v-if="returnedCommissions && returnedCommissions.length > 0">
        <h2 class="tp-TechnicianCommission__sub-title">Devoluções</h2>
        <commissionsReturnTable :commissions="returnedCommissions" />
      </template>

      <div class="tp-TechnicianCommission__group">
        <p>Numero de Reparações: <strong>{{ commissions.length }}</strong></p>
      </div>

      <div class="tp-TechnicianCommission__group">
        <p>Valor total de reparações: <b>{{ totalRepairs.toFixed(3) | money }}</b></p>
        <p>Valor total em material s/iva: <b> {{ totalMat | money }} </b> Total com IVA = <b>{{ (totalMat * 1.23).toFixed(3)| money  }}</b></p>
      </div>

      <div class="tp-TechnicianCommission__group">
        <p>Penalizações: <b>  A calcular </b></p>
        <p>Valor das comissões a deduzir por devolução:  <b> {{ (totalDevolution * -1).toFixed(3) | money }}</b></p>
        <p v-if="totalCommissions <= 80">Valor da comissão:<b> {{ total.toFixed(3) | money }}</b></p>
      </div>

      <div class="tp-TechnicianCommission__group">
        <p>Valor final: {{ total.toFixed(3) }} - {{ totalDevolutionFormated }} = <b> {{ toReceiveFormated | money }}</b></p>

        <p>
          O valor da comissão é calculado usando a seguinte formula: (TotalReparações - ValorMaterial * 1.23 - Valor fixo) * <b>{{ commission }} %</b> <br>
          Ao valor final da comissão poderão ser retiradas as penalizações por reclamações e devoluções. <br>
          As reclamações em que a responsabilidade está por confirmar poderão transitar para o mês em que for definida a responsabilidade.
        </p>
      </div>
    </div>
  </ui-bottom-sheet>
</template>

<script>
import { Loading } from "element-ui";
import { printHTML } from "@/utils/print-html";

import CommissionsTable from "./commissions-table.vue";
import CommissionsReturnTable from "./commissions-returns-table.vue";

export default {
  name: "TechnicianCommissions",

  components: {
    CommissionsTable,
    CommissionsReturnTable,
  },

  data() {
    return {
      selectedDate: new Date(),

      technician: "",
      commissions: [],
      total: 0,
      totalCommissions: 0,
      percentageCommission: [],
    };
  },

  computed: {
    commission() {
      return this.percentageCommission[0].value;
    },
    totalRepairs() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalRepairs, row) => {
        if (row.value <= 0) {
          return totalRepairs;
        }

        return totalRepairs + row.repairValue;
      }, 0);
    },
    totalMat() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalMat, row) => totalMat + row.totalMaterial, 0);
    },
    numberOfCommissions() {
      return this.commissions.filter(item => item.value > 0).length;
    },
    totalDevolution() {
      if (this.commissions.length === 0) {
        return 0;
      }

      return this.commissions.reduce((totalRepairs, row) => {
        if (row.value > 0) {
          return totalRepairs;
        }

        return totalRepairs + row.value;
      }, 0);
    },
    totalDevolutionFormated() {
      return Math.abs(this.totalDevolution.toFixed(3));
    },
    toReceiveFormated() {
      return (this.total - Math.abs(this.totalDevolution)).toFixed(3);
    },

    /**
     * Get the last day of the year.
     */
    lastDayOfMounth() {
      // this set Date to first day (00h:01m:00s)of next month of selectedDate (example: if month = 10(october) selectedDate is set to 1 nov 00:01:00)
      return new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 1, 0, 1);
    },

    returnedCommissions() {
      return this.commissions.filter(entry => entry.value < 0);
    },

    positiveCommissions() {
      return this.commissions.filter(entry => entry.value > 0);
    },
  },

  watch: {
    selectedDate(newVal) {
      this.fetchData();
    },
  },

  methods: {
    setTechnician(technician) {
      this.technician = JSON.parse(JSON.stringify(technician));
    },

    show(val = true) {
      // if this methods was called with a true value we must call the
      // fetchData method
      if (val) {
        this.fetchData();
      }

      // change the panel visibility
      this.$refs.editSheet.show(val);
    },

    /**
     * Fetch the data for the selected date.
     */
    async fetchData() {
      const configs = await this.$stellar.action("getConfigs");
      this.percentageCommission = configs.configs.filter(config => config.key === "commission.percentage");

      const loadingInstance = Loading.service({ fullscreen: true });

      // get first day of the selected mounth
      const firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);

      // Request the data to the API
      this.$stellar
        .action("getCommissionForTechnician", {
          technician: this.technician.id || this.technician._id,
          start: firstDay,
          end: this.lastDayOfMounth,
        })
        .then(response => {
          // save the response data
          this.commissions = response.repairs;
          this.total = response.total;
          this.totalCommissions = this.commissions.length;

          // disable loading
          loadingInstance.close();

          // recompute the bootom sheet size
          this.$nextTick(_ => {
            this.$refs.editSheet.adjustSize();
          });
        })
        .catch(_ => {});
    },

    /**
     * Print out the information.
     */
    printElem() {
      const printStyle = `
        body {
          font-family: Roboto, 'arial', sans-serif;
          font-size: 12pt;
          color: #757575 !important;

          -webkit-print-color-adjust: exact;
        }
        .tp-TechnicianCommission__name {
          font-size: 1.5em;
          margin-bottom: 10px;
        }
        .tp-TechnicianCommission__date {
          margin-bottom: 10px;
        }
        .el-table {
          overflow: hidden;
          background-color: #fff !important;
          border: 1px solid #e0e6ed !important;
          font-size: 14px;
          color: #1f2d3d;

          margin-bottom: 10px;
        }
        .el-table .cell {
          padding: 0 10px;
        }
        .el-table__body, .el-table__header {
          table-layout: fixed;
        }
        .el-table th {
          overflow: hidden;
          background-color: #EFF2F7 !important;
          text-align: left;
        }
        .el-table td, .el-table th {
          height: 40px;
          min-width: 0;
          text-overflow: ellipsis;
        }
        .el-table--striped .el-table__body tr:nth-child(2n) td {
          background: #FAFAFA !important;
        }
        .el-table .cell {
          white-space: normal;
          word-break: break-all;
          line-height: 24px;
        }
        .expand-trigger { display: none; }

        .el-table, .el-table__body, .el-table__header {
          width: 756px !important;
        }
        tr td {
          width: 100px !important;
        }
      `;

      const contents = document.getElementById("toPrint").innerHTML;
      printHTML(contents, printStyle, "");
    },
  },
};
</script>

<style lang="scss">
.tp-TechnicianCommission {
  &__sub-title {
    margin-top: 16px;

    font-size: 18px;
    font-weight: 500;
  }

  &__select {
    width: 100% !important;
  }

  &__name {
    font-size: 1.5em;
    margin-bottom: 5px;
  }

  &__date {
    margin-bottom: 10px;
  }

  &__group {
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }
}
</style>
