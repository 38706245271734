
<el-dialog
  :visible.sync="dialogVisible"
  :show-close="false"
  title="Grupo">
  <el-form
    ref="form"
    :model="group"
    :rules="rules"
    label-position="top"
    class="tp-Contacts__form">
    <!-- name -->
    <el-form-item
      label="Nome"
      prop="name">
      <el-input
        v-model="group.name"
        type="text"
        placeholder="Nome"/>
    </el-form-item>

    <!-- role -->
    <el-form-item
      label="Grupos de Utilizadores"
      prop="roles">
      <el-select
        v-model="group.roles"
        :style="{ width: '100%' }"
        multiple
        placeholder="Papeis">
        <el-option
          v-for="item in roles"
          :key="item.id"
          :label="item.name"
          :value="item.id"/>
      </el-select>
    </el-form-item>
  </el-form>

  <span
    slot="footer"
    class="dialog-footer">
    <el-button
      @click="cancelHandler">
      Cancel
    </el-button>

    <el-button
      :loading="loading"
      type="primary"
      @click="saveHandler">
      Guardar
    </el-button>
  </span>
</el-dialog>
