
<section class="warranty-list-to-dispatch">
  <SelectTechnicianModal
    :visibility.sync="isSelectRepairVisible"
    @selected="changeRepair" />

  <PageToolbar>
    <template slot="left">
      <el-select
        v-model="entity"
        placeholder="Entidade"
        size="small">
        <el-option
          v-for="e in entities"
          :key="e.value"
          :label="e.name"
          :value="e.id" />
      </el-select>
      <el-button
        :disabled="isGetButtonDisabled"
        type="success"
        size="small"
        @click="fetchList">
        Obter
      </el-button>
    </template>

    <template slot="right">
      <el-button
        :loading="loading"
        size="small"
        type="success"
        @click="dispatchHandler">
        Enviar
      </el-button>
    </template>
  </PageToolbar>

  <el-table
    v-if="entity !== null && requested"
    id="toPrint"
    :data="repairs"
    :loading="loading">
    <el-table-column
      :width="100"
      label="N. Ficha"
      prop="repairNumber" />

    <el-table-column
      :width="300"
      label="Equipamento">
      <template slot-scope="scope">
        {{ scope.row | formatEquipmentName }}
      </template>
    </el-table-column>

    <el-table-column
      :width="160"
      label="Imei/SN">
      <template slot-scope="scope">
        <span
          v-if="scope.row.clientEquipment.attributes && (scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber)">
          {{ scope.row.clientEquipment.attributes.imei || scope.row.clientEquipment.serialNumber
          }}
        </span>
        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column
      label="Anomalia"
      prop="problemDescription" />
    <el-table-column
      :width="270"
      label="">
      <template slot-scope="scope">
        <el-button
          :loading="loading"
          type="success"
          size="mini"
          @click="onReturnRepairClick(scope.row)">
          Atribuir a Tecnico
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <div
    v-else
    class="warranty-list-to-dispatch__empty-panel">
    <span>Selecine uma entidade para obter os equipamentos a enviar.</span>
  </div>
</section>
