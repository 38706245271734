import Vue from "vue";

Vue.filter("formatMaterialName", material => {
  let out = material.description;

  if (material.brand.length > 0) {
    out += `, ${material.brand}`;
  }

  if (material.model.length > 0) {
    out += ` ${material.model}`;
  }

  return out;
});
