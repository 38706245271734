
<el-dialog
  v-loading.fullscreen="isLoading"
  :visible.sync="visibility"
  title="Alterar Prioridade"
  size="tiny">
  <p class="repair-priority-modal__description">Alterar a prioridade da reparação para:</p>
  <el-select
    v-model="repairPriority"
    placeholder="select">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value" />
  </el-select>

  <div
    slot="footer"
    class="dialog-footer">
    <el-button
      :loading="isLoading"
      @click="closeHandler">
      Cancelar
    </el-button>
    <el-button
      :loading="isLoading"
      type="primary"
      @click="updateRepairPriorityHandler">
      Alterar
    </el-button>
  </div>
</el-dialog>
