
<ui-bottom-sheet
  ref="editSheet"
  class="tp-TechnicianCommission"
  actions>
  <!-- actions ------------------------------------------------------------- -->
  <div
    slot="actions"
    class="right">
    <el-button @click="show(false)">Cancelar</el-button>
    <el-button
      :disabled="commissions.length === 0"
      type="primary"
      @click="printElem">
      Imprimir
    </el-button>
  </div>

  <el-form label-positon="top">
    <!-- form to select the mounth -->
    <el-form-item label="Mês de Comissões">
      <el-date-picker
        v-model="selectedDate"
        :clearable="false"
        type="month"
        placeholder="Selecione um mês"
        class="tp-TechnicianCommission__select"/>
    </el-form-item>
  </el-form>

  <!-- warning for when there is no commissions for the selected mounth -->
  <el-alert
    v-if="commissions.length === 0"
    :closable="false"
    title="Não existem comissões para o mês selecionado."
    show-icon
    type="info"/>

  <div
    v-if="commissions.length > 0"
    id="toPrint">
    <!-- name -->
    <div class="tp-TechnicianCommission__name">
      {{ technician.name }}
    </div>

    <!-- date -->
    <div class="tp-TechnicianCommission__date">
      Data: {{ selectedDate | dateFormat('yyyy/MM') }}
    </div>


    <commissionsTable :commissions="positiveCommissions" />

    <template v-if="returnedCommissions && returnedCommissions.length > 0">
      <h2 class="tp-TechnicianCommission__sub-title">Devoluções</h2>
      <commissionsReturnTable :commissions="returnedCommissions" />
    </template>

    <div class="tp-TechnicianCommission__group">
      <p>Numero de Reparações: <strong>{{ commissions.length }}</strong></p>
    </div>

    <div class="tp-TechnicianCommission__group">
      <p>Valor total de reparações: <b>{{ totalRepairs.toFixed(3) | money }}</b></p>
      <p>Valor total em material s/iva: <b> {{ totalMat | money }} </b> Total com IVA = <b>{{ (totalMat * 1.23).toFixed(3)| money  }}</b></p>
    </div>

    <div class="tp-TechnicianCommission__group">
      <p>Penalizações: <b>  A calcular </b></p>
      <p>Valor das comissões a deduzir por devolução:  <b> {{ (totalDevolution * -1).toFixed(3) | money }}</b></p>
      <p v-if="totalCommissions <= 80">Valor da comissão:<b> {{ total.toFixed(3) | money }}</b></p>
    </div>

    <div class="tp-TechnicianCommission__group">
      <p>Valor final: {{ total.toFixed(3) }} - {{ totalDevolutionFormated }} = <b> {{ toReceiveFormated | money }}</b></p>

      <p>
        O valor da comissão é calculado usando a seguinte formula: (TotalReparações - ValorMaterial * 1.23 - Valor fixo) * <b>{{ commission }} %</b> <br>
        Ao valor final da comissão poderão ser retiradas as penalizações por reclamações e devoluções. <br>
        As reclamações em que a responsabilidade está por confirmar poderão transitar para o mês em que for definida a responsabilidade.
      </p>
    </div>
  </div>
</ui-bottom-sheet>
