
<section class="tp-RepairsList">
  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler" />

  <!-- list filters -------------------------------------------------------- -->
  <div class="tp-RepairsList__filters">
    <div>
      <!-- select technician -->
      <el-select
        v-model="filters.technician"
        placeholder="Técnico"
        class="tp-RepairsList__filters__technician"
        clearable>
        <el-option
          v-for="tech in technicians"
          :key="tech.id"
          :value="tech.id"
          :label="tech.name">
          <span>{{ tech.name }}</span>
        </el-option>
      </el-select>

      <!-- states -->
      <el-select
        v-model="filters.states"
        class="tp-RepairsList__filters__states"
        placeholder="Estado"
        clerable
        multiple>
        <el-option
          v-for="state in allStatesOrdered"
          :key="state.key"
          :value="state.key"
          :label="state.value">
          <span>{{ state.value }}</span>
        </el-option>
      </el-select>

      <el-date-picker
        v-model="range"
        :picker-options="pickerOptions"
        class="tp-RepairList__filters__dateSet"
        type="daterange"
        align="right"
        placeholder="Intervalo de Datas"
        @change="fetchPage"/>
    </div>

    <div class="tp-RepairsList__filters__right">
      <el-checkbox
        v-model="filters.rejectQuotation"
        class="tp-RepairsList__filters__quotation">
        Orçamento rejeitado
      </el-checkbox>

      <!-- client filter -->
      <client-selection
        ref="clientSelect"
        class="tp-RepairsList__filters__client"
        @change="clientChanged"/>

      <!-- search bar  -->
      <el-input
        v-model="searchQuery"
        class="tp-RepairsList__filters__search"
        placeholder="Reparações..."/>

      <!-- clean -->
      <el-button
        :plain="true"
        type="primary"
        size="small"
        class="tp-RepairsList__filters__clean"
        @click="cleanFilters">
        Limpar
      </el-button>

    </div>
  </div>

  <!-- client dialog -->
  <el-dialog
    :visible.sync="seeClientDialog"
    title="Informação do cliente"
    size="small">
    <client-card :client="client"/>
  </el-dialog>

  <!-- list -------------------------------------------------------------- -->
  <div class="tp-RepairsList__tableContent">
    <el-table
      v-loading="loading"
      :data="repairs"
      :row-class-name="tableRowClassName"
      stripe
      class="tp-RepairsList__table"
      style="width: 100%">
      <!-- repair number -->
      <el-table-column
        :width="70"
        label="#">
        <template slot-scope="scope">
          <div class="tp-repairList_quotation">
            <span class="tp-RepairsList-approved">
              <el-tooltip
                v-if="scope.row.quotationApproved === true"
                effect="dark"
                content="Orçamento aprovado"
                placement="top">
                <div class="tp-RepairsList-approved__circle"/>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.quotationApproved === false"
                effect="dark"
                content="Orçamento recusado"
                placement="top">
                <div class="tp-RepairsList-rejected__circle"/>
              </el-tooltip>
              {{ scope.row.repairNumber }}
            </span>
          </div>
        </template>

      </el-table-column>

      <!-- store number -->
      <el-table-column
        :width="95"
        label="F. Loja"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.storeRepairIdentifier || '-' }}
        </template>
      </el-table-column>

      <!-- show the box where the equipment is stored -->
      <el-table-column
        :width="60"
        align="center"
        label="Cx.">
        <template slot-scope="scope">
          {{ scope.row.storeBox || '-' }}
        </template>
      </el-table-column>

      <!-- equipment brand and model -->
      <el-table-column
        :width="150"
        label="Modelo">
        <template slot-scope="scope">
          <span>{{ scope.row.clientEquipment.brand
          }} {{ scope.row.clientEquipment.model }}</span>
        </template>
      </el-table-column>

      <!-- client -->
      <el-table-column
        :width="200"
        label="Cliente">
        <template slot-scope="scope">
          <a @click="seeClient(scope.row.client)">
            <span
              v-if="scope.row.client.isStore">{{ scope.row.client.name | truncate(20)
              }}</span>
            <span v-else>{{ scope.row.client.name }}</span>
          </a>
        </template>
      </el-table-column>

      <!-- repair state -->
      <el-table-column
        :width="150"
        label="Estado">
        <template slot-scope="{ row }">
          <span>{{ row.state | repairState }}</span>
        </template>
      </el-table-column>

      <!-- problem -->
      <el-table-column
        label="Avaria"
        align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.problemName === 'Outro'">
            <span>{{ scope.row.problemDescription }}</span>
          </div>
          <div v-else>
            <span>{{ scope.row.problemName }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :width="100"
        label="Entrada"
        align="center">
        <template slot-scope="scope">
          {{ diffedDuration(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <!-- operations -->
      <el-table-column
        :width="200"
        :context="_self"
        label="Operações">
        <template slot-scope="scope">
          <!-- see repair details -->
          <el-button
            size="mini"
            type="info"
            @click="showRepairDetails(scope.row)">Ver
          </el-button>

          <!-- change technician -->
          <el-button
            :loading="loading"
            :disabled="!canChangeTechnician(scope.row)"
            size="mini"
            type="warning"
            @click="changeTechnician(scope.row)">Alterar Tecnico
          </el-button>
        </template>
      </el-table-column>

      <!-- technician -->
      <el-table-column
        :width="120"
        label="Tecnico">
        <template slot-scope="scope">
          <span v-if="scope.row.technician">{{ scope.row.technician.name }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- pagination ------------------------------------------------------ -->
    <el-pagination
      :current-page="currentPage"
      :page-size="20"
      :total="total"
      class="tp-RepairsList__pagination"
      layout="prev, pager, next"
      @current-change="currentPageChange"/>
  </div>
</section>
