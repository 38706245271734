<template>
  <div class="tp-typesList_wrap">
    <!-- type form ----------------------------------------------------------- -->

    <type-form ref="form"/>

    <!-- equipment list ------------------------------------------------------ -->

    <ui-list :data="resources">
      <ui-list-item
        v-for="item in orderedData"
        :key="item.id">
        <!-- name -->
        <span class="name">{{ item.name }}</span>

        <!-- actions -->
        <div slot="actions">
          <!-- edit types -->
          <el-button
            type="info"
            size="small"
            @click="editHandler(item)">
            <i class="fa fa-pencil"/>
          </el-button>

          <!-- enable and disable types -->
          <el-button
            v-show="item.active"
            type="danger"
            size="small"
            @click="disable(item)">Desativar</el-button>
          <el-button
            v-show="!item.active"
            type="success"
            size="small"
            @click="activate(item)">Ativar</el-button>
        </div>
      </ui-list-item>
    </ui-list>
  </div>
</template>

<script>
import _ from "lodash";

import TypeFormCmp from "./TypeForm.vue";

export default {
  components: {
    TypeForm: TypeFormCmp,
  },

  data() {
    return {
      resources: [],
    };
  },

  computed: {
    orderedData() {
      return _.orderBy(this.resources, "name");
    },
  },

  created() {
    // get all equipment types
    this.fetchData();
  },

  methods: {
    /**
     * Fetch all equipments types.
     */
    fetchData() {
      this.$stellar
        .action("getEquipmentTypes", { onlyActives: false })
        .then(({ equipments }) => {
          this.resources = equipments;
        });
    },

    editHandler(resource) {
      const panel = this.$refs.form;

      panel.setResource(resource);

      panel.show();
    },

    disable(resource) {
      this.$confirm(
        "Tem a certeza que pretende desativar o tipo de equipamento?",
        "Desativar tipo",
        {
          type: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Desativar",
        },
      )
        .then(() => {
          // make a call to the API to disable the resource
          return this.$stellar.action("disableEquipmentType", {
            id: resource.id,
          });
        })
        .then(response => {
          // change the response state on the list
          this.resources.find(el => el.id === resource.id).active = false;

          // show a message
          this.$message.success("Tipo desativado");
        });
    },

    activate(resource) {
      this.$confirm(
        "Tem a certeza que pretende ativar o tipo de equipamento?",
        "Ativar tipo",
        {
          type: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Ativar",
        },
      )
        .then(() => {
          // make a call to the API to activate the resource
          return this.$stellar.action("activateEquipmentType", {
            id: resource.id,
          });
        })
        .then(response => {
          // change the response state on the list
          this.resources.find(el => el.id === resource.id).active = true;

          // show a message
          this.$message.success("Tipo ativado");
        });
    },
  },
};
</script>
