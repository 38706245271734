
<section class="tp-materialListReseller_wrap">
  <!-- list -------------------------------------------------------------- -->
  <div class="tp-materialListReseller__content">

    <!-- action -->
    <div class="tp-materialListReseller__actions">
      <!-- search -->
      <el-input
        v-model="searchQuery"
        class="tp-materialListReseller__search"
        placeholder="Pesquise aqui por materiais..."/>
    </div>

    <el-table
      v-loading.body="loading"
      :data="orderedData"
      stripe>
      <el-table-column label="Descrição">
        <template slot-scope="scope">
          {{ scope.row.description }} , {{ scope.row.brand }} {{ scope.row.model }}
        </template>
      </el-table-column>

      <el-table-column
        :width="90"
        label="Preço"
        align="center">
        <template slot-scope="{ row }">
          <span v-if="row.price > 0">{{ row.price + resellerCommission | money }}</span>
          <span v-else>{{ row.price | money }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :width="150"
        label="Preço c/ IVA"
        align="center">
        <template slot-scope="{ row }">
          <span v-if="row.price > 0">{{ (row.price + resellerCommission) * 1.23 | money }}</span>
          <span v-else>{{ row.price | money }}</span>
        </template>
      </el-table-column>

      <!-- stock (new and used) -->
      <el-table-column
        :width="100"
        label="Stock"
        align="center">
        <template slot-scope="scope">
          <el-tag :type="getTagColor(scope.row)">{{ scope.row.newStock }}/{{ scope.row.usedStock }}</el-tag>
        </template>
      </el-table-column>

      <!-- purchase -->
      <el-table-column
        :width="100"
        label="Aguarda"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.requestedQuantity }}
        </template>
      </el-table-column>
    </el-table>

    <!-- pagination ------------------------------------------------------ -->

    <el-pagination
      :current-page="currentPage"
      :total="total"
      :page-size="20"
      class="tp-materialListReseller__pagination"
      layout="prev, pager, next"
      @current-change="currentPageChange"/>
  </div>
</section>
