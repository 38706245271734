
<div class="tp-InTransit">
  <ui-list
    :loading="loading"
    :data="repairs"
    selectable
    @selection-change="selectionChangeHandler">
    <span slot="actions">
      <!-- delivery selection -->
      <el-button
        :pain="true"
        type="primary"
        @click="onClickDeliverySelectionHandler">
        Entrar em Loja
      </el-button>
    </span>

    <ui-list-item
      v-for="item in repairs"
      :key="item.id"
      :data="item">
      <!-- name -->
      <span>#{{ item.repairNumber }} - {{ item.clientEquipment.brand }} {{ item.clientEquipment.model }}</span>

      <!-- decription (equipment) -->
      <span slot="details">Caixa {{ item.storeBox }}</span>

      <!-- actions -->
      <div slot="actions">
        <!-- see -->
        <el-button
          type="info"
          @click="showRepairDetails(item)">Ver</el-button>
      </div>
    </ui-list-item>
  </ui-list>
</div>
