import Vue from "vue";
import { format } from "date-fns";

/**
 * Filter to format the given date directly on Vue templates.
 */
Vue.filter("dateFormat", (value: Date | string, dateFormat: string) => {
  const dateToFormat = typeof value === "string" ? new Date(value) : value;
  return format(dateToFormat, dateFormat);
});
