<template>
<div class="tp-RepairView__bottomBar">
  <!-- other actions -->
  <el-dropdown
    split-button
    size="small"
    type="primary">
    Outros
    <el-dropdown-menu slot="dropdown">
      <!-- change value -->
      <el-dropdown-item
        v-if="repair.state == 'waiting_delivery' || repair.state == 'closed_without_permission' && $auth.is('admin')"
        @click.native="changeValueHandler">
        Alterar Valor
      </el-dropdown-item>

      <!-- cancel repair -->
      <el-dropdown-item
        v-if="canCancelCommission"
        @click.native="cancelComissionHandler">
        Cancelar Comissão
      </el-dropdown-item>

      <!-- receipt-->
      <el-dropdown-item @click.native="$emit('printReceipt')">
        Imprimir Talão
      </el-dropdown-item>

      <!-- change repair to complaint -->
      <el-dropdown-item
        v-if="repair.state != 'closed_without_permission' && repair.state != 'waiting_delivery' && repair.state != 'delivered' && repair.state != 'waiting_complaint_avaluation'"
        @click.native="changeRepairToComplaint">
        Reclamação
      </el-dropdown-item>

      <!-- send repair to warranty -->
      <el-dropdown-item
        @click.native="$emit('sendToWarranty')">
        Garantia
      </el-dropdown-item>

    </el-dropdown-menu>

  </el-dropdown>

  <!-- quotation -->
  <template v-if="showApproveQuotation">
    <el-button :loading="loading" size="small" type="success" @click.native="approveQuotationHandler">
      Aprovar Orçamento
    </el-button>

    <el-button :loading="loading" size="small" type="danger" @click.native="rejectQuotationHandler">
      Recusar Orçamento
    </el-button>
  </template>

  <div v-if="$auth.can('see_delivers')">
    <!-- delevery action -->
    <el-button
      v-if="['waiting_delivery', 'closed_without_permission', 'problematic'].includes(repair.state)"
      :loading="loading"
      size="small"
      type="success"
      @click="closeHandler">
      Entregar
    </el-button>

    <!-- button to cancel the repair -->
    <el-button
      v-if="!['waiting_delivery', 'closed_without_permission', 'delivered'].includes(repair.state)"
      :loading="loading"
      size="small"
      type="danger"
      @click="cancelHandler">
      Cancelar
    </el-button>
  </div>

  <div
    v-if="repair.state !== 'problematic'"
    class="noBlock">
    <div
      v-if="repair.state === 'in_repair' && isTechnicianUser"
      class="noBlock">
      <span>
        <el-button
          :loading="loading"
          size="small"
          type="warning"
          @click="$emit('showQuotation')">
          Orçamento
        </el-button>

        <!-- state change -->
        <el-button
          :loading="loading"
          size="small"
          type="info"
          @click="stopRepairHandler">
          Pausar
        </el-button>
        <el-button
          :loading="loading"
          size="small"
          type="info"
          @click="handleInTestes">
          Em Testes
        </el-button>
      </span>

      <el-button
        :loading="loading"
        size="small"
        type="warning"
        @click="$emit('finish')">
        Fechar Reparação
      </el-button>
    </div>
    <div v-else>
      <!-- start button -->
      <el-button
        v-if="showStartButton"
        :loading="loading"
        :disabled="workHasOpenRepair"
        size="small"
        type="primary"
        @click="startRepairHandler">
        Iniciar
      </el-button>
    </div>
  </div>

  <!-- problematic repairs -->
  <div v-else>
    <el-button
      v-if="$auth.can('see_work')"
      :disabled="workLastUpdate === null"
      :loading="isLoading"
      size="small"
      type="warning"
      @click="pickStartHandle">
      Pegar & Iniciar
    </el-button>

    <el-button
      :loading="loading"
      size="small"
      type="danger"
      @click="$emit('finish')">
      Fechar reparação
    </el-button>
  </div>
  <span v-if="repair.complaint === true && repair.prevRepair">
    <el-button
      :loading="loading"
      size="small"
      type="success"
      @click="emit('prevRepair')">
      Rep. Anterior
    </el-button>
  </span>
</div>
</template>

<script lang="ts">
import Vue from "vue";
import { get } from "vuex-pathify";

import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "RepairBorromBar",

  props: {
    isTechnicianUser: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["user", "workHasOpenRepair", "workLastUpdate"]),

    repair: get("repair/repair"),

    loading: get("repair/loading"),

    /**
     * Check if the current app state meets all the necessary requirements to
     * show:
     *
     * - be the associated technician
     * - repair state must be 'waiting_repair' ou 'paused'
     */
    showStartButton() {
      const validStates = ["waiting_repair", "paused", "in_tests"];

      if (typeof this.repair.technician === "string") {
        return false;
      }

      return (
        this.repair.technician &&
        this.repair.technician.id === this.user.personId &&
        validStates.indexOf(this.repair.state) > -1
      );
    },

    /**
     * Can present the option to approve quotation to the user?
     */
    showApproveQuotation() {
      return this.repair.state === "waiting_quotation_approve" && this.$auth.can("edit_quotation");
    },

    canCancelCommission() {
      return ["delivered", "waiting_delivery"].includes(this.repair.state) && this.$auth.can("cancel_commission");
    },
  },

  methods: {
    ...mapActions(["putWorkInTests", "putWorkInRepair", "putWorkInPause"]),

    /**
     * Change the repair value.
     */
    async changeValueHandler() {
      try {
        await this.$confirm("Tem a certeza que pretende alterar o valor?", "Alterar Valor", {
          type: "warning",
          confirmButtonText: "Continuar",
        });
      } catch (_) {
        return;
      }

      // Open a prompt requesting the new value and update the value on the server
      const { value } = await this.$prompt("Por favor, insira o novo valor", "Alterar Valor", {
        inputValue: this.repair.value,
      });
      await this.$store.dispatch(ERepairActions.CHANGE_VALUE, value);

      this.$message.success("Valor atualizado");
    },

    /**
     * Cancel commision.
     */
    async cancelComissionHandler() {
      // request the cancelation reason to the user
      const { value } = await this.$prompt("Insira a razão do cancelamento", "Cancelar Comissão");

      // if the value is empty show a error message
      if (value.length === 0) {
        return this.$message.warning("Justificação obrigatória.");
      }

      await this.$store.dispatch(ERepairActions.CANCEL_COMMISSION, value.trim());
      this.$message.success("Comissão Cancelada");
    },

    // change repair to complaint
    async changeRepairToComplaint() {
      try {
        this.$confirm("Tem a certeza que pretende alterar a reparação para reclamação?", "Alterar Para reclamação", {
          type: "warning",
          confirmButtonText: "Continuar",
        });
      } catch (e) {
        return;
      }

      // Open a prompt requesting the new value
      const { value } = await this.$prompt("Por favor, insira a razão", "Justificação", {
        inputValue: "",
      });

      const isSuccess = await this.$store.dispatch(ERepairActions.CHANGE_TO_COMPLAINT, value.trim());

      if (isSuccess) {
        this.$message.success("Acção efectuada com sucesso.");
      } else {
        this.$message.error("Impossivel converter para reclamação.");
      }
    },

    async approveQuotationHandler() {
      try {
        // request confirmation to the user
        await this.$confirm("Pretende aprovar o orçamento?", "Aprovar", {
          confirmButtonText: "Aprovar",
        });
      } catch (_) {
        return;
      }

      const { value } = await this.$prompt(
        "Defina o valor do orçamento: ",
        "Tecnico externo, alterar valor e orçamento",
        {
          confirmButtonText: "Definir",
          cancelButtonText: "Cancelar",
          inputValue: this.repair.quotation || 0,
        },
      );

      // update data on the API
      const valueToSet = value || this.repair.quotation;
      await this.$store.dispatch(ERepairActions.APPROVE_QUOTATION, valueToSet);

      this.$message.success("Orçamento aprovado");
    },

    async rejectQuotationHandler() {
      try {
        await this.$confirm("Pretende rejeitar o orçamento?", "Rejeitar", {
          confirmButtonText: "Rejeitar",
          type: "warning",
        });
      } catch (_) {
        return;
      }

      await this.$store.dispatch(ERepairActions.REJECT_QUOTATION);
      this.$message.success("Orçamento aprovado.");
    },

    /**
     * Change the repair state.
     */
    async closeHandler() {
      await this.$store.dispatch(ERepairActions.DELIVER);
      this.$message.success("Equipamento entrege.");

      // redirect to the list of repairs
      this.$router.push({ name: "repairs.list" });
    },

    /**
     * Cancel a repair.
     */
    async cancelHandler() {
      try {
        await this.$confirm("Tem certeza que pretende cancelar a reparação?", "Cancelar Reparação", {
          type: "warning",
          cancelButtonText: "Não",
          confirmButtonText: "Cancelar",
        });
      } catch (e) {
        return;
      }

      this.$store.dispatch(ERepairActions.CANCEL);
      this.$message.success("Reparação cancelada.");

      if (this.repair.storeBox) {
        this.$alert(`O dispositivo deve ser levantado da caixa ${this.repair.storeBox}.`, {
          confirmButtonText: "Feito!",
        });
      }

      // redirect to dashboard
      this.$router.push({ name: "dashboard" });
    },

    /**
     * Put a repair into "in testes".
     */
    async handleInTestes() {
      await this.putWorkInTests(this.repair.id);
      this.$message.success("Reparação entrou em testes");

      this.$store.dispatch(ERepairActions.RELOAD);

      // redirect technician back to the list of work
      this.$router.push({ name: "work.list" });
    },

    /**
     * Start repair.
     */
    async startRepairHandler() {
      await this.putWorkInRepair(this.repair.id);
      this.$message.success("Reparação pronta a ser trabalho");

      this.$store.dispatch(ERepairActions.RELOAD);
    },

    /**
     * Put a repair into pause.
     */
    async stopRepairHandler() {
      try {
        await this.putWorkInPause(this.repair.id);
      } catch (exception) {
        if (exception.error === "max_repairs_paused") {
          this.$message.error("Não pode ter mais reparações em pausa.");
        }

        return;
      }

      this.$router.push({ name: "work.list" });
      this.$message.success("Reparação pausada");
    },

    async pickStartHandle() {
      if (this.workHasOpenRepair) {
        try {
          await this.$confirm("Pretende pausar a reparação atual?", "Reparação em progresso", {
            type: "warning",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Pausar",
          });

          await this.stopRepairHandler();
        } catch (_) {
          return;
        }
      }

      // change the technician for the current logged user and update the repair data
      await this.putWorkInRepair([this.repair.id, true]);
      await this.$store.dispatch(ERepairActions.RELOAD);

      this.$message.success("Reparação transferida");
    },
  },
});
</script>
