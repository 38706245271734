<template>
  <div class="tp-clientSendNotification_wrap">
    <el-popover
      ref="popover"
      v-model="visibility"
      placement="top"
      width="160">
      <p>Pretende enviar a mensagem? Esta operação não pode ser desfeita!</p>
      <div style="text-align: right; margin: 0">
        <el-button
          size="mini"
          type="text"
          @click="visibility = false">Cancelar</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="sendMessage">
          Enviar
        </el-button>
      </div>
    </el-popover>

    <ui-bottom-sheet
      ref="sheet"
      actions>

      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          v-popover:popover
          :loading="loading"
          type="primary">
          Enviar
        </el-button>
        <el-button @click="show(false)">Cancelar</el-button>
      </div>

      <el-form
        ref="notification"
        :model="notification"
        :rules="rules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item
              label="Insira a mensagem a ser enviada ao cliente"
              prop="message">
              <el-input
                v-model="notification.message"
                type="textarea"
                placeholder="Mensagem"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
import stellar from "@/services/stellar";

export default {
  data() {
    return {
      notification: {
        message: "",
      },

      client: null,
      visibility: false,
      loading: false,

      rules: {
        message: [
          {
            required: true,
            message: "Introduza a mensagem para enviar ao cliente",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    setClient(client) {
      this.client = client;
    },

    sendMessage() {
      this.visibility = false;

      // enable loading
      this.loading = true;

      this.$refs.notification.validate(valid => {
        if (!valid) {
          // disable loading
          this.loading = false;
          return;
        }

        // make an API call to create a new notification
        stellar
          .action("createClientNotification", {
            message: this.notification.message,
            client: this.client.id || this.client._id,
          })
          .then(response => {
            // disabled loading
            this.loading = false;

            // close panel
            this.show(false);

            // show a toast
            this.$message.success("Mensagem criada!");
          });
      });
    },

    show(val = true) {
      this.$refs.sheet.show(val);

      if (!val) {
        this.reset();
      }
    },

    reset() {
      this.notification.message = "";
      this.loading = false;
      this.visibility = false;
    },
  },
};
</script>
