
<div class="repair-list-entry">
  <div class="repair-list-entry__device-info">
    <img
      :src="equipmentTypeImgUrl"
      class="repair-list-entry__device-type">
    <div class="repair-list-entry-info">
      <div
        class="repair-list-entry-info__number"
        @click="$router.push({ name: 'repair.single', params: { id: repair.id } })">
        {{ repair.repairNumber }}
      </div>
      <div class="repair-list-entry-info__device">
        {{ repair.clientEquipment.model }}
      </div>
    </div>
  </div>
  <div class="repair-list-entry__state">
    <RepairState :state="repair.state"/>
  </div>
  <div class="repair-list-entry__problem repair-list-entry-item">
    <div class="repair-list-entry-item__title">Problema</div>
    <div class="repair-list-entry-item__content">
      <span class="repair-list-entry__problem-name">({{ repair.problemName }}) </span>
      <span class="repair-list-entry__problem-description">{{ repair.problemDescription }}</span>
    </div>
  </div>
  <div class="repair-list-entry__client repair-list-entry-item">
    <div class="repair-list-entry-item__title">Cliente</div>
    <div class="repair-list-entry-item__content">
      {{ repair.client.name }}
    </div>
  </div>
  <div class="repair-list-entry__extra-info">

    <!-- material available -->
    <el-tooltip
      v-if="repair.materials.length > 0 && repair.state !== 'waiting_material'"
      content="Material disponivel"
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--green">
        <span class="repair-list-entry-tag__text is-box">Material disponivel</span>
      </div>
    </el-tooltip>

    <!-- material available -->
    <el-tooltip
      v-if="repair.materials.length > 0 && repair.state === 'waiting_material'"
      content="Espera Material "
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--grey">
        <span class="repair-list-entry-tag__text is-box">Espera material</span>
      </div>
    </el-tooltip>

    <!-- quotation rejected -->
    <el-tooltip
      v-if="repair.quotationApproved === false"
      content="Orçamento recusado"
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--red">
        <span class="repair-list-entry-tag__text is-euro">Orçamento recusado</span>
      </div>
    </el-tooltip>

    <!-- quotation approved -->
    <el-tooltip
      v-if="repair.quotationApproved === true"
      content="Orçamento aprovado"
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--green">
        <span class="repair-list-entry-tag__text is-euro">Orçamento aprovado</span>
      </div>
    </el-tooltip>

    <!-- urgent Repairs -->
    <el-tooltip
      v-if="repair.priority === 'urgent'"
      content="Urgente"
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--red">
        <span class="repair-list-entry-tag__text is-alert">Urgente</span>
      </div>
    </el-tooltip>

    <!-- complaint Repairs -->
    <el-tooltip
      v-if="repair.complaint === true"
      content="Reclamação"
      placement="top">
      <div
        class="repair-list-entry-tag repair-list-entry-tag--red">
        <span class="repair-list-entry-tag__text is-complaint">Reclamação</span>
      </div>
    </el-tooltip>
  </div>

  <div class="repair-list-entry__actions">
    <!-- view butotn -->
    <el-button
      class="tp-btn tp-btn--info repair-list-entry__view-btn"
      type="info"
      size="small"
      @click="$router.push({ name: 'repair.single', params: { id: repair.id } })">
      Ver
    </el-button>

    <!-- start button -->
    <el-button
      v-if="showStartButton"
      :loading="workLoading"
      :disabled="workHasOpenRepair"
      class="tp-btn tp-btn--success"
      type="success"
      size="small"
      @click="takeRepairHandler">
      Iniciar
    </el-button>

    <!-- pause button -->
    <el-button
      v-if="repair.state === 'in_repair'"
      :loading="workLoading"
      class="tp-btn tp-btn--warning"
      type="warning"
      size="small"
      @click="stopRepairHandler">
      Pausar
    </el-button>
  </div>
</div>
