<template>
  <el-dialog
    v-loading.fullscreen="isLoading"
    :visible.sync="visibility"
    title="Numero da ficha de loja">
    <el-form label-position="top">
      <el-form-item>
        <el-input
          v-model="storeIdentifier"
          placeholder="Numero de ficha da loja" />
      </el-form-item>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer">
      <el-button
        :loading="isLoading"
        @click="closeHandler">
        Cancelar
      </el-button>
      <el-button
        :loading="isLoading"
        type="primary"
        @click="updateStoreIdentifierHandler">
        Alterar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";

export default {
  name: "ChangeStoreNumberModal",

  data() {
    return {
      visibility: false,
      storeIdentifier: "",
    };
  },

  computed: {
    ...mapGetters("repair", ["repair"]),
    ...mapState("repair", {
      isLoading: state => state.loading,
    }),
  },

  created() {
    this.storeIdentifier = this.repair.storeRepairIdentifier;
  },

  methods: {
    show() {
      this.visibility = true;
    },

    closeHandler() {
      this.visibility = false;
      this.$emit("closed");
    },

    /**
     * Update the store identifier.
     */
    async updateStoreIdentifierHandler() {
      await this.$store.dispatch(ERepairActions.UPDATE, {
        storeRepairIdentifier: this.storeIdentifier,
      });
      this.closeHandler();
      this.$message.success("Numero da ficha atualizada.");
    },
  },
};
</script>
