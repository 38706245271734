
<div
  :class="{'is-creation': canCreate}"
  class="tp-ClientSearch">
  <!-- form to create a new client -->
  <client-form
    ref="clientPanel"
    @created="afterClientCreation"/>

  <!-- input -->
  <el-autocomplete
    v-model="selectedClient"
    :fetch-suggestions="searchForClients"
    :trigger-on-focus="false"
    placeholder="Cliente"
    custom-item="client-item"
    class="tp-ClientSearch__input"
    @select="changeHandler"/>

  <!-- button to create a new client -->
  <el-button
    v-if="canCreate"
    class="tp-ClientSearch__btn"
    type="primary"
    @click="showClientCreationForm">
    Novo
  </el-button>
</div>
