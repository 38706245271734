
<section class="tp-ProblemDescription">
  <el-dialog
    v-loading.fullscreen="problemDescriptionForm.loading"
    :visible.sync="problemDescriptionForm.visibility"
    title="Descrição do Problema">
    <el-form label-position="top">
      <el-form-item>
        <el-input
          v-model="problemDescriptionForm.value"
          placeholder="Descrição do Problema"
          type="textarea"
          row="6"/>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="problemDescriptionForm.visibility = false">Cancelar</el-button>
      <el-button
        type="primary"
        @click="saveProblemDescriptionHandler">Guardar</el-button>
    </span>
  </el-dialog>

  <!-- problem -->
  <div class="tp-ProblemDescription__entry">
    <h2 class="tp-ProblemDescription__entry__title">
      <span>Problema</span>
    </h2>
    <p class="tp-ProblemDescription__entry__value is-problem">
      <span v-if="repair.problemName">
        <img
          :src="problemImageUrl"
          alt="Problema">

        {{ repair.problemName }}
      </span>
      <span v-else>-</span>
    </p>
  </div>

  <!-- problem -->
  <div class="tp-ProblemDescription__entry">
    <h2 class="tp-ProblemDescription__entry__title">
      <span>Descrição do Problema</span>

      <el-button
        v-if="repair.state !== 'delivered'"
        icon="edit"
        size="mini"
        class="tp-ProblemDescription__entry__title__editBtn"
        @click="problemDescriptionHandler"/>
    </h2>
    <p class="tp-ProblemDescription__entry__value">
      <span v-if="repair.problemDescription">
        {{ repair.problemDescription }}
      </span>
      <span v-else>-</span>
    </p>
  </div>
</section>
