<template>
  <section class="tp-clientList_wrap">
    <client-form
      ref="clientForm"
      @updated="fetchPage"
      @created="afterClientCreation"/>

    <send-message ref="sendMessage"/>

    <ui-list
      ref="list"
      :data="clients"
      :total="total"
      :creation-method="addClient"
      :loading="loading"
      :remote-search="searchClientsHandler"
      :per-page="customersPerPage"
      allow-creation
      paginate
      searchable
      @current-changed="fetchPage"
      @mode-changed="modeChangeHandler">
      <template slot="actions" class="extra-actions">
        <el-button type="info" @click="onClickCustomerGroupsHandler">Grupos</el-button>
      </template>
      <ui-list-item
        v-for="client in orderedClients"
        :key="client.id">
        <!-- avatar -->
        <div slot="image">
          <img
            :src="getAvatar(client.email)"
            class="tp-clientList_avatar img-circle"
            alt="Avatar" >
        </div>

        <!-- name -->
        <span class="name">{{ client.name }}</span>

        <!-- email -->
        <span slot="details">{{ client.email || client.phone }}</span>

        <!-- actions -->
        <div slot="actions">
          <el-button
            :plain="true"
            type="info"
            @click="sendMessage(client)">Contactar</el-button>
          <el-button
            type="info"
            @click.native="editClient(client)">Editar</el-button>
        </div>
      </ui-list-item>
    </ui-list>

  </section>
</template>

<script>
import _ from "lodash";

import ClientFormCmp from "./ClientForm.vue";
import ClientSendNotificationCmd from "./ClientSendNotification.vue";
import gravatar from "../../filters/gravatar";
import { CLIENT_ROUTE_CLIENT_GROUP_LIST } from "../../modules/clients/services/router/routes-names";

export default {
  components: {
    "client-form": ClientFormCmp,
    "send-message": ClientSendNotificationCmd,
  },

  data() {
    return {
      clients: [],
      loading: false,
      total: 0,
      query: "",
    };
  },

  computed: {
    orderedClients() {
      return _.orderBy(this.clients, "name");
    },

    customersPerPage() {
      return 15;
    },
  },

  created() {
    // fetch the first page
    this.fetchPage();
  },

  methods: {
    onClickCustomerGroupsHandler() {
      this.$router.push({ name: CLIENT_ROUTE_CLIENT_GROUP_LIST });
    },

    modeChangeHandler(mode) {
      if (mode === "normal") {
        this.query = "";
      }
    },

    /**
     * Fetch a page of clients.
     *
     * @type {number} page
     */
    fetchPage(page = 1) {
      // enable loading
      this.loading = true;

      // get the data from the API
      this.$stellar
        .action("paginateClients", {
          page,
          limit: this.customersPerPage,
          query: this.query,
        })
        .then(({ clients, total }) => {
          // update the list data
          this.total = total;
          this.clients = clients;

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Handler the search query changes.
     *
     * Save the query and fetch the first page of results.
     */
    searchClientsHandler(query) {
      this.query = query;
      this.fetchPage();
    },

    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    addClient() {
      this.$refs.clientForm.show();
    },

    /**
     * Handler for the client creation.
     *
     * After a client insertion we need to add the new client to the clients
     * list.
     */
    afterClientCreation(newClient) {
      this.clients.push(newClient);
    },

    /**
     * Set the user to be editted and show the edit panel.
     */
    editClient(client) {
      // get the edit panel
      const panel = this.$refs.clientForm;

      // set the client to be editted
      panel.setClient(client);

      // show edit panel
      panel.show();
    },

    /**
     * Show a panel to send a new message to a client.
     */
    sendMessage(client) {
      // get the send message panel
      const panel = this.$refs.sendMessage;

      // set the client
      panel.setClient(client);

      // show panel
      panel.show();
    },
  },
};
</script>
