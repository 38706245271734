<template>
  <div id="password">
    <div class="form">
      <p id="head">Recuperação de password</p>
      <div id="message"> <p>{{ message }}</p></div>
      <p>email: <input
        type="email"
        class="form-control"
        placeholder="email"
        required></p>
      <button
        class="btn btn-primary"
        @click="recovery">Continuar</button>
      <div id="message"> <p>{{ messages }}</p></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetPassword",

  data() {
    return {
      message:
        "Introduza o enderço de email associado á sua conta, será enviado um email com os passos a necessários para recuperar a sua password.",
      messages: "",
    };
  },
  methods: {
    recovery() {
      this.message = "";
      this.messages =
        "Foi enviado um email para o enderço introduzido a fim de recuperar a sua password, por favor verifique a sua conta de email e siga as indicações deste para recuperar a sua password ";
    },
  },
};
</script>
<style>
#message {
  background-color: LavenderBlush;
}
</style>
