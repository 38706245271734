import Vue from "vue";

Vue.filter("priority", value => {
  switch (value) {
    case "normal":
      return "Normal";
    case "urgent":
      return "Urgente";
    case "ontime":
      return "Agora";
    default:
      return "Invalid";
  }
});
