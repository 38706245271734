import { MutationTree } from "vuex";
import {
  INavigationState,
  ENavigationMutations,
  INavigationMenuItem,
} from "@/services/store/modules/navigation/navigation-types";

export const mutations: MutationTree<INavigationState> = {
  /**
   * Replace existing menu items with the given set.
   */
  [ENavigationMutations.SET_MENU_ITEMS](
    state,
    newItems: Array<INavigationMenuItem>,
  ) {
    state.menuItems = newItems;
  },
};
