
<el-dialog
  v-loading.fullscreen="loading"
  :visible.sync="visibility"
  class="tp-CloseWithoutReapirDialog"
  title="Fechar reparação">
  <el-form
    ref="form"
    :rules="rules"
    :model="data"
    label-position="top">
    <!-- internal observation -->
    <el-form-item
      label="Observações Internas"
      prop="internalObservation">
      <el-input
        v-model="data.internalObservation"
        type="textarea"
        row="6"/>
    </el-form-item>

    <!-- observation for client -->
    <el-form-item
      label="Observações para o cliente"
      prop="clientObservation">
      <el-input
        v-model="data.clientObservation"
        type="textarea"
        row="6"/>
    </el-form-item>
  </el-form>

  <!-- footer -->
  <span
    slot="footer"
    class="dialog-footer">
    <!-- cancel button -->
    <el-button @click="$emit('cancel')">Cancelar</el-button>

    <!-- save button -->
    <el-button
      type="primary"
      @click="withoutRepairHandler">Fechar</el-button>
  </span>
</el-dialog>
