<template>
  <el-row
    :gutter="10"
    class="ui-ImageSelect">
    <!-- list of options -->
    <el-col
      v-for="item in data"
      :key="item.value"
      :span="2">
      <el-card
        :class="[
          'ui-ImageSelect__card',
          { 'is-selected': item.value === selected }
        ]"
        body-style="{ padding: '0px' }"
        @click.native="changeSelection(item)">
        <el-tooltip
          :content="item.title"
          effect="dark"
          placement="top">
          <!-- imagem -->
          <img
            :src="generateImageUrl(item)"
            :alt="item.title"
            class="ui-ImageSelect__image">
        </el-tooltip>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Generate an URL for the image
     */
    generateImageUrl(item) {
      return `${import.meta.env.VITE_API_URL}/problems/${item.value}.jpg`;
    },

    /**
     * Change the selected item.
     */
    changeSelection(item) {
      this.$emit("input", item.value);
    },
  },
};
</script>

<style lang="scss">
.ui {
  &-ImageSelect {
    &__card {
      margin-bottom: 10px;
      will-change: scale;
      transition: all 0.2s ease-in;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
      &.is-selected {
        border: 2px solid #5baae3;
      }

      /* fix tooltip display */
      .el-tooltip,
      .el-tooltip__rel {
        display: block;
      }
    }
    &__image {
      width: 100%;
      display: block;
    }
  }
}
</style>
