
<div id="password">
  <div class="form">
    <p id="head">Recuperação de password</p>
    <div id="message"> <p>{{ message }}</p></div>
    <p>email: <input
      type="email"
      class="form-control"
      placeholder="email"
      required></p>
    <button
      class="btn btn-primary"
      @click="recovery">Continuar</button>
    <div id="message"> <p>{{ messages }}</p></div>
  </div>
</div>
