
<div class="ui-Notifications">
  <div class="ui-notifications_header">
    <h1>Notificações</h1>
  </div>

  <div
    v-if="notifications.length === 0"
    class="ui-notifications_empty">
    <i
      class="fa fa-thumbs-o-up"
      aria-hidden="true"/>
    <span>Sem notificações!</span>
  </div>

  <div class="ui-Notifications__box">
    <template v-if="notifications.length !== 0">
      <div
        v-for="entry in orderedNotifications"
        :key="entry.id"
        :class="{ notRead: !entry.read }"
        class="ui-notifications_entry"
        @click="markNotificationAsRead(entry)">
        <!-- image -->
        <i
          class="fa fa-cube image"
          aria-hidden="true"/>

        <div class="content">
          <!-- title -->
          <span class="title">{{ entry.title }}</span>

          <!-- content for a new repair -->
          <div v-if="entry.type === 'new_repair_wa'">
            <router-link :to="{ name: 'repair.waiting', params: { id: entry.content } }">Nova reparação à espera de triagem!</router-link>
          </div>

          <!-- client_request_response -->
          <div v-if="entry.type === 'client_request_response'">
            <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Uma reparação obteve resposta do cliente.</router-link>
          </div>

          <!-- material_available -->
          <div v-if="entry.type === 'material_available'">
            <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Uma reparação tem agora material disponível.</router-link>
          </div>

          <!-- content for work attribution -->
          <div v-if="entry.type === 'work_attribution'">
            <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Novo trabalho!</router-link>
          </div>

          <!-- -->
          <div v-if="entry.type === 'quotation_approve'">
            <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Orçamento aprovado para uma das reparações.</router-link>
          </div>

          <!-- new_urgent_repair -->
          <div v-if="entry.type === 'new_urgent_repair'">
            <router-link :to="{ name: 'repair.single', params: { id: entry.content } }">Tem um novo trabalho urgente.</router-link>
          </div>

          <!-- technician_has_little_work -->
          <div v-if="entry.type === 'technician_has_little_work'">
            <router-link :to="{ name: 'repair.waiting', query: { id: entry.content } }">Um tecnico está a ficar sem trabalho.</router-link>
          </div>

          <!-- new_contact -->
          <div v-if="entry.type === 'new_contact'">
            <router-link :to="{ name: 'clientContacts.list' }">Novo contacto para realizar.</router-link>
          </div>

          <!-- new_complaint_to_validate -->
          <div v-if="entry.type === 'new_complaint_to_validate'">
            <router-link :to="{ name: 'complaint.create' }">Tem uma nova reclamação para avalidar.</router-link>
          </div>

          <!-- material_over_value -->
          <div v-if="entry.type === 'material_over_value'">
            <router-link :to="{ name: 'repair.single' }">Valor da reparação inferior ao material.</router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</div>
