import { Module } from "vuex";
import { IUsersState } from "./users.types";
import { IStoreRootState } from "../../types/store-root-state.interface";
import { make } from "vuex-pathify";
import { usersMutations } from "./users.mutations";
import { usersActions } from "./users.actions";
import { usersGetters } from "./users.getters";

const state: IUsersState = {
  loading: false,
  error: null,
  users: {},
};

export const usersModule: Module<IUsersState, IStoreRootState> = {
  namespaced: true,
  state,
  actions: usersActions,
  mutations: {
    ...make.mutations(state),
    ...usersMutations,
  },
  getters: {
    ...make.getters(state),
    ...usersGetters,
  },
};
