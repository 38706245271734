<template>
  <div class="tp-dashboard_wrap">
    <!-- other information for the logged user -->
    <dash-states/>

    <section class="tp-Mural">
      <!-- sidebar -->
      <div class="tp-Mural__sidebar">
        <!-- list of users -->
        <div
          v-for="user in users"
          :key="user.id"
          :class="
            [
              'tp-Mural__sidebar__entry is-user',
              { 'is-selected': user.selected }
            ]"
          @click="user.selected = !user.selected">
          <div class="avatar">
            <img
              :src="getAvatar(user.email)"
              :alt="user.name">
          </div>
          <div class="name">{{ user.name }}</div>
        </div>
      </div>

      <!-- messages -->
      <div class="messages_container">
        <div class="tp-Mural__messages">
          <li
            v-for="message in orderedMessages"
            :key="message.id"
            :class="{
              'is-mine': user.id === message.sender.id,
              'is-other': user.id !== message.sender.id,
              'is-toAttach': isToAttach(message),
              'is-hideName': isToHideName(message)
            }"
            class="tp-Mural__entry">

            <div class="avatar">
              <!-- user image -->
              <img
                :alt="message.sender.name"
                :src="getAvatar(message.sender.email)" >
            </div>

            <!-- name -->
            <div class="name">{{ message.sender.name }}</div>

            <!-- text -->
            <div class="text">{{ message.content }}</div>

            <!-- time -->
            <div class="time">{{ message.createdAt | dateDistanceFrom }}</div>
          </li>
        </div>

        <!-- form -->
        <div class="tp-Mural__form">
          <input
            v-model="newMessage"
            type="text"
            placeholder="Escreva uma mensagem..."
            @keyup.enter="sendMessage">
          <a
            class="fa fa-paper-plane send"
            @click.prevent="sendMessage"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import gravatar from "@/filters/gravatar";
import DashStatesCmp from "./states.vue";

// this will store the previous message
let previousMessage = null;

// flag to check if new messages are in load
let loading = false;

// scroll position
let isCreating = false;
let firstTime = true;

export default {
  components: {
    "dash-states": DashStatesCmp,
  },

  data() {
    return {
      newMessage: "",

      // list of registred users to show on the sidebar
      users: [],
    };
  },

  computed: {
    ...mapGetters(["muralMessages", "user", "muralScrollToBottom"]),

    orderedMessages() {
      // get mural messages
      let messages = this.muralMessages;

      // if there is any user selected we must filter the messages to contain
      // that users
      if (this.selectedUsers.length > 0) {
        const selectionSet = [...this.selectedUsers, this.user].map(item => item.id);

        messages = messages.filter(message => {
          const receivers = message.receivers.map(item => item.to.id);
          return _.intersection(selectionSet, receivers).length > 0;
        });
      }

      return _.orderBy(messages, "createdAt");
    },

    selectedUsers() {
      return this.users.filter(item => item.selected);
    },

    isStore() {
      return this.user.role.slug === "store";
    },
  },

  watch: {
    muralMessages() {
      // when is the user who are sending a message we must focus the bottom of
      // the list of messages
      if (isCreating || firstTime) {
        isCreating = false;
        firstTime = false;
        return this.$nextTick(this.listenerForResize);
      }
    },

    muralScrollToBottom(newVal) {
      if (newVal) {
        this.$nextTick(this.listenerForResize);
        this.$store.commit("MURAL_SET_LOADING_TO_BOTTOM", false);
      }
    },

    selectedUsers() {
      this.$nextTick(this.listenerForResize);
    },
  },

  created() {
    // reset first time flag
    firstTime = true;

    // get the first page of messages
    this.loadFirstPage();

    // get the registred users to fill the sidebar
    this.$stellar.action("auth.getUsers").then(({ users }) => {
      // append a selected flag to the each user
      for (const user of users) {
        user.selected = false;
      }

      if (this.user.role.slug === "store") {
        this.users = users.filter(item => import.meta.env.VITE_MURAL_STORE_USERS.split(" ").includes(item.id));
      } else {
        // filter inative users
        this.users = users.filter(user => user.active);
      }
    });
  },

  mounted() {
    // start listening for resizes
    window.addEventListener("resize", this.listenerForResize);

    const messagesBox = document.querySelector(".tp-Mural__messages");

    // listen for scroll changes on the messages div
    messagesBox.addEventListener("scroll", this.loadMessagesOnScroll);

    // adjust the scroll position
    this.$nextTick(() => {
      messagesBox.scrollTop = messagesBox.scrollHeight;
    });
  },

  beforeDestroy() {
    // remove the listeners
    window.removeEventListener("resize", this.listenerForResize);
  },

  methods: {
    ...mapActions(["sendMuralMessage", "muralFetchNextPage"]),

    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    /**
     * This loads the first page of messages.
     *
     * We only must load a page if the `muralMessages` state is empty, other
     * wise the `loadMessagesOnScroll` is our guy.
     */
    loadFirstPage() {
      // we only load if the muralMessages is empty
      if (this.muralMessages.length !== 0) {
        return;
      }

      // load the first page
      this.muralFetchNextPage();
    },

    sendMessage() {
      // we need a message to send
      if (this.newMessage.trim() === "") {
        return;
      }

      // get the selected users
      let receivers = this.selectedUsers;

      // map only the user ids
      receivers = receivers.map(item => item.id);

      // build the data to send
      const data = {
        content: this.newMessage,
        receivers,
      };

      // set the flag isCreating to true
      isCreating = true;

      // send the message
      this.sendMuralMessage(data).then(response => {
        // cleanup the form
        this.newMessage = "";
      });
    },

    listenerForResize() {
      // get the element reference
      const messagesBox = document.querySelector(".tp-Mural__messages");

      // adjust the scroll position
      messagesBox.scrollTop = messagesBox.scrollHeight;
    },

    /**
     * Check if the entry is to attach to the previous one.
     */
    isToAttach(message) {
      if (!message) {
        return;
      }

      const messages = this.orderedMessages;
      const index = messages.findIndex(item => item._id === message._id);

      // check if we have another ID after this one
      if (index >= messages.length - 1) {
        return false;
      }

      // get the next message to compare the IDs
      const nextMessage = messages[index + 1];

      // if the next sender is the current one we need to attache the message
      if (nextMessage.sender.id === message.sender.id) {
        return true;
      }

      return false;
    },

    isToHideName(message) {
      if (previousMessage === null || previousMessage.sender.id !== message.sender.id) {
        // update previous message
        previousMessage = message;
        return false;
      }

      // update previous message
      previousMessage = message;

      return true;
    },

    loadMessagesOnScroll() {
      // get the element reference
      const messagesBox = document.querySelector(".tp-Mural__messages");

      if (!loading && messagesBox.scrollTop < 70) {
        // mark as loading
        loading = true;

        // load the next page of messages
        this.muralFetchNextPage().then(() => {
          loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.tp-Mural {
  margin: 20px 30px;
  border-radius: 2px;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12),
    0 24px 38px 3px rgba(0, 0, 0, 0.2);

  height: 500px;
  max-height: 80vh;

  overflow: hidden;
  position: relative;

  display: flex;

  /** adapt the messages width automatically **/
  .messages_container {
    /** make the messages container be placed on right  **/
    flex: 1;
    order: 1;

    /** display the messages and the form in column mode **/
    display: flex;
    flex-direction: column;
  }

  /*** Sidebar **************************************************************/

  &__sidebar {
    flex: 0 0 200px;
    height: 100%;

    background-color: #fff;

    /** make the DIV scrollable */
    overflow-y: scroll;
    will-change: height;

    border-right: 1px solid rgba(238, 238, 238, 0.53);

    &__entry {
      position: relative;
      padding: 0 0 5px 55px;
      display: block;
      cursor: pointer;
      margin: 0 0 3px;

      &:first-child {
        margin-top: 3px;
      }

      .avatar {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 5px 0;

        & > img {
          display: block;
          border-radius: 50%;
          height: 100%;
        }
      }

      .name {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.is-selected {
        background-color: var(--sidebar-bg);

        .name {
          color: white;
        }
      }
    }
  }

  /*** Messages *************************************************************/

  &__messages {
    list-style: none;
    padding: 30px 20px 10px 20px;
    margin: 0;

    /** make the DIV scrollable */
    overflow-y: scroll;
    will-change: height;

    /** make the message box stay above the form */
    flex: 1;
    order: 0;

    &:after {
      content: "";
      clear: both;
      display: block;
    }
  }

  /* Each message */
  &__entry {
    position: relative;
    max-width: 99%;
    overflow: hidden;
    clear: both;

    /* User image */
    .avatar {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 26px;

      & > img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f3f3f3;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      }
    }

    /* name */
    .name {
      font-size: 14px;
      line-height: 36px;
    }

    /* text */
    .text {
      padding: 8px 12px;
      font-size: 0.95em;
      line-height: 26px;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        border: 18px solid transparent;
        border-bottom-width: 0;
      }
    }

    /* time */
    .time {
      font-size: 0.8em;
      line-height: 25px;
      position: relative;
      padding-bottom: 1px;
    }

    /* when the message is from other */
    &.is-other {
      padding: 0 0 0 58px;
      text-align: left;
      float: left;

      .avatar {
        left: 0;
      }

      .text {
        position: relative;
        padding: 8px 12px;
        font-size: 0.95em;
        line-height: 26px;

        color: #333333;
        word-wrap: break-word;

        background: #ebebeb;
        border-radius: 8px 8px 8px 0;

        &:before {
          left: -30px;
          border-right-color: #ebebeb;
          border-right-width: 12px;
        }
      }

      /* time */
      .time {
        padding-left: 12px;
        color: #333333;
      }
    }

    /* whent the message is mine */
    &.is-mine {
      padding: 0 58px 0 0;
      text-align: right;
      float: right;

      .avatar {
        right: 0;
      }

      .text {
        background: #7266ba;
        color: #ffffff;
        border-radius: 8px 8px 0 8px;
        text-align: left;

        &:before {
          right: -30px;
          border-left-color: #7266ba;
          border-left-width: 12px;
        }
      }

      .time {
        padding-right: 12px;
        color: #333333;
      }
    }

    &.is-toAttach {
      .avatar,
      .time {
        display: none;
      }

      .text::before,
      .text::after {
        display: none;
      }
    }

    &.is-hideName {
      .name {
        display: none;
      }

      .text {
        margin-top: 3px;
      }
    }

    /** other **/
    &.is-other.is-toAttach .text {
      border-bottom-left-radius: 0px;
    }
    &.is-other.is-hideName .text {
      border-top-left-radius: 0px;
    }
    /** mine **/
    &.is-mine.is-toAttach .text {
      border-bottom-right-radius: 0px;
    }
    &.is-mine.is-hideName .text {
      border-top-right-radius: 0px;
    }
  }

  /******* Form ****/
  &__form {
    clear: both;
    position: relative;
    background: #46be8a;

    /** make fixed to the bottom **/
    flex: 0 0 60px;

    input {
      border: none;
      background: none;
      display: block;
      width: 95%;
      font-size: 16px;
      line-height: 20px;
      padding: 20px;
      color: #ffffff;

      -webkit-appearance: none;
      border-radius: 0;

      &::-webkit-input-placeholder {
        color: #fff;
      }

      &:-moz-placeholder {
        color: #fff;
      }

      &::-moz-placeholder {
        color: #fff;
      }

      &:-ms-input-placeholder {
        color: #fff;
      }
    }

    .send {
      display: block;
      cursor: pointer;
      font-size: 1.5em;
      position: absolute;
      top: 35px;
      margin-top: -18px;
      color: #fff;
      right: 20px;

      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.2);
        text-decoration: none;
      }
    }
  }
}
</style>
