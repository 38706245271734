import { IBaseState } from "@/services/store/types/base-state.interface";

export interface ISpaces {
  [key: string]: Array<ISpaceEntry>;
}

/**
 * This stores the entry of a component to be dynamically
 * rendered on a space.
 */
export interface ISpaceEntry {
  name: string;
  order: number;
}

/**
 * Represents the state structure for spaces modules.
 */
export interface ISpacesSate extends IBaseState {
  spaces: ISpaces;
}

/**
 * List of actions that the spaces module have
 */
export enum ESpacesActions {
  ADD_SPACE = "spaces/addSpace",
  REMOVE_SPACE = "spaces/removeSpace",
}

/**
 * List of mutations implemented by this module.
 */
export enum ESpacesMutations {
  ADD_SPACE = "addSpace",
  REMOVE_SPACE = "removeSpace",
}
