
<section class="repair-requests-list">
  <div class="material-requests-list material-requests-list--client">
    <el-table
      v-loading.body="loading"
      :data="data"
      stripe>

      <!-- request number -->
      <el-table-column
        :width="100"
        label="#Pedido"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.requestNumber"> {{ scope.row.requestNumber }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column label="Data">
        <template slot-scope="scope">
          <span v-if="scope.row.createdAt"> {{ scope.row.createdAt | dateFormat('dd/MM/Y HH:mm') }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- material description -->
      <el-table-column label="Descrição">
        <template slot-scope="scope">
          {{ scope.row.material.brand }} {{ scope.row.material.model }}, {{ scope.row.material.description }}
        </template>
      </el-table-column>

      <!-- quantity -->
      <el-table-column
        :width="70"
        property="quantity"
        label="Qtn."
        align="center"/>

      <!-- box -->
      <el-table-column
        :width="80"
        label="Caixa"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.storeBox || '-' }}
        </template>
      </el-table-column>

      <!-- current price -->
      <el-table-column
        :width="150"
        align="center"
        label="Preço unidade">
        <template slot-scope="scope">
          {{ scope.row.price || scope.row.material.price | money }}
        </template>
      </el-table-column>

      <!-- request observation-->
      <el-table-column label="Observação">
        <template slot-scope="scope">
          <span v-if="scope.row.observation"> {{ scope.row.observation }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- Actions -->
      <el-table-column
        v-if="$auth.role==='attendance' || $auth.can('edit_material_requests')"
        :width="230"
        align="right"
        label="">
        <template slot-scope="scope">
          <!-- See the repair view -->
          <el-button
            v-if="scope.row.repair"
            type="info"
            size="small"
            @click="showRepairDetails(scope.row.repair)">
            #{{ scope.row.repair.repairNumber }}
          </el-button>

          <!-- TODO: convert this into permissions -->
          <template v-if="scope.row.state !== 'waiting_delivery' && $auth.role !== 'attendance'">
            <TooltipButton
              v-if="scope.row.state !== 'waiting_delivery'"
              content="Comprar"
              icon="el-icon-s-shop"
              color="primary"
              @click="$emit('process-request', scope.row)" />

            <TooltipButton
              content="Cancelar"
              icon="el-icon-delete"
              color="danger"
              @click="$emit('remove-request', scope.row)" />
          </template>
          <template v-else>
            <!-- mark as delivered -->
            <el-button
              v-if="($auth.role=='attendance' && !scope.row.repair) ||($auth.can('edit_material_requests'))"
              type="primary"
              size="small"
              @click="$emit('deliver-material', scope.row)">
              Entregar
            </el-button>

            <!-- put the material into the stock -->
            <TooltipButton
              content="Colocar no stock"
              icon="el-icon-upload2"
              @click="$emit('put-material-on-stock', scope.row)" />
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</section>
