<template>
  <div class="tp-DeliveryList">

    <!-- state 0 - select client -->
    <div
      v-if="currentStep === 0"
      class="step1">
      <el-card class="tp-DeliveryList__initial-card">
        <el-form
          v-loading.full="loading"
          class="tp-DeliveryList__clientForm">
          <!-- select the client -->
          <el-select
            v-model="selectedClientTemp"
            :remote-method="searchForClients"
            class="tp-DeliveryList__clientSelect"
            filterable
            remote
            placeholder="Escolha o Cliente">
            <el-option
              v-for="item in clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"/>
          </el-select>

          <p class="tp-DeliveryList__selectionDivider">- OU -</p>

          <!-- select repair number -->
          <div class="tp-DeliveryList__repairNumber">
            <el-input
              v-model="repairNumber"
              class="tp-DeliveryList__repairNumber__field"
              placeholder="Numero reparação/Numero de loja"
              @keyup.enter.native="searchRepair"/>

            <el-button
              class="tp-DeliveryList__repairNumber__button"
              type="primary"
              @click="searchRepair">
              Procurar
            </el-button>
          </div>
        </el-form>
      </el-card>
    </div>
    <!-- stage 1 - select the  -->
    <div
      v-if="currentStep === 1"
      class="">
      <div class="tp-DeliveryList__deliveryAll">

        <div
          id="toPrint"
          style="display: none">
          <div class="header">
            <p class="name">
              <b>Cliente: </b>
              {{ client.name }}
            </p>
            <p class="phone">
              <b>Contacto: </b>
              {{ client.phone }}
            </p>
          </div>
          <table
            v-if="repairs.length>0"
            class="table">
            <thead>
              <tr>
                <th>F.Tec</th>
                <th>Equipamento</th>
                <th>S/N IMEI</th>
                <th>F.Loja</th>
                <th>Cx</th>
                <th>Descrição</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody v-if="selectedItems">
              <tr
                v-for="repair in selectedItems"
                :key="repair.id">
                <td>{{ repair.repairNumber }}</td>
                <td>{{ repair.clientEquipment.model }}</td>
                <td v-if="repair.clientEquipment.attributes && repair.clientEquipment.attributes.imei">{{ repair.clientEquipment.attributes.imei }}</td>
                <td v-else>{{ repair.clientEquipment.serialNumber }}</td>
                <td>{{ repair.storeRepairIdentifier || '-' }}</td>
                <td>{{ repair.storeBox || '-' }}</td>
                <td>{{ repair.repairObservation || repair.problemName }}</td>
                <td>{{ repair.value | money }}</td>
              </tr>
            </tbody>
          </table>
          <!-- total -->
          <p
            v-if="repairs.length>0 && requests.requests.length>0"
            class="total">SubTotal: {{ repairTotal | money }}</p>
          <hr>
          <table
            v-if="requests.requests.length>0"
            class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Material</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody v-if="selectedRequestedItems">
              <tr
                v-for="request in selectedRequestedItems"
                :key="request.id">
                <td>{{ request.requestNumber }}</td>
                <td>{{ request.material.brand }} - {{ request.material.model }}</td>
                <td>{{ request.quotation | money }}</td>
              </tr>
            </tbody>
          </table>
          <!-- total -->
          <p
            v-if="repairs.length>0 && requests.requests.length>0"
            class="total">SubTotal: {{ totalRequests | money }}</p>
          <p class="total">Total: {{ repairTotal + totalRequests | money }}</p>
        </div>
      </div>

      <span
        v-if="repairs.length>0"
        class="tp-DeliveryList__title">Reparações</span>
      <ui-list
        ref="list"
        :loading="loading"
        :data="repairs"
        selectable
        @selection-change="selectionChangeHandler">
        <span slot="actions">
          <!-- delivery selection -->
          <el-button
            :plain="true"
            type="primary"
            @click="deliverySelectionHandler">
            Entregar Selecionados
          </el-button>

          <!-- delivery all -->
          <el-button
            :disabled="repairs.length === 0 && requests.requests.length === 0"
            type="success"
            @click="deliveryAll">
            Entregar Todos
          </el-button>
        </span>
        <template v-if="repairs.length > 0">
          <ui-list-item
            v-for="item in repairs"
            :key="item.id"
            :data="item">
            <!-- name -->
            <span>#{{ item.repairNumber }} - {{ item.clientEquipment.brand }} {{ item.clientEquipment.model }}</span>

            <!-- decription (equipment) -->
            <span slot="details">Caixa {{ item.storeBox }}</span>

            <!-- decription (equipment) -->
            <span slot="details"> - Valor {{ item.value | money }}</span>

            <!-- actions -->
            <div slot="actions">
              <!-- see -->
              <el-button
                type="info"
                @click="showRepairDetails(item)">Ver</el-button>

              <!-- deliver -->
              <el-button
                type="success"
                @click="markDelivered(item)">Entregar</el-button>
            </div>
          </ui-list-item>
        </template>
      </ui-list>
      <div v-if="repairs.length>0 && requests.requests.length>0">
        <hr>
        <span
          slot="subtotal"
          class="subtotal">SubTotal {{ repairTotal | money }}</span>
        <hr>
      </div>

      <span
        v-if="requests.requests.length>0"
        class="tp-DeliveryList__title">Pedidos de material</span>
      <div v-if="requests.requests">
        <ui-list
          v-if="requests.requests.length>0"
          ref="requestsList"
          :loading="loading"
          :data="requests.requests"
          selectable
          @selection-change="requestSelectionChangeHandler">

          <ui-list-item
            v-for="item in requests.requests"
            :key="item.id"
            :data="item">
            <span>#{{ item.requestNumber }} - {{ item.material | formatMaterialName }}</span>
            <span slot="details">Caixa {{ item.material.storeBox }}</span>
            <div slot="actions">
              <el-button
                type="success"
                @click="markRequestDelivered(item)">
                Entregar
              </el-button>
            </div>
          </ui-list-item>
        </ui-list>
        <div v-if="repairs.length>0 && requests.requests.length>0">
          <hr>
          <span
            slot="subtotal"
            class="subtotal">SubTotal {{ totalRequests | money }}</span>
          <hr>
        </div>
      </div>
      <span
        slot="total"
        class="subtotal"> Total {{ totalRequests + repairTotal |money }}</span>
    </div>
  </div>
</template>

<script>
import printHTML from "@/utils/print-html";
// store the timer for the query
let queryTimer = null;

export default {
  data() {
    return {
      // auxiliar properties
      currentStep: 0,
      loading: false,

      // properties for clients search
      clients: [],
      selectedClientTemp: null,
      client: null,

      // repair number to searching for
      repairNumber: "",

      // selected repair to view the details
      selectedRepair: null,

      // list of repairs to be delivered
      repairs: [],

      // List of selected items
      selectedItems: [],
      selectedRequestedItems: [],
      requests: null,
    };
  },

  computed: {
    repairTotal() {
      return this.selectedItems.reduce((total, item) => total + item.value, 0);
    },
    totalRequests() {
      return this.selectedRequestedItems.reduce((totalRequest, item) => totalRequest + parseInt(item.quotation, 10), 0);
    },
  },

  watch: {
    /**
     * When the 'selectedClientTemp' changes we must filter the query resutls
     * and set the 'client' with the actual object.
     */
    selectedClientTemp(newVal) {
      if (typeof newVal !== "string" || !Array.isArray(this.clients)) {
        return;
      }

      // set the client
      this.client = this.clients.find(item => item.id === newVal);

      // enable loading
      this.loading = true;

      // push the client id into the URL
      this.$router.push({ query: { client: this.client.id } });
    },

    /**
     * We just redirect here in order to allow backing to the same listing when
     * backing in the history.
     */
    "$route.query.client": {
      handler(newVal) {
        if (!newVal) {
          return this.backToStart();
        }

        // enable loading
        this.loading = true;

        // if we enter directly here we must get the client info first
        let clientInfo = null;

        if (this.client === null) {
          clientInfo = this.$stellar.action("getClient", { id: newVal }).then(({ client }) => {
            this.client = client;
          });
        } else {
          clientInfo = Promise.resolve();
        }

        // get the list of client equipments who are waiting delivery
        clientInfo
          .then(_ =>
            this.$stellar.action("getRepairsByClient", {
              client: newVal,
              state: ["waiting_delivery", "closed_without_permission"],
            }),
          )
          .then(async ({ repairs }) => {
            this.requests = await this.$stellar.action("getMaterialrequestByClient", { client: newVal });

            // if there is no repairs we show an alert an stay in the same page
            if (repairs.length === 0 && this.requests.length === 0) {
              this.$alert("Não existem items para entrega.", "Sem items");
              return this.backToStart();
            }

            // store client repairs
            this.repairs = repairs;

            // disable loading
            this.loading = false;

            // when the user isn't a store and just have one repair to delivery we
            // must jump to the repair view
            if (this.repairs.length === 1 && !this.client.isStore) {
              this.$router.push({
                name: "repair.single",
                params: { id: this.repairs[0].id },
              });
              return;
            }

            // go to the next step
            this.currentStep = 1;
          });
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * Back to start view.
     */
    backToStart() {
      this.currentStep = 0;
      this.loading = false;
      this.selectedClientTemp = null;
      this.client = null;
    },

    /**
     * Search repair by repairNumber.
     */
    searchRepair() {
      // enable loading
      this.loading = true;

      // search for a repair with the requested number
      this.$stellar.action("getRepairByNumber", { filter: this.repairNumber }).then(({ repair }) => {
        // disable loading
        this.loading = false;

        // check if the repiar exists
        if (repair === null) {
          return this.$alert("A reparação que procura não existe!", "Reparação Enexistente");
        }

        // check if the repair is already colsed
        if (repair.state === "delivered") {
          return this.$alert("A reparação já foi entregue.", "Reparação Entregue");
        }

        // navigate to the repair single
        this.$router.push({
          name: "repair.single",
          params: { id: repair.id },
        });
      });
    },
    /**
     * Handler to search for clients.
     */
    searchForClients(query = "") {
      // if there already there is a timer cancel it first
      clearTimeout(queryTimer);

      if (this.loading === true) {
        return;
      }
      // only make a call the API with at leats 2 characters, otehrwise clean
      // the list of available options
      if (query.length <= 2) {
        this.clients = [];
        return;
      }

      // enable loading
      this.loading = true;

      // create a new timer
      queryTimer = setTimeout(_ => {
        this.$stellar
          .action("searchForClients", { query })
          .then(response => {
            this.clients = response.clients;
          })
          .then(() => {
            // end loading
            this.loading = false;

            // clean timeout
            queryTimer = null;
          });
      }, 400);
    },

    /**
     * Go to the repair view to see the repair details.
     *
     * @param  {object} repair Repair object.
     */
    showRepairDetails(repair) {
      // navigate to the repair single view
      this.$router.push({ name: "repair.single", params: { id: repair.id } });
    },

    /**
     * This handler store the selected items after a section change.
     *
     * @param  {array} newSelection New selection group.
     */
    selectionChangeHandler(newSelection) {
      this.selectedItems = newSelection;
    },
    requestSelectionChangeHandler(newSelection) {
      this.selectedRequestedItems = newSelection;
    },

    /**
     * delevery one repair
     * @type {[type]}
     */
    async markDelivered(repair) {
      // add the item to the selcted item array
      this.selectedItems = [repair];

      try {
        // confirm the action
        await this.$confirm("Pretende dar saida das reparações?", "Confirmação", {
          confirmButtonText: "Sim",
        });

        // enable loading
        this.loading = true;

        // print equipments list
        this.printDocument();

        // make an API call to mark the repair as delivered
        await this.$stellar.action("markRepairAsDelivered", { id: repair.id });

        // remove the delivered repair
        const index = this.repairs.findIndex(item => item.id === repair.id);
        this.repairs.splice(index, 1);

        // show a success message
        this.$message.success("Equipamento entregue!");

        // remove loading
        this.loading = false;

        // clean selected items
        this.$refs.list.clearSelections();
      } catch (e) {
        // Do nothing
      }
    },
    /**
     * delevery one repair
     * @type {[type]}
     */
    async markRequestDelivered(request) {
      // add the item to the selcted item array
      this.selectedRequestedItems = [request];

      try {
        // confirm the action
        await this.$confirm("Pretende dar saida dos pedidos?", "Confirmação", {
          confirmButtonText: "Sim",
        });

        // enable loading
        this.loading = true;

        // print equipments list
        this.printDocument();
        // make an API call to update the material request status
        await this.$stellar.action("editMaterialrequest", {
          id: request.id,
          state: "delivered",
        });
        // remove the delivered repair
        const index = this.requests.requests.findIndex(item => item.id === request.id);
        this.requests.requests.splice(index, 1);

        // show a success message
        this.$message.success("Material entregue!");

        // remove loading
        this.loading = false;

        // clean selected items
      } catch (e) {
        // Do nothing
      }
      this.$refs.requestsList.clearSelections();
    },
    /**
     * Delivery all the visible items.
     */
    deliveryAll() {
      // set the selected items
      this.selectedItems = this.repairs;
      this.selectedRequestedItems = this.requests.requests;

      // call the delivery handler to save the chnages on the API
      this.deliverySelectionHandler();
    },

    /**
     * Delivery just the selected items.
     */
    deliverySelectionHandler() {
      if (this.selectedItems.length === 0 && this.selectedRequestedItems === 0) {
        return this.$alert("Não existem items selecionadas.", "Nenhuma Seleção");
      }

      let ids = [];
      let requestsIds = [];

      // confirm the action
      this.$confirm("Pretende dar saida das items selecionados?", "Confirmação", {
        confirmButtonText: "Sim",
      })
        .then(_ => {
          // enable loading
          this.loading = true;

          // print equipments list
          this.printDocument();

          // get just the repair identifiers
          ids = this.selectedItems.map(item => item.id);
          requestsIds = this.selectedRequestedItems.map(request => request.id);
          this.$stellar.action("deliverMultipleRequests", { requestsIds });

          // call the API action
          return this.$stellar.action("deliverMultipleRepairs", { ids });
        })
        .then(_ => {
          // disable loading
          this.loading = false;

          // remove selected items from the list
          for (const repair of ids) {
            const index = this.repairs.findIndex(item => item.id === repair);
            this.repairs.splice(index, 1);
          }

          // remove selected requests from the list
          for (const request of requestsIds) {
            const index = this.requests.requests.findIndex(item => item.id === request);
            this.requests.requests.splice(index, 1);
          }

          // if there is no more repairs to deliver we must redirect to the step
          // zero
          if (this.repairs.length === 0 && this.requests.requests === 0) {
            this.backToStart();
          }

          // show a success message
          this.$message.success("Items entregues.");
        })
        .catch(_ => {});
    },

    /**
     * Print a document with the delivered equipments.
     */
    printDocument() {
      const printStyle = `
        body {
          font-family: Roboto, 'arial', sans-serif;
          font-size: 12pt;
          color: #757575 !important;

          -webkit-print-color-adjust: exact;
        }

        .header {
          padding: 10px;
          max-width: 300px;
          background: #f3f3f3 !important;
        }
        .header h1 {
          margin: 0 0 7px 0;
          font-size: 16pt;
        }
        .header p {
          font-size: 11pt;
          margin-top: 0px;
          margin-bottom: 3px;
        }
        .header p:last-child {
          margin-bottom: 0;
        }

        .table {
          overflow: hidden;
          background-color: #fff !important;
          border: 1px solid #e0e6ed !important;
          font-size: 14px;
          color: #1f2d3d;

          margin: 10px 0;
          width: 100%;
        }
        .table tbody tr:nth-child(2n) td {
          background: #FAFAFA !important;
        }
        .table th {
          overflow: hidden;
          background-color: #EFF2F7 !important;
          text-align: left;
        }
        .table td, .table th {
          padding: 0 10px;
          height: 40px;
          min-width: 0;
          text-overflow: ellipsis;
        }
        .total {
          display: block;
          text-align: right;
          font-size: 14pt;
          color: #272727;
          margin-top: 0;
        }
      `;
      const contentToPrint = document.getElementById("toPrint").innerHTML;
      printHTML(contentToPrint, printStyle, "Lista de Entregas");
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.tp {
  &-DeliveryList {
    padding: 20px;

    &__clientSelect {
      margin: 0 auto;
      max-width: 600px;
      display: block !important;
    }

    &__selectionDivider {
      font-size: 15px;
      max-width: 40px;
      text-align: center;
      margin: 10px auto;
    }

    &__deliveryAll {
      max-width: 1000px;
      margin: 0 auto;

      text-align: right;
    }
  }
}

.tp-DeliveryList__initial-card {
  max-width: 710px;
  padding: 40px 15px;
  margin: 0 auto;
}

.tp-DeliveryList__clientForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tp-DeliveryList__clientSelect,
.tp-DeliveryList__repairNumber {
  display: flex;
  width: 400px;

  font-size: 0;
  line-height: 0;
}

.tp-DeliveryList__repairNumber__field {
  display: inline-block;
  width: calc(100% - 94px);
}

.tp-DeliveryList__repairNumber__button {
  display: inline-block;
  margin-left: 8px;
}

.tp-DeliveryList__title {
  margin-left: 40%;
  font-size: 30px;
}

.subtotal {
  margin-left: 80%;
  font-size: 26px;
}
</style>
