<template>
  <section class="tp-materialRequest_wrap">
    <!-- edit form for material ---------------------------------------------- -->

    <material-form
      ref="materialForm"
      @created="afterMaterialCreation"
      @canceled="resetVisibility"/>

    <!-- material sheet ------------------------------------------------------ -->

    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="addToRequestHandler">
          Adicionar
        </el-button>
        <el-button
          :loading="loading"
          type="success"
          @click="processRequestHandler">
          Finalizar
        </el-button>
        <el-button @click="show(false)">Cancelar</el-button>
      </div>

      <!-- list of materials to request -->
      <el-table
        v-if="requestsToMade.length > 0"
        v-loading.body="loading"
        :data="requestsToMade"
        class="tp-MaterialRequest__table">
        <el-table-column
          :width="100"
          label="Dest.">
          <template slot-scope="scope">
            <span v-if="scope.row.toStock">Stock</span>
            <span v-else>Reparação</span>
          </template>
        </el-table-column>
        <el-table-column
          :width="85"
          label="Qnt."
          prop="quantity"/>
        <el-table-column
          label="Material"
          prop="name"/>
        <el-table-column
          :width="100"
          label="Estado"
          align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'new'">Novo</span>
            <span v-else>Usado</span>
          </template>
        </el-table-column>
        <el-table-column :width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="warning"
              @click="returnMaterialHandler(scope.row)">Remover</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- show an alert when the stock is low -->
      <el-alert
        v-if="data.selectedMaterial && data.selectedMaterial.newStock + data.selectedMaterial.requestedQuantity < data.selectedMaterial.stockMin"
        :closable="false"
        class="tp-MaterialRequest__alert"
        show-icon
        type="warning"
        title="O Stock está baixo! Considere comprar mais."/>

      <!-- show the stock quantity and the number of requests -->
      <el-alert
        v-if="data.selectedMaterial"
        :closable="false"
        :title="infoMessageMaterial"
        class="tp-MaterialRequest__alert"
        show-icon
        type="info"/>

      <el-form
        ref="data"
        :model="data"
        :rules="rules"
        label-position="top" >
        <el-row :gutter="10">
          <!--material -->
          <el-col :span="11">
            <el-form-item
              label="Material"
              prop="selectedMaterial">
              <el-select
                v-model="selectedMaterial"
                :remote-method="materialRemoteMethod"
                :loading="search.loading"
                filterable
                remote
                placeholder="Material"
                class="tp-MaterialRequest__material">
                <el-option
                  v-for="item in search.materials"
                  :key="item.id"
                  :label="item.brand + ' ' + item.model + ', ' + item.description"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- new material -->
          <el-col :span="2">
            <el-button
              class="tp-MaterialRequest__newMaterial"
              type="primary"
              @click="showMaterialForm">Novo
            </el-button>
          </el-col>

          <!-- quantity -->
          <el-col :span="5">
            <el-form-item label="Qtn. Reparação">
              <el-input-number
                v-model="data.quantity"
                :min="0"
                type="number"/>
            </el-form-item>
          </el-col>

          <!-- quantity -->
          <el-col :span="5">
            <el-form-item label="Qtn. Stock">
              <el-input-number
                v-model="data.stockQuantity"
                :min="0"
                type="number"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="17">
            <el-form-item label="Retirar do material">
              <el-select
                v-model="data.type"
                :style="{ width: '100%' }">
                <el-option
                  label="Novo"
                  value="new"/>
                <el-option
                  label="Usado"
                  value="used"/>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="Observações">
              <el-input
                v-model="data.observation"
                type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </section>
</template>

<script>
import { mapActions } from "vuex";

import MaterialFormCmp from "../material/MaterialForm.vue";

let timerIdentifier = null;

export default {
  components: {
    "material-form": MaterialFormCmp,
  },

  props: {
    repair: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selectedMaterial: [],
      loading: false,

      data: {
        quantity: 1,
        stockQuantity: 0,
        selectedMaterial: null,
        type: "new",
        observation: "",
      },

      rules: {
        selectedMaterial: [
          {
            type: "object",
            required: true,
            message: "Introduza um material",
            trigger: "blur",
          },
        ],
      },

      // serach data
      search: {
        materials: [],
        loading: false,
      },

      // list of requestes to repair
      requestsToMade: [],
    };
  },

  computed: {
    infoMessageMaterial() {
      const material = this.data.selectedMaterial;
      let message = `Existem ${material.newStock} peças em stock.`;

      if (material.requestedQuantity > 0) {
        message += ` E aguarda-se a receção de ${
          material.requestedQuantity
        } peças.`;
      }

      return message;
    },
  },

  watch: {
    selectedMaterial(newVal) {
      this.data.selectedMaterial = this.search.materials.find(
        item => item.id === newVal,
      );
    },
  },

  methods: {
    ...mapActions(["getWork"]),

    async makeApiRequest(query) {
      const { data } = await this.$stellar.action("paginateMaterials", {
        limit: 30,
        query,
      });

      this.search.materials = data;
      this.search.loading = false;
    },

    /**
     * Search for materials on the API.
     *
     * @param query query to searching for.
     */
    materialRemoteMethod(query) {
      // remove the previous timer in order to prevent multiple
      // requests to the API, and create a new one.
      if (timerIdentifier !== null) {
        clearTimeout(timerIdentifier);
        timerIdentifier = null;
      }

      if (query.length < 2) {
        this.search.materials = [];
        this.search.loading = false;
        return;
      }

      this.search.loading = true;
      timerIdentifier = setTimeout(() => this.makeApiRequest(query), 400);
    },

    /**
     * Define the visibility.
     *
     * @type {Boolean} val component visibility.
     */
    show(val = true) {
      this.$refs.editSheet.show(val);

      if (!val) {
        this.reset();
      }
    },

    /**
     * Remove one material from the list of requests to made.
     */
    returnMaterialHandler(item) {
      const index = this.requestsToMade.findIndex(
        e => e.materialId === item.materialId,
      );
      this.requestsToMade.splice(index, 1);
    },

    /**
     * Add the selected material to the request set.
     *
     * When the material exists in stock we perform a sale and add it to the
     * repair. Otherwise, we push it to the the list of requests to be made.
     */
    async addToRequestHandler() {
      // get the selected material
      const material = this.data.selectedMaterial;

      // ignore when the two quantities are zero
      if (this.data.quantity === 0 && this.data.stockQuantity === 0) {
        return;
      }

      // function to create a well formated material name
      const formatMaterialName = materialToFormat => {
        let out = materialToFormat.description;

        if (materialToFormat.brand.length > 0) {
          out += `, ${materialToFormat.brand}`;
        }

        if (materialToFormat.model.length > 0) {
          out += ` ${materialToFormat.model}`;
        }

        return out;
      };

      // check if the material is selected
      if (!material) {
        return this.$message.error("Nenhum material selecionado.");
      }

      // handle requests for stock
      if (this.data.type === "new" && this.data.stockQuantity > 0) {
        const indexToStock = this.requestsToMade.findIndex(
          item => item.material === material.id && item.toStock,
        );

        // append quantity for stock entry
        if (indexToStock >= 0) {
          this.requestsToMade[indexToStock].quantity += this.data.stockQuantity;
        } else {
          // push it to the set of materials to stock to be requested
          this.requestsToMade.push({
            material: material.id,
            name: formatMaterialName(material),
            quantity: this.data.stockQuantity,
            type: this.data.type,
            toStock: true,
          });
        }
      }

      // check if there enouth quantity to precced with the sell
      if (
        this.data.type === "new" &&
        this.data.quantity > 0 &&
        material.newStock - this.data.quantity < 0
      ) {
        // open the request
        this.$confirm("Pretende criar um pedido de material?", "Sem stock", {
          confirmButtonText: "Pedir",
          cancelButtonText: "Cancelar",
          type: "warning",
        }).then(() => {
          // check if there is already a material with the same ID
          const index = this.requestsToMade.findIndex(
            item => item.material === material.id && !item.toStock,
          );

          if (index >= 0) {
            this.requestsToMade[index].quantity += this.data.quantity;
          } else {
            // push it to the set of materials to be requested
            this.requestsToMade.push({
              material: material.id,
              name: formatMaterialName(material),
              quantity: this.data.quantity,
              type: this.data.type,
              observation: this.data.observation,
            });
          }

          this.resetFormInfo();
        });

        return;
      } else if (
        this.data.type === "used" &&
        material.usedStock - this.data.quantity < 0
      ) {
        return this.$message.error("Não existe stock suficiente!");
      }

      // ignore when there is nothing to sell
      if (this.data.quantity === 0) {
        return;
      }

      // decrement quantity from stock
      const newMaterial = await this.$stellar.action("getMaterial", {
        id: this.data.selectedMaterial.id,
      });
      if (this.data.type === "new") {
        newMaterial.material.newStock -= 1;
      } else {
        newMaterial.material.usedStock -= 1;
      }
      await this.$stellar.action("editMaterial", newMaterial.material);

      this.loading = true;

      this.$alert(
        `Pode levandar ${this.data.quantity} unidade(s) da caixa ${
          material.storeBox
        }.`,
        "Venda Efetuada",
        {
          confirmButtonText: "Feito!",
          type: "success",
        },
      );

      // add the material to the repair
      material.id = material.id || material._id;
      await this.$stellar.action("addMaterialToRepair", {
        repair: this.repair.id,
        material,
        quantity: this.data.quantity,
        type: this.data.type,
      });

      this.$emit("added");
      this.resetFormInfo();
      this.loading = false;
    },

    /**
     * Create a new request for the selected material.
     */
    async processRequestHandler() {
      if (this.requestsToMade.length === 0) {
        // show a info message
        this.$message.info("Sem pedidos a fazer.");

        // close the panel and return
        return this.show(false);
      }

      try {
        // confirm with the user if him want really make the request
        await this.$confirm(
          "Pretende finalizar o seu pedido?",
          "Finaliazar pedido",
          {
            confirmButtonText: "Finalizar",
            cancelButtonText: "Ainda não",
            type: "warning",
          },
        );
      } catch (e) {
        return;
      }

      try {
        // get request to made for stock
        const requestsToMadeToStock = this.requestsToMade.filter(
          item => item.toStock,
        );
        if (requestsToMadeToStock.length > 0) {
          await this.$stellar.action("createMaterialrequest", {
            requests: requestsToMadeToStock,
          });
        }

        // make the requests for this repair
        const requestsToMadeForRepair = this.requestsToMade.filter(
          item => !item.toStock,
        );
        if (requestsToMadeForRepair.length > 0) {
          await this.$stellar.action("createMaterialrequest", {
            requests: requestsToMadeForRepair,
            repair: this.repair.id,
          });
        }

        // show success message
        this.$message.success("O pedido foi criado.");

        // reset the request
        this.reset();

        // refetch the list of work
        this.getWork(true);

        // redirect the user to the list of work
        if (requestsToMadeForRepair.length > 0) {
          this.$router.push({
            name: "work.list",
          });
        }

        this.show(false);
      } catch (e) {
        // Do nothing!
      }
    },

    /**
     * Show a form to create a new material.
     */
    showMaterialForm() {
      // get the material form panel
      const panel = this.$refs.materialForm;

      // set the execution mode
      panel.enableSimpleMode();

      // show the material form
      panel.show();

      // for now we need hide this manually
      this.$refs.editSheet.show(false);
    },

    /**
     * Handle the material creation.
     *
     * We must fill in the form with the new material data.
     */
    afterMaterialCreation(newMaterial) {
      newMaterial._id = newMaterial._id ? newMaterial._id : newMaterial.id;

      // HACK: add the new material to the list of searched items
      this.search.materials = [newMaterial];

      // select the created material
      this.selectedMaterial = newMaterial.id;

      // reset visibility
      this.resetVisibility();
    },

    resetVisibility() {
      this.$refs.editSheet.show(true);
    },

    /**
     * This reset method only reset the form info.
     */
    resetFormInfo() {
      this.selectedMaterial = null;
      this.data.selectedMaterial = null;
      this.data.type = "new";
      this.data.quantity = 1;
      this.data.stockQuantity = 0;
      this.data.observation = "";
    },

    /**
     * Reset the components data.
     */
    reset() {
      // reset form info
      this.resetFormInfo();

      // reset other vars
      this.loading = false;
      this.requestsToMade = [];
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-MaterialRequest {
    /** table */
    &__table {
      margin-bottom: 20px !important;
    }

    &__alert {
      margin-bottom: 10px !important;
    }

    &__material {
      width: 100%;
    }

    &__newMaterial {
      margin-top: 31px !important;
    }
  }
}
</style>
