<template>
  <div class="tp-notes">
    <ui-bottom-sheet 
      ref="editSheet" 
      actions>
      <!-- actions -->
      <div 
        slot="actions" 
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="save">
          Guardar
        </el-button>

        <el-button
          :loading="loading"
          @click="cancelAction">
          Cancelar
        </el-button>
      </div>

      <el-form
        ref="resource"
        :model="resource"
        :rules="rules"
        label-position="top">
        <el-row :gutter="10">
          <!-- title -->
          <el-col :span="24">
            <el-form-item 
              prop="title" 
              label="Titulo">
              <el-input
                v-model="resource.title"
                placeholder="Titulo"/>
            </el-form-item>
          </el-col>

          <!-- content -->
          <el-col :span="24">
            <el-form-item 
              prop="content" 
              label="Nota">
              <el-input
                v-model="resource.content"
                type="textarea"
                placeholder="Nota"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resource: {
        title: "",
        content: "",
      },
      loading: false,
      edit: false,

      rules: {
        title: {
          required: true,
          message: "Introduza o titulo da nota",
          trigger: "blur",
        },
      },
    };
  },

  methods: {
    save() {
      // enable loading
      this.loading = true;

      this.$refs.resource.validate(valid => {
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.createResource();
        } else {
          this.editResource();
        }
      });
    },

    cancelAction() {
      this.$refs.editSheet.show(false);
      this.reset();
    },

    show(val = true) {
      this.innerShow = val;
      this.$refs.editSheet.show(val);

      if (!val) {
        this.reset();
      }
    },

    reset() {
      // reset form data
      this.resource = {
        title: "",
        content: "",
      };
      this.edit = false;
      this.loading = false;
    },

    /**
     * Set a resource to edit.
     */
    setResource(note) {
      this.resource = JSON.parse(JSON.stringify(note));
      this.edit = true;
    },

    async createResource() {
      // enable loading
      this.loading = true;

      // call API to create a new note
      await this.$stellar.action("createNote", this.resource);

      // emit an event with the new resource
      this.$emit("created");

      // close the panel
      this.show(false);

      // show a message to the user to inform the resource creation
      this.$message.success("Nota criada.");
    },

    async editResource() {
      // make an API call to update the material information
      await this.$stellar.action("editNote", this.resource);

      // emit an event with the resource update
      this.$emit("updated");

      // close the panel
      this.show(false);

      // show a message to the user to inform the resource update
      this.$message.success("Nota atualizada.");
    },
  },
};
</script>
