
<el-form
  label-position="top"
  class="tp-RepairForm__step4">
  <el-card>
    <!-- header -->
    <div
      slot="header"
      class="clearfix">
      <h2>Dados da Reparação</h2>
    </div>

    <el-row :gutter="10">
      <!-- warranty -->
      <el-col :span="3">
        <el-form-item label="Garantia">
          <el-switch
            v-model="innerRepair.warranty"
            on-text="sim"
            off-text="não"/>
        </el-form-item>
      </el-col>

      <!-- complaint -->
      <el-col :span="3">
        <el-form-item label="Reclamação">
          <el-switch
            v-model="innerRepair.complaint"
            on-text="sim"
            off-text="não"/>
        </el-form-item>
      </el-col>

      <!-- contact -->
      <el-col v-if="!isStoreUser" :span="5">
        <el-form-item label="Contacto">
          <el-tooltip
            class="item"
            effect="dark"
            content="Para onde irá ser notificado."
            placement="right">
            <el-input
              v-model="innerRepair.phone"
              placeholder="Numero de Contacto"/>
          </el-tooltip>
        </el-form-item>
      </el-col>

      <!-- store's repair identifier -->
      <el-col
        v-if="client.isStore || isStoreUser"
        :span="5">
        <el-form-item label="Ficha da Loja">
          <el-input
            v-model="innerRepair.storeRepairIdentifier"
            placeholder="Identificador"/>
        </el-form-item>
      </el-col>
    </el-row>

    <!-- dynamic view -->
    <dynamic-attributes
      ref="dynamicViewRepair"
      v-model="innerRepair.attributes"
      :equipment="equipmentType"
      moment="repair"/>

    <el-row :gutter="10">
      <!-- equipment state observation -->
      <el-col :span="12">
        <el-form-item label="Estado do equipamento">
          <el-select
            v-model="innerRepair.stateObservation"
            :style="{ width: '100%' }"
            placeholder="Sem riscos">
            <el-option
              v-for="item in statesObser"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>

      <!-- problem description -->
      <el-col :span="12">
        <el-form-item label="Descrição da avaria">
          <el-input
            v-model="innerRepair.problemDescription"
            :rows="5"
            type="textarea"
            placeholder="Problema do Equipamento"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-if="!isStoreUser" :gutter="10">
      <el-col :span="12">
        <el-form-item label="Prioridade">
          <el-radio-group v-model="innerRepair.priority">
            <el-radio label="normal">Normal</el-radio>
            <el-radio label="urgent">Urgente </el-radio>
            <el-radio label="ontime">Na Hora</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Orçamento">
          <el-input
            v-model="innerRepair.quotation"
            :min="0"
            type="number"
            placeholder="Orçamento">
            <template slot="append">€</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <!-- technician -->
      <el-col
        v-if="innerRepair.priority === 'ontime' || innerRepair.priority === 'urgent'"
        :span="7">
        <el-form-item label="Técnico">
          <el-select
            v-model="innerRepair.technician"
            :style="{ width: '100%' }"
            placeholder="Técnico"
            filterable>
            <el-option
              v-for="item in technicians"
              :key="item.id"
              :label="item.name"
              :value="item.id"/>
          </el-select>
        </el-form-item>
      </el-col>

      <!-- materials -->
      <el-col
        v-if="innerRepair.priority === 'ontime' || innerRepair.priority === 'urgent'"
        :span="24">

        <!-- table of materials -->
        <el-table
          v-if="innerRepair.materials.length > 0"
          :data="innerRepair.materials"
          class="tp-RepairForm__materialTable">
          <el-table-column
            :width="100"
            label="Qnt."
            prop="quantity"/>
          <el-table-column
            label="Material"
            prop="name"/>
          <el-table-column :width="100">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="warning"
                @click="returnMaterialHandler(scope.row)">Remover
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-row :gutter="10">
          <el-col :span="17">
            <el-form-item label="Material (apenas novo)">
              <el-select
                v-model="materialsToRequest.materialId"
                :style="{ width: '100%' }"
                :remote-method="remoteMethod"
                :loading="search.loading"
                filterable
                remote
                placeholder="Material">
                <el-option
                  v-for="item in search.materials"
                  :key="item.id"
                  :label="item.brand + ' ' + item.model + ', ' + item.description"
                  :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="Quantidade">
              <el-input-number
                v-model="materialsToRequest.quantity"
                :min="1"/>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-button
              :loading="loading"
              class="tp-RepairForm__addMaterialBtn"
              type="primary"
              @click="addMaterialToRequestHandler">
              Adicionar
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- conclusion button -->
    <el-row
      type="flex"
      justify="end">
      <el-button
        :loading="loading"
        type="primary"
        @click="addRepair">
        Criar
      </el-button>
    </el-row>
  </el-card>
</el-form>
