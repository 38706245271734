import { getStore } from "../index";
import stellar from "@/services/stellar";

import { Notification } from "element-ui";

// number of elements to get on each fetch
const limit = 7;

const NOTIFICATION_SET = "NOTIFICATION_SET";
const NOTIFICATION_ADD = "NOTIFICATION_ADD";
const NOTIFICATION_READ = "NOTIFICATION_READ";
const NOTIFICATION_SET_LOADING = "NOTIFICATION_SET_LOADING";
const NOTIFICATION_SET_CURRENT_PAGE = "NOTIFICATION_SET_CURRENT_PAGE";
const NOTIFICATION_APPEND = "NOTIFICATION_APPEND";

const state = {
  notifications: [],
  currentPage: 0,
  stopLoading: false,
};

const mutations = {
  [NOTIFICATION_SET](state, notifications) {
    state.notifications = notifications;
  },

  [NOTIFICATION_ADD](state, notification) {
    state.notifications.push(notification);
  },

  [NOTIFICATION_READ](state, notification) {
    // get the notification index
    const index = state.notifications.findIndex(
      item => item.id === notification.id,
    );

    // remove the notification
    state.notifications.splice(index, 1);
  },

  [NOTIFICATION_SET_LOADING](state, value) {
    state.stopLoading = value;
  },

  [NOTIFICATION_SET_CURRENT_PAGE](state, value) {
    state.currentPage = value;
  },

  [NOTIFICATION_APPEND](state, notifications) {
    // NOTE: the less expensive way to add the new messages to the begin of
    // array is using the `.unshift` method instead of `.concat`

    for (const item of notifications) {
      state.notifications.unshift(item);
    }
  },
};

const actions = {
  addNotification({ getters, commit }, notification) {
    if (!getters.user) {
      return;
    }

    const userId = getters.user._id;

    if (notification.recipient !== userId) {
      return;
    }

    // commit the new message
    commit(NOTIFICATION_ADD, notification);

    // build the message
    let message = "";
    if (notification.type === "new_repair_wa") {
      message = "Nova reparação à espera de atribuição.";
    } else if (notification.type === "work_attribution") {
      message = "Novo trabalho.";
    } else if (notification.type === "new_contact") {
      message = "Tem um novo contacto para realizar.";
    } else if (notification.type === "new_complaint_to_validate") {
      message = "Tem uma nova reclamação para avalidar.";
    } else if (notification.type === "new_urgent_repair") {
      message = "Novo trabalho urgente.";
    } else if (notification.type === "client_request_response") {
      message = "Uma reparação obteve resposta do cliente.";
    } else if (notification.type === "material_available") {
      message = "Uma reparação tem agora material disponível.";
    } else if (notification.type === "quotation_approve") {
      message = "Orçamento aprovado para uma das reparações.";
    } else {
      // eslint-disable-next-line
      console.warn(
        `Missing message build for the notification type '${
          notification.type
        }'`,
      );
    }

    // show a el-notification
    Notification.info({
      title: notification.title,
      message,
    });
  },

  /**
   * Get the next page of messages.
   *
   * This action load pages of notifications in a linear way, this doesn't load
   * an specific page of all messages.
   */
  notificationsFetchNextPage({ state, getters, commit }) {
    // when we are loading a page or there is no more notifications to load we
    // stop right here
    if (state.stopLoading) {
      return;
    }

    // bock the loading
    commit("NOTIFICATION_SET_LOADING", true);

    // get the current logged user id
    const userId = getters.user.id;

    // get the current fetched page and increment one to get the next page of
    // notifications
    const page = getters.notificationsCurrentPage + 1;

    // update the current page
    commit("NOTIFICATION_SET_CURRENT_PAGE", page);

    // call the API to get the requested page of notifications
    return stellar
      .action("getUserNotifications", { userId, page, limit })
      .then(({ notifications }) => {
        // append the messages to the current array
        commit("NOTIFICATION_APPEND", notifications);

        // whether there is no notifications or the length is less than 10 we
        // stop loading pages. There is no more notifications
        if (notifications.length === limit) {
          commit("NOTIFICATION_SET_LOADING", false);
        }
      });
  },

  /**
   * Mark a notification as read. This is the same thing like remove a
   * notification.
   */
  markNotificationAsRead({ commit }, notification) {
    // enter in loading mode
    commit("NOTIFICATION_SET_LOADING", true);

    // make an API call to remove the notification
    stellar
      .action("removeNotification", { id: notification.id })
      .then(response => {
        // remove the notification from the list
        commit(NOTIFICATION_READ, notification);

        // exiting the loading state
        commit("NOTIFICATION_SET_LOADING", false);
      });
  },
};

const getters = {
  notifications(state) {
    return state.notifications;
  },

  notificationUnreadNumber() {
    return state.notifications.filter(i => !i.read).length;
  },

  notificationsCurrentPage(state) {
    return state.currentPage;
  },
};

// handle the arrive of new notifications
stellar.on("say", async packet => {
  if (packet.room === "notifications") {
    const store = await getStore();
    store.dispatch("addNotification", packet.message);
  }
});

export default {
  actions,
  getters,
  mutations,
  state,
};
