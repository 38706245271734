<template>
  <div
    :class="{ 'is-loading': loading }"
    class="ui-List">
    <!-- actions ------------------------------------------------------------- -->
    <div class="ui-List__actions">
      <div class="ui-List__leftActions">
        <el-button
          v-if="allowCreation"
          type="success"
          class="ui-List__creationButton"
          @click="creationMethod">
          Adicionar
        </el-button>

        <slot name="actions"/>
      </div>


      <div class="ui-List__rightActions">
        <slot name="rightActions" />

        <!-- search -->
        <el-input
          v-if="searchable"
          v-model="query"
          :placeholder="searchPlaceholder"
          class="ui-List__search"
          icon="search"
          type="text"/>
      </div>
    </div>

    <!-- list it self -------------------------------------------------------- -->
    <div class="ui-List__content">
      <slot
        v-if="showNoItem && data.length === 0"
        name="noElements">
        <p>Não existem elementos!</p>
      </slot>

      <!-- item render area -->
      <slot/>
    </div>

    <!-- bottom section ------------------------------------------------------ -->
    <div class="ui-List__bottomSection">
      <div
        v-if="paginate"
        class="ui-List__pagination">
        <el-pagination
          :current-page="currentPage"
          :total="total"
          :page-size="perPage"
          layout="prev, pager, next"
          @current-change="currentPageChangeHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
// we use a time to reduce the number of query events, using this mechanism we
// can prevent unnecessary API calls
let queryTimer = null;

// var to save the current page when we enter in search mode
let savedCurrentPage = null;

export default {
  props: {
    /**
     * Allow the creation of new items.
     *
     * @type {Object}
     */
    allowCreation: {
      type: Boolean,
      default: false,
    },

    /**
     * Method to be cassed when the create button is pressed.
     *
     * @type {Object}
     */
    creationMethod: {
      type: Function,
      default: () => {},
    },

    /**
     * When this is active a pagination will be shown.
     *
     * @type {Object}
     */
    paginate: {
      type: Boolean,
      default: false,
    },

    searchPlaceholder: {
      type: String,
      default: "Pesquise aqui...",
    },

    showNoItem: {
      type: Boolean,
      default: true,
    },

    /**
     * Array with the data to be shown on the list.
     *
     * @type {[type]}
     */
    data: {
      type: Array,
      default: () => [],
    },

    /**
     * Number of total items to show.
     *
     * @type {Object}
     */
    total: {
      type: Number,
      default: 0,
    },

    /**
     * Number of items per page.
     *
     * @type {Object}
     */
    perPage: {
      type: Number,
      default: 10,
    },

    /**
     * Enable or disable the loading.
     *
     * @type {Object}
     */
    loading: {
      type: Boolean,
      default: false,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    remoteSearch: {
      type: Function,
      default: _ => {},
    },

    minimalCharsToSearch: {
      type: Number,
      default: 2,
    },

    selectable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      loadingInstance: null,
      query: "",
      mode: "normal",

      // set with the selected items
      selectedItems: [],
    };
  },

  watch: {
    total(newVal) {
      if (savedCurrentPage !== null && this.mode === "normal") {
        this.$nextTick(() => {
          this.currentPage = savedCurrentPage;
          savedCurrentPage = null;
        });
      }
    },

    query(query) {
      // the minimal number of chars must be ultrapassed in order to perform a
      // query
      if (query.length < this.minimalCharsToSearch) {
        // check if we need restore the page
        if (this.paginate && this.mode === "search") {
          this.currentPage = savedCurrentPage;
          this.$emit("mode-changed", "normal");

          // when the prev page is the number one, we need to manually notify
          // the parent
          if (this.currentPage === 1) {
            this.$emit("current-changed", 1);
          }
        }

        return;
      }

      // if the pagination is active we must save the current page to restore
      // later
      if (this.paginate && savedCurrentPage === null) {
        // save the current page and go to the first page
        savedCurrentPage = this.currentPage;
        this.currentPage = 1;

        // enter in search mode
        this.$emit("mode-changed", "search");
      }

      // cancel the previous time if exists one
      if (queryTimer !== null) {
        clearTimeout(queryTimer);
      }

      queryTimer = setTimeout(() => {
        this.remoteSearch(query);
      }, 300);
    },
  },

  created() {
    // register a new event to listening for item selection changes
    this.$on("item-selection-change", (value, data) => {
      if (value === true) {
        this.selectedItems.push(data);
      } else {
        const index = this.selectedItems.findIndex(item => item === data);
        this.selectedItems.splice(index, 1);
      }

      // emit the changes
      this.$emit("selection-change", this.selectedItems);
    });

    this.$on("mode-changed", mode => {
      this.mode = mode;
    });

    // if there is a page param on the query string of the present URL we must
    // use it
    const urlPage = parseInt(this.$router.currentRoute.query.page, 10);
    if (urlPage) {
      this.currentPageChangeHandler(urlPage);
    }
  },

  methods: {
    /**
     * This method handles the pages on the pagination component.
     */
    currentPageChangeHandler(newPage) {
      // update the current page
      this.currentPage = newPage;

      // replace the current page with a query
      this.$router.replace({ query: { page: newPage } });

      // emit the page change
      this.$emit("current-changed", newPage);
    },

    /**
     * Remove all selections.
     *
     * @return {[type]} [description]
     */
    clearSelections() {
      this.$emit("clear-selection", true);
    },
  },
};
</script>

<style lang="scss">
@import "../var.css";

.ui {
  &-List {
    min-height: 50px;
    max-width: 1000px;

    position: relative;

    &.is-loading {
      .ui-List__content::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
        background-color: hsla(0, 0%, 100%, 0.9);
      }

      .ui-List__content::after {
        content: "";
        z-index: 10010;
        position: absolute;
        border-width: 3px;
        border-style: solid;
        border-color: transparent #20a0ff #20a0ff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
        animation: 2s linear 0s normal none infinite running spin;
        filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
      }
    }

    /** actions */
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: inherit;
      margin-bottom: 10px;

      /* place element side by side */
      & > * {
        display: inline-block;
      }
    }

    /** search */
    &__search {
      max-width: 300px;
    }

    /** bottom section */
    &__bottomSection {
      margin-top: 7px;
    }

    /** pagination */
    &__pagination {
      .el-pagination {
        padding-left: 0;
      }
    }

    &__content {
      position: relative;

      border-top: 1px solid var(--colors-light);
      border-bottom: 1px solid var(--colors-light);

      & > p {
        font-weight: 300;
        padding-top: 15px;
        text-align: center;
      }
    }

    /** items **/

    &__item {
      width: 100%;
      height: 85px;
      min-height: 60px;
      padding: 15px 15px;

      /* enable flex */
      display: flex;
      flex-flow: row;
      align-items: center;

      border-bottom: 1px solid var(--list-border-color);

      transition: background 0.2s ease-out;

      &:hover {
        background-color: #dce4ec;
      }

      &:last-child {
        border-bottom: none;
      }

      &.is-active {
        background-color: var(--list-active-bg);
      }

      /* selection */
      &__selection {
        margin-top: 5px;
        margin-right: 15px;
      }

      /* image */
      &__image {
        margin-right: 15px;

        & img {
          width: 45px;
        }
      }

      /* content */
      &__content {
        /* make the content growable to align the action on the correct place */
        flex-grow: 1;
      }

      &__title {
        color: var(--colors-darker);
        display: block;
      }

      /* details */

      &__details {
        font-size: 0.85em;
      }

      /* actions */
      &__actions {
        padding-top: 5px;

        /* enable flex */
        display: flex;
      }
    }
  }
}

/** 768px **/

@media (min-width: 768px) {
  .ui {
    &-List {
      margin: 20px auto;

      &__content {
        border-radius: 2px;
        border: 1px solid var(--list-border-color);

        background-color: #ffffff;
      }
    }
  }
}
</style>
