
<section class="tp-BasicInfo">
  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler" />

  <ChangeBrandAndModelModal
    ref="changeBrandAndModelModal"/>
  <!-- create a contact panel -------------------------------------------- -->
  <contact-panel
    ref="contactPanel"
    @contacted="afterContactHandler"/>

  <!-- client panel ------------------------------------------------------ -->
  <client-form
    ref="clientPanel"
    @updated="client => $emit('update:client', client)"/>

  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler" />

  <!-- client info -->
  <div class="tp-BasicInfo__client">
    <!-- avatar -->
    <div class="tp-BasicInfo__client__avatar">
      <img
        :src="clientAvatar"
        alt="Avatar">
    </div>

    <div class="tp-BasicInfo__client__content">
      <div class="tp-BasicInfo__client__content__inner">
        <!-- header -->
        <header class="tp-BasicInfo__client__content__header">
          <!-- name -->
          <h1 class="tp-BasicInfo__client__content__header__name">
            <span>{{ client.name }}</span>
            <span v-if="client.isStore">(Loja)</span>
          </h1>

          <!-- number of repair -->
          <el-tooltip
            effect="dark"
            content="Numero de Reparações do Cliente"
            placement="bottom">
            <h2
              class="tp-BasicInfo__client__content__header__numberOsRepairs">
              {{ numberRepair || '-' }}
            </h2>
          </el-tooltip>
        </header>

        <!-- phone number -->
        <p class="tp-BasicInfo__client__content__phone">
          <i class="fa fa-phone"/>

          <a
            v-if="client.phone && $auth.can('view_client')"
            :href="`tel:${client.phone}`">
            {{ client.phone }}
          </a>
          <span v-else>-</span>
        </p>

        <!-- email -->
        <p class="tp-BasicInfo__client__content__email">
          <i class="fa fa-envelope"/>

          <a
            v-if="client.email && $auth.can('view_client')"
            :href="`to:${client.email}`">
            {{ client.email }}
          </a>
          <span v-else>-</span>
        </p>

        <!-- tax number -->
        <p class="tp-BasicInfo__client__content__taxNumber">
          <i class="fa fa-id-card"/>
          <span
            v-if="client.taxNumber && $auth.can('view_client')">
            {{ client.taxNumber }}
          </span>
          <span v-else>-</span>
        </p>
      </div>

      <!-- actions -->
      <div class="tp-BasicInfo__client__content__actions">
        <!-- contact -->
        <el-button
          :loading="loading"
          :disabled="!userIsTechnician"
          type="success"
          @click="contactClient">
          Contactar
        </el-button>

        <!-- edit -->
        <el-button
          v-if="$auth.can('view_client')"
          :loading="loading"
          @click="editClient">
          Editar
        </el-button>
      </div>
    </div>
  </div>

  <!-- divider -->
  <div class="tp-BasicInfo__divider">
    <div class="tp-BasicInfo__divider__inner"/>
  </div>

  <!-- other info -->
  <ul class="tp-BasicInfo__otherInfo">
    <!-- IMEI/SN -->
    <li class="tp-BasicInfo__otherInfo__entry">
      <span class="tp-BasicInfo__otherInfo__entry__title">IMEI/SN: </span>
      <span class="tp-BasicInfo__otherInfo__entry__value">
        <span v-if="typeof equipment === 'object'">
          <span v-if="equipment.attributes && equipment.attributes.imei">
            {{ equipment.attributes.imei }}
          </span>
          <span v-else>
            {{ equipment.serialNumber }}
          </span>
        </span>

        <!-- change -->
        <el-button
          :loading="loading"
          type="text"
          class="ui-smallButton is-green"
          @click="changeEquipmentIdentification">
          Alterar
        </el-button>
      </span>
    </li>

    <!-- equipment type -->
    <li class="tp-BasicInfo__otherInfo__entry">
      <span class="tp-BasicInfo__otherInfo__entry__title">Tipo: </span>
      <span
        v-if="equipmentType"
        class="tp-BasicInfo__otherInfo__entry__value">
        {{ equipmentType.name }}
      </span>
    </li>

    <!-- equipment -->
    <li class="tp-BasicInfo__otherInfo__entry">
      <span class="tp-BasicInfo__otherInfo__entry__title">Equipamento: </span>
      <span class="tp-BasicInfo__otherInfo__entry__value">
        {{ equipment.brand }} {{ equipment.model }}
      </span>

      <!-- button -->
      <el-button
        :loading="loading"
        type="text"
        class="ui-smallButton is-green"
        @click="changeModelHandler">
        Alterar
      </el-button>
    </li>

    <!-- times in the store -->
    <li class="tp-BasicInfo__otherInfo__entry">
      <span class="tp-BasicInfo__otherInfo__entry__title">Vezes em loja: </span>
      <span class="tp-BasicInfo__otherInfo__entry__value">
        <span v-if="timesInStore === 0">Primeira vez em loja</span>
        <span v-else>{{ timesInStore }} vezes em loja</span>
      </span>
    </li>

    <!-- technician -->
    <li class="tp-BasicInfo__otherInfo__entry">
      <span
        class="tp-BasicInfo__otherInfo__entry__title">
        Tecnico:
      </span>
      <span class="tp-BasicInfo__otherInfo__entry__value">
        <span
          v-if="technician && typeof technician === 'object'">
          {{ technician.name }}
        </span>
        <span v-else>-</span>

        <!-- button to set the technician -->
        <el-button
          v-if="$auth.can('set_repair_technician') || ($auth.is('technician') && user.personId === technician.id)"
          :loading="loading"
          :disabled="!canChangeTechnician"
          :class="{'is-green': technician, 'is-orange': !technician}"
          type="text"
          class="ui-smallButton"
          @click="() => isSelectTechnicianVisible = true">
          <span v-if="technician">Alterar</span>
          <span v-else>Atribuir</span>
        </el-button>
      </span>
    </li>
    <!-- estado -->
    <li class="tp-BasicInfo__otherInfo__entry is-problem">
      <span class="tp-BasicInfo__otherInfo__entry__title">Estado: </span>
      <span class="tp-BasicInfo__otherInfo__entry__value">
        {{ repair.stateObservation }}
      </span>
    </li>
  </ul>
</section>
