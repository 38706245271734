<template>
  <div class="tp-ContactRequest">
    <ui-bottom-sheet
      ref="sheet"
      actions>

      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="lock"
          type="primary"
          @click="sendMessage">Enviar</el-button>
        <el-button @click="show(false)">Cancelar</el-button>
      </div>

      <el-form>
        <el-row
          v-if = "data.client && data.client.isStore"
          :gutter="10">
          <el-col :span="24">
            <el-form-item label="Descreva o porque do contacto">
              <el-input
                v-model="data.message"
                type="textarea"
                placeholder="Mensagem"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24">
            <el-form-item label="Solicitar ao cliente:">
              <el-select
                v-model="data.message"
                filterable
                allow-create
                placeholder="Escolha ou escreva a mensagem">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row></el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: {
        message: "",
        client: null,
        repair: null,
      },
      lock: false,
      options: [
        {
          value: "carregador",
          label: "Carregador",
        },
        {
          value: "codigo",
          label: "Código de segurança",
        },
        {
          value: "emailpass",
          label: "Email e password",
        },

        {
          value: "emailPassCodigo",
          label: "Email, password e código de segurança",
        },
        {
          value: "matriz",
          label: "Matriz",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["createContactRequest"]),

    /**
     * Set some necessary data.
     */
    setData(clientId, repairId) {
      this.data.client = clientId;
      this.data.repair = repairId;
    },

    sendMessage() {
      this.lock = true;

      this.createContactRequest(this.data).then(response => {
        // close panel
        this.show(false);

        // show a toast
        this.$message.success("Pedido de contacto criado");

        // emit an event to be catched by the RepairView
        this.$emit("contacted", this.data);
      });
    },

    show(val = true) {
      this.$refs.sheet.show(val);

      if (!val) {
        this.reset();
      }
    },

    reset() {
      this.data = {
        message: "",
        client: null,
        repair: null,
      };
      this.lock = false;
    },
  },
};
</script>
