import { ActionTree } from "vuex";
import {
  ISpacesSate,
  ESpacesActions,
  ESpacesMutations,
} from "@/services/store/modules/spaces/spaces-types";
import { IStoreRootState } from "@/services/store/types/store-root-state.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";

export const actions: ActionTree<ISpacesSate, IStoreRootState> = {
  [getBaseName(ESpacesActions.ADD_SPACE)]({ commit }, data) {
    commit(ESpacesMutations.ADD_SPACE, data);
  },
};
