<template>
  <section class="tp-RepairForm__step3">
    <!-- types of equipment ------------------------------------------------ -->
    <transition name="fade">
      <el-row
        v-show="!equipmentType"
        :gutter="10">
        <el-col
          v-for="type in types"
          :key="type.id"
          :span="3">
          <el-card
            :body-style="{ padding: '0px' }"
            class="tp-RepairForm__step3__card"
            @click.native="$emit('update:equipmentType', type)">
            <el-tooltip
              :content="type.name"
              effect="dark"
              placement="top">
              <img
                :src="getEquipmentTypeImgUrl(type.id)"
                :alt="type.name"
                class="image">
            </el-tooltip>
          </el-card>
        </el-col>
      </el-row>
    </transition>

    <!-- create a new equipment for the client ----------------------------- -->
    <transition name="fade">
      <el-form label-position="top">
        <!-- title -->
        <h2
          v-if="equipmentType"
          class="tp-RepairForm__step3__titleType">
          {{ equipmentType.name }}
          <el-button
            type="text"
            @click="$emit('update:equipmentType', null)">
            Alterar
          </el-button>
        </h2>

        <el-card
          v-if="equipmentType"
          class="tp-repairForm_createNewEquipment">
          <!-- header -->
          <div
            slot="header"
            class="clearfix">
            <h2>Novo equipamento</h2>
          </div>

          <el-row :gutter="10">
            <!-- model -->
            <el-col :span="12">
              <el-form-item label="Modelo">
                <el-select
                  v-model="tempBrand"
                  :remote-method="equipmentRemoteMethod"
                  :loading="search.loading"
                  class="tp-RepairForm__newBrandField"
                  placeholder="Modelo"
                  default-first-option
                  filterable
                  allow-create
                  remote
                  @change="modelChange">
                  <el-option
                    v-for="equip in equipments"
                    :key="equip.id"
                    :label="equip.model"
                    :value="equip.id">
                    <span>{{ equip.brand + ' ' + equip.model }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- brand -->
            <el-col :span="12">
              <el-form-item label="Marca">
                <el-input
                  v-model="selectedEquipment.brand"
                  type="text"
                  placeholder="Marca"/>
              </el-form-item>
            </el-col>

            <!-- serial number -->
            <el-col :span="24">
              <el-form-item label="Numero de Serie">
                <el-input
                  v-model="selectedEquipment.serialNumber"
                  type="text"
                  placeholder="Numero de Serie"/>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- dynamic view -->
          <dynamic-attributes
            v-model="selectedEquipment.attributes"
            :equipment="equipmentType"
            moment="normal"/>

          <!-- actions -->
          <el-row
            type="flex"
            justify="end">
            <!-- create button -->
            <el-button
              type="primary"
              @click="createClientEquipment">
              Criar
            </el-button>
          </el-row>
        </el-card>
      </el-form>
    </transition>
  </section>
</template>

<script>
import DynamicAttributes from "../EquipmentDynamicForm.vue";

let searchEquipmentTimer = null;

export default {
  components: {
    DynamicAttributes,
  },

  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
    equipment: {
      type: Object,
      default: () => ({}),
    },
    equipmentType: {
      type: Object,
      default: () => ({}),
    },
    repair: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Number,
      default: 1,
    },
    types: {
      type: Array,
      default: () => [],
    },
    tempIdentifier: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // list of equipment to chose
      equipments: [],

      // search data
      search: {
        loading: false,
      },

      selectedEquipment: {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      },

      tempBrand: null,
    };
  },

  watch: {
    equipmentType(newVal) {
      if (newVal.name === "Telemóvel") {
        this.selectedEquipment.attributes.imei = this.tempIdentifier;
      } else {
        this.selectedEquipment.serialNumber = this.tempIdentifier;
      }
    },

    equipment() {
      this.selectedEquipment.serialNumber = "";
    },
  },

  created() {
    // copy equipment prop to local
    this.selectedEquipment = JSON.parse(JSON.stringify(this.equipment));
  },

  methods: {
    /**
     * Build the url for the equipment type image.
     */
    getEquipmentTypeImgUrl(id) {
      return `${import.meta.env.VITE_API_URL}/equipment_types/${id}.jpg`;
    },

    equipmentRemoteMethod(query = "") {
      // clean the previous query
      clearTimeout(searchEquipmentTimer);

      if (query.length < 3) {
        this.equipments = [];
        return;
      }

      searchEquipmentTimer = setTimeout(async () => {
        // enter in loading mode
        this.search.loading = true;

        // make an API call in order to search for the equipments
        const { equipments } = await this.$stellar.action("searchEquipment", {
          query,
        });

        // disable loading
        this.search.loading = false;

        // save the found equipments
        this.equipments = equipments;
      }, 600);
    },

    modelChange(value) {
      // try get the equipment data from the list of found equipments
      const item = this.equipments.find(equipment => equipment.id === value);

      if (item) {
        this.selectedEquipment.brand = item.brand;
        this.selectedEquipment.model = item.model;
      } else {
        // Craft an equipment to be temporarily used on the
        // select component.
        this.selectedEquipment = {
          id: value,
          brand: "",
          model: value,
        };

        // push the selectedEquipment to the array of fund equipments
        this.equipments = [this.selectedEquipment];
        this.tempBrand = value;
      }
    },

    async createClientEquipment() {
      const _repair = this.repair;

      // set the client field
      this.selectedEquipment.client = this.client.id;

      // set the equipment type
      this.selectedEquipment.equipmentType = this.equipmentType.id;
      delete this.selectedEquipment.id;

      const { clientequipment } = await this.$stellar.action(
        "createClientequipment",
        this.selectedEquipment,
      );

      this.$emit("update:equipment", clientequipment);

      _repair.clientEquipment = clientequipment.id;
      this.$emit("update:repair", _repair);

      // go to the next step
      this.$emit("update:step", this.step + 1);
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-RepairForm {
    &__step3 {
      margin-top: 20px;

      .tp-RepairForm__newBrandField {
        width: 100% !important;
      }

      &__card {
        margin-bottom: 10px;
        will-change: scale;
        transition: all 0.2s ease-in;

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }

        .el-tooltip,
        .el-tooltip__rel {
          width: 100%;
          display: block !important;
        }

        .image {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
