import { IBaseState } from "@/services/store/types/base-state.interface";
import { ICustomerGroup } from "@/modules/clients/types/customer-group.interface";

export interface ICustomerGroupStore extends IBaseState {
  groups: Array<ICustomerGroup>;
}

export enum ECustomerGroupActions {
  CLEAN = "customerGroups/clean",
  FETCH_ALL = "customerGroups/fetch",
  CREATE = "customerGroups/create",
}

export enum ECustomerGroupMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_GROUPS = "SET_GROUPS",
}
