
<div class="tp-RepairView__entry">
  <h2 class="title">
    <span>Detalhes do Equipamento</span>
    <el-button v-if="repair.state !== 'delivered' && !isEditing" icon="el-icon-edit" size="mini" class="EditButton" @click="onClickEdit" />

    <!-- editing mode buttons -->
    <div v-if="isEditing">
      <el-button icon="el-icon-close" type="danger" size="mini" @click="onClickCancel" />
      <el-button icon="el-icon-check" type="primary" size="mini" @click="onClickSave" />
    </div>
  </h2>

  <div class="content">
    <el-form label-position="top">
      <!-- show the equipment's details -->
      <dynamic-attributes
        v-if="repair.clientEquipment.attributes"
        :read-only="true"
        :equipment="equipmentType"
        :value="repair.clientEquipment.attributes"
        moment="normal"
         />

      <!-- show the equipment's repair details -->
      <dynamic-attributes
        :read-only="!isEditing"
        :equipment="equipmentType"
        v-model="repairAttributes"
        moment="repair"/>
    </el-form>
  </div>
</div>
