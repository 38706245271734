
<div class="tp-RepairExternalCreate">
  <div class="tp-RepairExternalCreate__container">
    <!-- current step header -->
    <el-steps
      :space="150"
      :active="currentStep"
      finish-status="success">
      <el-step title="Dispositivo"/>
      <el-step title="Equipamento"/>
      <el-step title="Reparação"/>
      <el-step title="Concluido"/>
    </el-steps>

    <!-- error alert -->
    <el-alert
      v-if="errorMessage !== null"
      :title="errorMessage"
      type="error"/>

    <step1
      v-if="currentStep === 0"
      :repair.sync="repair"
      :equipment.sync="selectedEquipment"
      :client.sync="selectedClient"
      @goStep="value => currentStep = value"
      @setEquipmentType="setEquipmentType"
      @setIdentifier="v => tempIdentifier = v"
      @latestRepair="v => prevRepair = v"/>

    <step2
      v-if="currentStep === 1"
      :temp-identifier="tempIdentifier"
      :repair.sync="repair"
      :equipment-type.sync="selectedEquipmentType"
      :equipment.sync="selectedEquipment"
      :step.sync="currentStep"
      :client.sync="selectedClient"
      :types="equipmentTypes"/>

    <step3
      v-if="currentStep === 2"
      :client="selectedClient"
      :repair.sync="repair"
      :equipment-type="selectedEquipmentType"
      @created="repairCreated"/>

    <step4
      v-if="currentStep === 3"
      :client="selectedClient"
      :equipment-type="selectedEquipmentType"
      :equipment="selectedEquipment"
      :repair="repair"
      :repairs-list="repairsList"
      @newRepair="newRepairSameClientHandler"/>
  </div>
</div>
