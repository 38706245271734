import Vue from "vue";

// values: [waiting_atribution, waiting_repair, in_repair,
// waiting_quotation_approve, waiting_material, waiting_delivery,
// closed_without_permission, delivered, waiting_avaluation, in_avaluation,
// contact_client, paused]
Vue.filter("repairState", value => {
  // ignore the undefined state
  if (value === undefined) {
    return "";
  }

  switch (value) {
    case "waiting_attribution":
      return "Espera atribuição";
    case "waiting_repair":
      return "Espera reparação";
    case "in_repair":
      return "Em reparação";
    case "waiting_quotation_approve":
      return "Espera aprovação do orçamento";
    case "waiting_material":
      return "Espera material";
    case "waiting_delivery":
      return "Espera entrega";
    case "closed_without_permission":
      return "Fechado sem permissão";
    case "delivered":
      return "Entregue";
    case "waiting_avaluation":
      return "Espera avaliação";
    case "in_avaluation":
      return "Em avaliação";
    case "contact_client":
      return "Contactar cliente";
    case "paused":
      return "Em pausa";
    case "waiting_complaint_avaluation":
      return "Avaliar responsabilidade";
    case "in_tests":
      return "Em testes";
    case "problematic":
      return "Problemático";
    case "waiting_answer":
      return "Espera resposta";
    case "in_transit":
      return "Em transito";
    case "waiting_warranty_dispatch":
      return "Espera garantia";
    case "in_warranty":
      return "Na garantia";
    default:
      // TODO: implement an error handle with a 3rd party service
      // console.warn(`Filter:repairState - invalid state "${value}"`);
      return "Invalid";
  }
});
