<template>
  <section class="tp-ContactsList">

    <!-- group form ---------------------------------------------------------- -->

    <group-form
      ref="groupForm"
      @created="fetchContactsList"
      @updated="fetchContactsList"/>

    <!-- contact form -------------------------------------------------------- -->

    <contact-form
      ref="contactForm"
      :groups="groups"
      @created="fetchContactsList"
      @updated="fetchContactsList"/>

    <!-- list of contacts and groups ----------------------------------------- -->

    <div class="tp-ContactList__container">
      <!-- button to add a new group -->
      <el-button
        type="success"
        @click="newGroupHandler">
        Novo Grupo
      </el-button>

      <el-card class="tp-ContactList__card">
        <el-row :gutter="0">
          <!-- groups -->
          <el-col :span="6">
            <el-menu
              :default-active="selectedGroupId"
              @select="handleGroupChange">
              <el-menu-item
                v-for="group in groups"
                :key="group.id"
                :index="group.id">
                <!-- group name -->
                <span>{{ group.name }}</span>

                <div>
                  <!-- edit contact -->
                  <el-button
                    circle
                    class="tp-ContactList__groupEditBtn"
                    size="small"
                    icon="el-icon-edit"
                    @click.stop="updateGroupHandler(group)"/>

                  <!-- delete contact -->
                  <el-button
                    circle
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    class="tp-ContactList__groupDeleteBtn"
                    @click.stop="deleteGroupHandler(group)"/>
                </div>
              </el-menu-item>
            </el-menu>
          </el-col>

          <!-- group contacts -->
          <el-col :span="18">
            <el-table
              v-loading.body="loading"
              :data="contacts"
              class="tp-ContactList__table"
              striped>
              <!-- full details -->
              <el-table-column type="expand">
                <template slot-scope="props">
                  <p>Empresa: {{ props.row.company || 'N/A' }}</p>
                  <p>Telefone: {{ props.row.phone || 'N/A' }}</p>
                  <p v-if="props.row.address">{{ props.row.address }}</p>
                  <p v-if="props.row.url"><a :url="props.row.url">Website</a></p>
                  <p v-if="props.row.email">Email: <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a></p>
                  <p>Notas: {{ props.row.note || 'N/A' }}</p>
                </template>
              </el-table-column>

              <!-- gravatar -->
              <el-table-column
                label=""
                width="70">
                <template slot-scope="props">
                  <img
                    :src="getAvatar(props.row.email)"
                    class="tp-ContactList__avatar img-circle"
                    alt="Avatar" >
                </template>
              </el-table-column>

              <!-- name -->
              <el-table-column label="Nome">
                <template slot-scope="props">
                  {{ props.row.name }} {{ props.row.surname }}
                </template>
              </el-table-column>
              <el-table-column
                label="Telemóvel"
                prop="cellphone"/>

              <el-table-column :width="150">
                <template slot-scope="props">
                  <!-- edit button -->
                  <el-button
                    :plain="true"
                    size="small"
                    type="primary"
                    icon="el-icon-edit"
                    @click="editContactHandler(props.row)"/>

                  <!-- delete button -->
                  <el-button
                    :plain="true"
                    size="small"
                    type="danger"
                    icon="el-icon-delete"
                    @click="deleteContactHandler(props.row)"/>
                </template>
              </el-table-column>
            </el-table>

            <!-- button to add a new contact -->
            <div
              class="tp-ContactList__newBtn"
              @click="newContactHandler">
              <i class="fa fa-plus"/>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import gravatar from "../../filters/gravatar";

import ContactFormCmp from "./ContactForm.vue";
import GroupFormCmp from "./GroupForm.vue";

export default {
  components: {
    "contact-form": ContactFormCmp,
    "group-form": GroupFormCmp,
  },

  data() {
    return {
      // store the groups and the selected group
      groups: [],
      selectedGroupId: null,

      // state variables
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    /**
     * Get the contacts for the selected group.
     */
    contacts() {
      const item = this.groups.find(group => group.id === this.selectedGroupId);
      return item ? item.contacts : [];
    },
  },

  created() {
    // fetch the contacts data
    this.fetchContactsList();
  },

  methods: {
    /**
     * Generate the gravatra image address by email.
     */
    getAvatar(email) {
      return gravatar(email);
    },

    /**
     * Fetch the list of contacts for a givin role.
     */
    fetchContactsList() {
      // enable loading
      this.loading = true;

      // make the API request
      this.$stellar
        .action("getContactsToRole", { role: this.user.role.slug })
        .then(({ groups }) => {
          // save the groups
          this.groups = groups;

          // select the first group
          this.$nextTick(_ => {
            // check if there is any group to select
            if (this.groups.length === 0 || this.selectedGroupId !== null) {
              return;
            }

            this.selectedGroupId = this.groups[0].id;
          });

          // disable loading
          this.loading = false;
        });
    },

    /**
     * Handle the click on the new group button.
     */
    newGroupHandler() {
      // get the panel reference
      const panel = this.$refs.groupForm;

      // show the form
      panel.show();
    },

    /**
     * Handle the edit click for a group.
     */
    updateGroupHandler(group) {
      // get the panel reference
      const panel = this.$refs.groupForm;

      // set a group to edit
      panel.setGroup(group);

      // show the form
      panel.show();
    },

    deleteGroupHandler(group) {
      // confirm the action with the user
      this.$confirm(
        "O grupo será apagado permanentemente. Continuar?",
        "Apagar Grupo",
        {
          confirmButtonText: "Apagar",
          cancelButtonText: "Cancelar",
          type: "warning",
        },
      )

        // delete the contact
        .then(() => this.$stellar.action("contacts.removeGroup", group))
        .then(({ contact }) => {
          // reload the contacts
          this.fetchContactsList();

          // show a success message
          this.$message.success("Grupo apagado.");
        })

        // catch the cancel action
        .catch(() => {});
    },

    /**
     * Handle the selected menu change.
     *
     * This saves the group ID.
     *
     * @type {[type]}
     */
    handleGroupChange(id) {
      this.selectedGroupId = id;
    },

    /**
     * Handle the click event on the new contact
     */
    newContactHandler() {
      // get the panel
      const panel = this.$refs.contactForm;

      // add one group
      panel.addGroup(this.selectedGroupId);

      // show the contact form
      panel.show(true);
    },

    /**
     * Handles the click event on the contact's edit button.
     */
    editContactHandler(contact) {
      // get the edit panel
      const panel = this.$refs.contactForm;

      // set the contact to be editted
      panel.setResource(contact);

      // show edit panel
      panel.show();
    },

    /**
     * Handle the click on the contact's delete button.
     */
    deleteContactHandler(contact) {
      // confirm the action with the user
      this.$confirm(
        "O contacto será apagado permanentemente. Continuar?",
        "Apagar",
        {
          confirmButtonText: "Apagar",
          cancelButtonText: "Cancelar",
          type: "warning",
        },
      )

        // delete the contact
        .then(() => this.$stellar.action("contacts.removeContact", contact))
        .then(() => {
          // reload the contacts
          this.fetchContactsList();

          // show a success message
          this.$message.success("Contacto apagado.");
        })

        // catch the cancel action
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-ContactList {
    /** group edit button */
    &__groupEditBtn {
      position: absolute;
      right: 5px;
      top: 13px;
    }

    &__groupDeleteBtn {
      position: absolute;
      right: 40px;
      top: 13px;
    }

    &__container {
      max-width: 1000px;
      margin: 10px auto;
    }

    &__card {
      max-width: 1000px;
      margin-top: 10px;

      & > .el-card__body {
        padding: 0;
      }
    }

    /** table **/
    &__table {
      width: 100%;

      border: none !important;
      border-left: 1px solid #c9c9c9 !important;
    }

    &__avatar {
      height: 30px;
    }

    /** new contact **/
    &__newBtn {
      width: 100%;
      height: 40px;
      text-align: center;

      font-size: 20px;
      background: #eff2f7;
      cursor: pointer;
      border-left: 1px solid #c9c9c9;

      transition: all 0.2s ease;

      &:hover {
        background: #e0e6ed;
      }

      i {
        padding-top: 10px;
        color: #1f2d3d;
      }
    }
  }

  &-Contacts {
    &__groupSelect {
      width: 100%;
      display: block;
    }

    &__newGroupBtn {
      margin-top: 32px !important;
    }
  }
}
</style>
