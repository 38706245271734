import { INavigationMenuItem } from "@/services/store/modules/navigation/navigation-types";

/**
 * Items that must be presented on the sidebar menu and
 * on the quick navigation panel.
 */
export const initialMenuItems: Array<INavigationMenuItem> = [
  {
    title: "Mural",
    route: "dashboard",
    icon: "tachometer",
    permission: "see_dashboard",
  },
  {
    title: "Clientes",
    route: "client.list",
    icon: "address-card-o",
    permission: "see_clients",
  },
  {
    title: "Contactos",
    route: "contact.list",
    icon: "address-book",
    permission: "see_contacts",
  },
  {
    title: "Configurações",
    route: "configs",
    icon: "cogs",
    permission: "see_configs",
  },
  {
    title: "Em progresso",
    route: "store.ongoing",
    icon: "binoculars",
    role: "store",
  },
  {
    title: "Utilizadores",
    route: "users.list",
    icon: "users",
    role: "admin",
  },
  {
    title: "Tecnicos",
    route: "technician.list",
    icon: "briefcase",
    role: "admin",
  },
  {
    title: "Fornecedores",
    route: "supplier.list",
    icon: "truck",
    permission: "see_suppliers",
  },
  {
    title: "Material Defeituoso",
    route: "damagedMaterial.list",
    icon: "chain-broken",
    permission: "see_damaged_materials",
  },
  {
    title: "Material",
    route: "material.list",
    icon: "cube",
    permission: "see_materials",
  },
  {
    title: "Material",
    route: "material_reseller.list",
    icon: "cube",
    permission: "see_materials_reseller",
  },
  {
    title: "Pedidos",
    route: "materialRequests.list",
    icon: "check",
    permission: "see_material_requests",
  },
  {
    title: "Entradas",
    route: "repair.create",
    icon: "arrow-right",
    permission: "create_repairs",
  },
  {
    title: "Entradas",
    route: "repair.externalCreate",
    icon: "arrow-right",
    permission: "create_external_repairs",
  },
  {
    title: "Reclamações",
    route: "complaint.create",
    icon: "frown-o",
    permission: "create_complaint",
  },
  {
    title: "Reparações",
    route: "repairs.list",
    icon: "cubes",
    permission: "see_repairs",
  },
  {
    title: "Triagem",
    route: "repair.waiting",
    icon: "tag",
    permission: "see_screening",
  },
  {
    title: "Compras",
    route: "purchases.list",
    icon: "money",
    permission: "see_purchases",
  },
  {
    title: "Contactar",
    route: "clientContacts.list",
    icon: "phone",
    permission: "see_contactsToMade",
  },
  { title: "Tipos", route: "types.list", icon: "clone", role: "admin" },
  {
    title: "Trabalho",
    route: "work.list",
    icon: "rocket",
    permission: "see_work",
  },
  {
    title: "Saidas",
    route: "deliver.list",
    icon: "arrow-left",
    permission: "see_delivers",
  },
  {
    title: "Histórico",
    route: "client.history",
    icon: "history",
    role: "store",
  },
  {
    title: "Em trânsito",
    route: "repair.inTransit",
    icon: "exchange",
    permission: "see_screening",
  },
  {
    title: "Notas",
    route: "notes.note",
    icon: "th-large",
    permission: "see_dashboard",
  },
  {
    title: "Comissões",
    route: "commissions.dalyCommissions",
    icon: "money",
    permission: "see_own-commissions",
    filter(): boolean {
      return (this as any).isComissionTechnician && !(this as any).isExternalTechnician;
    },
  },
  {
    title: "Garantia",
    route: "warranty.list",
    icon: "external-link-square",
    permission: "see_repairs",
  },
  {
    title: "Lucro lojas",
    route: "profits.storeProfits",
    icon: "usd",
    role: "admin",
  },
  {
    title: "Fechadas hoje",
    route: "closedRepairsByTechnician",
    icon: "usd",
    role: "admin",
  },
] as Array<INavigationMenuItem>;
