
<div
  v-if="visible"
  class="tp-QuickPanel">
  <div class="tp-QuickPanel__panel">
    <!-- input -->
    <input
      ref="input"
      v-model="query"
      class="tp-QuickPanel__input"
      type="text"
      placeholder="Nome da secção..."
      @keyup.enter="handleEnter">

    <!-- list of results -->
    <div
      class="tp-QuickPanel__results"
      @click="hide">
      <router-link
        v-for="result in results"
        :key="result.route"
        :to="{ name: result.route }"
        class="tp-QuickPanel__results__entry"
        exact>
        <span>{{ result.title }}</span>
      </router-link>
    </div>
  </div>
</div>
