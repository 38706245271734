
<div class="tp-UserForm">
  <!-- Dialog to Select a Client ------------------------------------------- -->

  <el-dialog
    :visible.sync="clientSelection.visibility"
    title="Selecionar o cliente"
    size="tiny">
    <el-form label-position="top">
      <el-select
        v-model="clientSelection.selectedClient"
        :style="{ width: '100%' }"
        :remote-method="searchForClients"
        :loading="clientSelection.loading"
        filterable
        remote
        placeholder="Escolha o Cliente">
        <el-option
          v-for="item in clientSelection.clients"
          :key="item.id"
          :label="item.name"
          :value="item">
          <span>{{ item.name }}</span>
        </el-option>
      </el-select>
    </el-form>

    <!-- show the selected client information -->
    <client-card :client="clientSelection.selectedClient">
      <div slot="actions">
        <!-- use it -->
        <el-button
          type="success"
          @click="useClient">Usar</el-button>
      </div>
    </client-card>
  </el-dialog>

  <SelectTechnicianModal
    :visibility.sync="isSelectTechnicianVisible"
    @selected="onSelectTechnicianHandler"
    @dismiss="onSelectTechnicianDismiss" />

  <!-- Main Form ----------------------------------------------------------- -->
  <ui-bottom-sheet
    ref="editSheet"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="save">Guardar</el-button>
      <el-button
        :loading="loading"
        @click="show(false)">Cancelar</el-button>
    </div>

    <el-form
      ref="user"
      :model="user"
      :rules="rules"
      label-position="top">
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :span="12">
          <el-form-item
            label="Nome"
            prop="name">
            <el-input
              v-model="user.name"
              placeholder="Nome"/>
          </el-form-item>
        </el-col>

        <!-- Email -->
        <el-col :span="12">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="user.email"
              type="email"
              placeholder="Email"/>
          </el-form-item>
        </el-col>

        <!-- role -->
        <el-col :span="12">
          <el-form-item
            label="Tipo de utilizador"
            prop="role">
            <el-select
              v-model="user.role"
              :style="{ width: '100%' }">
              <el-option
                v-for="item in roles"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- password -->
        <el-col :span="12">
          <el-form-item
            label="Password"
            prop="password">
            <el-input
              v-model="user.password"
              placeholder="Password"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
