import UiAnimatedNumber from "./AnimatedNumber.vue";
import UiBottomSheet from "./BottomSheet.vue";
import UiImageSelect from "./ImageSelect.vue";
import UiList from "./List.vue";
import UiListItem from "./ListItem.vue";
import UiPattern from "./Pattern.vue";
import PageToolbar from "./page-toolbar.vue";
import Vue from "vue";

export const components: any = {
  UiAnimatedNumber,
  UiBottomSheet,
  UiImageSelect,
  UiList,
  UiListItem,
  UiPattern,
  PageToolbar,
};

// this will install all components on the Vue, so you can use the UI
// components as a normal component
export default {
  install(_vue: any) {
    Object.keys(components).forEach((name: string) => {
      _vue.component(name, components[name]);
    });
  },
};
