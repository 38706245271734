<template>
  <section class="tp-repairWatingAtributionList_wrap">
    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler" />

    <!-- add material to the request -->
    <material-to-repair
      ref="materialAdd"
      :repair="selectedRepair"
      @requests="addRequest"/>

    <div
      v-if="selectedTechnician"
      class="tp-RepairWatingAtributionList__technician">
      <el-alert
        :title="'Os trabalhos aqui atribuidos refere-se ao tecnico ' + selectedTechnician.name + '.'"
        :closable="false"
        type="error"/>
    </div>

    <!-- send equipment to warranty -->
    <el-dialog
      v-loading.fullscreen="loading"
      :visible.sync="warrantyVisibility"
      class="tp-SendtoWarrantyDialog"
      title="Enviar para garantia"
      size="tiny">
      <el-select
        v-model="entity"
        placeholder="Entidade">
        <el-option
          v-for="e in entities"
          :key="e.value"
          :label="e.name"
          :value="e.id" />
      </el-select>

      <el-button
        type="success"
        size="small"
        @click="sendToWarrantyEntity(entity)">
        Enviar
      </el-button>
    </el-dialog>

    <!-- List os equipments -------------------------------------------------- -->
    <div class="tp-Screening__content" >
      <el-table
        v-loading.body="loading"
        :data="repairs"
        class="tp-Screening__table"
        stripe>
        <el-table-column
          :width="70"
          prop="repairNumber"
          label="#"/>
        <!-- client -->
        <el-table-column
          label="Cliente"
          width="200">
          <template slot-scope="scope">
            <div class="tp-Screening-client">
              <span class="tp-Screening-client__name">
                <el-tooltip
                  v-if="isNewClient(scope.row)"
                  effect="dark"
                  content="Novo cliente"
                  placement="top">
                  <div class="tp-Screening-client__circle"/>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.complaint"
                  effect="dark"
                  content="Reclamação"
                  placement="top">
                  <div class="tp-Screening-complaint__circle"/>
                </el-tooltip>
                {{ scope.row.client.name }}
              </span>
            </div>
          </template>
        </el-table-column>

        <!-- clientEquiment -->
        <el-table-column
          label="Equipamento"
          width="200">
          <template slot-scope="{row}">
            <span>{{ row.clientEquipment.brand }} {{ row.clientEquipment.model }}</span>
          </template>
        </el-table-column>

        <!-- problem -->
        <el-table-column
          label="Problema"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.problemDescription || scope.row.problemName }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Caixa"
          width="60"
          align="center">
          <template slot-scope="scope">
            <span> {{ scope.row.storeBox || '-' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Orçamento">
          <template slot-scope="scope">
            {{ scope.row.quotation | money }}
          </template>
        </el-table-column>

        <el-table-column
          property="priority"
          label="Prioridade"/>

        <!-- actions -->
        <el-table-column
          :width="260"
          label="">
          <template slot-scope="scope">
            <el-button-group class="repairWaitAtribList-buttonsGroup">
              <el-button
                type="info"
                size="mini"
                @click="showRepairDetails(scope.row)">Ver</el-button>
              <el-button
                type="primary"
                size="mini"
                @click="atribTech(scope.row)">Atribuir</el-button>
              <el-button
                type="warning"
                size="mini"
                @click="purchase(scope.row)">Material</el-button>
              <el-button
                type="success"
                size="mini"
                @click="openWarrantyModal(scope.row)">
                Garantia
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script >
import { mapActions, mapGetters } from "vuex";

import { ACTION_ENTITY_FETCH } from "@/services/store/modules/entities/entities-types";

import MaterialRequestCmp from "../materialRequest/MaterialRequestToRepair.vue";
import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";
import { isSameDay } from "date-fns";

export default {
  components: {
    "material-to-repair": MaterialRequestCmp,
    SelectTechnicianModal,
  },

  data() {
    return {
      isSelectTechnicianVisible: false,

      repairs: [],
      technician: [],
      materials: [],
      requests: [],
      materialToSave: [],

      selectedRepair: null,
      need: {
        material_id: "",
        repair_type: "repair",
        repair_id: "",
        client_id: null,
        observation: "",
        quantity: "",
        state: "waiting_purchase",
        validated: "true",
      },

      selectedTechnician: null,
      loading: true,
      warrantyVisibility: false,
      entity: null,
    };
  },

  computed: {
    ...mapGetters("entities", ["entities"]),
  },

  created() {
    // fetch the list
    this.fetchList();

    // get warranty entities
    this.fetchEntities();

    // if there is an ID defined we get the technician information
    if (this.$route.query.id) {
      this.$stellar.action("getTechnician", { id: this.$route.query.id }).then(({ technician }) => {
        this.selectedTechnician = technician;
      });
    }
  },

  methods: {
    ...mapActions("entities", {
      fetchEntities: ACTION_ENTITY_FETCH,
    }),

    // fetch list
    async fetchList() {
      this.loading = true;

      const { repairs } = await this.$stellar.action("getRepairByState", {
        state: "waiting_attribution",
      });
      this.repairs = repairs;

      this.loading = false;
    },

    atribTech(row) {
      this.selectedRepair = row;

      if (this.selectedTechnician) {
        return this.onSelectTechnicianHandler(this.selectedTechnician);
      }

      // show modal to select technician
      this.isSelectTechnicianVisible = true;
    },

    async onSelectTechnicianHandler(technician) {
      const { repair } = await this.$stellar.action("setTechnicianForRepair", {
        id: this.selectedRepair.id,
        technician: technician.id,
      });

      // to reduce the number of call to the API just remove the repair entry
      // from the list
      const index = this.repairs.findIndex(item => item.id === repair.id);
      this.repairs.splice(index, 1);
    },

    purchase(row) {
      this.selectedRepair = row;

      this.$refs.materialAdd.show();
    },

    addRequest(requests) {
      let usedStock = 0;
      let quantity = 0;
      let newStock = 0;

      // get Materials from database
      this.$stellar.action("getMaterials").then(response => {
        this.materials = response.materials;

        requests.forEach(request => {
          this.materials.forEach(material => {
            if (request.material_id === material.id) {
              // material object to update
              this.materialToSave = JSON.parse(JSON.stringify(material));

              quantity = request.quantity;
              usedStock = material.usedStock;
              newStock = material.newStock;

              // stock de items usados maior que a quantidade pedida
              if (quantity <= usedStock) {
                usedStock -= quantity;
                quantity = 0;
              }
              // sock de usados não chega para satisfazer quantidade pedida
              if (quantity > usedStock) {
                quantity -= usedStock;
                usedStock = 0;
              }
              // verificar se existe items no stock novo
              if (quantity <= newStock) {
                newStock -= quantity;
                quantity = 0;
              }
              if (quantity > newStock) {
                quantity -= newStock;
                newStock = 0;
              }

              // guardar objecto material
              this.materialToSave.newStock = newStock;
              this.materialToSave.usedStock = usedStock;

              // update material object
              this.$stellar.action("editMaterial", this.materialToSave);
            }

            if (quantity > 0) {
              // fazer pedido de material com esta quantidade
              this.need.quantity = quantity;
              this.need.material_id = this.materialToSave.id;
              this.need.repair_id = this.selectedRepair.id;
              this.need.observation = request.observation;
              this.$stellar.action("createMaterialrequest", { requests: [this.need] }).catch(() => {});
              quantity = 0;
            }
          });
        });
      });
    },

    showRepairDetails(repair) {
      // update the selected repair
      this.selectedRepair = repair;

      // show the repair details
      this.$router.push({ name: "repair.single", params: { id: repair.id } });
    },

    isNewClient(row) {
      return isSameDay(new Date(row.client.createdAt), new Date(row.createdAt));
    },

    openWarrantyModal(repair) {
      this.selectedRepair = repair;
      this.warrantyVisibility = true;
    },

    async sendToWarrantyEntity(entity) {
      if (entity === null) {
        return this.$message.info("Deve escolher a entidade para enviar o equipamento");
      }

      this.loading = true;
      this.selectedRepair.state = "waiting_warranty_dispatch";
      this.selectedRepair.warrantyEntity = entity;

      await this.$stellar.action("editRepair", this.selectedRepair);

      this.loading = false;
      this.warrantyVisibility = false;

      this.$message.success("Equipamento enviado para a entidade externa");

      this.fetchList();
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-Screening {
    &__content {
      max-width: 98% !important;
      margin: 10px auto;
    }
  }

  &-RepairWatingAtributionList {
    &__technician {
      width: 98%;
      margin: 10px auto;
    }
  }
}

.tp-Screening-client {
  position: relative;
  display: block;
}

.tp-Screening-client__circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;

  background-color: #54c688;
  border-radius: 50%;
}

.tp-Screening-complaint__circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;

  background-color: red;
  border-radius: 50%;
}

.tp-Screening-client__name {
  display: inline-block;
}

.repairWaitAtribList-buttonsGroup .el-button {
  padding-right: 10px;
  padding-left: 10px;
}
</style>
