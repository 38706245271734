
<div class="tp-notes">
  <ui-bottom-sheet 
    ref="editSheet" 
    actions>
    <!-- actions -->
    <div 
      slot="actions" 
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="save">
        Guardar
      </el-button>

      <el-button
        :loading="loading"
        @click="cancelAction">
        Cancelar
      </el-button>
    </div>

    <el-form
      ref="resource"
      :model="resource"
      :rules="rules"
      label-position="top">
      <el-row :gutter="10">
        <!-- title -->
        <el-col :span="24">
          <el-form-item 
            prop="title" 
            label="Titulo">
            <el-input
              v-model="resource.title"
              placeholder="Titulo"/>
          </el-form-item>
        </el-col>

        <!-- content -->
        <el-col :span="24">
          <el-form-item 
            prop="content" 
            label="Nota">
            <el-input
              v-model="resource.content"
              type="textarea"
              placeholder="Nota"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ui-bottom-sheet>
</div>
