<template>
  <section class="tp-BasicInfo">
    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler" />

    <ChangeBrandAndModelModal
      ref="changeBrandAndModelModal"/>
    <!-- create a contact panel -------------------------------------------- -->
    <contact-panel
      ref="contactPanel"
      @contacted="afterContactHandler"/>

    <!-- client panel ------------------------------------------------------ -->
    <client-form
      ref="clientPanel"
      @updated="client => $emit('update:client', client)"/>

    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler" />

    <!-- client info -->
    <div class="tp-BasicInfo__client">
      <!-- avatar -->
      <div class="tp-BasicInfo__client__avatar">
        <img
          :src="clientAvatar"
          alt="Avatar">
      </div>

      <div class="tp-BasicInfo__client__content">
        <div class="tp-BasicInfo__client__content__inner">
          <!-- header -->
          <header class="tp-BasicInfo__client__content__header">
            <!-- name -->
            <h1 class="tp-BasicInfo__client__content__header__name">
              <span>{{ client.name }}</span>
              <span v-if="client.isStore">(Loja)</span>
            </h1>

            <!-- number of repair -->
            <el-tooltip
              effect="dark"
              content="Numero de Reparações do Cliente"
              placement="bottom">
              <h2
                class="tp-BasicInfo__client__content__header__numberOsRepairs">
                {{ numberRepair || '-' }}
              </h2>
            </el-tooltip>
          </header>

          <!-- phone number -->
          <p class="tp-BasicInfo__client__content__phone">
            <i class="fa fa-phone"/>

            <a
              v-if="client.phone && $auth.can('view_client')"
              :href="`tel:${client.phone}`">
              {{ client.phone }}
            </a>
            <span v-else>-</span>
          </p>

          <!-- email -->
          <p class="tp-BasicInfo__client__content__email">
            <i class="fa fa-envelope"/>

            <a
              v-if="client.email && $auth.can('view_client')"
              :href="`to:${client.email}`">
              {{ client.email }}
            </a>
            <span v-else>-</span>
          </p>

          <!-- tax number -->
          <p class="tp-BasicInfo__client__content__taxNumber">
            <i class="fa fa-id-card"/>
            <span
              v-if="client.taxNumber && $auth.can('view_client')">
              {{ client.taxNumber }}
            </span>
            <span v-else>-</span>
          </p>
        </div>

        <!-- actions -->
        <div class="tp-BasicInfo__client__content__actions">
          <!-- contact -->
          <el-button
            :loading="loading"
            :disabled="!userIsTechnician"
            type="success"
            @click="contactClient">
            Contactar
          </el-button>

          <!-- edit -->
          <el-button
            v-if="$auth.can('view_client')"
            :loading="loading"
            @click="editClient">
            Editar
          </el-button>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="tp-BasicInfo__divider">
      <div class="tp-BasicInfo__divider__inner"/>
    </div>

    <!-- other info -->
    <ul class="tp-BasicInfo__otherInfo">
      <!-- IMEI/SN -->
      <li class="tp-BasicInfo__otherInfo__entry">
        <span class="tp-BasicInfo__otherInfo__entry__title">IMEI/SN: </span>
        <span class="tp-BasicInfo__otherInfo__entry__value">
          <span v-if="typeof equipment === 'object'">
            <span v-if="equipment.attributes && equipment.attributes.imei">
              {{ equipment.attributes.imei }}
            </span>
            <span v-else>
              {{ equipment.serialNumber }}
            </span>
          </span>

          <!-- change -->
          <el-button
            :loading="loading"
            type="text"
            class="ui-smallButton is-green"
            @click="changeEquipmentIdentification">
            Alterar
          </el-button>
        </span>
      </li>

      <!-- equipment type -->
      <li class="tp-BasicInfo__otherInfo__entry">
        <span class="tp-BasicInfo__otherInfo__entry__title">Tipo: </span>
        <span
          v-if="equipmentType"
          class="tp-BasicInfo__otherInfo__entry__value">
          {{ equipmentType.name }}
        </span>
      </li>

      <!-- equipment -->
      <li class="tp-BasicInfo__otherInfo__entry">
        <span class="tp-BasicInfo__otherInfo__entry__title">Equipamento: </span>
        <span class="tp-BasicInfo__otherInfo__entry__value">
          {{ equipment.brand }} {{ equipment.model }}
        </span>

        <!-- button -->
        <el-button
          :loading="loading"
          type="text"
          class="ui-smallButton is-green"
          @click="changeModelHandler">
          Alterar
        </el-button>
      </li>

      <!-- times in the store -->
      <li class="tp-BasicInfo__otherInfo__entry">
        <span class="tp-BasicInfo__otherInfo__entry__title">Vezes em loja: </span>
        <span class="tp-BasicInfo__otherInfo__entry__value">
          <span v-if="timesInStore === 0">Primeira vez em loja</span>
          <span v-else>{{ timesInStore }} vezes em loja</span>
        </span>
      </li>

      <!-- technician -->
      <li class="tp-BasicInfo__otherInfo__entry">
        <span
          class="tp-BasicInfo__otherInfo__entry__title">
          Tecnico:
        </span>
        <span class="tp-BasicInfo__otherInfo__entry__value">
          <span
            v-if="technician && typeof technician === 'object'">
            {{ technician.name }}
          </span>
          <span v-else>-</span>

          <!-- button to set the technician -->
          <el-button
            v-if="$auth.can('set_repair_technician') || ($auth.is('technician') && user.personId === technician.id)"
            :loading="loading"
            :disabled="!canChangeTechnician"
            :class="{'is-green': technician, 'is-orange': !technician}"
            type="text"
            class="ui-smallButton"
            @click="() => isSelectTechnicianVisible = true">
            <span v-if="technician">Alterar</span>
            <span v-else>Atribuir</span>
          </el-button>
        </span>
      </li>
      <!-- estado -->
      <li class="tp-BasicInfo__otherInfo__entry is-problem">
        <span class="tp-BasicInfo__otherInfo__entry__title">Estado: </span>
        <span class="tp-BasicInfo__otherInfo__entry__value">
          {{ repair.stateObservation }}
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import _ from "lodash";
import gravatar from "gravatar";
import { mapGetters } from "vuex";

import { ERepairActions } from "@/modules/repairs/services/store/repair/repair.types";
import { EEquipmentAction } from "@/modules/repairs/services/store/equipment/equipment.types";

import ClientForm from "../../client/ClientForm.vue";
import ContactPanel from "../ContactRequestPanel.vue";
import ChangeBrandAndModelModal from "../modals/change-brand-and-model-modal.vue";
import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";

export default {
  components: {
    ContactPanel,
    ClientForm,
    ChangeBrandAndModelModal,
    SelectTechnicianModal,
  },

  props: {
    equipmentType: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    repair: {
      type: Object,
      default: () => null,
    },
    timesInStore: {
      type: Number,
      default: 0,
    },
    equipment: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      isSelectTechnicianVisible: false,
      numberRepair: null,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    client() {
      return this.repair.client;
    },

    clientAvatar() {
      return gravatar.url(this.client.email, { s: "400" });
    },

    technician() {
      return this.repair.technician;
    },

    /**
     * Check if the technician can be changed.
     */
    canChangeTechnician() {
      const validStates = ["waiting_attribution", "problematic", "in_tests", "waiting_repair", "in_repair", "paused"];
      return validStates.includes(this.repair.state);
    },

    /**
     * Check if the current user is the technician.
     */
    userIsTechnician() {
      if (!this.technician || typeof this.technician === "string") {
        return false;
      }
      return this.technician.id === this.user.personId;
    },
  },

  async created() {
    // get the number of repairs of a given client
    await this.fetchNumberOfRepairs();
  },

  methods: {
    /**
     * Edit information of the selected user.
     */
    editClient() {
      // get the edit panel
      const panel = this.$refs.clientPanel;

      // set the client to be edited
      panel.setClient(this.client);

      // show edit panel
      panel.show();
    },

    /**
     * Handler to create a new client contact request.
     */
    contactClient() {
      // get the contact panel
      const panel = this.$refs.contactPanel;

      // set the client and repair
      panel.setData(this.client.id, this.repair.id);

      // show panel
      panel.show();
    },

    /**
     * Handle the event after contact the client.
     */
    afterContactHandler() {
      // show a success message
      this.$message.success("O cliente será contactado pelo atendimento");

      // redirect the user back to the list of work
      this.$router.push({ name: "work.list" });
    },

    /**
     * Open a dialog to change the equipment model.
     */
    async changeModelHandler() {
      this.$refs.changeBrandAndModelModal.show();
    },

    /**
     * Handle the technician change.
     */
    async onSelectTechnicianHandler(technician) {
      // ignore when the technician is the same as before
      if (this.technician && technician.id === this.technician.id) {
        return this.$message.info("Sem alteração!");
      }

      // make an API call to set the new technician
      await this.$store.dispatch(ERepairActions.SET_TECHNICIAN, {
        id: this.repair.id,
        technician: technician.id,
      });

      this.$message.success("Tecnico alterado.");
    },

    /**
     * Allows to chnage the IMEI/SN.
     *
     * @return {Promise.<void>}
     */
    async changeEquipmentIdentification() {
      try {
        // confirm with the user if the action is to proceed
        await this.$confirm("Tem a certeza que pretende alterar o IMEI/SN?", "Alterar IMEI/SN", {
          type: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Alterar",
        });
      } catch (e) {
        return;
      }

      const defaultValue =
        this.equipment.attributes && this.equipment.attributes.imei
          ? this.equipment.attributes.imei
          : this.equipment.serialNumber;

      // request the new identifier
      const { value } = await this.$prompt("Por favor, insira o novo identificador.", "Alterar IMEI/SN", {
        inputValue: defaultValue,
      });

      const newEquipment = _.cloneDeep(this.equipment);
      if (newEquipment.attributes && newEquipment.attributes.imei) {
        newEquipment.attributes.imei = value;
      } else {
        newEquipment.serialNumber = value;
      }

      try {
        await this.$store.dispatch(EEquipmentAction.UPDATE, newEquipment);
        this.$message.success("Identificador alterado.");
      } catch (e) {
        this.checkIfUserWantsOverride(newEquipment);
      }
    },

    /**
     * Check if the user really wants replace the equipment
     * identifier.
     */
    async checkIfUserWantsOverride(newEquipment) {
      try {
        await this.$confirm("Pretende forçar a substituição?", "Identificador duplicado");
      } catch (e) {
        return;
      }

      newEquipment.replace = true;
      await this.updateClientEquipment(newEquipment);

      // Link the prev repair to the current repair
      await this.$stellar.call("connectPrevRepair", {
        repairId: this.repair.id,
      });
    },

    /**
     * Get the number of repairs of the requested client.
     *
     * @return {Promise.<void>}
     */
    async fetchNumberOfRepairs() {
      const { total } = await this.$stellar.action("getNumberRepairsClient", {
        clientId: this.client.id,
      });

      this.numberRepair = total;
    },
  },
};
</script>

<style lang="scss">
.tp-BasicInfo {
  max-width: 97%;
  margin: 20px auto;
  padding: 25px 40px;
  display: flex;

  background: #fff;
  color: #3d4a5a;
  box-shadow: 0 1px 5px #d7e4ed, 0 1px 10px rgba(215, 228, 237, 0.77);

  &__client {
    display: flex;

    &__avatar {
      padding: 0 40px 0 0;
      height: auto;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        box-shadow: 0 1px 5px #d7e4ed, 0 1px 10px rgba(215, 228, 237, 0.77);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__header {
        display: flex;
        justify-content: space-between;
        min-width: 400px;
        margin-bottom: 7px;

        &__name {
          display: inline-block;
          font-size: 1.6em;

          span:nth-child(2) {
            font-size: 0.7em;
            line-height: 0.7em;
          }
        }

        &__numberOsRepairs {
          display: inline-block;
          font-size: 1.5em;
        }
      }

      /** reset p margin **/
      &__phone,
      &__email {
        margin: 0 0 5px 0;

        i {
          width: 20px;
        }
      }

      &__taxNumber {
        margin-bottom: 20px;

        i {
          width: 20px;
        }
      }
    }
  }

  &__divider {
    flex-direction: column;
    justify-content: center;
    width: 1px;
    background: #e5ecf2;
    margin: 0 20px;

    &__inner {
      flex: 1;
    }
  }

  &__otherInfo {
    display: inline-block;
    list-style: none;

    margin: 0;
    padding: 0;

    &__entry {
      margin-bottom: 7px;

      &__title {
        display: inline-block;
        font-weight: bold;
        width: 150px;

        color: #3d4a5a;
      }

      &__value {
        color: #627082;
      }
    }
  }
}
</style>
