<template>
  <div class="tp-RepairView__entry is-history">
    <h4>Historico</h4>

    <el-table
      :data="orderedLogs"
      stripe
      class="tp-RepairView__tableHistory">
      <el-table-column label="Evento">
        <template slot-scope="{ row }">
          <b>{{ row.type | repairLogType }}</b>
          <br>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <i v-html="row.message"/>
        </template>
      </el-table-column>

      <!-- date -->
      <el-table-column
        :width="150"
        label="Data">
        <template slot-scope="scope">
          {{ scope.row.date | dateFormat('dd/MM/yyyy HH:mm') }}
        </template>
      </el-table-column>

      <!-- hour -->
      <el-table-column
        :width="130"
        label="Hora">
        <template slot-scope="scope">
          {{ scope.row.date | dateFormat('HH:mm') }}
        </template>
      </el-table-column>

      <!-- user -->
      <el-table-column
        :width="170"
        label="Por">
        <template slot-scope="{row}">
          <span v-if="row.context.user && users[row.context.user]">{{ users[row.context.user].name }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import _, { isNil } from "lodash";

import { ELogsAction } from "@/modules/repairs/services/store/repair/submodules/logs/logs.types";
import { IRepair } from "@/modules/repairs/types/repair.interface";
import { ILog } from "@/modules/repairs/types/log.interface";
import { EUsersActions } from "@/services/store/modules/users/users.types";
import { IUser } from "@/services/store/modules/user-types";

export default Vue.extend({
  name: "RepairHistory",

  props: {
    repairId: {
      type: String,
      required: true,
    },
  },

  computed: {
    logs(): Array<ILog> {
      return this.$store.get("repair/logs/logs");
    },

    users(): Array<IUser> {
      return this.$store.get("users/users");
    },

    orderedLogs() {
      const self = this as any;
      return _.orderBy(self.logs, "date", "desc");
    },
  },

  watch: {
    repairId: {
      immediate: true,
      async handler(newId: IRepair) {
        await this.$store.dispatch(ELogsAction.FETCH, newId);
        this.fetchUsers();
      },
    },
  },

  methods: {
    async fetchUsers() {
      const ids = this.logs.map(entry => entry.context.user).filter(id => !isNil(id));
      this.$store.dispatch(EUsersActions.FETCH_USER, ids);
    },
  },
});
</script>
