
<section class="tp-RepairForm__step2">
  <!-- client panel -->
  <client-form
    ref="clientPanel"
    @updated="afterClientUpdate"/>

  <!-- client select card -->
  <el-card class="tp-RepairForm__step2__card">
    <client-search
      can-create
      @change="val => $emit('update:client', val)"/>
  </el-card>

  <!-- client card -->
  <client-card :client="client">
    <div slot="actions">
      <!-- edit -->
      <el-button
        type="info"
        @click="editClientHandler">
        Editar
      </el-button>

      <!-- use it -->
      <el-button
        class="tp-ClientCard__useBtn"
        type="success"
        @click="useClientHandler">
        Usar
      </el-button>
    </div>
  </client-card>
</section>
