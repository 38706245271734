<template>
  <aside
    :class="{ 'is-close': !isSidebarOpen }"
    class="tp-Sidebar">
    <div class="tp-Sidebar__actions">
      <div
        class="tp-Sidebar__actions__item is-back"
        @click="$router.go(-1)">
        <div class="tp-Sidebar__actions__container">
          <i class="fa fa-arrow-left"/>
        </div>
      </div>

      <div
        class="tp-Sidebar__actions__item is-hamburger"
        @click="toggleSidebar">
        <div class="tp-Sidebar__actions__container">
          <i class="fa fa-bars"/>
        </div>
      </div>
    </div>

    <nav class="tp-Sidebar__menu">
      <router-link
        v-for="entry in items"
        :key="entry.route"
        :to="{ name: entry.route }"
        exact>
        <div class="icon">
          <i
            :class="[ 'fa', 'fa-' + entry.icon ]"
            aria-hidden="true"/>
        </div>

        <span>{{ entry.title }}</span>
      </router-link>
    </nav>

    <div class="tp-Sidebar__data">
      <span class="tp-Sidebar__data__hour">{{ date | dateFormat('HH:mm') }}</span>
      <span class="tp-Sidebar__data__day">{{ date | dateFormat('MMM, EEE dd') }}</span>
    </div>
  </aside>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "BaseSidebar",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      date: new Date(),
    };
  },

  computed: {
    /**
     * Get user preferences.
     */
    userPreferences(): any {
      return (this.$store as any).getters.getPreferences;
    },

    /**
     * Check if the current logged user is an external technician.
     */
    isExternalTechnician(): boolean {
      return (this.$store as any).getters.isExternalTechnician;
    },

    /**
     * Check if the sidebar is open or closed.
     */
    isSidebarOpen(): boolean {
      const sidebarOpenOptions = (this as any).userPreferences.sidebarOpen;

      return typeof sidebarOpenOptions === "boolean" ? sidebarOpenOptions : true;
    },
  },

  created() {
    // create a timer to update the date information on the sidebar
    // the update rate is 1 min
    setInterval(() => {
      (this as any).date = new Date();
    }, 1000 * 60);
  },

  methods: {
    /**
     * Update the client options.
     */
    toggleSidebar() {
      this.$store.dispatch("updateUserPreference", ["sidebarOpen", !(this as any).isSidebarOpen]);
    },
  },
});
</script>

<style lang="scss" scoped>
.tp-Sidebar {
  /* configure flex */
  order: 0;
  flex: 0 0 var(--sidebar-width);

  display: flex;
  flex-flow: column nowrap;

  font-weight: 300;

  /* hide the data shadow */
  overflow: hidden;

  color: var(--sidebar-color);
  background-color: var(--sidebar-bg);

  transition: all 0.3s ease;

  /** actions **/
  &__actions {
    /** configure flex */
    order: 0;
    flex: none;

    padding: 10px 14px 0 14px;
    color: white;

    /** make this looks like a ball */
    &__container {
      width: 35px;
      height: 35px;
      padding: 5px 0 0 11px;
      border-radius: 50%;

      &:hover {
        background-color: var(--sidebar-active-bg);
        box-shadow: 0px 0px 9px -1px var(--sidebar-active-bg);
      }
    }

    &__item {
      cursor: pointer;
      display: inline-block;

      /** hamburger **/
      &.is-hamburger {
        cursor: pointer;
        display: inline-block;

        transition: all 0.3s ease;
        float: right;
      }
    }
  }

  /** favs **/
  &__favs {
    margin: 10px 0 0 0;

    &__entry {
      width: 90%;
      height: 35px;
      margin: 0 auto 10px auto;

      &.is-empty {
        border: 2px dashed var(--sidebar-active-bg);
        text-align: center;

        i {
          padding-top: 8px;
          color: var(--sidebar-active-bg);
        }
      }
    }
  }

  /** menu **/
  &__menu {
    /** configure flex */
    order: 1;
    flex: auto;
    overflow-y: auto;

    & > a {
      color: var(--sidebar-color);
      display: block;
      cursor: pointer;
      font-weight: 200;
      padding: 12px 25px;
      text-decoration: none;

      margin-bottom: 10px;

      transition: 0.3s ease;
      transition: border 0.1s ease;

      /** menu item hover **/
      &:hover {
        color: var(--sidebar-active-color);
        text-decoration: none;
        background-color: var(--sidebar-active-bg);
      }

      /** menu icon **/
      .icon {
        width: 22px;
        display: inline-block;
      }

      span {
        transition: font 0.3s ease;
      }

      /** active link **/
      &.router-link-active {
        color: white;
        font-weight: 300;

        background-color: var(--sidebar-active-bg);
        border-left: 3px solid var(--sidebar-active-border);
        box-shadow: 0px 0px 9px -1px var(--sidebar-active-bg);
      }
    }
  }

  /** date **/
  &__data {
    /** configure flex */
    order: 2;
    flex: none;

    padding: 14px 0;
    text-align: center;

    color: var(--sidebar-active-color);
    background-color: var(--sidebar-active-bg);
    box-shadow: 0px -2px 9px -1px var(--sidebar-active-bg);

    &__hour {
      display: block;

      color: white;
      font-weight: 300;
      font-size: 1.6em;
    }

    &__day {
      font-size: 0.84em;
      font-weight: 400;

      color: var(--sidebar-color);
    }
  }
}

.tp-Sidebar.is-close {
  flex: 0 0 60px;

  /** sidebar actions */
  .tp-Sidebar__actions {
    padding: 10px 5px 0 4px;

    .tp-Sidebar__actions__item {
      display: block;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .tp-Sidebar__actions__container {
      margin: 0 auto;
    }
  }

  /** sidebar items */
  .tp-Sidebar__menu {
    a {
      padding: 14px 0;

      text-align: center;
    }

    span {
      font-size: 0;
      transition: font 0s ease;
    }

    .icon {
      font-size: 1.2em;
    }
  }

  .tp-Sidebar__data__hour {
    font-size: 1.3em;
  }

  .tp-Sidebar__data__day {
    font-size: 0.8em;
  }
}
</style>
