<template>
  <div class="tp-UserForm">
    <!-- Dialog to Select a Client ------------------------------------------- -->

    <el-dialog
      :visible.sync="clientSelection.visibility"
      title="Selecionar o cliente"
      size="tiny">
      <el-form label-position="top">
        <el-select
          v-model="clientSelection.selectedClient"
          :style="{ width: '100%' }"
          :remote-method="searchForClients"
          :loading="clientSelection.loading"
          filterable
          remote
          placeholder="Escolha o Cliente">
          <el-option
            v-for="item in clientSelection.clients"
            :key="item.id"
            :label="item.name"
            :value="item">
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form>

      <!-- show the selected client information -->
      <client-card :client="clientSelection.selectedClient">
        <div slot="actions">
          <!-- use it -->
          <el-button
            type="success"
            @click="useClient">Usar</el-button>
        </div>
      </client-card>
    </el-dialog>

    <SelectTechnicianModal
      :visibility.sync="isSelectTechnicianVisible"
      @selected="onSelectTechnicianHandler"
      @dismiss="onSelectTechnicianDismiss" />

    <!-- Main Form ----------------------------------------------------------- -->
    <ui-bottom-sheet
      ref="editSheet"
      actions>
      <!-- actions -->
      <div
        slot="actions"
        class="right">
        <el-button
          :loading="loading"
          type="primary"
          @click="save">Guardar</el-button>
        <el-button
          :loading="loading"
          @click="show(false)">Cancelar</el-button>
      </div>

      <el-form
        ref="user"
        :model="user"
        :rules="rules"
        label-position="top">
        <el-row :gutter="20">
          <!-- Name -->
          <el-col :span="12">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="user.name"
                placeholder="Nome"/>
            </el-form-item>
          </el-col>

          <!-- Email -->
          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email">
              <el-input
                v-model="user.email"
                type="email"
                placeholder="Email"/>
            </el-form-item>
          </el-col>

          <!-- role -->
          <el-col :span="12">
            <el-form-item
              label="Tipo de utilizador"
              prop="role">
              <el-select
                v-model="user.role"
                :style="{ width: '100%' }">
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- password -->
          <el-col :span="12">
            <el-form-item
              label="Password"
              prop="password">
              <el-input
                v-model="user.password"
                placeholder="Password"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </div>
</template>

<script>
import SelectTechnicianModal from "@/components/technician/select-technician-modal.vue";
import ClientCardCmp from "../client/ClientCard.vue";

// store the client search timer
let clientSearchTimer = null;

/**
 * Check if the password met the requirements.
 */
const checkPassword = (rule, value, callback) => {
  if (value && value.length > 0 && value.length < 6) {
    callback(new Error("Password deve ter pelo menos 6 caracteres."));
  }

  callback();
};

// todo: add a field to reset the password
export default {
  components: {
    "client-card": ClientCardCmp,
    SelectTechnicianModal,
  },

  data() {
    return {
      isSelectTechnicianVisible: false,

      user: {
        name: "",
        email: "",
        role: "",
        personId: "",
        password: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Introduza o nome do utilizador",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: "true",
            type: "email",
            message: "Verifique o formato do email inserido",
            trigger: "blur",
          },
        ],
        role: [
          {
            required: "true",
            message: "Escolha um tipo para o utilizador",
            trigger: "blur",
          },
        ],
        password: {
          trigger: "blur",
          validator: checkPassword,
        },
      },

      clientSelection: {
        loading: false,
        visibility: false,
        selectedClient: null,
        clients: [],
      },

      edit: false,
      loading: false,
      roles: [],
      ignorePersonaSection: false,
    };
  },

  watch: {
    "user.role"(newVal, oldVal) {
      if (this.ignorePersonaSection) {
        this.ignorePersonaSection = false;
        return;
      }

      if (newVal === oldVal || newVal === "") {
        return;
      }

      // execute the correspondent handler of the new role
      const role = this.roles.find(item => item.id === newVal);

      if (role.slug === "technician") {
        this.chooseTechnician();
      } else if (role.slug === "store") {
        this.chooseClient();
      }
    },
  },

  /**
   * Operations to be performed when the component is created.
   */
  created() {
    // get all role in order to populate the list of roles
    this.$stellar.action("entrust.getRoles").then(({ roles }) => {
      this.roles = roles;
    });
  },

  methods: {
    onSelectTechnicianDismiss() {
      this.$refs.editSheet.show(true);
      this.user.role = "";
    },

    /**
     * Open a panel to choose a technician to associate to this user.
     */
    chooseTechnician() {
      this.isSelectTechnicianVisible = true;
      this.$refs.editSheet.show(false);
    },

    /**
     * Catch the technician selection and set the personId field.
     */
    onSelectTechnicianHandler(technician) {
      // set the personId field
      this.user.personId = technician.id;

      // show a success message
      this.$message.success("Tecnico selecionado.");

      // back showing the edit form
      this.$refs.editSheet.show();
    },

    /**
     * Show dialog to choose client.
     */
    chooseClient() {
      this.clientSelection.visibility = true;
    },

    searchForClients(query = "") {
      // cancel any timer
      clearTimeout(clientSearchTimer);

      if (query.length <= 2) {
        this.clientSelection.clients = [];
        return;
      }

      // enable loading
      this.clientSelection.loading = true;

      clientSearchTimer = setTimeout(_ => {
        // make an API call to get the clients
        this.$stellar
          .action("searchForClients", { query })
          .then(({ clients }) => {
            this.clientSelection.clients = clients;

            // end loading
            this.clientSelection.loading = false;
          });
      }, 700);
    },

    /**
     * Set the person ID and hide the client selection dialog.
     */
    useClient() {
      this.user.personId = this.clientSelection.selectedClient.id;
      this.clientSelection.visibility = false;
    },

    /**
     * Set an user to edition.
     */
    setUser(_user) {
      this.ignorePersonaSection = true;

      // make a copy of the user object
      const user = JSON.parse(JSON.stringify(_user));

      // remove password field
      delete user.password;

      // when set the user we must depopulate the role
      if (typeof user.role === "object") {
        user.role = user.role.id;
      } else if (user.role === undefined) {
        user.role = "";
      }

      this.user = user;

      // set this as a edit operation
      this.edit = true;
    },

    show(val = true) {
      this.$refs.editSheet.show(val);

      if (!val) {
        this.reset();
      }
    },

    /**
     * Reset the component data.
     */
    reset() {
      this.user = {
        name: "",
        email: "",
        role: "",
        personId: "",
        password: "",
      };

      this.edit = false;
      this.loading = false;

      this.clientSelection = {
        loading: false,
        visibility: false,
        selectedClient: null,
        clients: [],
      };
    },

    /**
     * Perform a save operation.
     */
    save() {
      this.$refs.user.validate(valid => {
        if (!valid) {
          return false;
        }

        if (!this.edit) {
          this.addResource();
        } else {
          this.editResource();
        }
      });
    },

    /**
     * Create a new user.
     */
    addResource() {
      // check if the password is filled
      if (!this.user.password || this.user.password.length < 6) {
        return this.$message.error("Preencha a password.");
      }

      // enable loading
      this.loading = true;

      this.$stellar
        .action("auth.register", this.user)
        .then(({ user }) => {
          // enable loading
          this.loading = false;

          // emit an event with the new resource
          this.$emit("created", user);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource creation
          this.$message.success("Utilizador criado.");
        })
        .catch(response => {
          // Do nothing!
        });
    },

    /**
     * Edit an existent user.
     */
    editResource() {
      // enter in loading mode
      this.loading = true;

      // make an API call to update the user
      this.$stellar
        .action("auth.updateUser", { user: this.user })
        .then(({ user }) => {
          // emit an event informing the update
          this.$emit("updated", user);

          // close the panel
          this.show(false);

          // show a message to the user to inform the resource update
          this.$message.success("Utilizador atualizado.");
        })
        .catch(response => {
          // Do nothing!
        });
    },
  },
};
</script>
