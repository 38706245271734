
<section class="tp-DashStates" v-if="user.personId">
  <el-dialog
    :visible.sync="listVisibility"
    title=" Novos clientes"
    size="large">
    <el-button @click="listVisibility = false">Sair</el-button>
    <el-table
      :data="newClients.clients"
      stripe=""
      width="100%">
      <el-table-column
        prop="name"
        label="Nome"
        width="280"/>

      <el-table-column
        prop="phone"
        label="Telefone"
        width="100"/>


      <el-table-column
        prop="street"
        label="Rua"
        width="240"/>

      <el-table-column
        label="Loja"
        width="40">
        <template slot-scope="scope">
          <span v-if="scope.row.isStore === true"> X </span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <el-dialog
    ref="dailyRepairList"
    :visible.sync="todayRepairsVisibility"
    title="Reparações fechadas hoje"
    size="large">
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button @click="show(false)">Cancelar</el-button>
      <el-button @click="printElem">Imprimir</el-button>
    </div>

    <div id="toPrint">
      <el-button @click="todayRepairsVisibility = false">Cancelar</el-button>
      <el-button @click.native="printElem">Imprimir</el-button>
      <el-table
        :data="todayRepairs"
        stripe
        style="width: 100%">
        <el-table-column
          prop="repair.repairNumber"
          label="#Rep"
          width="180"/>
        <el-table-column
          prop="repair.clientEquipment.brand"
          label="Equipamento"
          width="180"/>
        <el-table-column
          prop="totalMaterial"
          label="Material"
          width="180"/>
        <el-table-column
          prop="repair.value"
          label="Valor da Reparação"
          width="180"/>
        <el-table-column
          prop="value"
          label="Comissão"
          width="180"/>

      </el-table>
      <span>Total comissões dia: {{ dailyTotal }}</span>
    </div>

  </el-dialog>

  <div
    v-if="loggedTechnician && loggedTechnician.technician.external === false"
    class="tp-DashStates__grid">
    <el-tooltip
      v-for="(entry, key) in dashStates"
      :key="key"
      :content="availableStates[key].hint">
      <div
        :md="6"
        :sm="24"
        :class="[ 'is-' + key ]"
        class="tp-DashStates__entry">
        <i
          :class="[ 'fa-' + availableStates[key].icon ]"
          class="fa tp-DashStates__entry__icon" />
        <ui-animated-number
          :value="entry"
          class="tp-DashStates__entry__content"
          @click.native="handleBoxClick(key)" />
      </div>
    </el-tooltip>

    <el-tooltip
      v-if="newClients.clients"
      :content="availableStates['newClients'].hint"
      class="is-newClients">
      <div
        :md="6"
        :sm="24"
        class="tp-DashStates__entry is-newClients">
        <i
          :class="[ 'fa-' + availableStates['newClients'].icon ]"
          class="fa tp-DashStates__entry__icon" />
        <ui-animated-number
          :value="newClients.clients.length"
          class="tp-DashStates__entry__content"
          @click.native="handleBoxClick('newClients')" />
      </div>
    </el-tooltip>

    <el-tooltip
      :content="availableStates['complaintRate'].hint"
      class="is-newClients">
      <div
        :md="6"
        :sm="24"
        class="tp-DashStates__entry is-newClients">
        <i
          :class="[ 'fa-' + availableStates['complaintRate'].icon ]"
          class="fa tp-DashStates__entry__icon" />
        <ui-animated-number
          :value="tecComplaintsRate"
          class="tp-DashStates__entry__content"
          @click.native="handleBoxClick('newClients')" />
      </div>
    </el-tooltip>
  </div>
</section>
