<template>
  <div class="page page-entities">
    <UiList
      :creation-method="addClientHandler"
      :loading="isLoading"
      :data="entities"
      allow-creation>
      <UiListItem
        v-for="entity in entities"
        :key="entity.id">
        <span>{{ entity.name }}</span>
        <span slot="details">{{ entity.description }}</span>
        <div slot="actions">
          <el-button
            type="primary"
            @click="editHandler(entity)">
            Editar
          </el-button>

          <el-button
            :type="entity.enabled ? 'danger' : 'success'"
            @click="toogleEnableHandler(entity)">
            <template v-if="!entity.enabled">Ativar</template>
            <template v-else>Desativar</template>
          </el-button>
        </div>
      </UiListItem>
    </UiList>

    <el-dialog
      :visible.sync="dialogFormVisible"
      title="Criar Entidade">
      <EntitiesForm
        @close="dialogFormVisible = false" />
    </el-dialog>

    <el-dialog
      :visible.sync="dialogEditFormVisibility"
      title="Editar Entidade">
      <EntitiesForm
        :entity="selectedEntity"
        @close="dialogEditFormVisibility = false" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import {
  ACTION_ENTITY_FETCH,
  ACTION_ENTITY_EDIT,
} from "@/services/store/modules/entities/entities-types";

import EntitiesForm from "./entities-form.vue";

export default {
  name: "EntitiesList",

  components: {
    EntitiesForm,
  },

  data() {
    return {
      dialogFormVisible: false,
      dialogEditFormVisibility: false,
      selectedEntity: null,
    };
  },

  computed: {
    ...mapGetters("entities", ["entities", "isLoading"]),
  },

  created() {
    this.fetchEntities({ includeDisabled: true });
  },

  methods: {
    ...mapActions("entities", {
      fetchEntities: ACTION_ENTITY_FETCH,
      editEntity: ACTION_ENTITY_EDIT,
    }),

    addClientHandler() {
      this.dialogFormVisible = true;
    },

    editHandler(entity) {
      this.dialogEditFormVisibility = true;
      this.selectedEntity = entity;
    },

    /**
     * Toogle the entity status.
     *
     * @param {Entity} entity Entity which status must be toogled.
     */
    async toogleEnableHandler(entity) {
      await this.editEntity({
        id: entity.id,
        enabled: !entity.enabled,
      });
      this.$message.success("Entidade atualizada.");
    },
  },
};
</script>

<style lang="scss">
</style>
