
<section class="tp-RepairForm__step1">
  <el-card class="tp-RepairForm__step1__card">
    <el-row :gutter="10">
      <el-col :span="20">
        <span class="tp-RepairForm__step1__description">Numero de Serie ou IMEI</span>

        <el-input
          v-model="identifier"
          type="text"
          placeholder="Numero de Serie/IMEI"
          @keyup.enter.native="checkEquipment"/>
      </el-col>

      <el-col :span="4">
        <el-button
          :loading="loading"
          class="tp-RepairForm__step1__button"
          type="primary"
          @click="checkEquipment">
          <span v-if="identifier === ''">Continuar</span>
          <span v-if="identifier !== ''">Usar</span>
        </el-button>
      </el-col>
    </el-row>
  </el-card>
</section>
