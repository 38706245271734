import { ActionTree } from "vuex";
import {
  INavigationState,
  ENavigationActions,
  INavigationMenuItem,
  ENavigationMutations,
} from "@/services/store/modules/navigation/navigation-types";
import { IStoreRootState } from "@/services/store/types/store-root-state.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";

export const actions: ActionTree<INavigationState, IStoreRootState> = {
  /**
   * Append new items to the existing set of menu items.
   */
  [getBaseName(ENavigationActions.ADD_MENU_ITEMS)](
    { commit, state },
    itemsToAdd: Array<INavigationMenuItem>,
  ) {
    const newItems = [...state.menuItems, ...itemsToAdd];
    commit(ENavigationMutations.SET_MENU_ITEMS, newItems);
  },
};
