<template>
  <section class="tp-Purchases">
    <el-table
      v-loading.body="loading"
      :data="purchases"
      :row-class-name="tableRowClassName"
      strip>
      <!-- material description -->
      <el-table-column label="Descrição">
        <template slot-scope="scope">
          {{ scope.row.material.brand }} {{ scope.row.material.model }}, {{ scope.row.material.description }}
        </template>
      </el-table-column>

      <!-- quantity -->
      <el-table-column
        :width="70"
        property="quantity"
        label="Qtn."
        align="center"/>

      <!-- origin -->
      <el-table-column
        :width="100"
        label="Origem"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.origin === 'europe'">Europa</span>
          <span v-if="scope.row.origin === 'other'">Fora da Europa</span>
        </template>
      </el-table-column>

      <!-- creation date -->
      <el-table-column
        :width="250"
        label="Compra"
        align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt | dateFormat('dd/MM/yy') }}</span>
        </template>
      </el-table-column>
      <!-- delivery prevision -->
      <el-table-column
        :width="250"
        label="Previsão"
        align="center">
        <template slot-scope="scope">
          <span>{{ estimateDelivery(scope.row) }}</span>
        </template>
      </el-table-column>

      <!-- link -->
      <el-table-column
        :width="70"
        label="Link"
        align="center">
        <template slot-scope="scope">
          <a
            v-if="scope.row.link"
            :href="scope.row.link"
            target="_blank">Link</a>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- price -->
      <el-table-column
        :width="100"
        align="center"
        label="Preço">
        <template slot-scope="scope">
          {{ scope.row.price || scope.row.material.price | money }}
        </template>
      </el-table-column>

      <!-- Actions -->
      <el-table-column
        :width="200"
        align="right"
        label="">
        <template slot-scope="scope">
          <!-- problem resolution -->
          <!-- <el-button type="warning" @click="handleProblemResolution(item)" v-if="showFixBtn(item)">Resolver</el-button> -->

          <!-- mark as arraived -->
          <el-button
            type="success"
            size="small"
            @click="handleConfirmation(scope.row)">Efetivar</el-button>

          <!-- cancel purchase -->
          <el-button
            type="danger"
            size="small"
            @click="handleCancel(scope.row)">
            <i class="fa fa-trash"/>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { addDays, addWeeks, format } from "date-fns/esm/fp";
import { DATE_DAY_FROM_MONTH } from "@/services/formatters";

// time margins for each origin
const dateMarginForEurope = addDays(4);
const dateMarginWordStart = addWeeks(2);
const dateMarginWordEnd = addWeeks(6);

export default {
  data() {
    return {
      purchases: [],
      loading: false,

      item: "",
      material: null,
      storeBoxDialog: false,
      storeBox: "",
    };
  },

  computed: {
    total() {
      return this.purchases ? this.purchases.lenght : 0;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { purchases } = await this.$stellar.action("getPurchaseMoreThanXDays");
        this.purchases = purchases;
      } catch (e) {
        // Do nothing!
      } finally {
        this.loading = false;
      }
    },
    /**
     * Generate the class row class.
     */
    tableRowClassName({ row }) {
      return `tp-Purchases__item is-${row.state}`;
    },

    /**
     * Format the estimated delivery date interval.
     */
    estimateDelivery(purchase) {
      let startDate = null;
      let endDate = null;

      if (purchase.origin === "europe") {
        startDate = new Date(purchase.createdAt);
        endDate = dateMarginForEurope(new Date(purchase.createdAt));
      } else {
        startDate = dateMarginWordStart(new Date(purchase.createdAt));
        endDate = dateMarginWordEnd(new Date(purchase.createdAt));
      }

      // format date
      startDate = format(DATE_DAY_FROM_MONTH, startDate);
      endDate = format(DATE_DAY_FROM_MONTH, endDate);

      return `(${startDate} - ${endDate})`;
    },

    showFixBtn(purchase) {
      return !["normal", "verified"].includes(purchase.state);
    },

    europeanFix(purchase) {
      this.$confirm("Arranja-se material?", "Verificação", {
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        type: "info",
      })

        .then(_ => {
          this.$confirm("Cliente concorda em esperar?", "Verificação", {
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            type: "info",
          })

            .then(() => {
              // remove purchase
              this.$stellar.action("deletePurchase", { id: this.purchase.id });

              // set the material
              this.$refs.purchases.setMaterial(purchase.material, purchase.quantity);

              // show the purchase form
              this.$refs.purchases.show();
            })
            .catch(() => {});
        })

        // There is no material for this purchase
        .catch(() => {});
    },

    handleProblemResolution(purchase) {
      if (purchase.origin === "europe") {
        this.europeanFix(purchase);
      }
    },

    /**
     * Confirm the purchase
     */
    handleConfirmation(purchase) {
      // save the storebox to use it later
      let _storeBox = purchase.material.storeBox;

      this.$confirm("Tem a certeza que pretende efetivar a compra?", "Efetivar compra", {
        type: "warning",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Continuar",
      })
        .then(_ => {
          if (!_storeBox) {
            return this.$prompt("Por favor, indique a caixa do material.", "Caixa do Material").then(({ value }) => {
              // save the storebox to use it later
              _storeBox = value;
              return value;
            });
          }

          return null;
        })
        .then(storeBox => {
          // start loading
          this.loading = true;

          // make the API call
          return this.$stellar.action("confirmPurchase", {
            id: purchase.id,
            storeBox,
          });
        })
        .then(_ => {
          // remove the item from the purchase list
          const index = this.purchases.findIndex(item => item.id === purchase.id);
          this.purchases.splice(index, 1);
          // show a success message
          this.$message.success("Compra efetivada");

          // show where the material must be placed
          this.$alert(`A peça deve ser colocada na caixa ${_storeBox}.`);

          // disable loading
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
        });
    },

    /**
     * Cancel purchase
     */
    handleCancel(purchase) {
      this.$confirm("Tem a certeza que pretende cancelar a compra?", "Cancelar compra", {
        type: "warning",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Continuar",
      })
        .then(_ => {
          return this.$stellar.action("removePurchase", {
            id: purchase.id,
          });
        })
        .then(response => {
          // remove the item from the purchase list
          const index = this.purchases.findIndex(item => item.id === purchase.id);
          this.purchases.splice(index, 1);

          // if the containsRequests is set we must show a message informing that
          // the material requests was canceled
          if (response.containsRequests) {
            this.$alert("Material voltou para os pedidos.", "Pedidos de Materiais");
          }

          // show a success message
          this.$message.success("Compra cancelada.");
        })
        .catch(_ => {});
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-Purchases {
    max-width: 98%;
    margin: 10px auto;

    &__item {
      transition: 0.3s all ease;

      &.is-overtime:not(:hover) {
        color: white;
        background-color: #ffa99b;

        .ui-List__item__title {
          color: white;
        }
      }

      &.is-first_verification:not(:hover) {
        color: white;
        background-color: #ffcd5c;

        .ui-List__item__title {
          color: white;
        }
      }
    }

    &__addBtn {
      margin-bottom: 10px !important;
    }

    &__pagination {
      padding-left: 0 !important;
      margin: 8px 0;
    }
  }
}
</style>
