
<div class="ui-List__item">
  <!-- selection -->
  <div
    v-if="$parent.selectable"
    class="ui-List__item__selection">
    <el-checkbox v-model="selectionValue"/>
  </div>

  <!-- imagem -->
  <div
    v-if="this.$slots['image']"
    class="ui-List__item__image">
    <slot name="image"/>
  </div>

  <!-- content -->
  <div class="ui-List__item__content">
    <div class="ui-List__item__title">
      <!-- title (default) -->
      <slot/>
    </div>
    <div class="ui-List__item__details">
      <!-- details -->
      <slot name="details"/>
    </div>
  </div>

  <!-- actions -->
  <div class="ui-List__item__actions">
    <slot name="actions"/>
  </div>
</div>
