import { IBaseState } from "@/services/store/types/base-state.interface";
import { ILabel } from "@/modules/common/types/label.interface";

export interface ILabelStore extends IBaseState {
  labels: Array<ILabel>;
}

export enum ELabelActions {
  CLEAN = "labels/clean",
  FETCH_ALL = "labels/fetch",
}

export enum ELabelMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_LABELS = "SET_LABELS",
}
