
<section class="tp-ContactsForm">

  <!-- group form ---------------------------------------------------------- -->

  <group-form
    ref="groupForm"
    @created="afterGroupCreationHandler"/>

  <!-- contact form -------------------------------------------------------- -->
  <ui-bottom-sheet
    ref="form"
    actions>
    <!-- actions -->
    <div
      slot="actions"
      class="right">
      <el-button
        :loading="loading"
        type="primary"
        @click="save">Guardar</el-button>
      <el-button @click="cancelHandler">Cancelar</el-button>
    </div>

    <!-- content -->
    <div>
      <el-form
        ref="contact"
        :model="contact"
        :rules="rules"
        label-position="top"
        class="tp-Contacts__form">
        <el-row :gutter="10">
          <!-- name -->
          <el-col :span="12">
            <el-form-item
              label="Nome"
              prop="name">
              <el-input
                v-model="contact.name"
                type="text"
                placeholder="Nome"/>
            </el-form-item>
          </el-col>

          <!-- surname -->
          <el-col :span="12">
            <el-form-item label="Sobrenome">
              <el-input
                v-model="contact.surname"
                type="text"
                placeholder="Sobrenome"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <!-- cellphone -->
          <el-col :span="8">
            <el-form-item
              label="Telemovel"
              prop="cellphone">
              <el-input
                v-model="contact.cellphone"
                type="text"
                placeholder="Telemovel"/>
            </el-form-item>
          </el-col>

          <!-- phone -->
          <el-col :span="8">
            <el-form-item label="Telefone">
              <el-input
                v-model="contact.phone"
                type="text"
                placeholder="Telefone"/>
            </el-form-item>
          </el-col>

          <!-- email -->
          <el-col :span="8">
            <el-form-item label="Email">
              <el-input
                v-model="contact.email"
                type="text"
                placeholder="Email"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <!-- address -->
          <el-col :span="12">
            <el-form-item label="Morada">
              <el-input
                v-model="contact.address"
                type="text"
                placeholder="Morada"/>
            </el-form-item>
          </el-col>

          <!-- company -->
          <el-col :span="12">
            <el-form-item label="Empresa">
              <el-input
                v-model="contact.company"
                type="text"
                placeholder="Empresa"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <!-- groups -->
          <el-col :span="10">
            <el-form-item
              label="Grupos"
              prop="groups">
              <el-select
                v-model="contact.groups"
                multiple
                placeholder="Grupos"
                class="tp-Contacts__groupSelect">
                <el-option
                  v-for="item in groups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="2">
            <el-button
              type="primary"
              class="tp-Contacts__newGroupBtn"
              @click="newGroupHandler">
              Novo
            </el-button>
          </el-col>

          <!-- url -->
          <el-col :span="12">
            <el-form-item label="Link">
              <el-input
                v-model="contact.url"
                type="text"
                placeholder="Link"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <!-- notes -->
          <el-col :span="24">
            <el-form-item label="Notas">
              <el-input
                v-model="contact.note"
                type="textarea"
                placeholder="Notas"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </ui-bottom-sheet>
</section>
