
<section class="tp-close-repars-by-technician">

  <div
    v-if="data.repaired">
    <div
      v-for="(repair,tec) in data.repaired"
      :key="tec">
      <span class="tech_name"><b>{{ tec }}</b></span>
      <el-table
        v-if="repair.repairs"
        :data="repair.repairs"
        class="rep_table"
        style="width: 100%">

        <el-table-column
          prop="repNum"
          label="#Rep"
          width="180"/>

        <el-table-column
          prop="quotation"
          label="Orçamento"/>

        <el-table-column
          prop="value"
          label="Valor"
          width="180"/>
      </el-table>
    </div>
  </div>


</section>
