<template>
  <header class="navbar ui-header_navbar">
    <div class="ui-header_container container-fluid">
      <div class="ui-header_content">
        <!-- notifications popover -->
        <el-popover
          ref="notificationPopover"
          v-model="notificationsVisible"
          placement="bottom"
          width="350"
          popper-class="ui-notifications_container">
          <notifications @click.native="$refs.notificationIcon.click()"/>
        </el-popover>

        <!-- right content -->
        <div class="ui-header_right">
          <ul class="ui-header_nav">
            <!-- notifications -->
            <li class="notifications">
              <span
                ref="notificationIcon"
                v-popover:notificationPopover
                :class="{ with: notificationUnreadNumber }">
                {{ notificationUnreadNumber }}
              </span>
            </li>

            <!-- user -->
            <li class="userSection">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <!-- avatar image -->
                  <img
                    :src="avatar"
                    class="userAvatar"
                    alt="Avatar" >

                  <!-- first name -->
                  <span class="userFirstName">Olá, {{ firstName }}</span>

                  <!-- arrow -->
                  <i class="el-icon-caret-bottom el-icon-right"/>
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="logout">Terminar Sessão</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>

        <!-- title -->
        <h1 class="title">{{ title }}</h1>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import gravatar from "gravatar";

import NotificationsCmp from "../Notifications.vue";

export default {
  name: "Header",

  components: {
    notifications: NotificationsCmp,
  },

  data() {
    return {
      notificationsVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      "title",
      "notifications",
      "notificationUnreadNumber",
      "user",
    ]),

    avatar() {
      return gravatar.url(this.user.email, { s: "60" });
    },

    firstName() {
      return this.user.shortName;
    },
  },

  methods: {
    ...mapActions(["markNotificationAsRead", "logout"]),
  },
};
</script>

<style>
.ui-header_navbar {
  padding: 0 16px;
  z-index: 100;
  margin-bottom: 0;
  height: 50px;
  background-color: #fafafa;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
}

.ui-header_container {
  width: 100% !important;
  filter: none;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}

/** Content */

.ui-header_content {
  position: relative;
  height: 50px;
}

.ui-header_content .title {
  position: relative;
  padding-right: 20px;
  margin: 0;
  font-size: 19px;
  max-width: 400px;
  display: inline-block;
  line-height: 50px;
  font-weight: normal;
  color: #5c5c5c;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}

/** header nav */
ul.ui-header_nav {
  padding-left: 0;
  list-style: none;
  margin: 9.25px 0;
}

ul.ui-header_nav li {
  position: relative;
  white-space: nowrap;
  display: block;

  float: left;
}

.ui-header_right {
  float: right;
}

/*** user */

.ui-header_content .userAvatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.ui-header_content .userFirstName {
  margin: 0 4px;
}

.ui-header_content .userSection .el-icon-caret-bottom::before {
  font-size: 0.8em;
}

/*** notifications */
.ui-header_content .notifications {
  margin-right: 20px;
}

.ui-header_content .notifications span {
  width: 30px;
  height: 30px;
  display: block;

  padding-top: 5px;

  border-radius: 50%;
  border: 1px solid #475669;

  text-align: center;
  font-size: 0.8em;
  color: #475669;

  transition: all 0.3s ease-out;
}

.ui-header_content .notifications span:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.ui-header_content .notifications span.with {
  background: #f47564;
  color: white;
  border: none;
}

.ui-header_content .notifications span.with:hover {
  background: #ec6654;
}

/** Desktop modifiers */

@media (min-width: 768px) {
  .ui-header_content {
    padding-right: 0;
  }

  .ui-header_nav {
    margin: 0;
  }
}
</style>
