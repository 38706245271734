<template>
  <div class="tp-RepairExternalCreate">
    <div class="tp-RepairExternalCreate__container">
      <!-- current step header -->
      <el-steps
        :space="150"
        :active="currentStep"
        finish-status="success">
        <el-step title="Dispositivo"/>
        <el-step title="Equipamento"/>
        <el-step title="Reparação"/>
        <el-step title="Concluido"/>
      </el-steps>

      <!-- error alert -->
      <el-alert
        v-if="errorMessage !== null"
        :title="errorMessage"
        type="error"/>

      <step1
        v-if="currentStep === 0"
        :repair.sync="repair"
        :equipment.sync="selectedEquipment"
        :client.sync="selectedClient"
        @goStep="value => currentStep = value"
        @setEquipmentType="setEquipmentType"
        @setIdentifier="v => tempIdentifier = v"
        @latestRepair="v => prevRepair = v"/>

      <step2
        v-if="currentStep === 1"
        :temp-identifier="tempIdentifier"
        :repair.sync="repair"
        :equipment-type.sync="selectedEquipmentType"
        :equipment.sync="selectedEquipment"
        :step.sync="currentStep"
        :client.sync="selectedClient"
        :types="equipmentTypes"/>

      <step3
        v-if="currentStep === 2"
        :client="selectedClient"
        :repair.sync="repair"
        :equipment-type="selectedEquipmentType"
        @created="repairCreated"/>

      <step4
        v-if="currentStep === 3"
        :client="selectedClient"
        :equipment-type="selectedEquipmentType"
        :equipment="selectedEquipment"
        :repair="repair"
        :repairs-list="repairsList"
        @newRepair="newRepairSameClientHandler"/>
    </div>
  </div>
</template>

<script lang="ts">
import { IClient } from "@/modules/clients/types/client.interface";
import { IEquipmentType } from "@/modules/repairs/types/equipment-type.interface";
import { IRepair } from "@/modules/repairs/types/repair.interface";
import Vue from "vue";

import Step1 from "../creation/Step1.vue";
import Step2 from "../creation/Step3.vue";
import Step3 from "../creation/Step4.vue";
import Step4 from "../creation/Step5.vue";

export default Vue.extend({
  name: "RepairExternalCreateView",

  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  data() {
    return {
      // auxiliar properties
      currentStep: 0,
      loading: false,
      errorMessage: null,

      // selected client's information
      selectedClient: null as IClient | null,

      // available types of equipments
      equipmentTypes: [] as Array<IEquipmentType>,

      // selected equipment
      tempIdentifier: "",
      selectedEquipmentType: null as IEquipmentType | null,
      selectedEquipment: {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      },

      // repair informations
      repair: {
        clientEquipment: null as string | null,
        warranty: false,
        complaint: false,
        stateObservation: "Sem riscos",
        problemDescription: "",
        repairObservation: "",
        internalObservation: "",
        technician: null,
        priority: "normal",
        attributes: {},
        materials: [],
        log: [],
        quotation: 0,
        storeRepairIdentifier: null,
        phone: "",
        prevRepair: null as string | null,
      },

      prevRepair: null as IRepair | null,

      searchEquipments: {
        brands: [],
        models: [],
        loading: false,
      },

      // array to keep the created repairs
      repairsList: [] as Array<IRepair>,
    };
  },

  watch: {
    selectedEquipmentType(newVal) {
      if (newVal.name === "Telemóvel") {
        this.selectedEquipment.attributes = {
          imei: this.selectedEquipment.serialNumber,
        };
        this.selectedEquipment.serialNumber = "";
      }
    },
  },

  created() {
    // fetch the list of equipments
    // TODO: move to VUEX
    this.fetchEquipmentTypes();

    // fetch client data
    this.fetchClientData();
  },

  methods: {
    /**
     * Get client objecty from the logged user.
     *
     * The user has a personId prop that contains a client id. On this case we can use that since is always a store
     * accessing this component.
     */
    async fetchClientData() {
      const clientId = this.$store.get('user').personId;
      this.selectedClient = (await this.$stellar.action("getClient", {id: clientId})).client
    },

    /**
     * Get all the equipment types.
     *
     * @returns {Promise.<void>}
     */
    async fetchEquipmentTypes() {
      // get the list of fetched equipments
      const { equipments } = await this.$stellar.action("getEquipmentTypes");

      // store them into an array
      this.equipmentTypes = equipments;
    },

    setEquipmentType(id: string) {
      this.selectedEquipmentType = this.equipmentTypes.find(item => item.id === id) || null;
    },

    /**
     * Go back to the equipment type selection, keep the same client and reset
     * the repair information.
     */
    newRepairSameClientHandler() {
      // save the current contact information
      const repairContact = this.repair.phone;

      // reset the equipment type selection and information
      this.selectedEquipmentType = null;
      this.tempIdentifier = "";
      this.selectedEquipment = {
        brand: "",
        model: "",
        serialNumber: "",
        attributes: {},
      };

      // reset the repair form
      this.repair = {
        clientEquipment: null,
        warranty: false,
        complaint: false,
        stateObservation: "",
        problemDescription: "",
        repairObservation: "",
        internalObservation: "",
        technician: null,
        priority: "normal",
        attributes: {},
        materials: [],
        log: [],
        quotation: 0,
        storeRepairIdentifier: null,
        phone: repairContact,
        prevRepair: null
      };

      // go back to step 3
      this.currentStep = 2;
    },

    async repairCreated(repair: any) {
      // create a copy of repair
      const newRepairToPush = Object.assign({}, repair);

      // append the clientEquipment
      newRepairToPush.clientEquipment = this.selectedEquipment;

      // add the repair to equipmentList Array
      this.repairsList.push(JSON.parse(JSON.stringify(newRepairToPush)));

      // go to the next step
      this.currentStep++;
    },

    async makeComplaint() {
      // enable loading
      this.loading = false;

      // copy the needed data for the new repair
      const dataToCopy = ["client", "stateObservation", "problemDescription", "attributes"];
      dataToCopy.forEach(key => {
        (this.repair as any)[key] = (this.prevRepair as any)[key];
      });

      // set prev repair and complaint to true
      this.repair.prevRepair = this.prevRepair?.id || null;
      this.repair.complaint = true;

      if (typeof this.prevRepair?.clientEquipment !== 'string') {
        this.repair.clientEquipment = this.prevRepair?.clientEquipment.id || null;
      }

      // go to step 4
      this.currentStep = 3;
    },
  },
});
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 1em;
  font-size: 1.3em;
}

.tp-RepairExternalCreate__container {
  padding: 20px;
}

.tp-RepairExternalCreate__complaint {
  max-width: 600px;
  margin: 0 auto;

  .el-button {
    width: 100%;
  }
}

.tp-RepairExternalCreate .el-card h2 {
  margin-bottom: 0;

  font-size: 1em;
  line-height: 1em;
}
</style>
