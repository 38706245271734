<template>
  <div class="no-padding-table">
    <!-- dynamic view goes here -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
/** Import Roboto Font Family */
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700";

@import "../../node_modules/patternlock/patternLock.css";

@import "./var.css";

/** make the browser set the correct height **/

html {
  height: 99%;
}

body {
  height: inherit;
  display: flex;
  flex-direction: column;

  font-family: Roboto, "arial", sans-serif;
  color: var(--colors-text-dark);
}

/** fix work break on tables */
.el-table .cell {
  word-break: break-word !important;
}

#app {
  display: flex;
  flex: auto;
  flex-direction: column;
  max-height: 100%;
}

p.is-info {
  color: var(--colors-text-dark);
}

.page-title {
  margin-top: 10px;
  margin-left: 20px;

  font-weight: 300;
  font-size: 1.6em;
}

.noscroll {
  overflow: hidden !important;
}

/*** custom buttons ***********************************************************/

.ui-smallButton {
  padding: 5px 7px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  margin: 0 5px !important;

  transition: 0.3s all ease;

  &:not(.is-disabled) {
    &.is-green {
      background-color: #5cd291;
      color: #ffffff;

      &:hover {
        background-color: #64dc94;
        box-shadow: 0 1px 5px #98f1c0;
      }
    }

    &.is-orange {
      background-color: #ffbd2c;
      color: #ffffff;

      &:hover {
        background-color: #ffc039;
        box-shadow: 0 1px 5px #ffd498;
      }
    }
  }
}

/*** stupid things ************************************************************/

.no-padding-table {
  .el-table .cell,
  .el-table th > div {
    padding: 0;
  }

  .el-table th:first-child > div,
  .el-table td:first-child > div {
    padding-left: 10px;
  }

  .el-table th:last-child > div,
  .el-table td:last-child > div {
    padding-right: 10px;
  }
}

/*** transitions **************************************************************/

/** Sping effect **/
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/** Fade */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/** Slide From Bottom */
.slide-bottom-leave {
  transition: all 0.2s ease;
}
.slide-bottom-leave-active {
  transform: translateY(100%);
}
.slide-bottom-enter-active {
  animation: expandUp-in 0.4s ease;
}

@keyframes expandUp-in {
  0% {
    transform: translateY(100%) scale(0.7) scaleY(0.6);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}
</style>
