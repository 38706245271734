<template>
  <section class="tp-MaterialRequestForm">
    <el-dialog
      ref="dailyRepairList"
      :visible.sync="requestVisibility"
      title="Pedido"
      size="large">
      <!-- actions -->
      <div class="right">
        <el-button @click="requestVisibility=false">Cancelar</el-button>
        <el-button @click="printElem">Imprimir</el-button>
      </div>

      <!-- table that will be used to print -->
      <div id="toPrint">
        <span v-if="requestClient">Cliente: {{ requestClient.name }} ({{ requestClient.phone }})</span>

        <div
          v-if="clientRequests && clientRequests.length>0"
          class="tp-MaterialRequestForm-toPrint">
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Material:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].name }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Quantidade:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].quantity }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Preço unitário:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].price | money }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Sinal:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].guarantee | money }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Valor a pagar:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].quotation | money }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Urgencia:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].urgency }}</span>
          </div>
          <div class="tp-MaterialRequestForm-toPrint__entry">
            <span class="tp-MaterialRequestForm-toPrint__entry-title">Observação:</span>
            <span class="tp-MaterialRequestForm-toPrint__entry-content">{{ clientRequests[0].observation }}</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- edit form for material ---------------------------------------------- -->
    <material-form
      ref="materialForm"
      @created="afterMaterialCreation"
      @canceled="resetVisibility" />

    <!-- form to create a new client -->
    <client-form
      ref="clientPanel"
      @created="afterClientCreation"
      @canceled="resetVisibility" />

    <ui-bottom-sheet
      ref="panel"
      actions>
      <div
        slot="actions"
        class="right">
        <!-- add the material to the request -->
        <el-button
          v-if="!isClient"
          :loading="loading"
          type="primary"
          @click="addToRequestHandler">
          Adicionar
        </el-button>

        <!-- create a new material request -->
        <el-button
          :loading="loading"
          type="success"
          @click="processRequestHandler">
          Requisitar
        </el-button>

        <!-- cancel button -->
        <el-button @click="show(false)">Cancelar</el-button>
      </div>

      <!-- material list ----------------------------------------------------- -->
      <el-table
        v-if="!isClient && requestsToMade.length > 0"
        v-loading.body="loading"
        :data="requestsToMade"
        class="tp-MaterialRequest__table"
        stripe>
        <el-table-column
          :width="170"
          label="Quantidade"
          prop="quantity" />
        <el-table-column
          label="Material"
          prop="name" />
        <el-table-column :width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="warning"
              @click="returnMaterialHandler(scope.row)">
              Remover
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-form
        ref="data"
        :model="data"
        :rules="rules"
        label-position="top" >
        <el-row :gutter="10">
          <!--material -->
          <el-col :span="10">
            <el-form-item
              label="Material"
              prop="material">
              <el-autocomplete
                v-model="tempMaterial"
                :style="{ width: '100%' }"
                :fetch-suggestions="materialRemoteMethod"
                :trigger-on-focus="false"
                placeholder="Material"
                custom-item="material-item"
                @select="materialChangeHandler" />
            </el-form-item>
          </el-col>

          <!-- new material -->
          <el-col :span="2">
            <el-button
              class="tp-MaterialRequest__newMaterial"
              type="primary"
              @click="showMaterialForm">
              Novo
            </el-button>
          </el-col>

          <!-- Client -->
          <!-- <el-col :span="10" v-if="!$auth.is('technician')">      retirado para o tecnico joão ter acessoa ao campo cliente-->
          <el-col :span="10">
            <el-form-item
              label="Cliente"
              prop="client">
              <el-select
                v-model="data.client"
                :disabled="!isClient"
                :remote-method="userRemoveMethod"
                :loading="search.loading"
                label="Cliente"
                filterable
                remote
                class="tp-MaterialRequest__fullFill"
                placeholder="Cliente"
                @change="getClient(search.clients)">
                <el-option
                  v-for="client in search.clients"
                  :key="client.id"
                  :label="client.name + ' (' + client.phone + ')'"
                  :value="client.id" />
              </el-select>
            </el-form-item>
          </el-col>


          <!-- button to create a new client -->
          <el-col :span="2">
            <el-button
              class="tp-MaterialRequest__newMaterial"
              type="primary"
              @click="showClientCreationForm">
              Novo
            </el-button>
          </el-col>

          <!-- urgency -->
          <el-col
            v-if="!$auth.is('technician')"
            :span="12">
            <el-form-item label="Urgencia">
              <el-select
                v-model="data.urgency"
                :style="{ width: '100%' }"
                placeholder="Urgencia"
                class="tp-MaterialRequest__urgency">
                <el-option
                  v-for="type in options"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value" />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- quantity -->
          <el-col :span="12">
            <el-form-item
              label="Quantidade">
              <el-input-number
                v-model="data.quantity"
                :min="1"
                :change="totalQuotation"
                class="tp-MaterialRequest__quantity"/>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- observation -->
        <el-form-item label="Observação">
          <el-input
            v-model="data.observation"
            type="textarea"
            placeholder="observação" />
        </el-form-item>

        <el-row :gutter="10">
          <div v-if="isClient">

            <!-- Guarantee -->
            <el-col :span="8">
              <el-form-item
                label="Preço unitário">
                <el-input
                  v-model="data.price"
                  type="number"
                  class="tp-MaterialRequest__guarantee"/>
              </el-form-item>
            </el-col>

            <!-- Guarantee -->
            <el-col :span="8">
              <el-form-item
                label="Valor de Entrada (sinal)">
                <el-input
                  v-model="data.guarantee"
                  class="tp-MaterialRequest__guarantee" />
              </el-form-item>
            </el-col>


            <!-- Quotation -->
            <el-col :span="8">
              <el-form-item
                label="Valor a Pagar">
                <el-input
                  v-model="data.quotation"
                  value="data.quotation"
                  class="tp-MaterialRequest__quotation"
                  readonly/>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </el-form>
    </ui-bottom-sheet>
  </section>
</template>

<script>
import Vue from "vue";

import { printHTML } from "@/utils/print-html";

import MaterialFormCmp from "../material/MaterialForm.vue";
import ClientForm from "../client/ClientForm.vue";

// function to create a well formated material name
const formatMaterialName = material => {
  let out = material.description;

  if (material.brand.length > 0) {
    out += `, ${material.brand}`;
  }

  if (material.model.length > 0) {
    out += ` ${material.model}`;
  }

  return out;
};

// this var will store the search timer
let timer = null;

Vue.component("material-item", {
  functional: true,

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  render(h, ctx) {
    const item = ctx.props.item;

    // build the correct
    const label = formatMaterialName(item);

    return h("li", ctx.data, [
      h("div", { attrs: { class: "value" } }, [label]),
    ]);
  },
});

export default {
  name: "MaterialRequestForm",

  components: {
    "material-form": MaterialFormCmp,
    ClientForm,
  },

  data() {
    return {
      requestVisibility: false,
      tempMaterial: "",
      selectedMaterial: "",
      loading: false,

      // form data
      data: {
        quantity: 1,
        observation: "",
        urgency: "normal",
        material: null,
        client: null,
        quotation: 0,
        guarantee: 0,
        price: 0,
      },
      isClient: false,
      rules: {
        material: [
          {
            type: "string",
            required: true,
            message: "Selecione o material",
            trigger: "none",
          },
        ],
      },

      // serach data
      search: {
        clients: [],
        materials: [],
        loading: false,
      },

      // list of requestes
      requestsToMade: [],
      clientRequests: [],

      clientName: "",
      // data from client to print receipt
      tempClient: null,
      requestClient: null,

      // urgency states
      options: [
        {
          value: "normal",
          label: "Normal",
        },
        {
          value: "one_week",
          label: "Uma semana",
        },
        {
          value: "three_days",
          label: "Três dias",
        },
        {
          value: "next_day",
          label: "Dia seguinte",
        },
      ],
    };
  },

  computed: {
    missingValue() {
      return (
        this.clientRequests[0].quotation - this.clientRequests[0].guarantee
      );
    },
    getUrgency() {
      if (this.clientRequests[0].urgency === "one_week") {
        return "Uma semana";
      }
      if (this.clientRequests[0].urgency === "three_days") {
        return "Três dias";
      }
      if (this.clientRequests[0].urgency === "next_day") {
        return "Dia seguinte";
      } else {
        return "Normal";
      }
    },
  },

  watch: {
    data() {
      this.userRemoveMethod();
    },

    "data.price"(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.totalQuotation();
    },

    "data.quantity"(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.totalQuotation();
    },

    "data.guarantee"(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.totalQuotation();
    },
  },

  methods: {
    getClient(clients) {
      this.tempClient = clients.find(item => item.id === this.data.client);
    },

    enableClientValidation() {
      this.rules.client = {
        type: "string",
        required: true,
        message: "Selecione o cliente",
        trigger: "blur",
      };
    },

    disableClientValidation() {
      delete this.rules.client;
    },

    /**
     * Handle the material creation.
     *
     * We must fill in the form with the new material data.
     */
    afterMaterialCreation(newMaterial) {
      // HACK: add the new material to the list of searched items
      this.search.materials = [newMaterial];

      // select the created material
      this.data.material = newMaterial.id;

      // reset visibility
      this.resetVisibility();
    },

    /**
     * Open a new panel with the client form.
     */
    showClientCreationForm() {
      // get the client panel
      const panel = this.$refs.clientPanel;

      // if the filled data on the prev input field can be helpfull for the
      // client creation use it
      panel.setData(this.data.client);

      // open the panel
      panel.show();

      // for now we need hide this manually
      this.$refs.panel.show(false);
    },

    /**
     * Set the client after the action.
     *
     * @param client Client to save.
     */
    async afterClientCreation(client) {
      this.search.clients = [client];
      this.data.client = client.id;
      this.resetVisibility();
    },

    /**
     * Reset this panel visibility.
     */
    resetVisibility() {
      this.$refs.panel.show(true);
    },

    /**
     * When the material change we must store the whole object.
     */
    materialChangeHandler(material) {
      this.selectedMaterial = material;
      this.data.material = material._id;
    },

    /**
     * Search for materials on the API.
     *
     * @param query query to searching for.
     */
    materialRemoteMethod(query, cb) {
      // cancel any defined timer
      clearTimeout(timer);

      // cleanup the sugestion above 2 characters
      if (query.length < 2) {
        return cb([]);
      }

      // search for materials
      timer = setTimeout(_ => {
        this.$stellar
          .action("searchMaterials", { query })
          .then(({ materials }) => {
            // define a value field for each sugestion
            materials = materials.map(item => {
              item.value = formatMaterialName(item);
              return item;
            });

            cb(materials);
          });
      }, 700);
    },

    /**
     * Remove one material from the list of requests to made.
     */
    returnMaterialHandler(item) {
      const index = this.requestsToMade.findIndex(
        e => e.materialId === item.materialId,
      );
      this.requestsToMade.splice(index, 1);
    },

    /**
     * Search for users on the server.
     *
     * @param  {[type]} query [description]
     * @return {[type]}       [description]
     */
    async userRemoveMethod(query) {
      if (!query || query.length <= 2) {
        this.search.clients = [];
        return;
      }

      // enable loading
      this.search.loading = true;

      // search for clients
      await this.$stellar
        .action("searchForClients", { query })
        .then(({ clients }) => {
          // disable loading
          this.search.loading = false;

          // save the found clients
          this.search.clients = clients;
        });
    },

    /**
     * Define the visibility.
     *
     * @type {Boolean} val component visibility.
     */
    show(val = true, isClient = false) {
      this.$refs.panel.show(val);
      this.isClient = isClient;

      if (this.isClient) {
        this.enableClientValidation();
      } else {
        this.disableClientValidation();
      }

      if (!val) {
        this.reset();
      }
    },

    setMaterial(material) {
      this.data.material = material.id;
      this.selectedMaterial = material;
      this.tempMaterial = `${material.description}, ${material.brand} ${
        material.model
      }`;
    },

    /**
     * Show a form to create a new material.
     */
    showMaterialForm() {
      // get the material form panel
      const panel = this.$refs.materialForm;

      // set the execution mode
      panel.enableSimpleMode();

      // show the material form
      panel.show();

      // for now we need hide this manually
      this.$refs.panel.show(false);
    },

    /**
     * Only reset the form data.
     */
    resetForm() {
      this.data = {
        quantity: 1,
        observation: "",
        urgency: "normal",
        material: null,
        client: null,
        quotation: 0,
        guarantee: 0,
      };
      this.tempMaterial = "";
      this.selectedMaterial = null;
      this.$refs.data.resetFields();
    },

    /**
     * Reset the form data.
     */
    reset() {
      this.resetForm();
      this.loading = false;
      this.search.clients = [];
      this.search.materials = [];
      this.search.loading = false;

      this.requestsToMade = [];
    },

    /**
     * Add the selected material to the request set.
     */
    addToRequestHandler() {
      this.$refs.data.validate(valid => {
        if (!valid) {
          return false;
        }

        // push the material to the set of request
        this.requestsToMade.push({
          material: this.data.material,
          name: formatMaterialName(this.selectedMaterial),
          observation: this.data.observation,
          quantity: this.data.quantity,
          urgency: this.data.urgency,
          quotation: this.data.quotation,
          guarantee: this.data.guarantee,
          price: this.data.price,
        });
        this.data.quotation += this.selectedMaterial.price;
        this.clientRequests = this.requestsToMade;
        this.requestClient = this.tempClient;

        // store the client in onder to reset it after the form data reset
        const client = this.data.client;
        this.clientName = this.data.client;

        // reset the form data
        this.resetForm();

        // restore the client
        this.data.client = client;
      });
    },

    async makeServerRequest() {
      await this.$stellar.action("createMaterialrequest", {
        requests: this.requestsToMade,
        client: this.data.client,
      });

      this.$message.success("O pedido foi criado.");
      this.$emit("created");
      this.show(false);
      this.requestVisibility = true;
    },

    /**
     * Create a new request for the selected material.
     */
    async processRequestHandler() {
      if (!this.isClient && this.requestsToMade.length === 0) {
        return this.$message.info("Sem pedidos a fazer.");
      }

      try {
        await this.$confirm(
          "Pretende finalizar o pedido?",
          "Finaliazar pedido",
          {
            confirmButtonText: "Finalizar",
            cancelButtonText: "Ainda não",
            type: "warning",
          },
        );
      } catch (e) {
        return;
      }

      // when is to create a material request for a client, the add button isn't
      // visible. So we need to emulate the click on that button before keep going
      // if the process of the request.
      if (this.isClient) {
        this.addToRequestHandler();
      }

      await this.makeServerRequest();
    },

    makeRequest() {
      this.loading = true;
      // hide the error alert
      this.errorMessage = null;

      // check if there is at least one material to create request
      if (this.requests.length === 0) {
        return this.noMaterialToAdd();
      }

      if (
        this.data.selectedMaterial !== null ||
        this.data.observation !== "" ||
        this.data.client !== null
      ) {
        return this.MaterialToAdd();
      }
      // create a new material request
      this.$stellar
        .action("createMaterialrequest", {
          client: this.data.client,
          requests: this.requests,
        })

        // close this panel
        .then(_ => {
          this.show(false);
          this.loading = false;
        })
        // catch API erros
        .catch(_ => {
          this.$refs.error.show();
        });

      this.requests = [];
    },
    cancelForm() {
      this.loading = false;
      this.show(false);
    },

    printElem() {
      const printStyle = `
          .tp-MaterialRequest__toPrint > span {
            display: block;
            padding: 5px 0;
          }
          .tp-MaterialRequest__toPrint > span:first-child {
            padding-top: 0;
          }

          .tp-MaterialRequest__toPrint > span:last-child {
            padding-bottom: 0;
          }`;

      const contents = document.getElementById("toPrint").innerHTML;
      printHTML(contents, printStyle, "");

      this.requestVisibility = false;
    },

    totalQuotation() {
      this.data.quotation = (
        this.data.quantity * this.data.price -
        this.data.guarantee
      ).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.tp-MaterialRequest {
  &__newMaterial {
    margin-top: 31px;
  }

  &__quantity {
    width: 100% !important;
    display: block;
  }

  &__table {
    margin-bottom: 10px;
  }

  &__fullFill {
    width: 100%;
    display: block;
  }
}

.receipt span {
  display: block;
  margin-top: 10px;
}
.line span {
  display: inline;
  margin-right: 10px;
  margin-top: 15px;
}
</style>
