import { Module } from "vuex";
import { INavigationState } from "@/services/store/modules/navigation/navigation-types";
import { IStoreRootState } from "@/services/store/types/store-root-state.interface";

import { state } from "./navigation-state";
import { mutations } from "./navigation-mutations";
import { actions } from "./navigation-actions";

export const navigationModule: Module<INavigationState, IStoreRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
