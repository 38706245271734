<template>
  <section class="tp-RepairForm__step2">
    <!-- client panel -->
    <client-form
      ref="clientPanel"
      @updated="afterClientUpdate"/>

    <!-- client select card -->
    <el-card class="tp-RepairForm__step2__card">
      <client-search
        can-create
        @change="val => $emit('update:client', val)"/>
    </el-card>

    <!-- client card -->
    <client-card :client="client">
      <div slot="actions">
        <!-- edit -->
        <el-button
          type="info"
          @click="editClientHandler">
          Editar
        </el-button>

        <!-- use it -->
        <el-button
          class="tp-ClientCard__useBtn"
          type="success"
          @click="useClientHandler">
          Usar
        </el-button>
      </div>
    </client-card>
  </section>
</template>

<script>
import ClientSearch from "../../client/ClientSearchInput.vue";
import ClientCard from "../../client/ClientCard.vue";
import ClientForm from "../../client/ClientForm.vue";

export default {
  components: {
    ClientSearch,
    ClientCard,
    ClientForm,
  },

  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
    repair: {
      type: Object,
      default: () => ({}),
    },
    equipment: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      originalClientId: null,
    };
  },

  created() {
    this.originalClientId = this.client ? this.client.id : null;
  },

  methods: {
    /**
     * Save the updated client information.
     */
    afterClientUpdate(client) {
      this.$emit("update:client", client);
    },

    /**
     * Edit information of the selected user.
     */
    editClientHandler() {
      // get the edit panel
      const panel = this.$refs.clientPanel;

      // set the client to be edited
      panel.setClient(this.client);

      // show edit panel
      panel.show();
    },

    /**
     * Go to the next step.
     */
    async useClientHandler() {
      const _repair = this.repair;

      if (_repair.clientEquipment && this.originalClientId !== this.client.id) {
        try {
          // ask user if really wants update the client on the equipment
          await this.$confirm(
            "Pretende alterar o cliente do equipamento?",
            "Alterar Cliente",
            {
              confirmButtonText: "Alterar",
            },
          );

          // enable loading
          const loadingInstance = this.$loading({ fullscreen: true });

          // update the equipment
          const _equipment = this.equipment;
          _equipment.client = this.client.id;
          _equipment.id = _equipment._id;
          await this.$stellar.action("editClientequipment", _equipment);

          // disable loading
          loadingInstance.close();
        } catch (e) {
          return;
        }
      }

      // set client on the repair object
      _repair.client = this.client.id;

      // set the contact phone
      _repair.phone = this.client.phone;

      // emit the repair update
      this.$emit("update:repair", _repair);

      // if the equipment already exists go to step 4, otherwise go to 3
      if (_repair.clientEquipment) {
        this.$emit("goStep", 3);
      } else {
        this.$emit("goStep", 2);
      }
    },
  },
};
</script>

<style lang="scss">
.tp {
  &-RepairForm {
    &__step2 {
      margin-top: 70px;

      &__card {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }

  &-ClientCard {
    &__useBtn {
      float: right;
    }
  }
}
</style>
