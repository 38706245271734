import { getStore } from "@/services/store";
import VueRouter, { Route } from "vue-router";

/**
 * Before each transition replace the page title on the store.
 */
export function registerPageTitleMiddleware(router: VueRouter): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router.beforeEach(async (to: Route, from: Route, next: any) => {
    const store = await getStore();

    // build the new title
    let newTitle = null;

    // TODO: add support for multilevel and add a link in that case
    to.matched.forEach(item => {
      newTitle = item.meta.title;
    });

    if (!newTitle) {
      newTitle = "Tecniphone";
    }

    // replace the title on the store
    store.dispatch("setTitle", newTitle);

    next();
  });
}
